/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

//import images
import placeholderImage from "assets/CourseDetailsAssests/images/blank-img.jpg";
import blankLesson from "assets/CourseAssets/svg/lesson-empty.svg";
import defaultUser from "assets/HomeAssets/Images/default-user.png";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* ----------------------------------------------------------------------------------- */

const Teams = () => {
  // saving user token here
  const token = localStorage.getItem("token");

  // 👇️ get ID from url
  const params = useParams();

  const { t } = useTranslation();

  const glowCount = [1, 2, 3, 4];

  // Initial useState to save all the incoming data from API.
  const [teamMemberList, setTeamMemberList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Function to call the API and get all the data
  const getJobDetailsData = async (id) => {
    setIsLoading(true);
    let requestURL =
      url.API_BaseUrl +
      url.API_CHALLENGES_DETAILS_TEAM_TAB +
      `?token=${token}` +
      `&cid=${id}`;

    try {
      const response = await getData(requestURL);

      if (response.status) {
        if (response.data) {
          if (response.data.members) {
            setTeamMemberList(response.data.members);
          } else {
            setTeamMemberList([]);
          }
        } else {
          setTeamMemberList([]);
        }
        setIsLoading(false);
      } else {
        setTeamMemberList([]);
      }
    } catch (error) {}
  };

  // useEffect from here
  useEffect(() => {
    getJobDetailsData(params.id);
  }, []);

  return (
    <>
      {isLoading ? (
        // ----------------------- Placeholder starts here -----------------------
        <div className="course_detailspnl">
          <div className="member_box_outer">
            {/* <div className="contnt_hdng tab_Hdng">
              <span
                className="placeholder rounded-3 col-2"
                style={{ height: "22px" }}
              ></span>
            </div> */}

            <div className="member_box_outer">
              <div className="row">
                {glowCount.map((count) => {
                  return (
                    <div className="col-lg-3 col-sm-6" key={count}>
                      <div className="expertbx_item members_teamItem">
                        <div
                          className="membrs_teamOuter"
                          style={{ minHeight: "85px" }}
                        >
                          <span
                            className="placeholder rounded-circle"
                            style={{ width: "40px", height: "40px" }}
                          ></span>
                          <div className="desc flex-fill ps-3">
                            <span
                              className="placeholder d-block rounded-3 col-8 mb-3"
                              style={{ height: "20px" }}
                            ></span>
                            <span className="placeholder placeholder-sm d-block rounded-3 col-6 mb-3"></span>
                            <span className="placeholder placeholder-sm d-block rounded-3 col-5"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          {/* <div className="member_box_outer">
            <div className="contnt_hdng tab_Hdng">
              <span
                className="placeholder rounded-3 col-2"
                style={{ height: "22px" }}
              ></span>
            </div>
            <div className="member_box_outer">
              <div className="row">
                <div className="col-lg-3 col-sm-6">
                  <div className="expertbx_item members_teamItem">
                    <div
                      className="membrs_teamOuter"
                      style={{ minHeight: "85px" }}
                    >
                      <span
                        className="placeholder rounded-circle"
                        style={{ width: "40px", height: "40px" }}
                      ></span>
                      <div className="desc flex-fill ps-3">
                        <span
                          className="placeholder d-block rounded-3 col-8 mb-3"
                          style={{ height: "20px" }}
                        ></span>
                        <span className="placeholder placeholder-sm d-block rounded-3 col-6 mb-3"></span>
                        <span className="placeholder placeholder-sm d-block rounded-3 col-5"></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="expertbx_item members_teamItem">
                    <div
                      className="membrs_teamOuter"
                      style={{ minHeight: "85px" }}
                    >
                      <span
                        className="placeholder rounded-circle"
                        style={{ width: "40px", height: "40px" }}
                      ></span>
                      <div className="desc flex-fill ps-3">
                        <span
                          className="placeholder d-block rounded-3 col-8 mb-3"
                          style={{ height: "20px" }}
                        ></span>
                        <span className="placeholder placeholder-sm d-block rounded-3 col-6 mb-3"></span>
                        <span className="placeholder placeholder-sm d-block rounded-3 col-5"></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="expertbx_item members_teamItem">
                    <div
                      className="membrs_teamOuter"
                      style={{ minHeight: "85px" }}
                    >
                      <span
                        className="placeholder rounded-circle"
                        style={{ width: "40px", height: "40px" }}
                      ></span>
                      <div className="desc flex-fill ps-3">
                        <span
                          className="placeholder d-block rounded-3 col-8 mb-3"
                          style={{ height: "20px" }}
                        ></span>
                        <span className="placeholder placeholder-sm d-block rounded-3 col-6 mb-3"></span>
                        <span className="placeholder placeholder-sm d-block rounded-3 col-5"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      ) : (
        // ----------------------- Placeholder ends here -----------------------
        <div className="course_detailspnl">
          {/* ----- HR round ----- */}

          {teamMemberList.length === 0 ? (
            <div className="member_box_outer">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="empty_lessonsArea">
                    <div className="empty_lessonpic">
                      <img src={blankLesson} alt="blanklesson" />
                    </div>
                    <div className="empty_lessons_text">
                      <h2>{t("Sorry....!")}</h2>
                      <p>
                        {t("Team members are not available for this moment.")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="member_box_outer">
              {/* <div className="contnt_hdng tab_Hdng">
                <h4>Team member</h4>
              </div> */}
              <div className="row">
                {teamMemberList.map((member, index) => {
                  return (
                    <div className="col-lg-3 col-sm-6" key={index}>
                      <div className="expertbx_item members_teamItem">
                        <div className="membrs_teamOuter">
                          <div className="team_membrs_pic">
                            <img
                              src={
                                member.image == null
                                  ? placeholderImage
                                  : member.image
                              }
                              alt="MemberPic"
                            />
                          </div>
                          <div className="membrs_team_details">
                            <div className="team_name">
                              <h3>{member.name}</h3>
                              <h5>{member.position}</h5>
                              <h5 className="mt-1">{member.role}</h5>
                            </div>
                            <div className="team_rating star_color ">
                              <h3>
                                <i className="material-icons-outlined">star</i>
                                <span>{member.rating}</span>
                              </h3>

                              <h6>
                                {member.reviews} <span>({t("reviews")})</span>
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {/* ----- HR round end ----- */}
        </div>
      )}
    </>
  );
};

export default Teams;
