import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Breadcrumb = () => {
  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  return (
    <section id="breadcrumbs_container" className="bg-info py-3">
      <div className="container">
        <ul className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="#">{t("Home")}</Link>
          </li>

          <li className="breadcrumb-item active text-yellow">
            <span>{t("Terms and Conditions")}</span>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Breadcrumb;
