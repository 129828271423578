// import components

/* ------------- Public Routes Components ---------------- */
import Home from "pages/Home/Home";
import Login from "pages/Login/Login";
import SignUp from "pages/SignUp/SignUp";
import Feedback from "pages/Feedback/Feedback";

/* ------------- Protected Routes Components ---------------- */
import Logout from "pages/Login/Logout";
import Activity from "pages/Activity/Activity";
import CourseList from "pages/CourseList/CourseList";
import CourseDetails from "pages/CourseDetails/CourseDetails";
import ConversationScreen from "pages/Conversation/Conversation";
import Challenges from "pages/Challenges/Challenges";
import ChallengesDetails from "pages/ChallengesDetails/ChallengesDetails";
import Champions from "pages/Champions/Champions";
import ChampionsDetails from "pages/ChampionsDetails/ChampionsDetails";
import Events from "pages/Events/Events";
import EventsDetails from "pages/EventsDetails/EventsDetails";
import Meeting from "pages/Calendar/Meeting";
import Groups from "pages/Groups/Groups";
import GroupDetails from "pages/GroupDetails/GroupDetails";
import Invitation from "pages/Invitation/Invitation";
import Search from "pages/Search/Search";
import Blog from "pages/Blog/Blog";
import BlogDetails from "pages/BlogDetails/BlogDetails";
import Legal from "pages/Legal/Legal";
import Contact from "pages/Contact/Contact";
import Terms from "pages/Terms&Conditions/Terms";
import PrivacyPolicy from "pages/PrivacyPolicy/PrivacyPolicy";
import CourseTrainingDetails from "pages/CourseTrainingDetails/CourseTrainingDetails";

/*---- routes for non authenticated users ----*/
const publicRoutes = [
  { path: "/", component: Home },
  { path: "/login", component: Login },
  { path: "/signup", component: SignUp },
  { path: "/survey/feedback", component: Feedback },
  { path: "/legal-terms-condition", component: Legal },
  // path for contact-us
  { path: "/contact-us", component: Contact },
  { path: "/terms-conditions", component: Terms },
  { path: "/privacy-policy", component: PrivacyPolicy },

  //path for blog
  { path: "/blogs", component: Blog },

  // path for blog details
  { path: "/blog/:postname", component: BlogDetails },

  // ----------------------- ADD ALL LINKS ABOVE THIS LINE ____________________________
];

/*---- routes for authenticated users ----*/
const protectedRoutes = [
  { path: "/logout", component: Logout },
  { path: "/activity", component: Activity },

  // path for course list module
  { path: "/courses", component: CourseList },

  // path for course details components, id will be added dynamically later.
  { path: "/course/details/:id", component: CourseDetails },

  // path for course training details components, id will be added dynamically later.
  { path: "/coursetraining/details/:id", component: CourseTrainingDetails },

  // path for conversation (chat) module
  { path: "/conversation", component: ConversationScreen },

  // path for challenges module
  { path: "/challenges", component: Challenges },

  // path for challenges details module
  { path: "/challenges/details/:id", component: ChallengesDetails },

  // path for champions kodule
  { path: "/champions", component: Champions },

  // path for champions details module
  { path: "/champions/details/:id", component: ChampionsDetails },

  // path for events module
  { path: "/events", component: Events },

  // path for events details module
  { path: "/events/details/:id", component: EventsDetails },

  // path for group module
  { path: "/groups", component: Groups },

  // path for group details components, id will be added dynamically later.
  { path: "/group/details/:id", component: GroupDetails },

  // path for temporary calendar module
  { path: "/calendar", component: Meeting },

  // path for my inviation
  { path: "/myinvitations", component: Invitation },

  // path for search
  { path: "/search", component: Search },

  // ----------------------- ADD ALL LINKS ABOVE THIS LINE ____________________________
];

export { publicRoutes, protectedRoutes };
