import React from "react";

//import background image
import graphBanner from "assets/BlogAssets/images/graph-bnr.jpg";

import { useTranslation } from "react-i18next";

const Banner = () => {
  const { t } = useTranslation();
  return (
    <section
      className="graph_bnr_area bg_area"
      style={{ backgroundImage: `url(${graphBanner})` }}
    >
      <div className="container">
        <div className="bnr_graph_textPnl">
          <h1>
            <span>{t("News and articles")} </span> {t("Indra ATM Ecosystem")}
          </h1>
        </div>
      </div>
    </section>
  );
};

export default Banner;
