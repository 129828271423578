/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useTranslation } from "react-i18next";
import { GlobalIndraInfo } from "App";

// import Gateway for API fetch call
import { getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* --------------------------------------------------------------*/

// import images here
import blankImage from "assets/CourseListAssets/images/blank-img.jpg";
import blankLesson from "assets/CourseAssets/svg/lesson-empty.svg";

const HomeCourseLists = () => {
  const [courseLists, setCourseLists] = useState([]); // initial for course lists
  // Initial loading status
  const [isLoading, setIsLoading] = useState(false);

  const dummyCourseList = [
    {
      address: null,
      city: null,
      email: "",
      fullimage: null,
      id: 1,
      image: null,
      position: null,
      name: "",
      surname: "",
      rating: 0,
      hideButton: true,
    },
    {
      address: null,
      city: null,
      email: "",
      fullimage: null,
      id: 2,
      image: null,
      position: null,
      name: "",
      surname: "",
      rating: 0,
      hideButton: true,
    },
    {
      address: null,
      city: null,
      email: "",
      fullimage: null,
      id: 3,
      image: null,
      position: null,
      name: "",
      surname: "",
      rating: 0,
      hideButton: true,
    },
    {
      address: null,
      city: null,
      email: "",
      fullimage: null,
      id: 4,
      image: null,
      position: null,
      name: "",
      surname: "",
      rating: 0,
      hideButton: true,
    },
  ];

  const { t } = useTranslation();
  // for routing
  const history = useHistory();

  const glowCount = [1, 2, 3];

  // saving user token here
  const token = localStorage.getItem("token");

  //resonsive properties for react multi carousel
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  // function to get all course lists
  const getAllCourseLists = async () => {
    setIsLoading(true);
    try {
      let requestURL =
        url.API_BaseUrl + url.API_COURSE_LIST + `?token=${token}&page=1`;

      const response = await getData(requestURL);

      if (response.status) {
        if (response.data) {
          if (response.data.length <= 3) {
            let courseResultData = response.data;
            let newCourseArr = courseResultData.concat(dummyCourseList);
            setCourseLists(newCourseArr);
          } else {
            setCourseLists(response.data);
          }
        } else {
          setCourseLists([]);
        }
        setIsLoading(false);
      }
    } catch (error) {}
  };

  // function to go to the details page
  const goToCourseDetails = (e, id, isTraining) => {
    e.preventDefault();
    if (isTraining) {
      history.push(`/coursetraining/details/${id}`);
    } else {
      history.push(`/course/details/${id}`);
    }
  };

  // useeeffect for get all course lists
  useEffect(() => {
    getAllCourseLists();
  }, []);

  return (
    <section className="course_area">
      <div className="container">
        <div className="slideHdng_outer">
          <div className="page_hdng">
            <h2>{t("Best Courses")}</h2>
          </div>
          <div className="see_moreBtn">
            <Link to="/courses" className="border_btn ylwbrdr_btn">
              {t("See All")}
            </Link>
          </div>
        </div>
        {/* check the the loading condition */}
        {isLoading ? (
          <div className="slide_Outer">
            <div className="course_slider slider_innr">
              <Carousel ssr responsive={responsive}>
                {glowCount.map((count) => {
                  return (
                    <div className="slide_Item mb-4" key={count}>
                      <div className="course_item">
                        <div className="champions_pic">
                          <img src={blankImage} alt="blankImage" />
                        </div>
                        <div className="plan p-3 border rounded-3 placeholder-glow">
                          <div className="user_placeHoledr">
                            <div className="default_usr_name">
                              <span className="placeholder placeholder-sm col-3 rounded-3"></span>
                              <span className="placeholder placeholder-sm col-3 rounded-3"></span>
                            </div>
                          </div>
                          <div className="data_btnOuter">
                            <span className="placeholder placeholder-sm col-12 rounded-3 m-0"></span>
                          </div>
                          <p className="data_btnOuter placeholder-glow">
                            <span className="placeholder placeholder-sm col-3 rounded-3 ml-0"></span>
                            <span className="placeholder placeholder-sm col-3 rounded-3"></span>
                            <span className="placeholder placeholder-sm col-3 rounded-3"></span>
                          </p>
                          <div className="data_btnOuter">
                            <span className="placeholder placeholder-sm col-12 rounded-3 m-0"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Carousel>
            </div>
          </div>
        ) : (
          <div className="slide_Outer">
            {/* check the the courselists condition */}
            {courseLists.length > 0 && (
              <div className="course_slider slider_innr">
                {/* check the the courselists condition greater then 10 or not */}
                {courseLists.length < 10 ? (
                  <Carousel ssr showDots responsive={responsive}>
                    {courseLists.map((course, index) => {
                      return (
                        <div className="slide_Item mb-4" key={index}>
                          {course.hideButton ? (
                            <div className="course_item">
                              <div className="champions_pic">
                                <img src={blankImage} alt="blankImage" />
                              </div>
                              <div className="plan p-3 border rounded-3 placeholder-glow">
                                <div className="user_placeHoledr">
                                  <div className="default_usr_name">
                                    <span className="placeholder placeholder-sm col-3 rounded-3"></span>
                                    <span className="placeholder placeholder-sm col-3 rounded-3"></span>
                                  </div>
                                </div>
                                <div className="data_btnOuter">
                                  <span className="placeholder placeholder-sm col-12 rounded-3 m-0"></span>
                                </div>
                                <p className="data_btnOuter placeholder-glow">
                                  <span className="placeholder placeholder-sm col-3 rounded-3 ml-0"></span>
                                  <span className="placeholder placeholder-sm col-3 rounded-3"></span>
                                  <span className="placeholder placeholder-sm col-3 rounded-3"></span>
                                </p>
                                <div className="data_btnOuter">
                                  <span className="placeholder placeholder-sm col-12 rounded-3 m-0"></span>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <>
                              {course.istraining ? (
                                <div className="course_item">
                                  <div className="course_blue_bx">
                                    <div className="champions_textpnl">
                                      <div className="course_hdngauthor">
                                        <h4>{course.moderator}</h4>
                                        <div className="chappions_rating">
                                          <Link to="#">
                                            <i className="material-icons-outlined">
                                              star
                                            </i>
                                            <span>{course.rating}</span>
                                            <em>({course.reviewcount})</em>
                                          </Link>
                                        </div>
                                      </div>
                                      <div className="leftchampion_text">
                                        <h3>{course.label}</h3>
                                      </div>
                                      {/* ------- Course Categories list section ------ */}
                                      <div className="experts_skill">
                                        <ul>
                                          {course.categories.map((item) => (
                                            <li key={item.id}>
                                              <Link to="#">
                                                <span>{item.label}</span>
                                              </Link>
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="traing_listbx">
                                    <div className="traning_list_inr">
                                      {course.childcourses.length ===
                                      0 ? null : (
                                        <div className="card_traing_outer">
                                          {course.childcourses
                                            .slice(0, 3)
                                            .map((courseName, index2) => {
                                              return (
                                                <div
                                                  className="card_training_row"
                                                  key={index2}
                                                >
                                                  <div className="card_traning_left">
                                                    <span className="material-icons-outlined">
                                                      check_circle{" "}
                                                    </span>
                                                    <p>
                                                      {courseName.label.slice(
                                                        0,
                                                        42
                                                      )}
                                                    </p>
                                                  </div>
                                                  {/* <div className="card_traning_right">
                                              <h4>78Hr</h4>
                                            </div> */}
                                                </div>
                                              );
                                            })}
                                        </div>
                                      )}

                                      {course.childcourses.length ===
                                      0 ? null : (
                                        <div className="add_course">
                                          {course.childcourses.length <=
                                          3 ? null : (
                                            <Link to="#">
                                              +{course.childcourses.length - 3}{" "}
                                              Course
                                            </Link>
                                          )}
                                        </div>
                                      )}
                                    </div>

                                    {/* ------- Course details section ------ */}
                                    <div className="details_btn">
                                      <Link
                                        to="#"
                                        className="basic_btn ylw_btn"
                                        onClick={(e) =>
                                          goToCourseDetails(
                                            e,
                                            course.id,
                                            course.istraining
                                          )
                                        }
                                      >
                                        {t("See Details")}
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="course_item">
                                  <div className="champions_pic">
                                    <img
                                      src={
                                        course.image ? course.image : blankImage
                                      }
                                      alt=""
                                    />
                                  </div>
                                  <div className="champions_textpnl">
                                    <div className="course_hdngauthor">
                                      <h4>{course.moderator}</h4>
                                      <div className="chappions_rating">
                                        <Link to="#">
                                          <i className="material-icons-outlined">
                                            star
                                          </i>
                                          <span>{course.rating}</span>
                                          {course.reviewcount > 0 ? (
                                            <em>({course.reviewcount})</em>
                                          ) : null}
                                        </Link>
                                      </div>
                                    </div>
                                    <div className="leftchampion_text">
                                      <h3>{course.label}</h3>
                                    </div>
                                    <div className="experts_skill">
                                      {course.categories.length === 0 ? null : (
                                        <ul>
                                          {course.categories.map((item) => (
                                            <li key={item.id}>
                                              <Link to="#">
                                                <span>{item.label}</span>
                                              </Link>
                                            </li>
                                          ))}
                                        </ul>
                                      )}
                                    </div>

                                    <div className="details_btn">
                                      <Link
                                        to="#"
                                        className="basic_btn ylw_btn"
                                        onClick={(e) =>
                                          goToCourseDetails(
                                            e,
                                            course.id,
                                            course.istraining
                                          )
                                        }
                                      >
                                        {t("See Details")}
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      );
                    })}
                  </Carousel>
                ) : (
                  <Carousel ssr showDots responsive={responsive}>
                    {courseLists.slice(0, 9).map((course, index) => {
                      return (
                        <div className="slide_Item mb-4" key={index}>
                          <div className="course_item">
                            <div className="champions_pic">
                              <img src={course.image} alt="" />
                            </div>
                            <div className="champions_textpnl">
                              <div className="course_hdngauthor">
                                <h4>{course.moderator}</h4>
                                <div className="chappions_rating">
                                  <Link to="#">
                                    <i className="material-icons-outlined">
                                      star
                                    </i>
                                    <span>{course.rating}</span>
                                    {course.reviewcount > 0 ? (
                                      <em>({course.reviewcount})</em>
                                    ) : null}
                                  </Link>
                                </div>
                              </div>
                              <div className="leftchampion_text">
                                <h3>{course.label}</h3>
                              </div>
                              <div className="experts_skill">
                                {course.categories.length === 0 ? null : (
                                  <ul>
                                    {course.categories.map((item) => (
                                      <li key={item.id}>
                                        <Link to="#">
                                          <span>{item.label}</span>
                                        </Link>
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              </div>

                              <div className="details_btn">
                                <Link
                                  to="#"
                                  className="basic_btn ylw_btn"
                                  onClick={(e) =>
                                    goToCourseDetails(e, course.id)
                                  }
                                >
                                  {t("See Details")}
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Carousel>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default HomeCourseLists;
