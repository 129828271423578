/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { GlobalIndraInfo } from "App";
import { useTranslation } from "react-i18next";

// importing images here
import sample from "assets/FeedbackAssets/images/pierre-gagnaire.jpg";
import coursePic1 from "assets/EventsAssets/images/course-pic1.jpg";
import blankGroup from "assets/GroupAssets/images/blannk_group.jpg";
import blankImage from "assets/CourseListAssets/images/blank-img.jpg";

/* -------- required imports for API Call --------- */
// import Gateway for API fetch call
import { getData, postData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* --------------------------------------------------------------*/

const LeftPanel = () => {
  let mod = "MOD_USER";
  let com = 21;
  let secret = 1234;

  // saving user token here
  const token = localStorage.getItem("token");

  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const { setHideRightPanel } = useContext(GlobalIndraInfo);

  const [championData, setChampionData] = useState(null);
  const [eventData, setEventData] = useState(null);
  const [groupData, setGroupData] = useState(null);
  const [courseData, setCourseData] = useState(null);
  const [challengesData, setchallengedData] = useState(null);

  // Initial loading status
  const [isLoading, setIsLoading] = useState(false);

  // Initial hiding status
  const [isHiding, setIsHiding] = useState(false);

  // function for get champion details
  const getChampionLeftPanelData = async (mod, com, secret) => {
    setIsLoading(true);

    let requestURL;

    if (token != null) {
      requestURL =
        url.API_BaseUrl +
        url.API_CHAMPION_ABOUT +
        `?token=${token}` +
        `&mod=${mod}` +
        `&uid=${com}` +
        `&secret=${secret}`;
    } else {
      requestURL =
        url.API_BaseUrl +
        url.API_CHAMPION_ABOUT +
        `?mod=${mod}` +
        `&uid=${com}` +
        `&secret=${secret}`;
    }

    try {
      const response = await getData(requestURL);

      if (response.status) {
        if (response.data.length === 0) {
          setChampionData(null);
          setHideRightPanel(true);
        } else {
          setChampionData(response.data);
        }
        setIsLoading(false);
      } else {
        setChampionData(null);
        setHideRightPanel(true);
      }
    } catch (error) {}
  };

  //function for get event details
  const getEventLeftPanelData = async (mod, com, secret) => {
    setIsLoading(true);

    let requestURL;

    if (token != null) {
      requestURL =
        url.API_BaseUrl +
        url.API_EVENT_ABOUT +
        `?token=${token}` +
        `&mod=${mod}` +
        `&id=${com}` +
        `&secret=${secret}`;
    } else {
      requestURL =
        url.API_BaseUrl +
        url.API_EVENT_ABOUT +
        `?mod=${mod}` +
        `&id=${com}` +
        `&secret=${secret}`;
    }

    try {
      const response = await getData(requestURL);

      if (response.status) {
        if (response.data.length === 0) {
          setEventData(null);
          setHideRightPanel(true);
        } else {
          setEventData(response.data);
        }
        setIsLoading(false);
      } else {
        setEventData(null);
        setHideRightPanel(true);
      }
    } catch (error) {
      setHideRightPanel(true);
    }
  };

  //function to get group details
  const getGroupLeftPanel = async (mod, com, secret) => {
    setIsLoading(true);

    let requestURL;

    if (token != null) {
      requestURL =
        url.API_BaseUrl +
        url.API_GROUP_DETAILS_TOP +
        `?token=${token}` +
        `&mod=${mod}` +
        `&id=${com}` +
        `&secret=${secret}`;
    } else {
      requestURL =
        url.API_BaseUrl +
        url.API_GROUP_DETAILS_TOP +
        `?mod=${mod}` +
        `&id=${com}` +
        `&secret=${secret}`;
    }

    try {
      const response = await getData(requestURL);

      if (response.status) {
        if (response.data.length === 0) {
          setGroupData(null);
          setHideRightPanel(true);
        } else {
          setGroupData(response.data);
        }
        setIsLoading(false);
      } else {
        setGroupData(null);
        setHideRightPanel(true);
      }
    } catch (error) {
      setHideRightPanel(true);
    }
  };

  //function to get group details
  const getCourseLeftPanel = async (mod, com, secret) => {
    setIsLoading(true);

    let requestURL;

    if (token != null) {
      requestURL =
        url.API_BaseUrl +
        url.API_VIEW_COURSE +
        `?token=${token}` +
        `&mod=${mod}` +
        `&cid=${com}` +
        `&secret=${secret}`;
    } else {
      requestURL =
        url.API_BaseUrl +
        url.API_VIEW_COURSE +
        `?mod=${mod}` +
        `&cid=${com}` +
        `&secret=${secret}`;
    }

    try {
      const response = await getData(requestURL);

      if (response.status) {
        if (response.data.length === 0) {
          setCourseData(null);
          setHideRightPanel(true);
        } else {
          setCourseData(response.data);
        }
        setIsLoading(false);
      } else {
        setCourseData(null);
        setHideRightPanel(true);
      }
    } catch (error) {
      setHideRightPanel(true);
    }
  };

  //function to get group details
  const getChallangesLeftPanel = async (mod, com, secret) => {
    setIsLoading(true);

    let requestURL;

    if (token != null) {
      requestURL =
        url.API_BaseUrl +
        url.API_VIEW_JOB_DETAILS +
        `${com}` +
        `?token=${token}`;
    } else {
      requestURL = url.API_BaseUrl + url.API_VIEW_JOB_DETAILS + `${com}`;
    }

    try {
      const response = await getData(requestURL);

      if (response.status) {
        if (response.data.length === 0) {
          setchallengedData(null);
          setHideRightPanel(true);
        } else {
          setchallengedData(response.data);
        }
        setIsLoading(false);
      } else {
        setchallengedData(null);
        setHideRightPanel(true);
      }
    } catch (error) {
      setHideRightPanel(true);
    }
  };

  useEffect(() => {
    if (params.get("mod") != null && params.get("com") != null) {
      if (params.get("mod") != null) {
        mod = params.get("mod");
      }

      if (params.get("com") != null) {
        com = params.get("com");
      }

      if (params.get("secret") != null) {
        secret = params.get("secret");
      }

      // for Champion survey
      if (params.get("mod") == "MOD_USER") {
        getChampionLeftPanelData(mod, com, secret);
      }

      // for Event survey
      if (params.get("mod") == "MOD_EVENT") {
        getEventLeftPanelData(mod, com, secret);
      }

      // for Group survey
      if (params.get("mod") == "MOD_GROUP") {
        getGroupLeftPanel(mod, com, secret);
      }

      // for Course survey
      if (params.get("mod") == "MOD_COURSE") {
        getCourseLeftPanel(mod, com, secret);
      }

      // for Challange survey
      if (params.get("mod") == "MOD_CHALLANGES") {
        getChallangesLeftPanel(mod, com, secret);
      }
    } else {
      // hide the left panel
      setIsHiding(true);
    }
  }, []);

  return (
    <>
      {isHiding ? (
        <>
          <div className="mdl_lftpnl">
            {/* ----- Glow placeholder starts here ----- */}
            <div className="champ_section">
              <aside id="champion_sidebar" className="border">
                <div className="events_itemBx">
                  <div className="champions_pic">
                    <span
                      className="placeholder col-12"
                      style={{ height: "197px" }}
                    ></span>
                  </div>
                  <div className="champions_textpnl">
                    <div className="events_hdngauthor">
                      <span className="placeholder placeholder-md rounded-2 col-3"></span>
                      <span className="placeholder placeholder-md rounded-2 col-3"></span>
                    </div>
                    <div className="leftchampion_text">
                      <span
                        className="placeholder rounded-2 col-6 mt-2"
                        style={{ height: "20px" }}
                      ></span>
                    </div>
                    <div className="experts_skill">
                      <ul>
                        <li>
                          <span
                            className="placeholder rounded-2"
                            style={{ width: "80px", height: "30px" }}
                          ></span>
                        </li>
                        <li>
                          <span
                            className="placeholder rounded-2"
                            style={{ width: "80px", height: "30px" }}
                          ></span>
                        </li>
                        <li>
                          <span
                            className="placeholder rounded-2"
                            style={{ width: "80px", height: "30px" }}
                          ></span>
                        </li>
                      </ul>
                    </div>
                    <div className="details_btn">
                      <span
                        className="placeholder rounded-3 col-12"
                        style={{ height: "50px" }}
                      ></span>
                    </div>
                  </div>
                </div>
              </aside>
            </div>
            {/* ----- Glow placeholder ends here ----- */}
          </div>
        </>
      ) : (
        <>
          {isLoading ? (
            <div className="mdl_lftpnl">
              {/* ----- Glow placeholder starts here ----- */}
              <div className="champ_section">
                <aside id="champion_sidebar" className="border">
                  <div className="events_itemBx">
                    <div className="champions_pic">
                      <span
                        className="placeholder col-12"
                        style={{ height: "197px" }}
                      ></span>
                    </div>
                    <div className="champions_textpnl">
                      <div className="events_hdngauthor">
                        <span className="placeholder placeholder-md rounded-2 col-3"></span>
                        <span className="placeholder placeholder-md rounded-2 col-3"></span>
                      </div>
                      <div className="leftchampion_text">
                        <span
                          className="placeholder rounded-2 col-6 mt-2"
                          style={{ height: "20px" }}
                        ></span>
                      </div>
                      <div className="experts_skill">
                        <ul>
                          <li>
                            <span
                              className="placeholder rounded-2"
                              style={{ width: "80px", height: "30px" }}
                            ></span>
                          </li>
                          <li>
                            <span
                              className="placeholder rounded-2"
                              style={{ width: "80px", height: "30px" }}
                            ></span>
                          </li>
                          <li>
                            <span
                              className="placeholder rounded-2"
                              style={{ width: "80px", height: "30px" }}
                            ></span>
                          </li>
                        </ul>
                      </div>
                      <div className="details_btn">
                        <span
                          className="placeholder rounded-3 col-12"
                          style={{ height: "50px" }}
                        ></span>
                      </div>
                    </div>
                  </div>
                </aside>
              </div>
              {/* ----- Glow placeholder ends here ----- */}
            </div>
          ) : (
            <div className="mdl_lftpnl">
              {params.get("mod") != null && params.get("mod") == "MOD_USER" ? (
                // {/* ---------- Champion Card starts here ---------- */}
                <div className="champ_section">
                  {championData == null ? (
                    <>
                      <div className="champ_section">
                        <aside id="champion_sidebar" className="border">
                          <div className="events_itemBx">
                            <div className="champions_pic">
                              <span
                                className="placeholder col-12"
                                style={{ height: "197px" }}
                              ></span>
                            </div>
                            <div className="champions_textpnl">
                              <div className="events_hdngauthor">
                                <span className="placeholder placeholder-md rounded-2 col-3"></span>
                                <span className="placeholder placeholder-md rounded-2 col-3"></span>
                              </div>
                              <div className="leftchampion_text">
                                <span
                                  className="placeholder rounded-2 col-6 mt-2"
                                  style={{ height: "20px" }}
                                ></span>
                              </div>
                              <div className="experts_skill">
                                <ul>
                                  <li>
                                    <span
                                      className="placeholder rounded-2"
                                      style={{ width: "80px", height: "30px" }}
                                    ></span>
                                  </li>
                                  <li>
                                    <span
                                      className="placeholder rounded-2"
                                      style={{ width: "80px", height: "30px" }}
                                    ></span>
                                  </li>
                                  <li>
                                    <span
                                      className="placeholder rounded-2"
                                      style={{ width: "80px", height: "30px" }}
                                    ></span>
                                  </li>
                                </ul>
                              </div>
                              <div className="details_btn">
                                <span
                                  className="placeholder rounded-3 col-12"
                                  style={{ height: "50px" }}
                                ></span>
                              </div>
                            </div>
                          </div>
                        </aside>
                      </div>
                    </>
                  ) : (
                    <aside id="champion_sidebar" className="border">
                      <div className="img-container">
                        <Link to="#">
                          <img
                            src={
                              championData.fullimage
                                ? championData.fullimage
                                : sample
                            }
                            alt="Champion"
                            className="img-fluid"
                          />
                        </Link>
                      </div>
                      <div className="champion-details p-4">
                        <div className="d-flex align-items-end justify-content-between mb-4">
                          <div className="name">
                            {/* ---- champion name start ---- */}
                            <h5 className="fw-bold">
                              {championData.name} {championData.surname}
                            </h5>
                            {/* ---- champion name end ---- */}

                            {/* ---- champion postion start ---- */}
                            {championData.position == null ||
                            championData.position == undefined ? null : (
                              <p className="designation">
                                {championData.position}
                              </p>
                            )}
                            {/* ---- champion postion end ---- */}
                          </div>

                          {/* ----- champion rating start ---- */}
                          {championData.rating == null ||
                          championData.rating == undefined ||
                          championData.rating == "" ? null : (
                            <Link
                              to="#"
                              onClick={(e) => e.preventDefault()}
                              className="rating d-flex align-items-center"
                            >
                              <i className="material-icons-outlined">star</i>
                              <strong>{championData.rating}</strong>
                            </Link>
                          )}
                          {/* ----- champion rating end ---- */}
                        </div>
                        <div className="skills mb-4">
                          {/* ----- champion skills start ---- */}
                          {championData.skills.length == 0 ||
                          championData.skills == undefined ||
                          championData.skills == null ? null : (
                            <div className="experts_skill">
                              <h6 className="mb-2 fw-bold">{t("Skills")}:</h6>
                              <ul>
                                {championData.skills.map((skill, index) => {
                                  return (
                                    <li key={index}>
                                      <Link to="#">
                                        <span>{skill.label}</span>
                                      </Link>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          )}
                        </div>
                        {/* ----- champion skills end ---- */}

                        {/* ----- champion about start ---- */}
                        {championData.about == null ||
                        championData.about == undefined ? null : (
                          <div className="about mb-4">
                            <h6 className="mb-2 fw-bold">{t("About")}:</h6>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: championData.about.slice(0, 150),
                              }}
                            ></p>
                          </div>
                        )}
                        {/* ----- champion about end ---- */}

                        <Link
                          to={`/champions/details/${
                            params.get("com") ? params.get("com") : com
                          }`}
                          target="_blank"
                          className="basic_btn ylw_btn"
                        >
                          {t("See Details")}
                        </Link>
                      </div>
                    </aside>
                  )}
                </div>
              ) : params.get("mod") != null &&
                params.get("mod") == "MOD_EVENT" ? (
                // {/* ---------- Event Card starts here ---------- */}
                <div className="event_section">
                  {eventData == null ? (
                    <>
                      {/* This is an event feedback so no champion data */}
                      {/* But we will show the hidden message */}
                      {/* <div className="activity_rightHdng">
                        <h3 style={{ color: "white" }}>
                          {t("Not enough privilege to view the feedback!")}
                        </h3>
                      </div> */}

                      {/* But we will show the glow placeholder */}
                      <div className="champ_section">
                        <aside id="champion_sidebar" className="border">
                          <div className="events_itemBx">
                            <div className="champions_pic">
                              <span
                                className="placeholder col-12"
                                style={{ height: "197px" }}
                              ></span>
                            </div>
                            <div className="champions_textpnl">
                              <div className="events_hdngauthor">
                                <span className="placeholder placeholder-md rounded-2 col-3"></span>
                                <span className="placeholder placeholder-md rounded-2 col-3"></span>
                              </div>
                              <div className="leftchampion_text">
                                <span
                                  className="placeholder rounded-2 col-6 mt-2"
                                  style={{ height: "20px" }}
                                ></span>
                              </div>
                              <div className="experts_skill">
                                <ul>
                                  <li>
                                    <span
                                      className="placeholder rounded-2"
                                      style={{ width: "80px", height: "30px" }}
                                    ></span>
                                  </li>
                                  <li>
                                    <span
                                      className="placeholder rounded-2"
                                      style={{ width: "80px", height: "30px" }}
                                    ></span>
                                  </li>
                                  <li>
                                    <span
                                      className="placeholder rounded-2"
                                      style={{ width: "80px", height: "30px" }}
                                    ></span>
                                  </li>
                                </ul>
                              </div>
                              <div className="details_btn">
                                <span
                                  className="placeholder rounded-3 col-12"
                                  style={{ height: "50px" }}
                                ></span>
                              </div>
                            </div>
                          </div>
                        </aside>
                      </div>
                    </>
                  ) : (
                    <aside id="champion_sidebar" className="border">
                      {/* ---------- Champion Card starts here ---------- */}
                      <div className="events_itemBx">
                        <div className="champions_pic">
                          <img
                            src={eventData.image ? eventData.image : coursePic1}
                            alt=""
                          />
                        </div>
                        <div className="champions_textpnl">
                          <div className="events_dateBx">
                            {eventData.startday == null ||
                            eventData.startday == undefined ? null : (
                              <h3>{eventData.startday}</h3>
                            )}
                            {eventData.startmonth == null ||
                            eventData.startmonth == undefined ? null : (
                              <h5>
                                {eventData.startmonth}
                                {eventData.startyear == null ||
                                eventData.startyear == undefined ? null : (
                                  <span>{eventData.startyear}</span>
                                )}
                              </h5>
                            )}
                          </div>
                          <div className="events_hdngauthor">
                            {eventData.address == undefined ? null : (
                              <h4>
                                {eventData.address != null
                                  ? eventData.address
                                  : "undisclosed"}
                              </h4>
                            )}
                            {eventData.members == null ||
                            eventData.members == undefined ? null : (
                              <div className="evnts_enroll">
                                <i className="material-icons-outlined">
                                  {" "}
                                  people{" "}
                                </i>
                                <span>
                                  {eventData.members != null
                                    ? eventData.members
                                    : "undisclosed"}{" "}
                                  Enrolled
                                </span>
                              </div>
                            )}
                          </div>
                          {eventData.title == null ||
                          eventData.title == undefined ? null : (
                            <div className="leftchampion_text">
                              <h3>
                                {eventData.title != null
                                  ? eventData.title
                                  : "Undisclosed"}
                              </h3>
                            </div>
                          )}
                          {eventData.category.length === 0 ||
                          eventData.category == undefined ||
                          eventData.category == null ? null : (
                            <div className="experts_skill">
                              <ul>
                                {eventData.category.map((tag, index) => (
                                  <li key={index}>
                                    <Link to="#">
                                      <span>{tag}</span>
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}

                          <div className="details_btn">
                            <Link
                              to={`/events/details/${
                                params.get("com") ? params.get("com") : com
                              }`}
                              target="_blank"
                              className="basic_btn ylw_btn"
                            >
                              {t("See Details")}
                            </Link>
                          </div>
                        </div>
                      </div>
                      {/* ---------- Champion Card ends here ---------- */}
                    </aside>
                  )}
                </div>
              ) : params.get("mod") != null &&
                params.get("mod") == "MOD_GROUP" ? (
                // {/* ---------- Group Card starts here ---------- */}
                <div className="group_section">
                  {groupData == null ? (
                    <>
                      {/* This is an event feedback so no champion data */}
                      {/* But we will show the hidden message */}
                      {/* <div className="activity_rightHdng">
                        <h3 style={{ color: "white" }}>
                          {t("Not enough privilege to view the feedback!")}
                        </h3>
                      </div> */}

                      {/* But we will show the glow placeholder */}
                      <div className="champ_section">
                        <aside id="champion_sidebar" className="border">
                          <div className="events_itemBx">
                            <div className="champions_pic">
                              <span
                                className="placeholder col-12"
                                style={{ height: "197px" }}
                              ></span>
                            </div>
                            <div className="champions_textpnl">
                              <div className="events_hdngauthor">
                                <span className="placeholder placeholder-md rounded-2 col-3"></span>
                                <span className="placeholder placeholder-md rounded-2 col-3"></span>
                              </div>
                              <div className="leftchampion_text">
                                <span
                                  className="placeholder rounded-2 col-6 mt-2"
                                  style={{ height: "20px" }}
                                ></span>
                              </div>
                              <div className="experts_skill">
                                <ul>
                                  <li>
                                    <span
                                      className="placeholder rounded-2"
                                      style={{ width: "80px", height: "30px" }}
                                    ></span>
                                  </li>
                                  <li>
                                    <span
                                      className="placeholder rounded-2"
                                      style={{ width: "80px", height: "30px" }}
                                    ></span>
                                  </li>
                                  <li>
                                    <span
                                      className="placeholder rounded-2"
                                      style={{ width: "80px", height: "30px" }}
                                    ></span>
                                  </li>
                                </ul>
                              </div>
                              <div className="details_btn">
                                <span
                                  className="placeholder rounded-3 col-12"
                                  style={{ height: "50px" }}
                                ></span>
                              </div>
                            </div>
                          </div>
                        </aside>
                      </div>
                    </>
                  ) : (
                    <aside id="champion_sidebar" className="border">
                      <div className="events_itemBx">
                        <div className="bookmarks_grps">
                          <Link to="#">
                            <i className="material-icons-outlined">
                              {" "}
                              bookmark_border{" "}
                            </i>
                          </Link>
                        </div>

                        <div className="groupsbx_outer">
                          <div className="groups_textbx_pnl pd_20">
                            <div className="chappions_rating">
                              <Link to="#">
                                <i className="material-icons-outlined">star</i>
                                <span>{groupData.rating}</span>
                                <em className="mx-1">({groupData.reviews})</em>
                              </Link>
                            </div>

                            <div className="leftchampion_text">
                              <h3>{groupData.name}</h3>
                            </div>

                            {/* ----------- Categories sections starts here ----------- */}
                            {groupData.category == null ? null : (
                              <div className="experts_skill">
                                {groupData.category.length > 0 ? (
                                  <ul>
                                    {groupData.category
                                      .slice(0, 2)
                                      .map((tag, index) => (
                                        <li key={index}>
                                          <Link to="#">
                                            <span>{tag}</span>
                                          </Link>
                                        </li>
                                      ))}

                                    {groupData.category.length <= 2 ? null : (
                                      <li>
                                        <Link to="#" className="skills_count">
                                          +{groupData.category.length - 2} more
                                        </Link>
                                      </li>
                                    )}
                                  </ul>
                                ) : null}
                              </div>
                            )}
                            {/* ----------- Tags sections ends here ----------- */}

                            {/* ----------- Members sections starts here ----------- */}
                            {groupData.members == null ? null : (
                              <div className="ambassadors_users grps_user">
                                {groupData.members.length > 0 ? (
                                  <ul>
                                    {groupData.members.map((member, index) => (
                                      <li key={index}>
                                        <Link to="#">
                                          <img src={member.image} alt="" />
                                        </Link>
                                      </li>
                                    ))}

                                    {/* -------- Total member count section -------- */}
                                    {groupData.members.length <= 8 ? null : (
                                      <li>
                                        <p style={{ marginLeft: "10px" }}>
                                          +{groupData.members.length - 8}
                                        </p>
                                      </li>
                                    )}
                                  </ul>
                                ) : null}
                              </div>
                            )}
                            {/* ----------- Members sections ends here ----------- */}
                          </div>

                          {/* ----------- Group Image section starts here ----------- */}
                          <div className="groups_pic">
                            {groupData.image == null ? (
                              <img src={blankGroup} alt="" />
                            ) : (
                              <img src={groupData.image} alt="" />
                            )}
                          </div>

                          {/* ----------- Details button sections starts here ----------- */}
                          <div className="details_btn pd_20">
                            <Link
                              to={`/group/details/${
                                params.get("com") ? params.get("com") : com
                              }`}
                              target="_blank"
                              className="basic_btn ylw_btn"
                            >
                              {t("See Details")}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </aside>
                  )}
                </div>
              ) : params.get("mod") != null &&
                params.get("mod") == "MOD_COURSE" ? (
                // {/* ---------- Group Card starts here ---------- */}
                <div className="course_section">
                  {courseData == null ? (
                    <>
                      {/* This is an event feedback so no champion data */}
                      {/* But we will show the hidden message */}
                      {/* <div className="activity_rightHdng">
                        <h3 style={{ color: "white" }}>
                          {t("Not enough privilege to view the feedback!")}
                        </h3>
                      </div> */}

                      {/* But we will show the glow placeholder */}
                      <div className="champ_section">
                        <aside id="champion_sidebar" className="border">
                          <div className="events_itemBx">
                            <div className="champions_pic">
                              <span
                                className="placeholder col-12"
                                style={{ height: "197px" }}
                              ></span>
                            </div>
                            <div className="champions_textpnl">
                              <div className="events_hdngauthor">
                                <span className="placeholder placeholder-md rounded-2 col-3"></span>
                                <span className="placeholder placeholder-md rounded-2 col-3"></span>
                              </div>
                              <div className="leftchampion_text">
                                <span
                                  className="placeholder rounded-2 col-6 mt-2"
                                  style={{ height: "20px" }}
                                ></span>
                              </div>
                              <div className="experts_skill">
                                <ul>
                                  <li>
                                    <span
                                      className="placeholder rounded-2"
                                      style={{ width: "80px", height: "30px" }}
                                    ></span>
                                  </li>
                                  <li>
                                    <span
                                      className="placeholder rounded-2"
                                      style={{ width: "80px", height: "30px" }}
                                    ></span>
                                  </li>
                                  <li>
                                    <span
                                      className="placeholder rounded-2"
                                      style={{ width: "80px", height: "30px" }}
                                    ></span>
                                  </li>
                                </ul>
                              </div>
                              <div className="details_btn">
                                <span
                                  className="placeholder rounded-3 col-12"
                                  style={{ height: "50px" }}
                                ></span>
                              </div>
                            </div>
                          </div>
                        </aside>
                      </div>
                    </>
                  ) : (
                    <aside id="champion_sidebar" className="border">
                      {courseData.istraining ? (
                        <div className="course_item">
                          <div className="course_blue_bx">
                            <div className="champions_textpnl">
                              <div className="course_hdngauthor">
                                <h4>{courseData.moderator}</h4>
                                <div className="chappions_rating">
                                  <Link to="#">
                                    <i className="material-icons-outlined">
                                      star
                                    </i>
                                    <span>{courseData.rating}</span>
                                    <em>({courseData.reviewcount})</em>
                                  </Link>
                                </div>
                              </div>
                              <div className="leftchampion_text">
                                <h3>{courseData.label}</h3>
                              </div>
                              {/* ------- Course Categories list section ------ */}
                              <div className="experts_skill">
                                <ul>
                                  {courseData.categories.map((item) => (
                                    <li key={item.id}>
                                      <Link to="#">
                                        <span>{item.label}</span>
                                      </Link>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="traing_listbx">
                            <div className="traning_list_inr">
                              {courseData.childcourses.length === 0 ? null : (
                                <div className="card_traing_outer">
                                  {courseData.childcourses
                                    .slice(0, 3)
                                    .map((courseName, index2) => {
                                      return (
                                        <div
                                          className="card_training_row"
                                          key={index2}
                                        >
                                          <div className="card_traning_left">
                                            <span className="material-icons-outlined">
                                              check_circle{" "}
                                            </span>
                                            <p>
                                              {courseName.label.slice(0, 42)}
                                            </p>
                                          </div>
                                          {/* <div className="card_traning_right">
                                              <h4>78Hr</h4>
                                            </div> */}
                                        </div>
                                      );
                                    })}
                                </div>
                              )}

                              {courseData.childcourses.length === 0 ? null : (
                                <div className="add_course">
                                  {courseData.childcourses.length <=
                                  3 ? null : (
                                    <Link to="#">
                                      +{courseData.childcourses.length - 3}{" "}
                                      Course
                                    </Link>
                                  )}
                                </div>
                              )}
                            </div>

                            {/* ------- Course details section ------ */}
                            <div className="details_btn">
                              <Link
                                to={`/coursetraining/details/${
                                  params.get("com") ? params.get("com") : com
                                }`}
                                target="_blank"
                                className="basic_btn ylw_btn"
                              >
                                {t("See Details")}
                              </Link>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="course_item">
                          {/* ------- Course image section ------ */}
                          <div className="champions_pic">
                            <img
                              src={
                                courseData.image ? courseData.image : blankImage
                              }
                              alt="courseBanner"
                            />
                          </div>
                          <div className="champions_textpnl">
                            {/* ------- Course author & rating section ------ */}
                            <div className="course_hdngauthor">
                              <h4>{courseData.moderator}</h4>
                              <div className="chappions_rating">
                                <Link to="#">
                                  <i className="material-icons-outlined">
                                    star
                                  </i>
                                  <span>{courseData.rating}</span>
                                  <em>({courseData.reviewcount})</em>
                                </Link>
                              </div>
                            </div>

                            {/* ------- Course name section ------ */}
                            <div className="leftchampion_text">
                              <h3>{courseData.label}</h3>
                            </div>

                            {/* ------- Course Categories list section ------ */}
                            <div className="experts_skill">
                              <ul>
                                {courseData.categories.map((item) => (
                                  <li key={item.id}>
                                    <Link to="#">
                                      <span>{item.label}</span>
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </div>

                            {/* ----- course training list start here ----- */}
                            {courseData.childcourses.length === 0 ? null : (
                              <div className="card_traing_outer">
                                {courseData.childcourses
                                  .slice(0, 3)
                                  .map((courseName, index2) => {
                                    return (
                                      <div
                                        className="card_training_row"
                                        key={index2}
                                      >
                                        <div className="card_traning_left">
                                          <span className="material-icons-outlined">
                                            check_circle{" "}
                                          </span>
                                          <p>{courseName.label.slice(0, 42)}</p>
                                        </div>
                                        {/* <div className="card_traning_right">
                                        <h4>78Hr</h4>
                                      </div> */}
                                      </div>
                                    );
                                  })}
                              </div>
                            )}

                            {courseData.childcourses.length === 0 ? null : (
                              <div className="add_course">
                                {courseData.childcourses.length <= 3 ? null : (
                                  <Link to="#">
                                    +{courseData.childcourses.length - 3} Course
                                  </Link>
                                )}
                              </div>
                            )}
                            {/* ----- course training list end here ----- */}

                            {/* ------- Course details section ------ */}
                            <div className="details_btn">
                              <Link
                                to={`/course/details/${
                                  params.get("com") ? params.get("com") : com
                                }`}
                                target="_blank"
                                className="basic_btn ylw_btn"
                              >
                                {t("See Details")}
                              </Link>
                            </div>
                          </div>
                        </div>
                      )}
                    </aside>
                  )}
                </div>
              ) : params.get("mod") != null &&
                params.get("mod") == "MOD_CHALLANGES" ? (
                // {/* ---------- Group Card starts here ---------- */}
                <div className="job_section">
                  {challengesData == null ? (
                    <>
                      {/* This is an event feedback so no champion data */}
                      {/* But we will show the hidden message */}
                      {/* <div className="activity_rightHdng">
                        <h3 style={{ color: "white" }}>
                          {t("Not enough privilege to view the feedback!")}
                        </h3>
                      </div> */}

                      {/* But we will show the glow placeholder */}
                      <div className="champ_section">
                        <aside id="champion_sidebar" className="border">
                          <div className="events_itemBx">
                            <div className="champions_pic">
                              <span
                                className="placeholder col-12"
                                style={{ height: "197px" }}
                              ></span>
                            </div>
                            <div className="champions_textpnl">
                              <div className="events_hdngauthor">
                                <span className="placeholder placeholder-md rounded-2 col-3"></span>
                                <span className="placeholder placeholder-md rounded-2 col-3"></span>
                              </div>
                              <div className="leftchampion_text">
                                <span
                                  className="placeholder rounded-2 col-6 mt-2"
                                  style={{ height: "20px" }}
                                ></span>
                              </div>
                              <div className="experts_skill">
                                <ul>
                                  <li>
                                    <span
                                      className="placeholder rounded-2"
                                      style={{ width: "80px", height: "30px" }}
                                    ></span>
                                  </li>
                                  <li>
                                    <span
                                      className="placeholder rounded-2"
                                      style={{ width: "80px", height: "30px" }}
                                    ></span>
                                  </li>
                                  <li>
                                    <span
                                      className="placeholder rounded-2"
                                      style={{ width: "80px", height: "30px" }}
                                    ></span>
                                  </li>
                                </ul>
                              </div>
                              <div className="details_btn">
                                <span
                                  className="placeholder rounded-3 col-12"
                                  style={{ height: "50px" }}
                                ></span>
                              </div>
                            </div>
                          </div>
                        </aside>
                      </div>
                    </>
                  ) : (
                    <aside id="champion_sidebar" className="border">
                      <div className="challenges_item">
                        {/* ----------- Job Icon & Rating section ----------- */}
                        <div className="challenge_top_head">
                          <div className="challenge_iconOuter">
                            {/* <i className="challenge_icon">
                            <img src={job.image} alt="companyLogo" />
                          </i> */}
                            <i className="material-icons-outlined challenge_icon">
                              business
                            </i>
                            <span className="challenge_icon_text">
                              {challengesData.company != null
                                ? challengesData.company
                                : ""}
                            </span>
                          </div>
                          <div className="bookmark_iconOuter">
                            <div className="chappions_rating">
                              <Link to="#">
                                <i className="material-icons-outlined">star</i>
                                <span>
                                  {challengesData.rating &&
                                  challengesData.rating != null
                                    ? challengesData.rating
                                    : "0"}
                                </span>
                                <em className="mx-1">
                                  ({challengesData.reviews})
                                </em>
                              </Link>
                            </div>
                            {/* <div className="bookmark_icon">
                              <Link to="#">
                                <i className="material-icons-outlined">
                                  bookmark_border
                                </i>
                              </Link>
                            </div> */}
                          </div>
                        </div>

                        {/* ----------- Job title, Price range, Location details section ----------- */}
                        <div className="club_nameLocation">
                          <h3>
                            {challengesData.name != null
                              ? challengesData.name
                              : ""}
                          </h3>
                          <h4>
                            €{" "}
                            {challengesData.price != null
                              ? challengesData.price
                              : t("private")}
                          </h4>
                          <div className="locate_text">
                            <h5 className="d-flex">
                              <i className="material-icons-outlined"> place </i>
                              <span>
                                {challengesData.location != null
                                  ? challengesData.location
                                  : t("Undisclosed")}
                              </span>
                            </h5>
                          </div>
                        </div>

                        {/* ----------- Tags section ----------- */}
                        <div className="experts_skill">
                          {challengesData.skills &&
                          challengesData.skills.length > 0 ? (
                            <ul>
                              {challengesData.skills
                                .slice(0, 5)
                                .map((skill, index) => {
                                  return (
                                    <li key={index}>
                                      <Link to="#">
                                        <span>{skill.label}</span>
                                      </Link>
                                    </li>
                                  );
                                })}
                              {challengesData.skills.length <= 5 ? null : (
                                <li>
                                  <Link to="#" className="skills_count">
                                    +{challengesData.skills.length - 5}{" "}
                                    {t("more")}
                                  </Link>
                                </li>
                              )}
                            </ul>
                          ) : null}
                        </div>

                        {/* ----------- Job Type & pay details section ----------- */}
                        <div className="work_details">
                          <ul>
                            <li>{challengesData.jobtype}</li>
                            <li>
                              {t("Experience")}:{" "}
                              {challengesData.experience != null
                                ? challengesData.experience + "+ years"
                                : " Undisclosed"}
                            </li>
                            <li>
                              {/* <span className="material-icons-outlined">
                              signal_cellular_alt
                            </span> */}
                              {challengesData.whf == "0" ? null : (
                                <span className="material-icons-outlined">
                                  home_work
                                </span>
                              )}
                            </li>
                          </ul>
                        </div>

                        {/* ----------- See details button section ----------- */}
                        <div className="details_btn">
                          <Link
                            to={`/challenges/details/${
                              params.get("com") ? params.get("com") : com
                            }`}
                            target="_blank"
                            className="basic_btn ylw_btn"
                            tabIndex="0"
                          >
                            {t("See Details")}
                          </Link>
                        </div>
                      </div>
                    </aside>
                  )}
                </div>
              ) : (
                <>
                  {/* This is an event feedback so no champion data */}
                  {/* But we will show the hidden message */}
                  {/* <div className="activity_rightHdng">
                        <h3 style={{ color: "white" }}>
                          {t("Not enough privilege to view the feedback!")}
                        </h3>
                      </div> */}

                  {/* But we will show the glow placeholder */}
                  <div className="champ_section">
                    <aside id="champion_sidebar" className="border">
                      <div className="events_itemBx">
                        <div className="champions_pic">
                          <span
                            className="placeholder col-12"
                            style={{ height: "197px" }}
                          ></span>
                        </div>
                        <div className="champions_textpnl">
                          <div className="events_hdngauthor">
                            <span className="placeholder placeholder-md rounded-2 col-3"></span>
                            <span className="placeholder placeholder-md rounded-2 col-3"></span>
                          </div>
                          <div className="leftchampion_text">
                            <span
                              className="placeholder rounded-2 col-6 mt-2"
                              style={{ height: "20px" }}
                            ></span>
                          </div>
                          <div className="experts_skill">
                            <ul>
                              <li>
                                <span
                                  className="placeholder rounded-2"
                                  style={{ width: "80px", height: "30px" }}
                                ></span>
                              </li>
                              <li>
                                <span
                                  className="placeholder rounded-2"
                                  style={{ width: "80px", height: "30px" }}
                                ></span>
                              </li>
                              <li>
                                <span
                                  className="placeholder rounded-2"
                                  style={{ width: "80px", height: "30px" }}
                                ></span>
                              </li>
                            </ul>
                          </div>
                          <div className="details_btn">
                            <span
                              className="placeholder rounded-3 col-12"
                              style={{ height: "50px" }}
                            ></span>
                          </div>
                        </div>
                      </div>
                    </aside>
                  </div>
                </>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default LeftPanel;
