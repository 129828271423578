import React from "react";
import { useTranslation } from "react-i18next";

import thankYou from "assets/ChampionAssets/images/thanks.png";

const ChampionConnectThankModal = () => {
  const { t } = useTranslation();
  return (
    <div className="modal fade show" id="champion_connect_thanks_mdl">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="material-icons-outlined">close</i>
            </button>
          </div>

          <div className="modal-body">
            <div className="login_body">
              <div className="success_mdlpic">
                <img src={thankYou} alt="" />
              </div>
              <div className="congrats_text">
                <h3>{t("Thanks!")}</h3>
                <h5>{t("Your connect request, sent successfully")}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChampionConnectThankModal;
