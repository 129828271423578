/* eslint-disable */
import React, { useContext } from "react";

// importing custom components here
import LeftPanel from "./Panels/LeftPanel";
import RightPanel from "./Panels/RightPanel";
import { GlobalIndraInfo } from "App";

const FeedbackMainContainer = () => {
  const { hideRightPanel } = useContext(GlobalIndraInfo);

  return (
    <>
      <section className="guest_feedarea">
        <div className="container">
          <div className="survay_feedmodal">
            <div className="srvy_feedbdy">
              <div className="mdl_outer">
                {/* ----------- Left Panel starts here ----------- */}
                <LeftPanel />

                {/* ----------- Left Panel starts here ----------- */}
                <RightPanel />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeedbackMainContainer;
