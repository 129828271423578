import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// importing context API here
import { GlobalIndraInfo } from "App";

const BreadCrumb = () => {
  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();

  // importing useContext functions here
  const { receivedCourseName } = useContext(GlobalIndraInfo);

  // return from her
  return (
    <>
      <div className="bredcum_area">
        <div className="container">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">{t("Home")}</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/courses">{t("Course")}</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {receivedCourseName != "" ? receivedCourseName : ""}
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </>
  );
};

export default BreadCrumb;
