/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const QuestionModal = () => {
  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className="question_modal_bx">
        <div
          className="modal fade"
          id="question_success_modal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          data-bs-backdrop="static"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <div className="sucess_question_icon">
                  <span className="material-icons-outlined">task_alt</span>
                </div>
                <div className="question_success_hdng">
                  <h2>{t("Your query has reached us successfully")}</h2>
                  <h5>{t("Soon we will send you a mail be ready")}</h5>
                </div>
                <div className="question_closeBtn">
                  <Link
                    to="#"
                    data-bs-dismiss="modal"
                    className="basic_btn ylw_btn"
                  >
                    {t("Close")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuestionModal;
