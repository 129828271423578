import { t } from "i18next";
import React from "react";
import { Link } from "react-router-dom";

const ApplySuccessModal = () => {
  return (
    <div className="question_modal_bx">
      <div
        className="modal fade"
        id="challenge_applied_success_modal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="sucess_question_icon">
                <span className="material-icons-outlined">task_alt</span>
              </div>
              <div className="question_success_hdng">
                <h2>{t("Great!")}</h2>
                <h5>{t("you have successfully applied for the challenge")}</h5>
                {/* <h5>{props.modalAdminMessage}</h5> */}
              </div>
              <div className="question_closeBtn">
                <Link
                  to="#"
                  data-bs-dismiss="modal"
                  className="basic_btn ylw_btn"
                >
                  {t("Close")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplySuccessModal;
