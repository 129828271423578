/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { postData } from "utils/Gateway";
import * as url from "helpers/url_helper";

//import images
import loginPic from "assets/CourseAssets/images/login-pic.jpg";

// import custom component
import Header from "common/Header";
import Footer from "common/Footer";
import ConfirmModal from "components/SignupComponents/Modals/ConfirmModal";
import ContactAdminModal from "components/LoginComponents/Modal/ContactAdminModal";

const SignUp = () => {
  let captcha = "indra";
  const token = localStorage.getItem("token");
  const history = useHistory();
  const { t } = useTranslation();

  const loadingCircle = [1, 2, 3, 4, 5, 6, 7]; // for loading status

  // initial for sign up values
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [agree, setAgree] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [firstNameWarning, setFirstNameWarning] = useState(false);
  const [lastNameWarning, setLastNameWarning] = useState(false);
  const [emailWarning, setEmailWarning] = useState(false);
  const [emailIsNotValid, setEmailIsNotValid] = useState(false);
  const [passwordWarning, setPasswordWarning] = useState(false);
  const [confirmPasswordWarning, setConfirmPasswordWarning] = useState(false);
  const [matchPassWarning, setMatchPassWarning] = useState(false);
  const [agreeWarning, setAgreeWarning] = useState(false);
  const [accountCreating, setAccountCreating] = useState(false);
  const [userSignUpEmail, setUserSignEmail] = useState("");
  const [confirmCode, setConfirmCode] = useState(0);

  //function for toggle show password
  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  //function for toggle show confirm password
  const toggleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  // function for complete registration process
  const signUpHandler = async (event) => {
    event.preventDefault();
    if (
      firstName == "" ||
      lastName == "" ||
      email == "" ||
      password == "" ||
      confirmPassword == "" ||
      agree == false
    ) {
      // first name validation
      if (firstName == "") {
        setFirstNameWarning(true);
      } else {
        setFirstNameWarning(false);
      }
      //last name validation
      if (lastName == "") {
        setLastNameWarning(true);
      } else {
        setLastNameWarning(false);
      }

      //email validation
      if (email == "") {
        setEmailWarning(true);
      } else {
        setEmailWarning(false);
      }

      //password validation
      if (password == "") {
        setPasswordWarning(true);
      } else {
        setPasswordWarning(false);
      }

      // confirm password validation
      if (confirmPassword == "") {
        setConfirmPasswordWarning(true);
      } else {
        setConfirmPasswordWarning(false);
      }

      // agree validation
      if (agree == false) {
        setAgreeWarning(true);
      } else {
        setAgreeWarning(false);
      }
    } else {
      const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

      setFirstNameWarning(false);
      setLastNameWarning(false);
      setEmailWarning(false);
      setPasswordWarning(false);
      setConfirmPasswordWarning(false);
      setAgreeWarning(false);

      if (email.match(mailformat)) {
        setEmailIsNotValid(false);
        if (confirmPassword == password) {
          setMatchPassWarning(false);
          setAccountCreating(true);

          // exectuing code for submit here
          try {
            let requestUrl = url.API_BaseUrl + url.API_REGISTARTION;

            let registartionData = {
              firstName,
              lastName,
              email,
              password,
              agree,
              captcha,
            };

            let result = await postData(requestUrl, registartionData);

            if (result.status) {
              setUserSignEmail(result.data.email);
              setConfirmCode(result.code);
              let myModal = new bootstrap.Modal(
                document.getElementById("register_success_modal")
              );
              myModal.show();
              resetData();
              setAccountCreating(false);
            }
          } catch (error) {}
        } else {
          setMatchPassWarning(true);
        }
      } else {
        setEmailIsNotValid(true);
      }
    }
  };

  // this is for testing purpose
  // const signUpHandler = (event) => {
  //   event.preventDefault();
  //   let myModal = new bootstrap.Modal(
  //     document.getElementById("register_success_modal")
  //   );
  //   myModal.show();
  // };

  const resetData = () => {
    setEmail("");
    setfirstName("");
    setlastName("");
    setPassword("");
    setConfirmPassword("");
    setAgree(false);
  };

  useEffect(() => {
    let preUrl = document.referrer;
    if (token) {
      if (preUrl == "" || preUrl == null) {
        history.push("/");
      } else {
        window.location.href = preUrl;
      }
    }
  }, [token]);

  useEffect(() => {
    document.title = "Sign Up";
  }, []);

  return (
    <>
      <Header />
      <section className="login_area">
        <div className="container">
          <div className="login_bxArea">
            <div className="signupleft_pnl">
              <div className="login_innr">
                <div className="login_hdng">
                  <h2>{t("Create Account")}</h2>
                  <h5>{t("It's quick and easy.")}</h5>
                </div>
                {/* ---- main form start ---- */}
                <form onSubmit={signUpHandler}>
                  <div className="login_fillOuter">
                    <div className="row">
                      {/* ---- first name start ---- */}
                      <div className="col-md-6">
                        <div className="input_fill jobinput_fill">
                          <label>{t("First Name")}</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t("First Name")}
                            value={firstName}
                            onChange={(e) => {
                              setFirstNameWarning(false);
                              setfirstName(e.target.value);
                            }}
                          />
                        </div>
                        {firstNameWarning && (
                          <p className="text-danger mb-2">
                            * {t("What's your name?")}
                          </p>
                        )}
                      </div>
                      {/* ---- first name end ---- */}

                      {/* ---- last name start ---- */}
                      <div className="col-md-6">
                        <div className="input_fill jobinput_fill">
                          <label>{t("Last Name")}</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t("Last Name")}
                            value={lastName}
                            onChange={(e) => {
                              setLastNameWarning(false);
                              setlastName(e.target.value);
                            }}
                          />
                        </div>
                        {lastNameWarning && (
                          <p className="text-danger mb-2">
                            * {t("What's your surname?")}
                          </p>
                        )}
                      </div>
                      {/* ---- last name end ---- */}

                      {/* ---- email start ---- */}
                      <div className="col-md-12">
                        <div className="input_fill jobinput_fill">
                          <label>{t("Email")}</label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder={t("Email")}
                            value={email}
                            onChange={(e) => {
                              setEmailWarning(false);
                              setEmail(e.target.value);
                            }}
                          />
                        </div>
                        {emailWarning && (
                          <p className="text-danger mb-2">
                            * {t("Email you use for login or reset password")}
                          </p>
                        )}
                        {emailIsNotValid && (
                          <p className="text-danger mb-2">
                            * {t("Enter valid email please")}
                          </p>
                        )}
                      </div>
                      {/* ---- email start ---- */}

                      {/* ---- password start ---- */}
                      <div className="col-md-6">
                        <div className="input_fill jobinput_fill">
                          <label>{t("Password")}</label>
                          <div className="eye_btnfill">
                            <input
                              type={showPassword ? "text" : "password"}
                              className="form-control"
                              placeholder={t("Password")}
                              value={password}
                              onChange={(e) => {
                                setPasswordWarning(false);
                                setPassword(e.target.value);
                              }}
                            />
                            <Link
                              to="#"
                              className="copy_link"
                              onClick={togglePassword}
                            >
                              <span className="material-icons-outlined">
                                visibility
                              </span>
                            </Link>
                          </div>
                        </div>
                        {passwordWarning && (
                          <p className="text-danger mb-2">
                            * {t("Password at least 3 digit")}
                          </p>
                        )}
                      </div>
                      {/* ---- password end ---- */}

                      {/* ---- confirm password start ---- */}
                      <div className="col-md-6">
                        <div className="input_fill jobinput_fill">
                          <label>{t("Confirm Password")}</label>
                          <div className="eye_btnfill">
                            <input
                              type={showConfirmPassword ? "text" : "password"}
                              className="form-control"
                              placeholder={t("Confirm Password")}
                              value={confirmPassword}
                              onChange={(e) => {
                                setConfirmPasswordWarning(false);
                                setConfirmPassword(e.target.value);
                              }}
                            />
                            <Link
                              to="#"
                              className="copy_link"
                              onClick={toggleConfirmPassword}
                            >
                              <span className="material-icons-outlined">
                                visibility
                              </span>
                            </Link>
                          </div>
                        </div>
                        {confirmPasswordWarning && (
                          <p className="text-danger mb-2">
                            * {t("Enter confirm password please")}
                          </p>
                        )}
                      </div>
                      {/* ---- confirm password end ---- */}
                      <div className="col-md-12">
                        <input type="hidden" value={captcha} />
                      </div>
                    </div>
                    {matchPassWarning && (
                      <p className="mb-3 text-danger">
                        * {t("Password and Confirm password are not matched")}
                      </p>
                    )}
                  </div>
                  {/* ---- checkbox section ----- */}
                  <div className="create_chk_outer">
                    <div className="trms_check">
                      <label className="radio-check">
                        <input
                          type="checkbox"
                          checked={agree}
                          onChange={(e) => {
                            setAgreeWarning(false);
                            setAgree(e.target.checked);
                          }}
                        />
                        <span></span>
                        <p>
                          {t("I have read and agree to the")}
                          <Link to="/blog/legal-terms-condition">
                            {t("Legal Notice")}
                          </Link>{" "}
                          &amp;
                          <Link to="/blog/privacy-policy">
                            {t("Privacy Policy")}
                          </Link>
                        </p>
                      </label>
                      {agreeWarning && (
                        <p className="mt-2 text-danger mb-0">
                          * {t("Please agree with terms and condition")}
                        </p>
                      )}
                    </div>

                    <div className="trms_check">
                      <label className="radio-check">
                        <input type="checkbox" />
                        <span></span>
                        <p>
                          {t(
                            "I agree to receive commercial information that INDRA Business"
                          )}
                          &amp;
                          {t(
                            "Collaboration considers appropriate to send me by email"
                          )}
                        </p>
                      </label>
                    </div>
                  </div>
                  {/* ---- checkbox section end ----- */}

                  {accountCreating && (
                    <div className="d-flex align-items-center justify-content-center alert alert-primary mt-3">
                      <h6 className="text-primary fw-bold">
                        {t("Creating account")}
                      </h6>
                      {loadingCircle.map((circle) => {
                        return (
                          <div
                            key={circle}
                            className="spinner-grow mx-1 text-primary"
                            style={{
                              height: "6px",
                              width: "6px",
                              marginLeft: "2px",
                            }}
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        );
                      })}
                    </div>
                  )}

                  {/* ----- button section ----- */}
                  <div className="create_sbmit">
                    <button
                      type="submit"
                      className="basic_btn ylw_btn"
                      disabled={accountCreating ? true : false}
                      style={{
                        cursor: accountCreating ? "not-allowed" : "pointer",
                      }}
                    >
                      {t("Create Account")}
                    </button>
                  </div>
                  {/* ----- button section end ----- */}

                  {/* ---- login redirect ---- */}
                  <div className="create_userOuter">
                    <div className="create_text">
                      <p>
                        {t("Have an account?")}{" "}
                        <Link to="/login">{t("Sign in")}</Link>
                      </p>
                    </div>
                  </div>
                </form>
                {/* ---- main form end ---- */}
              </div>
            </div>
            <div className="signupright_pnl">
              <div className="signup_pic">
                <figure>
                  <img src={loginPic} alt="login" />
                </figure>
                <div className="login_caption">
                  <h2>
                    <span>{t("Discover Indra")} </span> ATM Ecosystem
                  </h2>
                  <p>
                    {t(
                      "More than 25% of the world s total airspace is managed with Indra s Automation Systems"
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <ConfirmModal
        userSignUpEmail={userSignUpEmail}
        confirmCode={confirmCode}
      />
      <ContactAdminModal />
    </>
  );
};

export default SignUp;
