import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Breadcrumb = () => {
  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  return (
    <>
      <section id="breadcrumb_container" className="bredcum_area">
        <div className="container">
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">{t("Home")}</Link>
            </li>
            <li className="breadcrumb-item active">
              <span>{t("Contact us")}</span>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default Breadcrumb;
