import React from "react";

// importing custom CSS here
import "./css/terms.css";

// importing custom components here
import Header from "common/Header";
import Breadcrumb from "components/TermsComponents/Breadcrumb";
import TermsContent from "components/TermsComponents/TermsContent";
import Footer from "common/Footer";

const Terms = () => {
  return (
    <>
      <Header />
      <Breadcrumb />
      <section id="blog_details_wrapper" className="py-5">
        <div className="container">
          <TermsContent />
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Terms;
