import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Importing images here
import TomHanks from "assets/BlogAssets/images/tom-hanks.png";
import BlogDetails from "assets/BlogAssets/images/blog-details.jpg";

const LegalContent = () => {
  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  return (
    <>
      {/* -------------- Legal Title section starts here -------------- */}
      <div className="post-head d-flex flex-wrap gap-3 align-items-center justify-content-between">
        <div className="post-title me-3">
          {/* <h1 className="fw-bold mb-3">Figma Design Event 2022</h1> */}
          <h1 className="fw-bold mb-3">{t("Legal Terms and Conditions")}</h1>
          {/* <ul className="post-meta d-flex align-items-center">
            <li>
              <Link to="#" className="author d-flex align-items-center">
                <img
                  src={TomHanks}
                  alt="Tom Hanks"
                  className="img-fluid me-2"
                />
                <span>Tom Hanks</span>
              </Link>
            </li>
            <li>
              <Link to="#" className="date d-flex align-items-center">
                <i className="material-icons me-2">date_range</i>
                <span>15th August 2022</span>
              </Link>
            </li>
          </ul> */}
        </div>

        {/* <Link
          to="#"
          id="postShare"
          className="d-flex align-items-center justify-content-center"
        >
          <i className="material-icons d-block">share</i>
        </Link> */}
      </div>
      {/* -------------- Legal Title section starts here -------------- */}

      {/* -------------- Legal body section starts here -------------- */}
      <div className="blog-details-container mt-5">
        {/* <div className="img-container mb-4">
          <img
            src={BlogDetails}
            alt="Figma Design Event 2022"
            className="img-fluid"
          />
        </div> */}
        <div className="blog-desc mb-4">
          <p>
            If you're not following Learn WordPress Online Workshops on
            Meetup.com, you may be missing out on some high quality events.
            WordPress experts from across the world have been collaborating on
            virtual events with instruction at the same caliber of excellence
            that you might find at an in-person WordCamp.
          </p>
          <p>
            On Thursday, August 18, at 3:00 PM EDT, WP Engine-sponsored
            developer advocate Nick Diego will be hosting an online workshop
            titled Taking Block Patterns to the Next Level. Diego will be
            uncovering “some lesser-known pattern implementations:”
          </p>
          <p>
            Diego identified the following learning objectives for participants
            in this workshop:
          </p>
          <ul>
            <li>
              Learn how to register a pattern in a block theme as well as with
              the designated registration function.
            </li>
            <li>
              Learn what properties are available to patterns during
              registration.
            </li>
            <li>
              Learn what semantic, contextual, and page creation patterns are
              and how to use them.
            </li>
          </ul>
          <p>
            This session is aimed at intermediate to advanced builders and will
            be held via Zoom in English with live Zoom transcription. More than
            127 people have already signed up to attend this free workshop.
          </p>
          <p>
            Later in the week, WP Engine-sponsored developer advocate Damon Cook
            and Automattic-sponsored WordPress educator Sarah Snow will be
            conducting a workshop for block theme builders titled Figma to Block
            Theme. This follows up a previous session called Design with Figma
            where participants learned how to use the WordPress.org Figma
            community's Theme Template file to get started with block theme
            design. That session is already available on WordPress.tv.
          </p>
          <p>
            Theme authors interested in learning how to convert Figma design
            files into a block theme can catch this event on Friday, Aug 19 at
            3:00 PM EDT. Cook and Snow will demonstrate how to set up colors,
            typography, and more. Anyone interested can sign up to attend online
            for free.
          </p>
        </div>
        {/* <div className="tags d-flex flex-wrap align-items-center gap-2">
          <i className="material-icons">label_outline</i>
          <span>Important</span>
          <span>Design</span>
          <span>Figma</span>
          <span>Event</span>
          <span>2022</span>
          <span>This Month</span>
        </div> */}
      </div>
      {/* -------------- Legal body section starts here -------------- */}
    </>
  );
};

export default LegalContent;
