import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

//importing images here
import empty from "assets/ConversationAssests/images/empty-pic.png";

const EmptyMessage = (props) => {
  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  return (
    <>
      <div className="chating_mdlPnl">
        <div className="chatting_innr chating_start">
          <div className="nothing_pic">
            <figure>
              <img src={empty} alt="nothing" />
            </figure>
            <div className="nothing_caption">
              <p>
                {t("Nothing found")}.....
                {t("Refresh or Send your first message")}{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmptyMessage;
