/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import Gateway for API fetch call
import {
  getData,
  postData,
  postActivityFormData,
  uploadMultipleFile,
  postFormdata,
} from "utils/Gateway";

// importing images here
import PDF from "assets/ActivityAssests/svg/pdf.svg";
import XLS from "assets/ActivityAssests/svg/xls.svg";
import DOCS from "assets/ActivityAssests/svg/docs.svg";
import userBlank from "assets/ConversationAssests/images/default-user.png";
import blankLesson from "assets/CourseAssets/svg/lesson-empty.svg";

// import all URLs from url_helper
import * as url from "helpers/url_helper";

// importing context API here
import { GlobalIndraInfo } from "App";
import SimilarGroups from "../common/SimilarGroups";

const Forum = () => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const createPostPermission = userInfo.privilege.PRV_ACTIVITY_CREATE;

  const { reviewPermission, reloadGroupForumTab, setReloadGroupForumTab } =
    useContext(GlobalIndraInfo);

  // Getting the course ID created in Step 1
  const params = useParams();

  const isGlobal = 0;

  const [title, setTitle] = useState("");
  const [query, setQuery] = useState("");

  const [page, setPage] = useState(0);
  //initial for validation
  const [titleError, setTitleError] = useState(false);
  const [queryError, setQueryError] = useState(false);
  const [queryLists, setQueryLists] = useState([]);
  // initial state for uploaded multiple files
  const [media, setMedia] = useState([]);

  //initial state for uploaded image id
  const [fileIds, setFileIds] = useState([]);
  // initial state for posting load
  const [isSubmiting, setIsSubmiting] = useState(false);

  //initial for upload bigger file
  const [fileIsBig, setFileIsBig] = useState(false);

  // initial for uploading
  const [isUploading, setIsUploading] = useState(false);
  const [forumCommentMessage, setForumCommentMessage] = useState("");
  //initial for empty message
  const [showEmptyMessage, setShowEmptyMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [commentIndex, setCommentIndex] = useState(null);

  const [showReplyBlock, setShowReplyBlock] = useState(false);

  const [replyIndex, setReplyIndex] = useState(null);
  // initial state for reply to parent message
  const [commentReplyMessage, setCommentReplyMessage] = useState("");
  //initial for empty message
  const [showEmptyReplyMessage, setShowEmptyReplyMessage] = useState(false);

  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  const spinCount = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  // function for multiple file upload
  const multipleFileUploadHandler = async (e) => {
    setFileIsBig(false);

    const selectedFIles = []; // initial empty values for file array

    const targetFiles = e.target.files; // store file information

    let filesLength = targetFiles.length;

    let proceed = false;

    if (filesLength > 0) {
      for (let i = 0; i < filesLength; i++) {
        let f = targetFiles[i];

        if (f.size <= 12500000) {
          proceed = true;
        } else {
          proceed = false;
        }
      }

      if (proceed) {
        setIsUploading(true);
        let requestURL =
          url.API_BaseUrl + url.API_FILE_UPLOAD_MULTIPLE + `?token=${token}`;

        try {
          const response = await uploadMultipleFile(requestURL, targetFiles);

          if (response.status) {
            let fileResponseId = response.data.map((data) => {
              return data.id;
            });
            if (fileIds.length == 0) {
              setFileIds(fileResponseId);
            } else {
              let fileArr = fileIds.concat(fileResponseId);
              setFileIds(fileArr);
            }

            const targetFilesObject = [...targetFiles]; // store array of those files

            // check condition whether file array is empty or not
            if (media.length == 0) {
              targetFilesObject.map((file) => {
                return selectedFIles.push(file);
              });

              setMedia(selectedFIles); // changing state of files array
            } else {
              targetFilesObject.map((file) => {
                return selectedFIles.push(file);
              });

              // changing state of files array with previous file array values
              setMedia((media) => [...media, ...selectedFIles]);
            }
            setIsUploading(false);
          }
        } catch (error) {}
      } else {
        setFileIsBig(true);
      }
    } else {
      return;
    }
  };

  // function for clear file value
  const resetFile = () => {
    const file = document.getElementById("groupForumFile");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  // reset function for reset all fields
  const resetQuery = () => {
    setTitle("");
    setQuery("");
    resetFile();
    setFileIds([]);
    setMedia([]);
  };

  //---------------------- Function to submit the question --------------------
  const saveQuery = async () => {
    if (title == "" || query == "") {
      if (title == "") {
        setTitleError(true);
      } else {
        setTitleError(false);
      }
      if (query == "") {
        setQueryError(true);
      } else {
        setQueryError(false);
      }
    } else {
      setTitleError(false);
      setQueryError(false);
      setIsSubmiting(true);
      let requestURL =
        url.API_BaseUrl + url.API_ACTIVITY_CREATE_POST + `?token=${token}`;

      let queryData = {
        isglobal: isGlobal,
        groups: params.id,
        title,
        message: query,
        media: fileIds,
      };

      try {
        const response = await postFormdata(requestURL, queryData);

        if (response.status == true) {
          resetQuery();
          setIsSubmiting(false);
          getQuery(params.id);
        }
      } catch (error) {}
    }
  };

  // function for get all forum lists
  const getQuery = async (id) => {
    setIsLoading(true);
    let requestURL =
      url.API_BaseUrl +
      url.API_LESSON_FORUM_LIST +
      `?token=${token}` +
      `&mod=group` +
      `&com=${id}`;

    try {
      const response = await getData(requestURL);

      console.log("Response---->", response);

      if (response.status) {
        if (response.data) {
          setQueryLists(response.data);
        } else {
          setQueryLists([]);
        }
        setIsLoading(false);
      }
    } catch (error) {}
  };

  // function for display question panel
  const toggleComment = (index) => {
    let x = document.getElementById(`forumgroupcomment${index}`);
    if (x.style.display == "block") {
      x.style.display = "none";
      setCommentIndex(null);
    } else {
      x.style.display = "block";
      setCommentIndex(index);
    }
    setShowEmptyReplyMessage(false);
    setForumCommentMessage("");
  };

  //function for toggle reply block
  const toggleReply = (index2) => {
    if (showReplyBlock) {
      setShowReplyBlock(false);
      setReplyIndex(null);
    } else {
      setShowReplyBlock(true);
      setReplyIndex(index2);
    }
    setShowEmptyReplyMessage(false);
    setCommentReplyMessage("");
  };

  // function for like
  const forumLikeHandler = async (postId) => {
    let requestURL =
      url.API_BaseUrl +
      url.API_ACTIVITY_LIKE_POST +
      `?token=${token}` +
      `&postid=${postId}`;

    try {
      const response = await getData(requestURL);

      if (response.status) {
        getQuery(params.id);
      }
    } catch (error) {}
  };

  // function for parent like
  const likeCommentHandler = async (commentId) => {
    let requestURL =
      url.API_BaseUrl +
      url.API_ACTIVITY_LIKE_POST_COMMENT +
      `?token=${token}` +
      `&commentid=${commentId}`;

    try {
      const response = await getData(requestURL);
      if (response.status) {
        getQuery(params.id);
        setShowReplyBlock(false);
        setReplyIndex(null);
      }
    } catch (error) {}
  };

  // function for post comment
  const replyToForum = async (postId, index) => {
    if (forumCommentMessage == "") {
      setShowEmptyMessage(true);
      return;
    } else {
      setShowEmptyMessage(false);
      let commentUrl =
        url.API_BaseUrl +
        url.API_ACTIVITY_POST_ANSWER +
        `${postId}?token=${token}`;

      let messageData = {
        message: forumCommentMessage,
      };

      setForumCommentMessage("");

      try {
        const response = await postData(commentUrl, messageData);

        if (response.status) {
          getQuery(params.id);
          toggleComment(index);
        }
      } catch (error) {}
    }
  };

  //function for reply comment
  const replyToComment = async (postId, commentId, index2) => {
    if (commentReplyMessage == "") {
      setShowEmptyReplyMessage(true);
      return;
    } else {
      setShowEmptyReplyMessage(false);

      let commentUrl =
        url.API_BaseUrl +
        url.API_ACTIVITY_REPLY_TO_ANSWER +
        `${postId}?token=${token}`;

      let messageData = {
        message: commentReplyMessage,
        commentid: commentId,
      };

      setCommentReplyMessage("");

      try {
        const response = await postData(commentUrl, messageData);

        console.log("reply response", response);

        if (response.status) {
          getQuery(params.id);
          toggleReply(index2);
        }
        setShowEmptyReplyMessage(false);
      } catch (error) {}
    }
  };

  // reload tab after join group
  useEffect(() => {
    if (reloadGroupForumTab) {
      getQuery(params.id);
      setReloadGroupForumTab(false);
    }
  }, [reloadGroupForumTab]);

  useEffect(() => {
    getQuery(params.id);
  }, []);

  return (
    <div className="tab-pane fade show active" id="forum">
      <div className="training_outer">
        <div className="row">
          <div className="col-lg-12">
            {/* ---- glow placeholder start ----- */}
            {isLoading ? (
              <div className="course_detailspnl">
                <div className="contnt_hdng tab_Hdng">
                  <span
                    className="placeholder rounded-3 col-2"
                    style={{ height: "22px" }}
                  ></span>
                </div>
                <div className="form_commnts_area">
                  <span
                    className="placeholder rounded-3 col-12"
                    style={{ height: "117px" }}
                  ></span>
                </div>
                <div className="rply_bxArea">
                  <div className="reply_forumBx forum_grayBx">
                    <div className="forum_cmntsRow">
                      <div className="share_btn forum_shreBtn">
                        <span
                          className="placeholder rounded-3"
                          style={{ width: "34px", height: "34px" }}
                        ></span>
                      </div>
                      <div className="team_userOuter forum_usrOuter">
                        <div className="team_usr_pic">
                          <span
                            className="placeholder rounded-circle"
                            style={{ width: "50px", height: "50px" }}
                          ></span>
                        </div>
                        <div className="team_usrTxt flex-fill">
                          <span className="placeholder placeholder-md rounded-2 col-2 mb-2"></span>
                          <span className="placeholder placeholder-sm rounded-2 col-1"></span>
                          <div className="forum_para">
                            <span className="placeholder placeholder-sm rounded-2 col-12 mb-2"></span>
                            <span className="placeholder placeholder-sm rounded-2 col-10 mb-2"></span>
                            <span className="placeholder placeholder-sm rounded-2 col-8 mb-2"></span>
                            <span className="placeholder placeholder-sm rounded-2 col-3"></span>
                          </div>
                          <div className="forumpic_outer">
                            <div className="forum_pic">
                              <span
                                className="placeholder rounded-3"
                                style={{ width: "130px", height: "86px" }}
                              ></span>
                            </div>
                            <div className="forum_pic">
                              <span
                                className="placeholder rounded-3"
                                style={{ width: "130px", height: "86px" }}
                              ></span>
                            </div>
                          </div>
                          <div className="form_share">
                            <span
                              className="placeholder rounded-3 me-3"
                              style={{ width: "97px", height: "38px" }}
                            ></span>
                            <span
                              className="placeholder rounded-3"
                              style={{ width: "97px", height: "38px" }}
                            ></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="forum_cmntsRow">
                      <div className="share_btn forum_shreBtn">
                        <span
                          className="placeholder rounded-3"
                          style={{ width: "34px", height: "34px" }}
                        ></span>
                      </div>
                      <div className="team_userOuter forum_usrOuter">
                        <div className="team_usr_pic">
                          <span
                            className="placeholder rounded-circle"
                            style={{ width: "50px", height: "50px" }}
                          ></span>
                        </div>
                        <div className="team_usrTxt flex-fill">
                          <span className="placeholder placeholder-md rounded-2 col-2 mb-2"></span>
                          <span className="placeholder placeholder-sm rounded-2 col-1"></span>
                          <div className="forum_para">
                            <span className="placeholder placeholder-sm rounded-2 col-12 mb-2"></span>
                            <span className="placeholder placeholder-sm rounded-2 col-4 mb-2"></span>
                          </div>
                          <div className="form_share">
                            <span
                              className="placeholder rounded-3"
                              style={{ width: "38px", height: "38px" }}
                            ></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="course_detailspnl">
                {/* <div className="contnt_hdng tab_Hdng">
                <h4>forum</h4>
              </div> */}

                {reviewPermission && createPostPermission ? (
                  <div className="forum_main">
                    <div className="activity_tagsRow">
                      <div className="reply_fill">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t("Enter Title")}
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                      </div>
                    </div>
                    {titleError && (
                      <p className="text-danger mb-2">
                        * {t("Please Fill up Title")}
                      </p>
                    )}

                    {/* ---------- Comments Textbox section starts here ---------- */}
                    <div className="form_commnts_area">
                      <textarea
                        className="form-control"
                        placeholder={t("Write your query here")}
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                      ></textarea>
                    </div>
                    {queryError && (
                      <p className="text-danger mb-3">
                        * {t("Please Fill up Details")}
                      </p>
                    )}

                    <div className="activity_tagsRow d-flex mt-4">
                      {/* ----- this style is for temporary purpose please change it in CSS   ----- */}
                      {media.map((files, index) => {
                        return (
                          <div key={index} className="uploaded-media">
                            {files.type == "image/jpeg" ||
                            files.type == "image/png" ? (
                              <img
                                src={URL.createObjectURL(files)}
                                alt="ImageFile"
                                className="rounded"
                                height="150"
                                width="150"
                              />
                            ) : null}
                            {files.type == "text/plain" ||
                            files.type ==
                              "application/vnd.openxmlformats-officedocument.presentationml.presentation" ? (
                              <img
                                src={DOCS}
                                alt="docs"
                                className="rounded"
                                height="150"
                                width="150"
                              />
                            ) : null}
                            {files.type ==
                              "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                            files.type == "application/msword" ? (
                              <img
                                src={DOCS}
                                alt="docs"
                                className="rounded"
                                height="150"
                                width="150"
                              />
                            ) : null}
                            {files.type == "application/pdf" ? (
                              <img
                                src={PDF}
                                alt="docs"
                                className="rounded"
                                height="150"
                                width="150"
                              />
                            ) : null}
                            {files.type ==
                              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                            files.type == "application/vnd.ms-excel" ? (
                              <img
                                src={XLS}
                                alt="docs"
                                className="rounded"
                                height="150"
                                width="150"
                              />
                            ) : null}
                            <p className="text-center">
                              <span>{files.name}</span>
                            </p>
                          </div>
                        );
                      })}
                    </div>
                    <div className="activity_tagsRow">
                      {/* ----- loading area for file uploading ----- */}
                      {isUploading && (
                        <div
                          className="d-flex align-items-center"
                          style={{ backgroundColor: "#044954" }}
                        >
                          <h6 className="text-white fw-bold">
                            {t("Uploading Files")}
                          </h6>
                          {spinCount.map((item) => {
                            return (
                              <div
                                key={item}
                                className="spinner-grow mx-1 text-white"
                                style={{ height: "6px", width: "6px" }}
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      )}
                      {/* ----- loading area for file uploading end ----- */}
                    </div>
                    {/* ---- file preview section end ---- */}
                    {fileIsBig ? (
                      <div className="activity_tagsRow">
                        <h6 className="text-white bg-danger">
                          * {t("Uploaded File Size must be lower than 10 mb")}
                        </h6>
                      </div>
                    ) : null}

                    {/* ----- file upload ------ */}
                    <div className="activity_tagsRow">
                      {/* <h4>{t("Attachments")}</h4> */}
                      <div className="reply_fill">
                        <input
                          type="file"
                          className="form-control"
                          id="groupForumFile"
                          onChange={multipleFileUploadHandler}
                          multiple
                        />
                      </div>
                    </div>

                    {isSubmiting && (
                      <div className="rounded mt-2">
                        <div className="alert alert-info">
                          {t("Submiting")}
                          <span style={{ fontWeight: "bold" }}>
                            {" "}
                            {t("Your Question")}{" "}
                          </span>
                          {t("please wait")} ....
                        </div>
                      </div>
                    )}

                    <div className="question_btnArea">
                      <button
                        type="submit"
                        className="basic_btn"
                        disabled={isSubmiting ? true : false}
                        style={{
                          cursor: isSubmiting ? "not-allowed" : "pointer",
                        }}
                        onClick={saveQuery}
                      >
                        {t("Submit your query")}
                        {isSubmiting && (
                          <div
                            className="mx-2 spinner-border spinner-border-sm"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        )}
                      </button>
                    </div>
                  </div>
                ) : null}

                {/* ---------- Comments Textbox section ends here ---------- */}
                {/* ---------- Replies section starts here ---------- */}

                {!reviewPermission || queryLists.length === 0 ? (
                  <div className="empty_lessonsArea">
                    <div className="empty_lessonpic">
                      <img src={blankLesson} alt="blanklesson" />
                    </div>
                    <div className="empty_lessons_text">
                      <h2>{t("Sorry....!")}</h2>
                      {!reviewPermission ? (
                        <p>{t("You don't have privilege")}</p>
                      ) : (
                        <p>
                          {queryLists.length === 0 && (
                            <span>
                              {t("No forum post added yet for discussion")}
                            </span>
                          )}
                        </p>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="rply_bxArea">
                    <div className="reply_forumBx forum_grayBx">
                      {queryLists.map((queries, index) => {
                        return (
                          <div className="forum_cmntsRow" key={index}>
                            <div className="share_btn forum_shreBtn">
                              {/* <Link to="#">
                                  <span className="material-icons-outlined">
                                    share
                                  </span>
                                </Link> */}
                            </div>
                            <div className="team_userOuter forum_usrOuter">
                              <div className="team_usr_pic">
                                <img
                                  src={
                                    queries.moderator.thumbnail
                                      ? queries.moderator.thumbnail
                                      : userBlank
                                  }
                                  alt="user"
                                />
                              </div>
                              <div className="team_usrTxt">
                                {queries.moderator.name ? (
                                  <h3>{queries.moderator.name}</h3>
                                ) : null}
                                <h5>{queries.created}</h5>
                                <div className="forum_para">
                                  <h3>{queries.title}</h3>
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: queries.description,
                                    }}
                                  ></p>
                                </div>
                                {queries.media && queries.media.length > 0 ? (
                                  <div className="forumpic_outer">
                                    {queries.media.map((mediaSrc, index2) => {
                                      return (
                                        <div
                                          className="forum_pic"
                                          key={index2}
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            window.open(mediaSrc, "_blank")
                                          }
                                        >
                                          {mediaSrc.split(".").pop() == "jpg" ||
                                          mediaSrc.split(".").pop() == "JPG" ||
                                          mediaSrc.split(".").pop() == "png" ? (
                                            <img src={mediaSrc} alt="image" />
                                          ) : (
                                            <div>
                                              {mediaSrc.split(".").pop() ==
                                              "pdf" ? (
                                                <img src={PDF} alt="image" />
                                              ) : (
                                                <div>
                                                  {mediaSrc.split(".").pop() ==
                                                    "xlsx" ||
                                                  mediaSrc.split(".").pop() ==
                                                    "xls" ? (
                                                    <img
                                                      src={XLS}
                                                      alt="image"
                                                    />
                                                  ) : (
                                                    <div>
                                                      {mediaSrc
                                                        .split(".")
                                                        .pop() == "docx" ||
                                                      mediaSrc
                                                        .split(".")
                                                        .pop() == "txt" ||
                                                      mediaSrc
                                                        .split(".")
                                                        .pop() == "doc" ? (
                                                        <img
                                                          src={DOCS}
                                                          alt="image"
                                                        />
                                                      ) : null}
                                                    </div>
                                                  )}
                                                </div>
                                              )}
                                            </div>
                                          )}
                                        </div>
                                      );
                                    })}
                                  </div>
                                ) : null}

                                {/* ----- like comment area start --- */}
                                <div className="form_share">
                                  <Link
                                    to="#"
                                    role="button"
                                    onClick={() => forumLikeHandler(queries.id)}
                                  >
                                    <i className="material-icons-outlined">
                                      thumb_up
                                    </i>
                                    <em>{t("Likes")}</em>
                                    <span>{queries.likes}</span>
                                  </Link>
                                  <Link
                                    to="#"
                                    className="recmnts_btn"
                                    role="button"
                                    onClick={() => {
                                      toggleComment(index);
                                    }}
                                  >
                                    <i className="material-icons-outlined">
                                      chat
                                    </i>
                                    <em>{t("Comment")}</em>
                                    <span>{queries.comments.count}</span>
                                  </Link>
                                </div>
                                {/* ----- like comment area end --- */}

                                {/* ---- below comments area ---- */}

                                <div
                                  className="recomments_area"
                                  id={`forumgroupcomment${index}`}
                                >
                                  <div className="commnts_send_innr">
                                    <div className="rating_hdng">
                                      <h5>{t("Comment Now")}</h5>
                                    </div>
                                    <div className="commnts_sendArea">
                                      <textarea
                                        className="form-control"
                                        placeholder={t("Write your query here")}
                                        value={forumCommentMessage}
                                        onChange={(e) =>
                                          setForumCommentMessage(e.target.value)
                                        }
                                      ></textarea>
                                      <div className="furmcmnt_btn">
                                        <button
                                          className="cmnt_btn"
                                          onClick={() => {
                                            replyToForum(queries.id, index);
                                          }}
                                        >
                                          <span className="material-icons-outlined">
                                            send
                                          </span>
                                        </button>
                                      </div>
                                    </div>
                                    {commentIndex == index ? (
                                      <p className="text-danger mt-1 ">
                                        {" "}
                                        {showEmptyMessage && (
                                          <>* {t("Please add a comment")}</>
                                        )}
                                      </p>
                                    ) : null}
                                  </div>

                                  {/* ----- comments list section ---- */}

                                  {queries.comments.records.map(
                                    (commentElements, index2) => {
                                      return (
                                        <div
                                          className="recomments_row"
                                          key={index2}
                                        >
                                          <div className="cmnts_user">
                                            <img
                                              src={
                                                commentElements.senderimage
                                                  ? commentElements.senderimage
                                                  : userBlank
                                              }
                                              alt="sender"
                                            />
                                          </div>
                                          <div className="cmnts_user_textpnl">
                                            <h3>{commentElements.sender}</h3>
                                            {commentElements.created && (
                                              <h5>{commentElements.created}</h5>
                                            )}
                                            <div className="forum_para">
                                              <p>{commentElements.message}</p>
                                            </div>
                                            <div className="form_share">
                                              <Link
                                                to="#"
                                                role="button"
                                                onClick={() =>
                                                  likeCommentHandler(
                                                    commentElements.id
                                                  )
                                                }
                                              >
                                                <i className="material-icons-outlined">
                                                  thumb_up
                                                </i>
                                                <em>{t("Likes")}</em>
                                                <span>
                                                  {commentElements.likes}
                                                </span>
                                              </Link>
                                              <Link
                                                to="#"
                                                className="reply_btn"
                                                onClick={() => {
                                                  toggleReply(index2);
                                                }}
                                              >
                                                <i className="material-icons-outlined">
                                                  chat
                                                </i>
                                                <em>Reply</em>
                                                <span>
                                                  {commentElements.reply.count}
                                                </span>
                                              </Link>
                                            </div>

                                            {/* ----- reply section start ---- */}
                                            {showReplyBlock &&
                                            replyIndex == index2 ? (
                                              <div className="post_reply_area d-block">
                                                {/* ----- reply of comment box start ----- */}
                                                <div className="activity_comntsRow">
                                                  <div className="input_fill">
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      placeholder="Reply to comment"
                                                      value={
                                                        commentReplyMessage
                                                      }
                                                      onChange={(e) => {
                                                        setShowEmptyReplyMessage(
                                                          false
                                                        );
                                                        setCommentReplyMessage(
                                                          e.target.value
                                                        );
                                                      }}
                                                    />
                                                  </div>
                                                  <button
                                                    className="cmnt_btn"
                                                    onClick={() => {
                                                      replyToComment(
                                                        queries.id,
                                                        commentElements.id,
                                                        index2
                                                      );
                                                    }}
                                                  >
                                                    <span className="material-icons-outlined">
                                                      send
                                                    </span>
                                                  </button>
                                                </div>
                                                {/* ----- reply of comment box end ----- */}

                                                {/* ----- reply of comments start ----- */}
                                                {commentElements.reply.records.map(
                                                  (reply, index3) => {
                                                    return (
                                                      <div
                                                        className="chat_likeBx mb-5"
                                                        key={index3}
                                                      >
                                                        <div className="activity_para">
                                                          <p>
                                                            <Link to="#">
                                                              @{reply.receiver}
                                                            </Link>{" "}
                                                            {reply.message}
                                                          </p>
                                                        </div>
                                                        <div className="likecmnts_pnl">
                                                          <div className="cmnts_usr">
                                                            <img
                                                              src={
                                                                reply.senderimage
                                                                  ? reply.senderimage
                                                                  : userBlank
                                                              }
                                                              alt=""
                                                            />
                                                          </div>
                                                          <div className="cmnts_usertext">
                                                            <h3>
                                                              {reply.sender}
                                                            </h3>
                                                            <div className="form_share">
                                                              <Link
                                                                to="#"
                                                                onClick={() =>
                                                                  likeCommentHandler(
                                                                    reply.id
                                                                  )
                                                                }
                                                              >
                                                                <i className="material-icons-outlined">
                                                                  thumb_up
                                                                </i>
                                                                <em>Likes</em>
                                                                <span>
                                                                  {reply.likes}
                                                                </span>
                                                              </Link>
                                                              {/* <Link to="#">
                                                                <i className="material-icons-outlined">
                                                                  delete
                                                                </i>
                                                                <em>Remove</em>
                                                              </Link> */}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    );
                                                  }
                                                )}
                                                {/* ----- reply of comments start ----- */}
                                              </div>
                                            ) : null}
                                            {/* ----- reply section end ---- */}
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}

                                  {/* ----- comments list section end ---- */}
                                </div>

                                {/* ---- end of below comments ---- */}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forum;
