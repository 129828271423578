import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

//import custom components
import Header from "common/Header";
import Footer from "common/Footer";

// importing images here
import errorFrame from "assets/ErrorAssets/images/error-frame.png";
import errorGraphics from "assets/ErrorAssets/images/error-graphics.png";

const Error = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = "INDRA - 404";
  }, []);

  return (
    <>
      <Header />
      {/* <!-- error_bnrArea=--=======start --> */}
      <section
        className="error_banner"
        style={{ backgroundImage: `url(${errorFrame})` }}
      >
        <div className="container">
          <div className="error_outer">
            <div className="row">
              <div className="col-lg-6">
                <div className="error_text">
                  <h1>
                    <span>{t("oops!")}</span> {t("page not found")}
                  </h1>
                  <p>
                    {t(
                      "Looks like you followed a bad link. If you think this is a problem with us, please let us know."
                    )}
                  </p>
                  <div className="error_btn">
                    <Link to="/" className="basic_btn ylw_btn">
                      {t("Go Home")}
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="error_graphics">
                  <img src={errorGraphics} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- error_bnrArea=--=======end --> */}

      <Footer />
    </>
  );
};

export default Error;
