import React, { useEffect } from "react";

// importing custom components here
import Header from "common/Header";
import BreadCrumbs from "components/GroupDetailsComponents/common/BreadCrumbs";
import GroupDetailsTopSection from "./GroupDetailsTopSection";
import GroupDetailsBottomSection from "./GroupDetailsBottomSection";
import Footer from "common/Footer";

const GroupDetails = () => {
  useEffect(() => {
    document.title = "Group-Details";
  }, []);

  return (
    <>
      <Header />
      <BreadCrumbs />
      <GroupDetailsTopSection />
      <GroupDetailsBottomSection />
      <Footer />
    </>
  );
};

export default GroupDetails;
