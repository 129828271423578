/* eslint-disable */
import React, { useState, useEffect, useContext, useRef } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GlobalIndraInfo } from "App";

// url and get params in urls
import queryString from "query-string";
/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData, postData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* ---------------------------------------------------------------------------------- */

//importing images here
import chatLogo from "assets/ConversationAssests/svg/cht-logo.svg";
import defaultUser from "assets/ConversationAssests/images/default-user.png";
import arrowImg from "assets/ConversationAssests/images/Arrow.png";
import addFriend from "assets/ConversationAssests/images/add-friend-graphics.png";

// importing Modal here
import GroupModal from "components/ConversationComponents/Modals/GroupModal";
import GroupPeopleModal from "components/ConversationComponents/Modals/GroupPeopleModal";
import StartNewChatModal from "components/ConversationComponents/Modals/StartNewChatModal";
import LeftProfilePanel from "components/ConversationComponents/Others/LeftProfilePanel";
import AddTagsModal from "components/ConversationComponents/Modals/AddTagsModal";
import AppMenu from "components/ConversationComponents/Others/AppMenu";

const LeftPanel = (props) => {
  // getting token here
  const token = localStorage.getItem("token");

  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  const { realoadChatPanel, setRealoadChatPanel } = useContext(GlobalIndraInfo);

  // assign useref hook for auto scrolling
  const chatlistStartRef = useRef(null);

  const params = useParams();
  const location = useLocation();

  const [loggedinUserData, setLoggedinUserData] = useState({});

  // initial state for all contact list
  const [contacts, setContacts] = useState([]);

  // initial state for all contact list
  const [allcontacts, setAllcontacts] = useState([]);

  // initial state for get specific contact
  const [searchValue, setSearchValue] = useState("");

  const [isActivePopup, setIsActivePopup] = useState(false);

  // Initial useState to hold data for all the associated groups in the modal
  const [allGroups, setAllGroups] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [showEmptyMessage, setShowEmptyMessage] = useState(false);

  // aray for glow template loop
  const glowTemplateLoop = [1, 2, 3, 4];

  /*--- function for filter specific contacts */
  const filterContacthandler = (name) => {
    setSearchValue(name);
  };

  // API call to fetch conversations list
  const getContactConversationsList = async () => {
    if (!realoadChatPanel) {
      setIsLoading(true);
    }
    let requestURL =
      url.API_BaseUrl + url.API_CONTACTS_ADDEDTOCHAT + `?token=${token}`;

    try {
      const response = await getData(requestURL);

      if (response) {
        if (response.status) {
          if (response.data.length > 0) {
            setContacts(response.data);
            var crecords = response.data;
            +(
              // sort records by lastMessageid to show last chat user on top
              (+crecords.sort(function (x, y) {
                return y["lastmessageid"] - x["lastmessageid"];
              }))
            );
            +setContacts(crecords);
            setShowEmptyMessage(false);
          } else {
            setShowEmptyMessage(true);
          }
        }
      } else {
        setShowEmptyMessage(true);
      }
    } catch (error) {}
    setIsLoading(false);
  };

  // API call to fetch conversations list
  const getAllContacts = async () => {
    let requestURL =
      url.API_BaseUrl + url.API_ALL_CONTACTS + `?token=${token}&status=11`;

    try {
      const response = await getData(requestURL);

      if (response.status) {
        setAllcontacts(response.data);
      }
    } catch (error) {}
  };

  // function for get group details from API for start new group chat modal
  const getAllGroups = async () => {
    let requestURL =
      url.API_BaseUrl + url.API_ASSOCIATED_GROUPS + `?token=${token}&status=11`;

    try {
      const response = await getData(requestURL);

      if (response.status) {
        setAllGroups(response.data);
      } else {
        setAllGroups([]);
      }
    } catch (error) {}
  };

  // function for new chat through start new chat modal props
  const newChatHandler = (item) => {
    document.body.classList.add("chatmsg_open");
    props.chatInfo(item);
  };

  // function for new group selection
  const selectedGroupHandler = (item) => {
    document.body.classList.add("chatmsg_open");
    props.chatInfo(item);
  };

  // function fro display left panel
  const displayLeftPanel = () => {
    getContactConversationsList();
    getAllContacts();
    getAllGroups();
    props.reloadRightPanel();
  };

  // function for reset tag id
  const resetTagModal = () => {
    props.resetTagModal();
  };

  // function for get chat info from param url
  const fetchContactSelected = async (id) => {
    let searchQuery = queryString.parse(location.search);

    let requestURL =
      url.API_BaseUrl + url.API_CONTACT_SELECTED + `?token=${token}`;

    if (searchQuery.cid) {
      requestURL = requestURL + `&cid=${id}`;
    } else {
      requestURL = requestURL + `&gid=${id}`;
    }

    try {
      const response = await getData(requestURL);

      if (response.status) {
        if (response.data) {
          props.chatInfo(response.data);
          document.body.classList.add("chatmsg_open");
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (realoadChatPanel) {
      getContactConversationsList();
      getAllContacts();
      getAllGroups();
      setRealoadChatPanel(false);
      chatlistStartRef.current?.scrollIntoView(); // for auto scrolling up
    }
  }, [realoadChatPanel]);

  // --------- useEffect -----------
  useEffect(() => {
    if (localStorage.getItem("token") != null) {
      setLoggedinUserData(JSON.parse(localStorage.getItem("userInfo")));

      getContactConversationsList();
      getAllContacts();
      getAllGroups();
    }

    // auto select from url params single chat or group chat
    let searchQuery = queryString.parse(location.search);
    if (searchQuery) {
      if (searchQuery.cid) {
        fetchContactSelected(searchQuery.cid);
      } else {
        fetchContactSelected(searchQuery.gid);
      }
    }
  }, []);

  return (
    <>
      {/* ----- menu button logo refresh and display picture area ---- */}
      <div className="left_topPnl">
        <div className="profile_usrOuter">
          {/* ----- menu and logo area ----- */}
          <div className="profile_row">
            {/* ----- hamburger menubar icon ----- */}
            <div className="profile_bar">
              <Link
                to="#"
                onClick={() => {
                  document.body.classList.toggle("hamberger_menuOpen");
                }}
              >
                <i className="material-icons-round">menu </i>
              </Link>
            </div>
            {/* ----- end of hamburger menubar icon ----- */}

            {/* ----- logo area ----- */}
            <div className="profile_pic">
              <Link to="/">
                <img src={chatLogo} alt="chatLogo" />
              </Link>
            </div>
            {/* ----- end of logo area----- */}
          </div>
          {/* ----- end of  menu and logo area ----- */}

          <div className="profile_rfresh_outer">
            <div className="rfresh_icon">
              <Link
                to="#"
                onClick={() => {
                  setSearchValue("");
                  getContactConversationsList();
                }}
              >
                <span className="material-icons-outlined">refresh</span>
              </Link>
            </div>
            <div className="rfresh_profilePic">
              <Link
                to="#"
                onClick={() => {
                  document.body.classList.toggle("profile_open");
                }}
              >
                {loggedinUserData.image == null ? (
                  <img src={defaultUser} alt="" />
                ) : (
                  <img src={loggedinUserData.image} alt="image" />
                )}
              </Link>
            </div>
          </div>
        </div>

        {/* ----- searchbox for searching contact or group ---- */}
        <div className="usr_srchRow">
          <input
            type="text"
            className="form-control"
            placeholder={t("Search people, chats")}
            value={searchValue}
            onChange={(e) => {
              filterContacthandler(e.target.value);
            }}
          />
        </div>
        {/* ----- end of searchbox for searching contact or group ---- */}
      </div>

      {/* ----- all contact with group list area ---- */}
      {isLoading ? (
        <div className="chat_usrPnl">
          {/* ----- glow place area ----- */}
          <div className="chat_innrScroll">
            {glowTemplateLoop.map((item, index) => {
              return (
                <div
                  className="chat_usrRow placeglow_chatouter placeholder-glow"
                  key={index}
                >
                  <div className="user_placeHoledr">
                    <div className="default_usr">
                      <img src={defaultUser} alt="" />
                    </div>
                    <div className="default_usr_name">
                      <span className="placeholder placeholder-sm col-10 rounded-3"></span>
                      <span className="placeholder placeholder-sm col-6 rounded-3"></span>
                    </div>
                  </div>
                  <div className="glow_status">
                    <span className="placeholder placeholder-sm col-3 rounded-3"></span>
                  </div>
                </div>
              );
            })}
          </div>
          {/* ----- glow place area end ----- */}
        </div>
      ) : (
        <div className="chat_usrPnl">
          {/* ----- scroll area ---- */}
          {showEmptyMessage == true ? (
            <>
              <div className="empty_addlftPnl">
                <div className="add_textlftPnl">
                  <h3>{t("Add Friends")}</h3>
                  <p>
                    {t(
                      "There is Nobody in your contact invite your friends Now"
                    )}
                  </p>
                </div>
                <div className="add_lftPnl_graphics">
                  <img src={addFriend} alt="addfriend" />
                </div>
              </div>
              <div className="start_arwbtn">
                <Link to="#">{t("Start Here")}</Link>
                <span className="ditect_arrw">
                  <img src={arrowImg} alt="arrow" />
                </span>
              </div>
            </>
          ) : (
            <div className="chat_innrScroll">
              <div ref={chatlistStartRef} name="scrollRefDiv" />
              {/* ----- alll contact and group list start from here ---- */}
              {contacts.map((item, index) => (
                <div
                  key={index}
                  className={
                    item.label.toLowerCase().includes(searchValue.toLowerCase())
                      ? ""
                      : "hide"
                  }
                  /*
              on click the current JSON object is sent back to the
              parent component (index.js) as props data
            */
                  onClick={() => {
                    props.chatInfo(item);
                    document.body.classList.add("chatmsg_open");
                  }}
                >
                  {item.isGroup ? (
                    <>
                      {/*--- group section if group is true ---- */}
                      <div className="chat_usrRow group_msg_row">
                        <Link to="#">
                          <div className="usr_lftPnl">
                            <div className="chatting_user">
                              {/*--- group media section ---- */}
                              {item.members
                                .slice(0, 3)
                                .map((member, index2) => (
                                  <figure key={index2} className="group_usr">
                                    {member.image == null ? (
                                      <i className="material-icons-round">
                                        person
                                      </i>
                                    ) : (
                                      <img src={member.image} alt="media" />
                                    )}
                                  </figure>
                                ))}
                              {/*--- end of group media section ---- */}
                              {item.memberscount > 3 ? (
                                <span className="group_usr plus_usr">
                                  {item.memberscount - 3} <em>+</em>
                                </span>
                              ) : (
                                ""
                              )}
                            </div>

                            {/*--- group name and message section ---- */}
                            <div className="user_name">
                              <h3 className="font-weight-bold">{item.label}</h3>
                              {item.lastcommtype == null ? null : (
                                <p>
                                  {item.lastcommtype == "sent" ? (
                                    <span>{t("You")}: </span>
                                  ) : (
                                    ""
                                  )}

                                  {item.lastmessagetype == "email" ? (
                                    <>
                                      <i className="material-icons-round">
                                        email
                                      </i>
                                      Sent an email
                                    </>
                                  ) : (
                                    item.lastmessage.slice(0, 40)
                                  )}
                                </p>
                              )}
                            </div>
                            {/*--- end of group name and message section ---- */}
                          </div>

                          {/*--- group message time and message status section ---- */}
                          <div className="msg_statusRight">
                            <span className="msg_time">
                              {item.lastcommdate}
                              <br />
                              {item.lastcommhrs}
                            </span>
                          </div>
                          {/*--- end of group message time and message status section ---- */}
                        </Link>
                      </div>
                      {/*--- end of group section ---- */}
                    </>
                  ) : (
                    <>
                      {/*--- contact message section if group is false ---- */}
                      <div
                        className={
                          item.lastmessagetype == "sent"
                            ? "chat_usrRow send_msg_row"
                            : "chat_usrRow incoming_msg_row"
                        }
                      >
                        <Link to="#">
                          <div className="usr_lftPnl">
                            {/*--- contact image section ---- */}
                            <div className="chatting_user">
                              {item.image == null ? (
                                <img src={defaultUser} alt="" />
                              ) : (
                                <img src={item.image} alt="profile" />
                              )}
                            </div>
                            {/*--- end of contact image section ---- */}

                            {/*--- contact details and message section ---- */}
                            <div className="user_name">
                              <h3>{item.label}</h3>
                              {item.position != null ? (
                                <p className="text-primary">{item.position}</p>
                              ) : (
                                ""
                              )}
                              <p>
                                {item.lastcommtype == "sent" ? (
                                  <span>{t("You")}: </span>
                                ) : (
                                  ""
                                )}

                                {item.lastmessagetype == "email" ? (
                                  <>
                                    <i className="material-icons-round">
                                      email
                                    </i>
                                    Sent an email
                                  </>
                                ) : (
                                  item.lastmessage.slice(0, 40)
                                )}
                              </p>
                            </div>
                            {/*--- contact details and message section ---- */}
                          </div>

                          <div className="msg_statusRight">
                            {/*--- contact message time ---- */}
                            <span className="msg_time">
                              {item.lastcommdate}
                              <br />
                              {item.lastcommhrs}
                            </span>
                            {/*--- message with status section ---- */}
                            {item.lastmessageseenstat ? (
                              <>
                                {item.unreadmessages == "0" ? (
                                  <>
                                    {item.lastcommtype == "sent" ? (
                                      <>
                                        {item.lastmessageseenstat ? (
                                          <span className="msg_status double_tick seen_tick">
                                            <i className="material-icons-round">
                                              done_all
                                            </i>
                                          </span>
                                        ) : (
                                          <span className="msg_status double_tick">
                                            <i className="material-icons-round">
                                              done_all
                                            </i>
                                          </span>
                                        )}
                                      </>
                                    ) : (
                                      <span className="msg_status">
                                        <i className="material-icons-round">
                                          done
                                        </i>
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  <span className="pending_msg">
                                    {item.unreadmessages}
                                  </span>
                                )}
                              </>
                            ) : null}
                            {/*--- end of message with status section ---- */}
                          </div>
                        </Link>
                      </div>
                      {/*--- end of contact message section ---- */}
                    </>
                  )}
                </div>
              ))}
              {/* ----- alll contact and group list end here ---- */}
            </div>
          )}
          {/* ----- end of scroll area ---- */}

          {/* ----- add new chat or group button ---- */}

          <div
            className={"chat_cmntBx " + (isActivePopup ? "show_chatpopup" : "")}
          >
            <Link
              to="#"
              onClick={() => {
                setIsActivePopup(!isActivePopup);
              }}
              className="chat_btn"
            >
              <span className="material-icons-round">add_comment</span>
            </Link>
            <div className="lft_cht_popup">
              <ul>
                <li>
                  <Link
                    to="#"
                    onClick={() => {
                      setIsActivePopup(!isActivePopup);
                    }}
                    data-bs-toggle="modal"
                    data-bs-target="#start_newchat"
                  >
                    <i className="material-icons-round">person</i>
                    <span>{t("Add Contact")}</span>
                  </Link>
                </li>

                {/* --------- New Group Modal Modal button --------- */}
                <li>
                  <Link
                    to="#"
                    onClick={() => {
                      // it will trigger the GroupModal.js file
                      setIsActivePopup(!isActivePopup);
                    }}
                    data-bs-toggle="modal"
                    data-bs-target="#group_modal"
                  >
                    <i className="material-icons-round">people</i>
                    <span>{t("Add Group")}</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
      {/* ----- end of all contact list area ---- */}

      {/* ------------ Left Profile Panel drawer ------------- */}
      <LeftProfilePanel />

      {/* ------------ App Menu Item ------------- */}
      <AppMenu />

      {/* ------------ Start New Chat Modal ------------- */}
      <StartNewChatModal contacts={allcontacts} chatPerson={newChatHandler} />

      {/* ------------ Group Modal ------------- */}
      <GroupModal groups={allGroups} selectedGroup={selectedGroupHandler} />

      {/* ------------ Group People Modal ------------- */}
      <GroupPeopleModal
        contacts={allcontacts}
        chatGroup={newChatHandler}
        displayLeftPanel={displayLeftPanel}
      />

      <AddTagsModal
        chatId={props.chatId}
        contactId={props.contactId}
        mailId={props.mailId}
        groupLabelId={props.groupLabelId}
        resetTagModal={resetTagModal}
        displayLeftPanel={displayLeftPanel}
      />
    </>
  );
};

export default LeftPanel;
