/* eslint-disable */
import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GlobalIndraInfo } from "App";
import CookieConsentPopup from "common/cookie/CookieConsentPopup";
import CookieConfigureModal from "common/Modals/CookieConfigureModal";
import CookieAcceptModal from "common/Modals/CookieAcceptModal";

// Importing the Contact Form here
import ContactForm from "common/slider/ContactForm";

//import images
import footerBg from "assets/ActivityAssests/images/ftr-bg.png";
import SentModal from "./Modals/SentModal";
import CookieConfigurePopup from "./cookie/CookieConfigurePopup";

const Footer = () => {
  const { t } = useTranslation();
  const { cookieReload, setCookieReload } = useContext(GlobalIndraInfo);
  const [showCookieClear, setShowCookieClear] = useState(false);

  // Function to get the cookie value
  const getCookie = (name) => {
    const value = " " + document.cookie;
    const parts = value.split(" " + name + "=");
    return parts.length < 2 ? undefined : parts.pop().split(";").shift();
  };

  // const clearCookie = () => {
  //   if (getCookie("indra_necessary") == undefined) {
  //     return;
  //   } else {
  //     document.cookie =
  //       "indra_necessary" + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";

  //     if (getCookie("indra_preference") != undefined) {
  //       document.cookie =
  //         "indra_preference" + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  //     }
  //     if (getCookie("indra_marketing") != undefined) {
  //       document.cookie =
  //         "indra_marketing" + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  //     }
  //     if (getCookie("indra_analytics") != undefined) {
  //       document.cookie =
  //         "indra_analytics" + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  //     }
  //     let myOffcanvas = new bootstrap.Offcanvas(
  //       document.getElementById("acceptCookies")
  //     );
  //     myOffcanvas.show();
  //     setCookieReload(true);
  //   }
  // };

  // const afterClearCookie = () => {
  //   let myOffcanvas = new bootstrap.Offcanvas(
  //     document.getElementById("acceptCookies")
  //   );
  //   myOffcanvas.show();
  //   setCookieReload(true);
  // };

  useEffect(() => {
    if (cookieReload) {
      if (getCookie("indra_necessary") == undefined) {
        setShowCookieClear(false);
      } else {
        setShowCookieClear(true);
      }
      setCookieReload(false);
    }
  }, [cookieReload]);

  useEffect(() => {
    if (getCookie("indra_necessary") == undefined) {
      setShowCookieClear(false);
    } else {
      setShowCookieClear(true);
    }
    if (getCookie("indra_necessary") == undefined) {
      let myOffcanvas = new bootstrap.Offcanvas(
        document.getElementById("acceptCookies")
      );
      myOffcanvas.show();
    }
  }, []);

  return (
    <>
      <footer
        className="footer_outer"
        style={{ backgroundImage: `url(${footerBg})` }}
      >
        <div className="container">
          <div className="footer_top">
            <div className="row">
              {/* ------------- Indra logo starts here ------------- */}
              <div className="col-lg-2">
                <div className="ftr_logo">
                  <Link to="/">
                    <img src="images/ftr-logo.png" alt="" />
                  </Link>
                </div>
              </div>

              {/* ------------- (Contact, Accessibility, minsait URL) starts here ------------- */}
              <div className="col-lg-2 col-md-4 col-4">
                <div className="ftr_item">
                  <div className="ftr_links">
                    <ul>
                      {/* ---------------- Portfolio here ---------------- */}
                      <li>
                        <a
                          href="https://www.indraairsolutions.com/es"
                          target="_blank"
                        >
                          {t("Portfolio")}
                        </a>
                      </li>

                      {/* ---------------- Contact Slider here ---------------- */}
                      <li>
                        {/* <Link to="/contact-us">{t("Contact")}</Link> */}
                        <Link
                          to="#"
                          onClick={() => {
                            document.body.classList.add("contact_open");
                          }}
                        >
                          {t("Contact")}
                        </Link>
                      </li>
                      {/* ----- blog tab start ----- */}
                      <li>
                        <Link to="/blogs">{t("Information")}</Link>
                      </li>
                      {/* ----- blog tab end ----- */}

                      <li>
                        <a href="https://www.indracompany.com/" target="_blank">
                          www.indracompany.com
                        </a>
                      </li>

                      {/* ---------------- Accessibility URL here ---------------- */}
                      {/* <li>
                        <a href="https://www.indracompany.com/es/accesibilidad">
                          {t("Accessibility")}
                        </a>
                      </li> */}

                      {/* ---------------- Minsait URL here ---------------- */}
                      {/* <li>
                        <a href="https://www.minsait.com/es" target="_blank">
                          www.minsait.com
                        </a>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>

              {/* ------------- (Blogs, Site Map, Legal Info, URL) starts here ------------- */}
              <div className="col-lg-3 col-md-4 col-8">
                <div className="ftr_item">
                  <div className="ftr_links">
                    <ul>
                      <li>
                        <Link to="/blog/privacy-policy" target="_blank">
                          {t("Privacy policy")}
                        </Link>
                      </li>

                      <li>
                        <Link to="/blog/cookies-policy" target="_blank">
                          {t("Cookies policy")}
                        </Link>
                      </li>

                      <li>
                        <Link to="/blog/legal-terms-condition" target="_blank">
                          {t("Legal policy")}
                        </Link>
                      </li>
                      {/* ----- clear cookie ------ */}
                      {/* {showCookieClear && (
                        <li>
                          <Link to="#" onClick={clearCookie}>
                            {t("Clear cookie")}
                          </Link>
                        </li>
                      )} */}
                      {/* ----- URL start ----- */}
                    </ul>
                  </div>
                </div>
              </div>

              {/* ------------- (Social Media) starts here ------------- */}
              <div className="col-lg-5 col-md-4 col-12">
                <div className="ftr_item">
                  <h3>{t("Follow networks")}</h3>
                  <div className="ftr_social">
                    <ul>
                      {/* ---------- Facebook ---------- */}
                      <li>
                        <a href="https://www.facebook.com/indracompany/">
                          <i className="fa-brands fa-facebook-f"></i>
                        </a>
                      </li>

                      {/* ---------- Twitter ---------- */}
                      <li>
                        <a href="https://twitter.com/indracompany">
                          <i className="fa-brands fa-twitter"></i>
                        </a>
                      </li>

                      {/* ---------- YouTube ---------- */}
                      <li>
                        <a href="https://www.youtube.com/user/theindracompany">
                          <i className="fa-brands fa-youtube"></i>
                        </a>
                      </li>

                      {/* ---------- LinkedIn ---------- */}
                      <li>
                        <a href="https://www.linkedin.com/company/indra/">
                          <i className="fa-brands fa-linkedin-in"></i>
                        </a>
                      </li>

                      {/* ---------- Instagram ---------- */}
                      <li>
                        <a href="https://www.instagram.com/indracompany/">
                          <i className="fa-brands fa-instagram"></i>
                        </a>
                      </li>

                      {/* ---------- Slideshare ---------- */}
                      <li>
                        <a href="https://www.slideshare.net/IndraCompany">
                          <i className="fa-brands fa-slideshare"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <CookieConsentPopup />
      <CookieConfigureModal />
      <CookieAcceptModal />
      <ContactForm />
      <SentModal />
      {showCookieClear && <CookieConfigurePopup />}
    </>
  );
};

export default Footer;
