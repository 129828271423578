/* eslint-disable */
import React, { useState, useEffect, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GlobalIndraInfo } from "App";

//import images
import sentImage from "assets/HomeAssets/Images/sent.png";

const CookieConfigureModal = () => {
  const { setCookieReload } = useContext(GlobalIndraInfo);
  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  // Function to get the cookie value
  const getCookie = (name) => {
    const value = " " + document.cookie;
    const parts = value.split(" " + name + "=");
    return parts.length < 2 ? undefined : parts.pop().split(";").shift();
  };

  let currentdate = new Date();
  let datetime =
    currentdate.getDate() +
    "/" +
    (currentdate.getMonth() + 1) +
    "/" +
    currentdate.getFullYear() +
    " " +
    currentdate.getHours() +
    ":" +
    currentdate.getMinutes() +
    ":" +
    currentdate.getSeconds();

  let language = localStorage.getItem("language");

  const [selectPreference, setSelectPreference] = useState(false);
  const [selectMarketing, setselectMarketing] = useState(false);
  const [selectAnalytics, setselectAnalytics] = useState(false);

  // function for accpet all cookies
  const acceptAllCookiesHandler = () => {
    if (language == null) {
      language = "en";
    }

    let noOfCookieAgree;

    if (selectPreference && selectMarketing && selectAnalytics) {
      noOfCookieAgree = 3;
    }

    if (
      (selectPreference && selectMarketing) ||
      (selectPreference && selectAnalytics) ||
      (selectMarketing && selectAnalytics)
    ) {
      noOfCookieAgree = 2;
    }

    if (selectPreference || selectMarketing || selectAnalytics) {
      noOfCookieAgree = 1;
    } else {
      noOfCookieAgree = 0;
    }

    let cookieValue = {
      cookieAgreed: 3,
      lastlogin: datetime,
      cookiesAccepted: true,
      cookies_preference: true,
      cookies_marketing: true,
      cookies_analytics: true,
      language,
    };

    const exdate = new Date();
    exdate.setHours(exdate.getHours() + 15 * 24);

    document.cookie = "indra_necessary" + "=" + JSON.stringify(cookieValue);
    +";expires=" +
      exdate.toUTCString() +
      ";path=" +
      "/" +
      ";domain=" +
      "" +
      ";SameSite=" +
      "none" +
      ";secure=" +
      "";

    document.cookie =
      "indra_preference" +
      "=" +
      true +
      ";expires=" +
      exdate.toUTCString() +
      ";path=" +
      "/" +
      ";domain=" +
      "" +
      ";SameSite=" +
      "none" +
      ";secure=" +
      "";

    document.cookie =
      "indra_marketing" +
      "=" +
      true +
      ";expires=" +
      exdate.toUTCString() +
      ";path=" +
      "/" +
      ";domain=" +
      "" +
      ";SameSite=" +
      "none" +
      ";secure=" +
      "";
    document.cookie =
      "indra_analytics" +
      "=" +
      true +
      ";expires=" +
      exdate.toUTCString() +
      ";path=" +
      "/" +
      ";domain=" +
      "" +
      ";SameSite=" +
      "none" +
      ";secure=" +
      "";

    let myConfigModal = document.querySelector("#cookie_config_mdl");
    let configModal = bootstrap.Modal.getInstance(myConfigModal);
    configModal.hide();
    let offCanvasPopup = document.querySelector("#acceptCookies");
    let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
    offCanvas.hide();
    // let myModal = new bootstrap.Modal(
    //   document.getElementById("cookie_accept_mdl")
    // );
    // myModal.show();
    setCookieReload(true);
  };

  // function for accpet selected cookies
  const saveCookiesHandler = () => {
    if (language == null) {
      language = "en";
    }

    let noOfCookieAgree;

    if (selectPreference && selectMarketing && selectAnalytics) {
      noOfCookieAgree = 3;
    }

    if (
      (selectPreference && selectMarketing) ||
      (selectPreference && selectAnalytics) ||
      (selectMarketing && selectAnalytics)
    ) {
      noOfCookieAgree = 2;
    }

    if (selectPreference || selectMarketing || selectAnalytics) {
      noOfCookieAgree = 1;
    }

    let cookieValue = {
      cookieAgreed: noOfCookieAgree,
      lastlogin: datetime,
      cookiesAccepted: true,
      cookies_preference: selectPreference,
      cookies_marketing: selectMarketing,
      cookies_analytics: selectAnalytics,
      language,
    };

    const exdate = new Date();
    exdate.setHours(exdate.getHours() + 15 * 24);

    document.cookie = "indra_necessary" + "=" + JSON.stringify(cookieValue);
    +";expires=" +
      exdate.toUTCString() +
      ";path=" +
      "/" +
      ";domain=" +
      "" +
      ";SameSite=" +
      "none" +
      ";secure=" +
      "";

    if (selectPreference) {
      document.cookie =
        "indra_preference" +
        "=" +
        selectPreference +
        ";expires=" +
        exdate.toUTCString() +
        ";path=" +
        "/" +
        ";domain=" +
        "" +
        ";SameSite=" +
        "none" +
        ";secure=" +
        "";
      setSelectPreference(false);
    }

    if (selectMarketing) {
      document.cookie =
        "indra_marketing" +
        "=" +
        selectMarketing +
        ";expires=" +
        exdate.toUTCString() +
        ";path=" +
        "/" +
        ";domain=" +
        "" +
        ";SameSite=" +
        "none" +
        ";secure=" +
        "";
      setselectMarketing(false);
    }

    if (selectAnalytics) {
      document.cookie =
        "indra_analytics" +
        "=" +
        selectAnalytics +
        ";expires=" +
        exdate.toUTCString() +
        ";path=" +
        "/" +
        ";domain=" +
        "" +
        ";SameSite=" +
        "none" +
        ";secure=" +
        "";
      setselectAnalytics(false);
    }

    let myConfigModal = document.querySelector("#cookie_config_mdl");
    let configModal = bootstrap.Modal.getInstance(myConfigModal);
    configModal.hide();
    let offCanvasPopup = document.querySelector("#acceptCookies");
    let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
    offCanvas.hide();
    // let myModal = new bootstrap.Modal(
    //   document.getElementById("cookie_accept_mdl")
    // );
    // myModal.show();
    setCookieReload(true);
  };

  // function for reset all states
  const closeModalHandler = () => {
    if (getCookie("indra_necessary") == undefined) {
      let myOffcanvas = new bootstrap.Offcanvas(
        document.getElementById("acceptCookies")
      );
      myOffcanvas.show();
    }
    setSelectPreference(false);
    setselectMarketing(false);
    setselectAnalytics(false);
  };

  return (
    <div className="job_modal">
      <div
        className="modal fade"
        id="cookie_config_mdl"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="cookie_close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={closeModalHandler}
              >
                <i className="material-icons-outlined">close</i>
              </button>
            </div>

            <div className="modal-body">
              <div className="login_body">
                <div className="success_mdlpic">
                  {/* <img src={sentImage} alt="" /> */}
                </div>

                <p className="text-secondary mb-2">
                  {t(
                    "You can configure and / or accept the use of cookies through the boxes shown below. If you want more information you can visit our"
                  )}

<Link to="/blog/cookies-policy" target="_blank" className="text-primary fst-italic text-decoration-underline">  {t("Extended Cookies Policy")} </Link>
                </p>

                <div className="row">
                  <div className="col-lg-9">
                    {/* ------ necessary block start ----- */}
                    <div className="d-flex align-items-baseline">
                      <div className="form-check form-switch toogle_switch_sec">
                        <input
                          className="form-check-input mt-3 toogle_switch"
                          type="checkbox"
                          id="flexSwitchCheckCheckedDisabled"
                          checked
                          disabled
                        />
                      </div>

                      <div className="">
                        <p className="fw-bold">{t("Necessary")}</p>
                        <p className="text-secondary">
                          {t(
                            "Strictly necessary cookies guarantee functions that are essential for the proper functioning of the website. As a result, these cookies cannot be disabled. They are used exclusively on this website and, therefore, they are own cookies. This means that all the information stored in the cookies will only be sent to this website."
                          )}
                        </p>
                      </div>
                    </div>
                    {/* ------ necessary block end ----- */}

                    {/* ------ preferences block start ----- */}
                    <div className="d-flex align-items-baseline">
                      <div className="form-check form-switch toogle_switch_sec">
                        <input
                          className="form-check-input mt-3 toogle_switch"
                          type="checkbox"
                          checked={selectPreference}
                          onChange={(e) =>
                            setSelectPreference(e.target.checked)
                          }
                        />
                      </div>

                      <div>
                        <p className="fw-bold">{t("Preferences")}</p>
                        <p className="text-secondary">
                          {t(
                            "Preference cookies allow a website to remember information that changes the way the page behaves or how it looks. Such as your preferred language or the region you are in."
                          )}
                        </p>
                      </div>
                    </div>
                    {/* ------ preferences block start ----- */}

                    {/* ------ Marketing block start ----- */}
                    <div className="d-flex align-items-baseline">
                      <div className="form-check form-switch toogle_switch_sec">
                        <input
                          className="form-check-input mt-3 toogle_switch"
                          type="checkbox"
                          checked={selectMarketing}
                          onChange={(e) => setselectMarketing(e.target.checked)}
                        />
                      </div>

                      <div>
                        <p className="fw-bold">{t("Marketing")}</p>
                        <p className="text-secondary">
                          {t(
                            "Marketing cookies are used to track visitors across web pages. The intent is to display relevant and engaging ads to the individual user."
                          )}
                        </p>
                      </div>
                    </div>
                    {/* ------ Marketing block start ----- */}

                    {/* ------ Analytics block start ----- */}
                    <div className="d-flex align-items-baseline">
                      <div className="form-check form-switch toogle_switch_sec">
                        <input
                          className="form-check-input mt-3 toogle_switch"
                          type="checkbox"
                          checked={selectAnalytics}
                          onChange={(e) => setselectAnalytics(e.target.checked)}
                        />
                      </div>

                      <div>
                        <p className="fw-bold">{t("Analytics")}</p>
                        <p className="text-secondary">
                          {t(
                            "Statistical cookies help website owners understand how visitors interact with websites by collecting and providing information anonymously."
                          )}
                        </p>
                      </div>
                    </div>
                    {/* ------ Analytics block start ----- */}
                  </div>
                  <div className="col-lg-3">
                    <div className="h-100 w-100 d-flex flex-column justify-content-end">
                      <button
                        className="basic_btn ylw_btn me-3 mb-2 mt-1"
                        onClick={acceptAllCookiesHandler}
                      >
                        {t("Accept all cookies")}
                      </button>

                      <button
                        type="button"
                        className="border_btn save_btn_cookie"
                        onClick={saveCookiesHandler}
                      >
                        {t("Save options")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookieConfigureModal;
