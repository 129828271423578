/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GlobalIndraInfo } from "App";
import { t } from "i18next";

// import SimilarGroups from "../common/SimilarGroups";

// import Gateway for API fetch call
import { getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";

//import images
import defaultUser from "assets/ConversationAssests/images/default-user.png";
import blankLesson from "assets/CourseAssets/svg/lesson-empty.svg";

const Members = () => {
  // saving user token here
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  // 👇️ get ID from url
  const params = useParams();

  const { t } = useTranslation();

  const glowCount = [1, 2, 3, 4];

  const { isApproved, reloadMemberTab, setReloadMemberTab } =
    useContext(GlobalIndraInfo);

  // initials for group details
  const [groupMemberLists, setGroupMemberLists] = useState([]);
  const [isLoading, setIsLoading] = useState(false); //for glow placeholder

  const getGroupMembers = async (id) => {
    setIsLoading(true);
    let requestURL =
      url.API_BaseUrl +
      url.API_GROUP_MEMBER_LIST +
      `?token=${token}` +
      `&id=${id}`;

    try {
      const response = await getData(requestURL);

      if (response.status) {
        if (response.data) {
          if (response.data.members) {
            setGroupMemberLists(response.data.members);
          } else {
            setGroupMemberLists([]);
          }
        } else {
          setGroupMemberLists([]);
        }
        setIsLoading(false);
      }
    } catch (error) {}
  };

  // reload tab after join group
  useEffect(() => {
    if (reloadMemberTab) {
      getGroupMembers(params.id);
      setReloadMemberTab(false);
    }
  }, [reloadMemberTab]);

  useEffect(() => {
    getGroupMembers(params.id);
  }, [params.id]);

  return (
    <div className="tab-pane fade show active" id="evnt_members">
      {isLoading ? (
        <div className="training_outer">
          <div className="course_detailspnl">
            {/* <div className="contnt_hdng">
              <span
                className="placeholder rounded-3 col-2"
                style={{ height: "30px" }}
              ></span>
            </div> */}
            <div className="member_box_outer">
              <div className="row">
                {glowCount.map((count) => {
                  return (
                    <div className="col-lg-3 col-sm-6" key={count}>
                      <div className="expertbx_item members_teamItem">
                        <div
                          className="membrs_teamOuter"
                          style={{ minHeight: "85px" }}
                        >
                          <span
                            className="placeholder rounded-circle"
                            style={{ width: "40px", height: "40px" }}
                          ></span>
                          <div className="desc flex-fill ps-3">
                            <span
                              className="placeholder d-block rounded-3 col-8 mb-3"
                              style={{ height: "20px" }}
                            ></span>
                            <span className="placeholder placeholder-sm d-block rounded-3 col-6 mb-3"></span>
                            <span className="placeholder placeholder-sm d-block rounded-3 col-5"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="training_outer">
          {groupMemberLists.length === 0 ? (
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="empty_lessonsArea">
                  <div className="empty_lessonpic">
                    <img src={blankLesson} alt="blanklesson" />
                  </div>
                  <div className="empty_lessons_text">
                    <h2>{t("Sorry....!")}</h2>
                    <p>{t("Members are not available at this moment.")}</p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              {isApproved || userInfo.isadmin ? (
                <div className="col-lg-12">
                  <div className="course_detailspnl">
                    {/* <div className="contnt_hdng tab_Hdng">
                    <h4>Members</h4>
                  </div> */}
                    <div className="champions_outer">
                      <div className="row">
                        {groupMemberLists.map((member, index) => {
                          return (
                            <div className="col-lg-3 col-sm-6" key={index}>
                              <div className="expertbx_item members_teamItem">
                                <div className="membrs_teamOuter">
                                  <div className="team_membrs_pic">
                                    <img
                                      src={
                                        member.image
                                          ? member.image
                                          : defaultUser
                                      }
                                      alt="MemberPic"
                                    />
                                  </div>
                                  <div className="membrs_team_details">
                                    <div className="team_name">
                                      <h3>{member.name}</h3>
                                      <h5>{member.position}</h5>
                                    </div>
                                    <div className="team_rating star_color ">
                                      <h3>
                                        <i className="material-icons-outlined">
                                          star
                                        </i>
                                        {member.rating && (
                                          <span>{member.rating}</span>
                                        )}
                                      </h3>

                                      <h6>
                                        {member.reviews}
                                        <span className="mx-1">
                                          ({t("reviews")})
                                        </span>
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="empty_lessonsArea">
                  <div className="empty_lessonpic">
                    <img src={blankLesson} alt="blanklesson" />
                  </div>
                  <div className="empty_lessons_text">
                    <h2>{t("Sorry....!")}</h2>
                    <p>{t("You don't have privilege")}</p>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Members;
