import React, { useEffect } from "react";

// importing custom components here
import Header from "common/Header";
import BreadCrumb from "components/CourseTrainingDetailsComponent/common/BreadCrumb";
import CourseTrainingDetailsTopSection from "./CourseTrainingDetailsTopSection";
import CourseTrainingDetailsBottomSection from "./CourseTrainingDetailsBottomSection";
import Footer from "common/Footer";

const CourseTrainingDetails = () => {
  useEffect(() => {
    document.title = "Course-Training-Details";
  }, []);

  return (
    <>
      <Header />
      <BreadCrumb />
      <CourseTrainingDetailsTopSection />
      <CourseTrainingDetailsBottomSection />
      <Footer />
    </>
  );
};

export default CourseTrainingDetails;
