/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// import Gateway for API fetch call
import { getData, postData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";

// importing images here.

const GroupRightProfilePanel = (props) => {
  // get loggedin User information from local storage
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  // getting token here
  const token = localStorage.getItem("token");

  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  // function for delete group
  const deleteGroupHandler = async () => {
    let gid = props.groupInfo.id;
    let requestURL =
      url.API_BaseUrl +
      url.API_GROUP_DELETE +
      `?token=${token}` +
      `&gid=${gid}`;

    try {
      const response = await getData(requestURL);

      if (response.status) {
        window.location.reload();
      }
    } catch (error) {}
  };

  // function for leave group
  const leaveGroupHandler = async () => {
    let gid = props.groupInfo.id;
    let requestURL =
      url.API_BaseUrl + url.API_GROUP_LEAVE + `?token=${token}` + `&gid=${gid}`;

    try {
      const response = await getData(requestURL);

      if (response.status) {
        window.location.reload();
      }
    } catch (error) {}
  };

  return (
    <>
      {props.groupInfo == undefined || props.groupInfo == null ? null : (
        <div className="profile_pnlArea">
          {/* ---------- Profile heading section ---------- */}
          <div className="profile_hdngTop">
            <h3>{t("Profile")}</h3>
            <span
              className="profile_crossbtn"
              onClick={() => {
                document.body.classList.toggle("profile_menuPnl_open");
              }}
            >
              <i className="material-icons-round"> close </i>
            </span>
          </div>
          {/* ---------- end of Profile heading section ---------- */}

          {/* ---------- Profile body section ---------- */}
          <div className="profile_innr_body">
            <div className="profile_innrScroll">
              {/* ---------- Group Image && name section ---------- */}
              {props.groupInfo == null ? null : (
                <div className="profile_info_row">
                  <div className="profile_picPnl">
                    <div className="profile_img grp_profileImg">
                      {props.groupInfo.image == null ? (
                        <div className="chatting_user">
                          {props.groupInfo.members
                            .slice(0, 3)
                            .map((item, index) => (
                              <figure className="group_usr" key={index}>
                                {item.image == null ? (
                                  <i className="material-icons-round">person</i>
                                ) : (
                                  <img src={item.image} alt="image" />
                                )}
                              </figure>
                            ))}

                          {props.groupInfo.members.length <= 3 ? null : (
                            <span className="group_usr plus_usr">
                              {props.groupInfo.members.length - 3}
                              <em>+</em>
                            </span>
                          )}
                        </div>
                      ) : (
                        <img src={props.groupInfo.image} alt="userProfilePic" />
                      )}
                    </div>
                    <div className="profile_textPnl">
                      <h4>
                        {props.groupInfo.name == null ||
                        props.groupInfo.name == undefined
                          ? props.groupInfo.label
                          : props.groupInfo.name}
                      </h4>
                      <h5>
                        {props.groupInfo.memberscount} {t("Members")}
                      </h5>
                    </div>
                  </div>
                  {props.groupInfo.tags.length > 0 ? (
                    <div className="tag_msg">
                      <Link to="#">
                        <i className="material-icons">label</i>
                        {props.groupInfo.tags.map((item2, index2) => {
                          return <span key={index2}>{item2.label},</span>;
                        })}
                      </Link>
                    </div>
                  ) : null}
                </div>
              )}
              {/* ---------- end of Group Image section ---------- */}

              {/* ---------- Group moderator section ---------- */}
              {props.groupInfo == null ? null : (
                <div className="profile_info_row">
                  <div className="profile_details_row">
                    <div className="info_icon">
                      <i className="material-icons-round">person </i>
                      <span>{t("Moderator")}</span>
                    </div>
                    <div className="chat_usrRow incoming_msg_row">
                      <Link to="#">
                        <div className="usr_lftPnl">
                          {/*--- contact image section ---- */}
                          <div className="chatting_user">
                            {props.groupInfo.moderatorimage == null ? (
                              <span className="material-icons-round">
                                person
                              </span>
                            ) : (
                              <img
                                src={props.groupInfo.moderatorimage}
                                alt="profile"
                              />
                            )}
                          </div>
                          {/*--- end of contact image section ---- */}

                          {/*--- contact details and message section ---- */}
                          <div className="user_name">
                            <h3>{props.groupInfo.moderator}</h3>
                          </div>
                          {/*--- contact details and message section ---- */}
                        </div>
                      </Link>
                    </div>

                    {/* {props.groupInfo.moderatorimage == null ? (
                      <span className="material-icons-round">person</span>
                    ) : (
                      <img src={props.groupInfo.moderatorimage} alt="profile" />
                    )}
                    <p>
                      <Link to="#">
                        {props.groupInfo.moderator != null
                          ? props.groupInfo.moderator
                          : null}
                      </Link>
                    </p> */}
                  </div>
                </div>
              )}
              {/* ---------- end of Group moderator section ---------- */}

              {/* ------- Member section ----- */}
              <div className="profile_info_row">
                <div className="profile_details_row">
                  <div className="info_icon">
                    <i className="material-icons-round">persons</i>
                    <span>{t("Members")}</span>
                  </div>
                  {props.groupInfo.members.map((item, index) => (
                    <div
                      key={index}
                      className={
                        item.lastmessagetype == "sent"
                          ? "chat_usrRow send_msg_row"
                          : "chat_usrRow incoming_msg_row"
                      }
                    >
                      <Link to="#">
                        <div className="usr_lftPnl">
                          {/*--- contact image section ---- */}
                          <div className="chatting_user">
                            {item.image == null ? (
                              <span className="material-icons-round">
                                person
                              </span>
                            ) : (
                              <img src={item.image} alt="profile" />
                            )}
                          </div>
                          {/*--- end of contact image section ---- */}

                          {/*--- contact details and message section ---- */}
                          <div className="user_name">
                            <h3>{item.name}</h3>
                          </div>
                          {/*--- contact details and message section ---- */}
                        </div>
                      </Link>
                    </div>
                  ))}
                  {props.groupInfo.memberscount <= 5 ? null : (
                    <div className="chat_usrRow send_msg_row incoming_msg_row">
                      <Link to="#">
                        <div className="usr_lftPnl">
                          {/*--- contact details and message section ---- */}
                          <div className="user_name">
                            <h3 className="fw-bold">
                              + {props.groupInfo.memberscount - 5} more
                            </h3>
                          </div>
                          {/*--- contact details and message section ---- */}
                        </div>
                      </Link>
                    </div>
                  )}
                </div>
              </div>

              {/* ------- end of Member section ----- */}

              {/* ---------- User address section ---------- */}
              {/* <div className="profile_info_row">
                <div className="profile_details_row">
                  <div className="info_icon">
                    <i className="material-icons-round">place </i>
                    <span>Address</span>
                  </div>
                  <p>
                    {props.groupInfo.address
                      ? props.groupInfo.address
                      : "Madrid, Spain"}
                  </p>
                </div>
              </div> */}
              {/* ---------- end of User address section ---------- */}

              {/* ---------- User work information section ---------- */}
              {/* <div className="profile_info_row">
                <div className="profile_details_row">
                  <div className="info_icon">
                    <i className="material-icons-round">location_city </i>
                    <span>Work</span>
                  </div>
                  <p>{props.groupInfo.work ? props.groupInfo.work : null}</p>
                </div>
              </div> */}
              {/* ---------- end of User work information section ---------- */}

              {/* ---------- Delete chat section ---------- */}
              {props.groupInfo.role == "Moderator" ? (
                <div
                  className="profile_info_row"
                  style={{ cursor: "pointer" }}
                  onClick={deleteGroupHandler}
                >
                  <div className="profile_details_row">
                    <div className="info_icon">
                      <i className="material-icons-round">delete </i>
                      <span>{t("Delete Group")}</span>
                    </div>
                  </div>
                </div>
              ) : null}
              {/* ---------- end of Delete chat section ---------- */}

              {/* ---------- Toggle 'mute' switch section ---------- */}
              {/* <div className="profile_info_row">
                <div className="profile_details_row mute_row">
                  <div className="info_icon">
                    <i className="material-icons-round">
                      notifications_active{" "}
                    </i>
                    <span>{t("Mute Notification")}</span>
                  </div>
                  <div className="toggle_switch">
                    <div className="switch_box box_1">
                      <input type="checkbox" className="switch_1" />
                    </div>
                  </div>
                </div>
              </div> */}
              {/* ---------- end of Toggle 'mute' switch section ---------- */}

              {/* ---------- User attachments section ---------- */}
              {/* <div className="profile_info_row">
                <div className="profile_details_row">
                  <div className="info_icon">
                    <i className="material-icons-round">collections </i>
                    <span>Attachments</span>
                  </div>
                  <div className="profile_attachedment_outer">
                    <div className="attachedment_item">
                      <Link to={fancyBig} data-fancybox="fancyBig">
                        <img src={attached1} alt="attached1" />
                      </Link>
                    </div>
                    <div className="attachedment_item">
                      <Link to={fancyBig} data-fancybox="fancyBig">
                        <img src={attached2} alt="attached2" />
                      </Link>
                    </div>
                    <div className="attachedment_item">
                      <Link to={fancyBig} data-fancybox="fancyBig">
                        <img src={attached3} alt="attached3" />
                      </Link>
                    </div>
                    <div className="attachedment_item">
                      <Link to={fancyBig} data-fancybox="fancyBig">
                        <img src={attached4} alt="attached4" />
                      </Link>
                    </div>
                    <div className="attachedment_item">
                      <Link to={fancyBig} data-fancybox="fancyBig">
                        <img src={attached5} alt="attached5" />
                      </Link>
                    </div>
                    <div className="attachedment_item">
                      <Link to={fancyBig} data-fancybox="fancyBig">
                        <img src={attached6} alt="attached6" />
                      </Link>
                    </div>
                    <div className="attachedment_item">
                      <Link to={fancyBig} data-fancybox="fancyBig">
                        <img src={attached2} alt="attached2" />
                      </Link>
                    </div>
                    <div className="attachedment_item">
                      <Link to={attached4} data-fancybox="fancyBig">
                        <img src={attached4} alt="attached4" />
                      </Link>
                    </div>
                    <div className="attachedment_item">
                      <Link to={attached6} data-fancybox="fancyBig">
                        <img src={attached6} alt="attached6" />
                      </Link>
                    </div>
                    <div className="attachedment_item">
                      <Link to={attached1} data-fancybox="fancyBig">
                        <img src={attached1} alt="attached1" />
                      </Link>
                    </div>

                    <div className="attachedment_item">
                      <Link to={attached3} data-fancybox="fancyBig">
                        <img src={attached3} alt="attached3" />
                      </Link>
                    </div>
                    <div className="attachedment_item">
                      <Link to={attached5} data-fancybox="fancyBig">
                        <img src={attached5} alt="attached5" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* ---------- end of User attachments section ---------- */}

              {/* ---------- Leave Group section ---------- */}
              {props.groupInfo.role == "Member" ? (
                <div className="profile_info_row">
                  <div className="profile_details_row signout_fill">
                    <Link
                      to="#"
                      className="info_icon"
                      onClick={leaveGroupHandler}
                    >
                      <i className="material-icons-round">account_circle </i>
                      <span>{t("Leave Group")}</span>
                    </Link>
                  </div>
                </div>
              ) : null}
              {/* ---------- end of Leave Group section ---------- */}
            </div>
          </div>
          {/* ---------- end of Profile heading section ---------- */}
        </div>
      )}
    </>
  );
};

export default GroupRightProfilePanel;
