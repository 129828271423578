/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// importing custom components here
import ChatInfo from "components/ConversationComponents/Others/ChatInfo";
import ChatBox from "components/ConversationComponents/Others/ChatBox";
import GlobalBoard from "components/ConversationComponents/All/GlobalBoard";
import ChatBoard from "components/ConversationComponents/Chat/ChatBoard";
import MailBoard from "components/ConversationComponents/Mail/MailBoard";
import RightProfilePanel from "components/ConversationComponents/Others/RightProfilePanel";

// importing modals here
import GroupModal from "components/ConversationComponents/Modals/GroupModal";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData, postData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* ---------------------------------------------------------------------------------- */

//import images
import defaultUser from "assets/ConversationAssests/images/default-user.png";

const ContactRightPanel = (props) => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  //user data initial
  const [chatData, setChatData] = useState(null);

  //use state for glow placeholder
  const [isHeaderLoading, setIsHeaderLoading] = useState(false);

  // initial state for last chat id
  const [lastChatId, setlastChatId] = useState(null);

  // initial state for contact chat section
  const [chatDetails, setChatDetails] = useState([]);

  // initial state for contact mail data
  const [mailDetails, setMailDetails] = useState([]);

  // initial state for cron id
  const [cronId, setCronId] = useState(null);

  // initial state for chat parent id
  const [chatParent, setChatParent] = useState({});

  //initial state for mail data
  const [mailInfo, setMailInfo] = useState({});

  /*----- initial state for toggling tabs ------*/
  const [allMessageTab, setAllMessageTab] = useState(false);
  const [chatMessageTab, setChatMessageTab] = useState(true);
  const [mailMessageTab, setMailMessageTab] = useState(false);
  /*----- end of initial state for toggling tabs ------*/

  const [hideReplyBox, setHideReplyBox] = useState(false); // for hide chat replybox

  // for chat search query
  const [searchQuery, setSearchQuery] = useState("");
  const [searchWarning, setSearchWarning] = useState(false);
  const [searchResultFound, setSearchResultFound] = useState(false);

  // for refresh chat and mail
  const [chatIsLoading, setChatIsLoading] = useState(false);
  const [mailIsLoading, setMailIsLoading] = useState(false);

  // funtion for display allmessage tab
  const showAllMessageTab = () => {
    setAllMessageTab(true);
    setChatMessageTab(false);
    setMailMessageTab(false);
  };

  // funtion for display chatmessage tab
  const showChatMessageTab = () => {
    setAllMessageTab(false);
    setChatMessageTab(true);
    setMailMessageTab(false);
  };

  // funtion for display mailmessage tab
  const showMailMessageTab = () => {
    setAllMessageTab(false);
    setChatMessageTab(false);
    setMailMessageTab(true);
  };

  /*------ temprary function for get chat details from sender side ------*/
  const messageDetails = (chatData, sender, parentMessage, media) => {
    let mediaUrl;

    if (media == [] || media == undefined) {
      mediaUrl = [];
    } else {
      mediaUrl = media;
    }

    if (sender == undefined || parentMessage == undefined) {
      setChatDetails([
        ...chatDetails,
        {
          type: "chat",
          id: 564,
          uid: userInfo.id,
          sender: userInfo.name,
          mail: userInfo.email,
          image: userInfo.image,
          message: chatData,
          media: mediaUrl,
          parent: null,
          time: "now",
          created: new Date().getTime(),
          apiResponse: false,
        },
      ]);
    } else {
      setChatDetails([
        ...chatDetails,
        {
          type: "chat",
          id: 564,
          uid: userInfo.id,
          sender: userInfo.name,
          mail: userInfo.email,
          image: userInfo.image,
          message: chatData,
          media: mediaUrl,
          parent: {
            sender: sender,
            message: parentMessage,
          },
          time: "now",
          created: new Date().getTime(),
          apiResponse: false,
        },
      ]);
    }
  };
  /*------ end of temprary function for get chat details from sender side ------*/

  /*------ function for get chat info --------*/
  const fetchContactSelected = async () => {
    setIsHeaderLoading(true);
    let requestURL =
      url.API_BaseUrl +
      url.API_CONTACT_SELECTED +
      `?token=${token}` +
      `&cid=${props.chatInfo.id}`;

    try {
      const response = await getData(requestURL);

      if (response.status) {
        if (response.data) {
          setChatData(response.data);
        } else {
          setChatData(null);
        }
        setIsHeaderLoading(false);
      }
    } catch (error) {}
  };

  /*------ function for get chat details from API ------*/
  const getChatDetails = async () => {
    setChatIsLoading(true);
    let requestURL =
      url.API_BaseUrl +
      url.API_CONTACT_CONVERSATION +
      `?token=${token}` +
      `&cid=${props.chatInfo.id}`;

    if (searchQuery != "") {
      requestURL = requestURL + `&query=${searchQuery}`;
    }

    try {
      const response = await getData(requestURL);

      if (response.status) {
        setChatDetails(response.data);

        /* check condition whether lastChat id 
        matched or not and set lastchat id from API */
        if (response.lastchatid != lastChatId) {
          setlastChatId(response.lastchatid);
        }
      }
    } catch (error) {}
    setChatIsLoading(false);
  };
  /*------ end of function to get chat details from API ------*/

  /*------ function for get mail details from API ------*/
  const getMailDetails = async () => {
    setMailIsLoading(true);
    let requestURL =
      url.API_BaseUrl +
      url.API_FETCH_ALL_CONTACT_EMAILS +
      `?token=${token}` +
      `&cid=${props.chatInfo.id}`;

    if (searchQuery != "") {
      requestURL = requestURL + `&query=${searchQuery}`;
    }

    try {
      const response = await getData(requestURL);

      if (response.status) {
        // save all the email details
        setMailDetails(response.data);
      }
      setMailIsLoading(false);
    } catch (error) {}
  };
  /*------ end of function to get the mail details from API ------*/

  /*----- cron function runs when new chat will come -----*/
  const cronJob = async () => {
    let requestURL =
      url.API_BaseUrl +
      url.API_CONTACT_CONVERSATION +
      `?token=${token}` +
      `&cid=${props.chatInfo.id}` +
      `&new=1`;

    try {
      const response = await getData(requestURL);

      if (response.data != [] || response.lastchatid != lastChatId) {
        response.data.map(function (chatData) {
          setChatDetails((chatDetails) => [...chatDetails, chatData]);
        });
      }
    } catch (error) {}
  };
  /*------ end of cron function runs when new chat will come -------*/

  // function for get props data via groupchat board
  const contactReplyChat = (data) => {
    setHideReplyBox(false);
    setChatParent(data);
  };

  // function for get mail details after sending mail
  const confirmSend = () => {
    getMailDetails();
  };

  // function for send mail data to chatbox
  const mailSingleData = (data) => {
    setMailInfo(data);
  };

  // function for passing chat id for tag modal via props
  const getChatTagId = (data) => {
    props.chatIdTag(data);
  };

  // function for passing mail id for tag modal via props
  const getMailTagId = (data) => {
    props.mailTagId(data);
  };

  // function for hiding reply box
  const onConfirmReply = () => {
    setHideReplyBox(true);
  };

  //function for reset search chat query
  const resetChatQuery = () => {
    setSearchWarning(false);
    setSearchQuery("");
  };

  // function for search chat by query
  const searchChatByQuery = () => {
    if (searchQuery == "") {
      setSearchWarning(true);
    } else {
      setSearchWarning(false);
      getChatDetails();
      getMailDetails();
      toggleBlockById("chatsearch_pnl");
      resetChatQuery();
    }
  };

  // function for refresh chat
  const refreshChatMail = () => {
    fetchContactSelected();
    getChatDetails();
    getMailDetails();
  };

  // sending contact id for add tag modal via props
  const sendContactTag = () => {
    props.tagFromContact(props.chatInfo.id);
  };

  // useeffect for reload right panel
  useEffect(() => {
    if (props.loadRightPanel) {
      refreshChatMail();
      props.stopLoadRightPanel();
    }
  }, [props.loadRightPanel]);

  /*----- useEffect runs on props and last chatId dependency ------*/
  useEffect(() => {
    if (localStorage.getItem("groupcronid")) {
      clearInterval(localStorage.getItem("groupcronid")); // clear groupcron if any
      localStorage.removeItem("groupcronid");
    }

    fetchContactSelected();

    // set previous chat data
    getChatDetails();

    // call API to fetch all the mail details
    getMailDetails();

    /*---- clear previous cron ----*/
    if (localStorage.getItem("contactcronid")) {
      clearInterval(cronId);
      clearInterval(localStorage.getItem("contactcronid"));
      localStorage.removeItem("contactcronid");
      setCronId(null);
    }

    /*----- start cron job -----*/
    let intervalId = setInterval(() => {
      cronJob();
    }, 5000);

    if (intervalId) {
      localStorage.setItem("contactcronid", intervalId);
      setCronId(intervalId);
    }
  }, [props.chatInfo]);

  return (
    <>
      {/* ---- tabbar for conditionaly rendering components --- */}
      <div className="chat_topPnl">
        {/* -------- top menu bar containing the user profile image and other links -------- */}
        <div className="chatusr_info">
          {/* ---- unknown back button section --- */}
          <div className="list_back">
            <Link
              to="#"
              onClick={() => {
                document.body.classList.remove("chatmsg_open");
              }}
            >
              <i className="material-icons-round">arrow_back</i>
            </Link>
          </div>
          {/* ---- end of unknown back button section --- */}

          {/* ---- contact user details section --- */}
          {isHeaderLoading ? (
            <div className="grp_userHead placeholder-glow">
              <div className="user_placeHoledr">
                <div className="default_usr">
                  <img src={defaultUser} alt="" />
                </div>
                <div className="default_usr_name">
                  <span className="placeholder placeholder-sm col-5 rounded-3"></span>
                  <span className="placeholder placeholder-sm col-2 rounded-3"></span>
                </div>
              </div>
            </div>
          ) : (
            <>
              {chatData ? (
                <Link
                  to="#"
                  role="button"
                  className="chat_usrOuter profile_linkBtn"
                  onClick={() => {
                    document.body.classList.toggle("profile_menuPnl_open");
                  }}
                >
                  <div className="chat_usrPic">
                    {chatData.image ? (
                      <img src={chatData.image} alt="userProfile" />
                    ) : (
                      <div className="chatting_user">
                        <img src={defaultUser} alt="user" />
                      </div>
                    )}
                  </div>
                  <div className="chat_usrtext">
                    {chatData.label ? <h3>{chatData.label}</h3> : null}
                    {chatData.lastcommtime ? (
                      <h5>
                        {t("Last Online")}: {chatData.lastcommtime}
                      </h5>
                    ) : null}
                  </div>
                </Link>
              ) : (
                <div className="grp_userHead placeholder-glow">
                  <div className="user_placeHoledr">
                    <div className="default_usr">
                      <img src={defaultUser} alt="" />
                    </div>
                    <div className="default_usr_name">
                      <span className="placeholder placeholder-sm col-5 rounded-3"></span>
                      <span className="placeholder placeholder-sm col-2 rounded-3"></span>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
          {/* ---- end of contact user details section --- */}

          {/* ----------------- External Chat Info component ----------------- */}
          <ChatInfo
            resetChatQuery={resetChatQuery}
            refreshChatMail={refreshChatMail}
            sendTag={sendContactTag}
          />
        </div>

        {/* ------------ search bar for current chat */}
        <div className="serch_pnl" id="chatsearch_pnl">
          <div className="srch_innrFill">
            <input
              type="text"
              value={searchQuery}
              className="form-control"
              placeholder="Search for conversation"
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <button className="srch_submitBtn" onClick={searchChatByQuery}>
              <i className="material-icons-round">send</i>
            </button>
          </div>
          {searchWarning && (
            <p className="text-warning mx-2">* {t("Enter search query")}</p>
          )}
        </div>

        {/* ------------- Tab Links ------------- */}
        <div className="chat_tablinks">
          <ul>
            {/* <li className={allMessageTab ? "active" : ""}>
              <Link to="#" onClick={showAllMessageTab}>
                <i className="material-icons-round">fact_check</i>
                <span> All</span>
              </Link>
            </li> */}
            <li className={chatMessageTab ? "active" : ""}>
              <Link
                to="#"
                onClick={() => {
                  showChatMessageTab();
                  resetChatQuery();
                  getChatDetails();
                }}
              >
                <i className="material-icons-round">question_answer</i>
                <span>{t("Chat")}</span>
              </Link>
            </li>
            <li className={mailMessageTab ? "active" : ""}>
              <Link
                to="#"
                onClick={() => {
                  showMailMessageTab();
                  resetChatQuery();
                  getMailDetails();
                }}
              >
                <i className="material-icons-round">email</i>
                <span>{t("Mail")}</span>
              </Link>
            </li>
          </ul>
        </div>
        {/* ------------- end of Tab Links ------------- */}
      </div>
      {/* ---- end of tabbar for conditionaly rendering components --- */}

      {/* ---- condition no 3 for displaying chat and mail messages --- */}

      {chatMessageTab && (
        <ChatBoard
          chatDetails={chatDetails}
          lastChatId={lastChatId}
          contactReplyChat={contactReplyChat}
          getTag={getChatTagId}
          hideReplyBox={hideReplyBox}
          chatIsLoading={chatIsLoading}
        />
      )}

      {mailMessageTab && (
        <MailBoard
          mailDetails={mailDetails}
          mailSingleData={mailSingleData}
          mailIsLoading={mailIsLoading}
          getTag={getMailTagId}
        />
      )}

      <ChatBox
        contactEmail={props.chatInfo.email}
        contactId={props.chatInfo.id}
        messageDetails={messageDetails}
        chatParent={chatParent}
        confirmSend={confirmSend}
        mailInfo={mailInfo}
        showChatMessageTab={showChatMessageTab}
        onConfirmReply={onConfirmReply}
      />

      {/* --------- User profile panel that pops up from the right hand side --------- */}
      <RightProfilePanel contactInfo={chatData} />
    </>
  );
};

export default ContactRightPanel;
