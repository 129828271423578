/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

// importing images here

import PDF from "assets/ConversationAssests/svg/pdf.svg";
import XLS from "assets/ConversationAssests/svg/xls.svg";
import DOCS from "assets/ConversationAssests/svg/docs.svg";
import VIDEO from "assets/ConversationAssests/svg/video.svg";
import ZIP from "assets/ConversationAssests/svg/zip.svg";

// import Gateway for API fetch call
import {
  getData,
  postData,
  postFormdata,
  uploadMultipleFile,
  postUploadFileData,
} from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";

const UploadPopupModal = (props) => {
  // getting token here
  const token = localStorage.getItem("token");

  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();

  // initial state for sending message with files
  const [message, setMessage] = useState("");

  // initial state for uploaded multiple files
  const [uploadedMedia, setUploadedMedia] = useState([]);

  const [uploadedFiles, setUploadedFiles] = useState([]);

  // inintial state for condition
  const [isUploading, setisUploading] = useState(false);
  const [fileIsBig, setFileIsBig] = useState(false);
  const [messageWarning, setMessageWarning] = useState(false);
  const [fileWarning, setFileWarning] = useState(false);
  const [isSending, setIsSending] = useState(false);

  // function for multiple file upload
  // allow upload files, show file thumbnail, save files in const media
  const uploadMultipleFiles = async (e) => {
    setFileIsBig(false);
    setFileWarning(false);

    const selectedFIles = []; // initial empty values for file array

    const targetFiles = e.target.files; // store file information

    let filesLength = targetFiles.length;

    let proceed = false;

    if (filesLength > 0) {
      for (let i = 0; i < filesLength; i++) {
        let f = targetFiles[i];

        if (f.size <= 12500000) {
          proceed = true;
        } else {
          proceed = false;
        }
      }

      if (proceed) {
        const targetFilesObject = [...targetFiles]; // store array of those files

        // check condition whether file array is empty or not
        if (uploadedMedia.length == 0) {
          targetFilesObject.map((file) => {
            return selectedFIles.push(file);
          });

          setUploadedMedia(selectedFIles); // changing state of files array
        } else {
          targetFilesObject.map((file) => {
            return selectedFIles.push(file);
          });

          // changing state of files array with previous file array values
          setUploadedMedia((media) => [...media, ...selectedFIles]);
        }
      } else {
        setFileIsBig(true);
      }
    }
  };

  // uploads media files to server and get uploaded file id and setUploadedFileId
  const loadingCircle = [1, 2, 3, 4, 5, 6, 7];

  // function for removing files from upload section
  const closeFileHandler = (index) => {
    const closedFile = [...uploadedMedia];
    closedFile.splice(index, 1);
    setUploadedMedia(closedFile);
  };

  // function for clear file value
  const resetFile = () => {
    const file = document.getElementById("inputSendMessageFile");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  // function for reset state after close modal
  const resetHandler = () => {
    setUploadedMedia([]);
    setMessage("");
    resetFile();
    setMessageWarning(false);
    setFileWarning(false);
    setFileIsBig(false);
  };

  // function for send message with files
  const sendFileMessageHandler = async () => {
    // upload all files added
    let targetFiles = uploadedMedia;

    let fileResponse;

    if (message == "" || uploadedMedia.length == 0) {
      if (message == "") {
        setMessageWarning(true);
      } else {
        setMessageWarning(false);
      }

      if (uploadedMedia.length == 0) {
        setFileWarning(true);
      } else {
        setFileWarning(false);
      }
    } else {
      setisUploading(true);

      let requestFileURL =
        url.API_BaseUrl + url.API_FILE_UPLOAD_MULTIPLE + `?token=${token}`;

      try {
        const response = await uploadMultipleFile(requestFileURL, targetFiles);

        if (response.status == true) {
          fileResponse = response.data.map((data) => {
            return data.url;
          });
        }

        setisUploading(false);
      } catch (error) {}

      // send form post request
      let fileData;
      let requestURL;
      let propsFileData;

      setMessageWarning(false);
      setFileWarning(false);
      setIsSending(true);

      // store file for store file information and send it to props
      propsFileData = {
        message: message,
        media: fileResponse,
      };

      if (props.gid > 0) {
        fileData = {
          gid: props.gid,
          message: message,
          media: uploadedMedia,
        };
        requestURL =
          url.API_BaseUrl + url.API_GROUP_CONVERSATION_SAVE + `?token=${token}`;
      } else {
        fileData = {
          cid: props.cid,
          message: message,
          media: uploadedMedia,
        };
        requestURL =
          url.API_BaseUrl +
          url.API_CONTACT_CONVERSATION_SAVE +
          `?token=${token}`;
      }

      // sending back data as props for appending purposes
      props.mediaMessageDetails(propsFileData);

      try {
        const response = await postUploadFileData(requestURL, fileData);

        if (response.status) {
        } else if (response.status == false) {
        }
        setIsSending(false);
        let uploadMessageModal = document.querySelector("#upload_modal");
        let modal = bootstrap.Modal.getInstance(uploadMessageModal);
        modal.hide();
        resetHandler();
      } catch (error) {}
    }
  };

  return (
    <>
      {/* ----------------- Uploaded Popup Modal starts here ----------------- */}
      <div className="chat_modalbx uloaded_viewModal">
        <div className="modal fade" id="upload_modal">
          <div className="modal-dialog" role="document">
            {/* ----------------- Content starts here ----------------- */}
            <div className="modal-content">
              {/* ----------------- Modal Title and Close button starts here ----------------- */}
              <div className="modal-header mdl_hdng">
                <h5 className="modal-title"></h5>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={resetHandler}
                >
                  <span className="material-icons-round">close </span>
                </button>
              </div>
              {/* ----------------- Modal Title and Close button ends here ----------------- */}

              {/* ----------------- Modal Body starts here ----------------- */}
              <div className="modal-body">
                <div className="upload_innrscroll">
                  {/* ---- file warning section ---- */}
                  {fileWarning && (
                    <div className="d-flex align-items-center justify-content-center mb-1">
                      <p className="text-warning">
                        * {t("Please upload Files")}
                      </p>
                    </div>
                  )}
                  {/* ---- file warning section end ---- */}
                  <div className="upload_innrBody">
                    <div className="upload_outer">
                      {/* ----------------- Main content starts here ----------------- */}
                      <div className="row">
                        {uploadedMedia.map((files, index) => {
                          return (
                            <div
                              className="col-lg-3 col-md-4 col-6"
                              key={index}
                            >
                              <div className="chat_uploadItem">
                                <div className="chat_upload_pic">
                                  {/* ---- creating file url for preview those files ----- */}
                                  {files.type == "image/jpeg" ||
                                  files.type == "image/png" ? (
                                    <img
                                      src={URL.createObjectURL(files)}
                                      alt="images"
                                    />
                                  ) : null}
                                  {files.type == "text/plain" ? (
                                    <img src={DOCS} alt="docs" />
                                  ) : null}
                                  {files.type ==
                                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                  files.type == "application/msword" ||
                                  files.type ==
                                    "application/vnd.oasis.opendocument.text" ||
                                  files.type ==
                                    "application/vnd.ms-word.document.macroEnabled.12" ||
                                  files.type ==
                                    "application/vnd.openxmlformats-officedocument.wordprocessingml.template" ||
                                  files.type ==
                                    "application/vnd.openxmlformats-officedocument.presentationml.presentation" ? (
                                    <img src={DOCS} alt="docs" />
                                  ) : null}
                                  {files.type == "application/pdf" ? (
                                    <img src={PDF} alt="pdf" />
                                  ) : null}
                                  {files.type ==
                                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                                  files.type == "application/vnd.ms-excel" ? (
                                    <img src={XLS} alt="docs" />
                                  ) : null}
                                  {files.type == "video/mp4" ? (
                                    <img src={VIDEO} alt="video" />
                                  ) : null}
                                  {files.type ==
                                  "application/x-zip-compressed" ? (
                                    <img src={ZIP} alt="video" />
                                  ) : null}
                                </div>
                                <div className="chat_upload_caption">
                                  <p>
                                    <span>{files.name}</span>
                                  </p>
                                  <Link
                                    to="#"
                                    className="upoad_cross"
                                    role="button"
                                    onClick={() => closeFileHandler(index)}
                                  >
                                    <i className="material-icons-round">
                                      close
                                    </i>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>

                      {/* ----------------- Main content ends here ----------------- */}
                    </div>
                  </div>
                </div>

                {/* ----- loading area for file uploading ----- */}
                {isUploading && (
                  <div className="d-flex align-items-center justify-content-center mb-1">
                    <h6 className="text-white fw-bold">
                      {t("Uploading Files")}
                    </h6>
                    {loadingCircle.map((count) => {
                      return (
                        <div
                          key={count}
                          className="spinner-grow mx-1 text-white"
                          style={{ height: "6px", width: "6px" }}
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      );
                    })}
                  </div>
                )}
                {/* ----- loading area for file uploading end----- */}

                {/* ----- big file message area ----- */}
                {fileIsBig && (
                  <div className="d-flex align-items-center justify-content-center">
                    <p className="text-light">
                      * {t("Uploaded File Size must be lower than 10 mb")}
                    </p>
                  </div>
                )}
                {/* ----- big file message area end ----- */}

                {/* ----- warning message area  ----- */}
                {messageWarning && (
                  <div className="d-flex align-items-center justify-content-center mb-1">
                    <p className="text-warning">
                      * {t("Please enter a message")}
                    </p>
                  </div>
                )}
                {/* ----- warning message area end ----- */}

                {/* ----------------- Upload footer starts here ----------------- */}
                <div className="upload_footerArea">
                  <div className="upload_footerInnr">
                    <div className="upload_input_msg">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={t("Enter your message")}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                    </div>
                    {/* ------ sending message display ----- */}
                    {isSending && (
                      <div className="d-flex align-items-center justify-content-center mb-3">
                        <h6 className="text-white fw-bold">
                          {t("Sending Message")}
                        </h6>
                        {loadingCircle.map((circle) => {
                          return (
                            <div
                              key={circle}
                              className="spinner-grow mx-1 text-white"
                              style={{ height: "6px", width: "6px" }}
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {/* ------ sending message display ----- */}

                    <div className="addsend_btnOuter">
                      <button className="upload_addBtn">
                        <i className="material-icons-round"> add</i>
                        <input
                          type="file"
                          id="inputSendMessageFile"
                          className="upload_fileBtn"
                          onChange={uploadMultipleFiles}
                          multiple
                        />
                      </button>
                      <button
                        onClick={sendFileMessageHandler}
                        className="upload_sendBtn"
                      >
                        <i className="material-icons-round"> send</i>
                      </button>
                    </div>
                  </div>
                </div>
                {/* ----------------- Upload footer ends here ----------------- */}
              </div>
              {/* ----------------- Modal Body ends here ----------------- */}
            </div>
            {/* ----------------- Content ends here ----------------- */}
          </div>
        </div>
      </div>
      {/* ----------------- Uploaded Popup Modal ends here ----------------- */}
    </>
  );
};

export default UploadPopupModal;
