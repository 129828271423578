/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GlobalIndraInfo } from "App";

// import methods from gatway for API call
import { getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";

//import images
import defaultUser from "assets/ConversationAssests/images/default-user.png";
import blankInvite from "assets/ChampionAssets/images/people-collaborating-with-tech- 1.png";
import "./css/InviteStyle.css";
import { useTranslation } from "react-i18next";

const Recieved = (props) => {
  const token = localStorage.getItem("token");

  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  //initial for recieved lists
  const [recievedLists, setRecievedLists] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);
  const [isAccepting, setIsAccepting] = useState(false);
  const [acceptIndex, setAcceptIndex] = useState(null);
  const [rejectIndex, setRejectIndex] = useState(null);

  // function for all recieved lists
  const getRecievedLists = async () => {
    setIsLoading(true);
    let requestURL =
      url.API_BaseUrl +
      url.API_INVITE_LISTS +
      `?token=${token}` +
      `&state=received`;

    try {
      const response = await getData(requestURL);

      if (response.status) {
        if (response.data) {
          setRecievedLists(response.data);
        } else {
          setRecievedLists([]);
        }
        setIsLoading(false);
      }
    } catch (error) {}
  };

  // function for accept invite
  const acceptHandler = async (connect, index) => {
    setAcceptIndex(index);
    setIsAccepting(true);
    let requestURL =
      url.API_BaseUrl +
      url.API_INVITE_ACTION +
      `?token=${token}` +
      `&connect=${connect}` +
      `&action=1`;

    try {
      const response = await getData(requestURL);

      if (response.status) {
        setIsAccepting(false);
        getRecievedLists();
      }
    } catch (error) {}
  };

  //function for reject invite
  const rejectHandler = async (connect, index) => {
    setRejectIndex(index);
    setIsRejecting(true);
    let requestURL =
      url.API_BaseUrl +
      url.API_INVITE_ACTION +
      `?token=${token}` +
      `&connect=${connect}` +
      `&action=2`;

    try {
      const response = await getData(requestURL);

      if (response.status) {
        getRecievedLists();
        setIsRejecting(false);
      }
    } catch (error) {}
  };

  useEffect(() => {
    props.isLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    getRecievedLists();
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="invite_table_innr">
          <table>
            <tbody>
              <tr>
                <td>
                  <div className="user_placeHoledr">
                    <div className="default_usr invite_usr">
                      <img src={defaultUser} alt="user" />
                    </div>
                    <div className="tble_usrtext_glow">
                      <span className="placeholder placeholder-sm col-10 rounded-3"></span>{" "}
                      <br />
                      <span className="placeholder placeholder-sm col-6 rounded-3"></span>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="tble_usrtext_glow">
                    <span className="placeholder placeholder-sm col-10 rounded-3"></span>{" "}
                    <br />
                    <span className="placeholder placeholder-sm col-6 rounded-3"></span>
                  </div>
                </td>
                <td>
                  <div className="tble_usrtext_glow">
                    <span className="placeholder placeholder-sm col-10 rounded-3"></span>{" "}
                    <br />
                    <span className="placeholder placeholder-sm col-6 rounded-3"></span>
                  </div>
                </td>
                <td>
                  <div
                    className="tble_usrtext_glow"
                    style={{ display: "flex", gap: "10px" }}
                  >
                    <span className="placeholder placeholder-sm col-5 rounded-3"></span>
                    <span className="placeholder placeholder-sm col-5 rounded-3"></span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <div className="invitations-container received">
          {recievedLists.length == 0 ? (
            <ul className="invitations">
              <li className="invitation">
                <div className="d-flex gap-3">
                  <img
                    src={blankInvite}
                    alt=""
                    style={{
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  />
                </div>
                <div
                  className="empty_lessons_text"
                  style={{ textAlign: "center" }}
                >
                  <h2>{t("Sorry....!")}</h2>
                  <p>{t("No more pending invitations!")}</p>
                </div>
              </li>
            </ul>
          ) : (
            <ul className="invitations">
              {recievedLists.map((list, index) => {
                return (
                  <li className="invitation" key={index}>
                    <div className="d-flex gap-3">
                      <Link
                        to="#"
                        className="avatar rounded-circle overflow-hidden"
                      >
                        <img
                          src={list.image ? list.image : defaultUser}
                          alt="Sent_User"
                          className="img-fluid"
                        />
                      </Link>
                      <div className="w-100">
                        <div className="user-info d-flex justify-content-between mb-2">
                          <div className="name">
                            {list.name == null ? null : (
                              <h6 className="fw-bold mb-1">{list.name}</h6>
                            )}
                            {list.position == null ? null : (
                              <p className="designation">{list.position}</p>
                            )}
                          </div>
                          <div className="action d-flex gap-2">
                            <button
                              to="#"
                              className="reject basic_btn ylw_btn"
                              onClick={() => rejectHandler(list.id, index)}
                              disabled={
                                isRejecting && index == rejectIndex
                                  ? true
                                  : false
                              }
                              style={{
                                cursor:
                                  isRejecting && index == rejectIndex
                                    ? "not-allowed"
                                    : "pointer",
                              }}
                            >
                              <i className="material-icons d-block me-1">
                                highlight_off
                              </i>{" "}
                              {t("Reject")}
                            </button>
                            <button
                              to="#"
                              className="accept basic_btn"
                              onClick={() => acceptHandler(list.id, index)}
                              disabled={
                                isAccepting && index == acceptIndex
                                  ? true
                                  : false
                              }
                              style={{
                                cursor:
                                  isAccepting && index == acceptIndex
                                    ? "not-allowed"
                                    : "pointer",
                              }}
                            >
                              <i className="material-icons d-block me-1">
                                check_circle_outline
                              </i>{" "}
                              {t("Accept")}
                            </button>
                          </div>
                        </div>
                        <p className="sent-info p-3">
                          {t("Sent to")} <Link to="#">{list.reason}</Link>{" "}
                          {t("and found him")} {t("on")}{" "}
                          <Link to="#">{list.relation}</Link>
                        </p>
                        <div className="comment p-3 bg-white">
                          {list.comment == "" ||
                          list.comment == null ||
                          list.comment == undefined ? null : (
                            <p>{list.comment}</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      )}
    </>
  );
};

export default Recieved;
