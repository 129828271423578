/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

// import Gateway for API fetch call
import { getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* --------------------------------------------------------------*/

// import images here
import exportLogo1 from "assets/ChampionsDetailsAssets/images/exprt-logo1.png";
import exportLogo2 from "assets/ChampionsDetailsAssets/images/exprt-logo2.png";
import exportLogo3 from "assets/ChampionsDetailsAssets/images/exprt-logo3.png";
import blankLesson from "assets/CourseAssets/svg/lesson-empty.svg";

const Overview = () => {
  const token = localStorage.getItem("token");
  const params = useParams();

  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();

  const [overviewData, setOverviewData] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  // function for get about details
  const getAboutDetails = async (id) => {
    setIsLoading(true);
    let requestURL =
      url.API_BaseUrl +
      url.API_CHAMPION_ABOUT +
      `?token=${token}` +
      `&uid=${id}`;

    try {
      const response = await getData(requestURL);

      if (response.status) {
        if (response.data) {
          setOverviewData(response.data);
        } else {
          setOverviewData(null);
        }
        setIsLoading(false);
      }
    } catch (error) {}
  };

  // useEffect from here
  useEffect(() => {
    getAboutDetails(params.id);
  }, [params.id]);

  // return function from here
  return (
    <>
      {isLoading ? (
        <div className="tab-pane fade show active" id="overview">
          <div className="exprt_overviewOuter">
            <div className="course_detailspnl">
              <div className="contnt_hdng">
                <span
                  className="placeholder rounded-3 col-2"
                  style={{ height: "22px" }}
                ></span>
              </div>
              <div className="results_para">
                <p>
                  <span className="placeholder placeholder-sm d-block rounded-2 col-12 mb-3"></span>
                  <span className="placeholder placeholder-sm d-block rounded-2 col-10 mb-3"></span>
                  <span className="placeholder placeholder-sm d-block rounded-2 col-8"></span>
                </p>
                <p>
                  <span className="placeholder placeholder-sm d-block rounded-2 col-12 mb-3"></span>
                  <span className="placeholder placeholder-sm d-block rounded-2 col-10 mb-3"></span>
                  <span className="placeholder placeholder-sm d-block rounded-2 col-8"></span>
                </p>
              </div>
            </div>
            <div className="course_detailspnl">
              <div className="contnt_hdng">
                <span className="placeholder placeholder-md rounded-3 col-1"></span>
              </div>
              <div className="experience_pnl">
                <div className="dates_pnlOuter">
                  <div className="dates_pnlItem experice_item">
                    <span className="dates_nmbr exprince_logo">
                      <span
                        className="placeholder d-block rounded-2"
                        style={{ width: "100%", height: "100%" }}
                      ></span>
                    </span>
                    <div className="experience_hdng">
                      <h3>
                        <span className="placeholder placeholder-md rounded-3 col-2 ms-0"></span>{" "}
                        <span className="placeholder placeholder-sm rounded-3 col-1"></span>
                      </h3>
                      <span
                        className="placeholder placeholder-sm rounded-3"
                        style={{ width: "134px" }}
                      ></span>
                    </div>
                    {/* <div className="job_tags">
                      <ul>
                        <li>
                          <span>San Franchisco, CA</span>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                  <div className="dates_pnlItem experice_item">
                    <span className="dates_nmbr exprince_logo">
                      <span
                        className="placeholder d-block rounded-2"
                        style={{ width: "100%", height: "100%" }}
                      ></span>
                    </span>
                    <div className="experience_hdng">
                      <h3>
                        <span className="placeholder placeholder-md rounded-3 col-2 ms-0"></span>{" "}
                        <span className="placeholder placeholder-sm rounded-3 col-1"></span>
                      </h3>
                      <span
                        className="placeholder placeholder-sm rounded-3"
                        style={{ width: "134px" }}
                      ></span>
                    </div>
                    {/* <div className="job_tags">
                      <ul>
                        <li>
                          <span>Newark, NJ</span>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                  <div className="dates_pnlItem experice_item">
                    <span className="dates_nmbr exprince_logo">
                      <span
                        className="placeholder d-block rounded-2"
                        style={{ width: "100%", height: "100%" }}
                      ></span>
                    </span>
                    <div className="experience_hdng">
                      <h3>
                        <span className="placeholder placeholder-md rounded-3 col-2 ms-0"></span>{" "}
                        <span className="placeholder placeholder-sm rounded-3 col-1"></span>
                      </h3>
                      <span
                        className="placeholder placeholder-sm rounded-3"
                        style={{ width: "134px" }}
                      ></span>
                    </div>
                    {/* <div className="job_tags">
                      <ul>
                        <li>
                          <span>San Franchisco, CA</span>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="tab-pane fade show active" id="overview">
          {overviewData == null ||
          overviewData.length == 0 ||
          ((overviewData.experience == null ||
            overviewData.experience == undefined ||
            overviewData.experience.length === 0) &&
            (overviewData.about == null || overviewData.about == undefined) &&
            (overviewData.skills == null ||
              overviewData.skills == undefined ||
              overviewData.skills.length === 0)) ? (
            <div className="empty_lessonsArea">
              <div className="empty_lessonpic">
                <img src={blankLesson} alt="blanklesson" />
              </div>
              <div className="empty_lessons_text">
                <h2>{t("Sorry....!")}</h2>
                <p>{t("No overview details available for this champion.")}</p>
              </div>
            </div>
          ) : (
            <div className="exprt_overviewOuter">
              {/* ---------------- Details section starts here ---------------- */}
              {overviewData.about == null ||
              overviewData.about == undefined ? null : (
                <div className="course_detailspnl">
                  <div className="contnt_hdng">
                    <h4>{t("Details")}</h4>
                  </div>
                  <div className="results_para">
                    <p
                      dangerouslySetInnerHTML={{ __html: overviewData.about }}
                    ></p>
                  </div>
                </div>
              )}
              {/* ---------------- Details section starts here ---------------- */}

              {/* ---------------- Experience section starts here ---------------- */}
              {overviewData.experience == null ||
              overviewData.experience == undefined ||
              overviewData.experience.length === 0 ? null : (
                <div className="course_detailspnl">
                  <div className="contnt_hdng">
                    <h4>{t("Experience")}</h4>
                  </div>
                  <div className="experience_pnl">
                    <div className="dates_pnlOuter">
                      {overviewData.experience.map((item, index) => (
                        <div
                          className="dates_pnlItem experice_item"
                          key={index}
                        >
                          <span className="dates_nmbr exprince_logo">
                            <i className="material-icons-outlined">business</i>
                          </span>
                          <div className="experience_hdng">
                            <h3>
                              {item.designation} <span>{item.company}</span>
                            </h3>
                            {item.datefrom || item.dateto ? (
                              <h5>
                                {item.datefrom} to {item.dateto}
                              </h5>
                            ) : null}
                          </div>
                          <div className="job_tags">
                            <ul>
                              <li>
                                <span>{item.location}</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              {/* ---------------- Experience section ends here ---------------- */}

              {/* ---------------- Skills section starts here ---------------- */}
              {overviewData.skills == null ||
              overviewData.skills == undefined ||
              overviewData.skills.length === 0 ? null : (
                <div className="course_detailspnl">
                  <div className="contnt_hdng">
                    <h4>{t("Skills")}</h4>
                  </div>
                  {overviewData.skills.length == 0 ? null : (
                    <div className="experts_skill">
                      <ul>
                        {overviewData.skills.map((item, index) => (
                          <li key={index}>
                            <Link to="#">{item.label}</Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              )}
              {/* ---------------- Skills section ends here ---------------- */}

              {/* ---------------- Additional Information section starts here ---------------- */}
              {/* <div className="course_detailspnl">
                <div className="contnt_hdng">
                  <h4>{t("Additional Information")}</h4>
                </div>
                <div className="expert_aditonal_outer">
                  <div className="row">
                    <div className="col-lg-3 col-md-6">
                      <div className="additional_info">
                        <h4>
                          <i className="material-icons-outlined">email</i>{" "}
                          {t("Email")}
                        </h4>
                        <Link to={`${overviewData.email}`}>
                          {overviewData.email}
                        </Link>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="additional_info">
                        <h4>
                          <i className="material-icons-outlined">event_note</i>
                          {t("Date of Birth")}
                        </h4>
                        <h3>{overviewData.dateOfBirth}</h3>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="additional_info">
                        <h4>
                          <i className="material-icons-outlined">language</i>
                          {t("Language")}
                        </h4>
                        <h3>{overviewData.language}</h3>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="additional_info">
                        <h4>
                          <i className="material-icons-outlined">school</i>{" "}
                          {t("Education")}
                        </h4>
                        <h3>{overviewData.education}</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* ---------------- Additional Information section ends here ---------------- */}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Overview;
