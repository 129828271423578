/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GlobalIndraInfo } from "App";
import { t } from "i18next";

// import Gateway for API fetch call
import { getData } from "utils/Gateway";

// importing images here
import defaultUser from "../../assets/ActivityAssests/images/default-user.png";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
import AddTagsModal from "components/GroupDetailsComponents/Modals/AddTagsModal";
import ThanksJoinGroupModal from "components/GroupDetailsComponents/Modals/ThanksJoinGroupModal";

const GroupDetailsTopSection = () => {
  // saving user token here
  const token = localStorage.getItem("token");

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  // 👇️ get ID from url
  const params = useParams();

  const { t } = useTranslation();

  const {
    setGroupName,
    setIsApproved,
    setReviewPermission,
    afterReviewGroup,
    setAfterReviewGroup,
    setReloadMemberTab,
    setReloadGroupForumTab,
    setReloadGroupReviewTab,
  } = useContext(GlobalIndraInfo);

  // initials for group details
  const [groupDetailsData, setGroupDetailsData] = useState(null);
  const [isLoading, setIsLoading] = useState(false); //for glow placeholder
  const [isJoining, setIsJoining] = useState(false);
  const [moderatorStatus, setModeratorStatus] = useState(false);

  const getGroupDetails = async (id) => {
    setIsLoading(true);
    let requestURL =
      url.API_BaseUrl +
      url.API_GROUP_DETAILS_TOP +
      `?token=${token}` +
      `&id=${id}`;

    try {
      const response = await getData(requestURL);

      if (response.status) {
        if (response.data) {
          setGroupDetailsData(response.data);
          setGroupName(response.data.name);
          if (response.data.moderatorid === userInfo.id) {
            setReviewPermission(true);
          } else {
            if (response.data.moderatorstatus && response.data.isjoined) {
              setReviewPermission(true);
              setIsApproved(true);
            } else {
              setReviewPermission(false);
              setIsApproved(false);
            }
          }
        } else {
          setGroupDetailsData(null);
        }
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Function to join the Event using API
  const joinGroup = async () => {
    setIsJoining(true);
    let requestURL =
      url.API_BaseUrl +
      url.API_JOIN_GROUP +
      `?token=${token}` +
      `&gid=${params.id}`;

    try {
      const response = await getData(requestURL);

      if (response.status) {
        setIsJoining(false);
        if (response.moderatorstatus) {
          setModeratorStatus(true);
        } else {
          setModeratorStatus(false);
        }
        let myModal = new bootstrap.Modal(
          document.getElementById("group_join_success_modal")
        );
        myModal.show();
      }
    } catch (error) {}
  };

  // function for calling API after closing add tag modal
  const afterModalClose = () => {
    getGroupDetails(params.id);
    window.scrollTo(0, 0);
  };

  // function for calling API after closing thank you modal
  const afterThankYouModalClose = () => {
    setReloadMemberTab(true);
    setReloadGroupForumTab(true);
    setReloadGroupReviewTab(true);
    getGroupDetails(params.id);
    window.scrollTo(0, 0);
  };

  // reload from review tab
  useEffect(() => {
    if (afterReviewGroup) {
      getGroupDetails(params.id);
      setAfterReviewGroup(false);
    }
  }, [afterReviewGroup]);

  // url based use effect
  useEffect(() => {
    getGroupDetails(params.id);
    window.scrollTo(0, 0);
  }, [params.id]);

  return (
    <>
      {isLoading ? (
        <section className="course_details_sec placeholder-glow">
          {/* ---- glow more area start ---- */}
          <div className="container">
            <div className="course_detailsBx">
              <div className="topdetails_left">
                <div className="expertholder_outer">
                  <div className="experts_holdertext flex-fill">
                    <span className="placeholder placeholder-sm rounded-2 col-6 mb-3"></span>
                    <span
                      className="placeholder rounded-3 col-8"
                      style={{ height: "26px" }}
                    ></span>
                    <div className="details_tags expert_details">
                      <span
                        className="placeholder placeholder-md rounded-2 mb-3"
                        style={{ width: "142px" }}
                      ></span>
                    </div>

                    <div className="experts_skill">
                      <ul>
                        <li>
                          <span
                            className="placeholder d-block rounded-2"
                            style={{ width: "80px", height: "30px" }}
                          ></span>
                        </li>
                        <li>
                          <span
                            className="placeholder d-block rounded-2"
                            style={{ width: "80px", height: "30px" }}
                          ></span>
                        </li>
                        <li>
                          <span
                            className="placeholder d-block rounded-2"
                            style={{ width: "80px", height: "30px" }}
                          ></span>
                        </li>
                      </ul>
                    </div>
                    <div className="vdo_link">
                      <span className="placeholder placeholder-sm rounded-2 col-4"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="topdetails_right">
                <div className="asign_top">
                  <div className="asign_outer">
                    <div className="asign_item">
                      <span
                        className="placeholder placeholder-md rounded-2 me-3"
                        style={{ width: "120px" }}
                      ></span>
                    </div>
                  </div>
                  <div className="exprt_share traning_share">
                    <ul>
                      <li>
                        <span
                          className="placeholder"
                          style={{ width: "40px", height: "40px" }}
                        ></span>
                      </li>
                      <li>
                        <span
                          className="placeholder"
                          style={{ width: "40px", height: "40px" }}
                        ></span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ---- glow more area end ---- */}
        </section>
      ) : (
        <section className="course_details_sec">
          {groupDetailsData == null ? null : (
            <div className="container">
              <div className="course_detailsBx">
                {/* ----- group details left section start here ----- */}
                <div className="topdetails_left">
                  <div className="expertholder_outer">
                    <div className="experts_holdertext">
                      <h5>{groupDetailsData.name}</h5>
                      <h3>{groupDetailsData.label}</h3>
                      <div className="details_tags expert_details">
                        <div className="jb_rating star_color">
                          <i className="material-icons-outlined"> star </i>
                          <span>{groupDetailsData.rating}</span>
                          <h6>
                            ({groupDetailsData.reviews}
                            <em className="mx-1">{t("reviews")}</em>)
                          </h6>
                        </div>
                      </div>

                      {/* ----- group details category start here ----- */}
                      {groupDetailsData.category ? (
                        <div className="experts_skill">
                          {groupDetailsData.category.length > 0 && (
                            <ul>
                              {groupDetailsData.category.map((item, index) => {
                                return (
                                  <li key={index}>
                                    <Link to="#">
                                      <span>{item}</span>
                                    </Link>
                                  </li>
                                );
                              })}
                            </ul>
                          )}
                        </div>
                      ) : null}
                      {/* ----- group details category end here ----- */}

                      {/* ----- group details members start here ----- */}
                      {groupDetailsData.members ? (
                        <div className="ambassadors_users grps_user">
                          {groupDetailsData.members.length > 0 && (
                            <ul>
                              {groupDetailsData.members.map((member, index) => {
                                return (
                                  <li key={index}>
                                    <Link to="#">
                                      <img
                                        src={
                                          member.image != null
                                            ? member.image
                                            : defaultUser
                                        }
                                        alt="member"
                                      />
                                    </Link>
                                  </li>
                                );
                              })}
                              {groupDetailsData.memberscount <= 5 ? null : (
                                <li className="group_member_more_text">
                                  + {groupDetailsData.memberscount - 5}{" "}
                                  {t("more")}
                                </li>
                              )}
                            </ul>
                          )}
                        </div>
                      ) : null}
                      {/* ----- group details members end here ----- */}

                      {/* ----- group details summary start here ----- */}
                      {/* <div className="training_para">
                        <p>{groupDetailsData.summary}</p>
                      </div> */}
                      {/* ----- group details summary end here ----- */}
                    </div>
                  </div>
                </div>
                {/* ----- group details left section end here ----- */}

                {/* ----- group details right section start here ----- */}
                <div className="topdetails_right d-flex flex-column align-items-end justify-content-between">
                  <div className="asign_top">
                    <div className="asign_outer">
                      <div className="asign_item">
                        <h4>
                          {t("Moderator")}:{" "}
                          <span>{groupDetailsData.moderator}</span>
                        </h4>
                      </div>
                    </div>

                    <div className="exprt_share traning_share">
                      <ul>
                        <li>
                          {/* --- add tag modal start here ---- */}
                          <Link
                            to="#"
                            data-bs-toggle="modal"
                            data-bs-target="#group_add_tag_mdl"
                          >
                            <i className="material-icons-outlined">more</i>
                          </Link>
                          {/* --- add tag modal end here ---- */}
                        </li>
                        {/* <li>
                      <Link to="#">
                        <i className="material-icons-outlined">share</i>
                      </Link>
                    </li> */}
                      </ul>
                    </div>
                  </div>

                  {/* --------- Tags list and dropdown start --------- */}
                  <div className="tags_menu">
                    {/* -------- Top 3 tags here -------- */}

                    {groupDetailsData.tags.length > 0 ? (
                      <ul className="tags_list">
                        {groupDetailsData.tags
                          .slice(0, 3)
                          .map((tags, index) => {
                            return (
                              <li key={index}>
                                <Link to="#">{tags.label}</Link>
                              </li>
                            );
                          })}
                      </ul>
                    ) : null}

                    {/* -------- Remaining tags here in dropdown --------- */}
                    {groupDetailsData.tags.length > 3 ? (
                      <div className="tags_drop dropdown">
                        <Link to="#" data-bs-toggle="dropdown">
                          <span className="material-icons-outlined">
                            expand_more
                          </span>
                        </Link>
                        {groupDetailsData.tags.length > 0 ? (
                          <ul className="dropdown-menu">
                            {groupDetailsData.tags
                              .slice(3, groupDetailsData.tags.length)
                              .map((tags, index) => {
                                return (
                                  <li key={index}>
                                    <Link to="#">{tags.label}</Link>
                                  </li>
                                );
                              })}
                          </ul>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                  {/* --------- Tags list and dropdown end --------- */}

                  {/* ----- Display join date by condition ----- */}
                  {groupDetailsData.moderatorid === userInfo.id ? null : (
                    <div className="joining_section">
                      {groupDetailsData.isjoined ? (
                        groupDetailsData.moderatorstatus ? (
                          <p className="text-end text-white join-event-request">
                            {t("Joined Date")} :{" "}
                            {groupDetailsData.joiningdate
                              ? groupDetailsData.joiningdate
                              : null}{" "}
                            <br />
                            {groupDetailsData.role ? t("Role :") : null}
                            <span>
                              {" "}
                              {groupDetailsData.role
                                ? groupDetailsData.role
                                : null}
                            </span>
                          </p>
                        ) : (
                          <p className="text-end text-white join-event-request">
                            <i className="material-icons me-2">error</i>
                            {t("Moderator Response Pending")} <br />
                            {t("Request Date")} : {groupDetailsData.joiningdate}
                          </p>
                        )
                      ) : null}
                    </div>
                  )}

                  {/* ----- display join button by condition ----- */}

                  {groupDetailsData.moderatorid === userInfo.id ? null : (
                    <div className="join_section">
                      {groupDetailsData.isjoined ? null : (
                        <Link
                          to="#"
                          className="basic_btn ylw_btn w-auto mt-1"
                          onClick={joinGroup}
                          style={{ pointerEvents: isJoining ? "none" : "" }}
                        >
                          {t("Join Now")}
                          {isJoining && (
                            <div
                              className="mx-2 spinner-border spinner-border-sm"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          )}
                        </Link>
                      )}
                    </div>
                  )}
                </div>
                {/* ----- group details right section end here ----- */}
              </div>
            </div>
          )}
        </section>
      )}
      <AddTagsModal afterModalClose={afterModalClose} />
      <ThanksJoinGroupModal
        afterThankYouModalClose={afterThankYouModalClose}
        moderatorStatus={moderatorStatus}
        groupDetailsData={groupDetailsData}
      />
    </>
  );
};

export default GroupDetailsTopSection;
