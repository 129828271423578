/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

const HomeChampionRequire = () => {
  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  return (
    <section className="system_area pd_30">
      <div className="container">
        <div className="system_outer">
          <div className="system_pic">
            <img src="images/system-pic2.jpg" alt="" />
          </div>
          <div className="system_textPnl">
            <h3>{t("What is the professional portal of Champions ATM?")}</h3>
            <div className="system_list">
              <ul>
                <li>
                  {/* <i className="material-icons-outlined">check_circle</i> */}
                  <span>
                    {t(
                      "It is a habitATM that encourages communication and collaboration between users of Indra air traffic systems to generate professional growth for the user as well as benefits for the collective through both knowledge and improvements in the use of systems"
                    )}
                  </span>
                </li>
                {/* <li>
                  <i className="material-icons-outlined">check_circle</i>
                  <span>
                    All the Lorem Ipsum generators on the Internet tend.
                  </span>
                </li>
                <li>
                  <i className="material-icons-outlined">check_circle</i>
                  <span>There are many variations of passages.</span>
                </li> */}
              </ul>
            </div>
            <div className="explore_btn">
              <Link
                to="#"
                className="basic_btn ylw_btn"
                onClick={() => {
                  document.body.classList.add("contact_open");
                }}
              >
                {t("Contact us")}
              </Link>
            </div>
            <div className="explore_btn ms-5">
              <Link to="/login" className="basic_btn ylw_btn">
                {t("Login")}
              </Link>
            </div>
            
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeChampionRequire;
