/* eslint-disable */
import { t } from "i18next";
import React from "react";
import { Link } from "react-router-dom";
import loginError from "assets/HomeAssets/Images/error-graphics.png";

const ErrorMessageModal = (props) => {
  return (
    <div className="signin_modal job_modal sucess_modal">
      <div className="modal fade show" id="login_error_mdl">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="material-icons-outlined">close</i>
              </button>
            </div>

            <div className="modal-body">
              <div className="login_body">
                <div className="success_mdlpic">
                  <img src={loginError} alt="loginerror" />
                </div>
                <div className="congrats_text">
                  <h3>{t("Error")}!</h3>
                  <h5>{t("Email and Password are not matched")}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorMessageModal;
