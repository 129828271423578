/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import _ from "lodash";
import Select from "react-select";
import { GlobalIndraInfo } from "App";
import { useTranslation } from "react-i18next";

// Importing Modal here
import ThankYouModal from "../Modals/ThankYouModal";

/* -------- required imports for API Call --------- */
// import Gateway for API fetch call
import { getData, postData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* --------------------------------------------------------------*/

const RightPanel = () => {
  // saving user token here
  const token = localStorage.getItem("token");

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  let mod = params.get("mod") ? params.get("mod") : null;
  let com = params.get("com") ? params.get("com") : null;
  let secret = params.get("secret") ? params.get("secret") : null;

  const { hideRightPanel } = useContext(GlobalIndraInfo);

  // Initial loading status
  const [isLoading, setIsLoading] = useState(false);

  // Initial priviledge status
  const [hasPriviledge, setHasPriviledge] = useState(true);

  // Initial no-priviledge comment status
  const [noPriviledgeComment, setNoPriviledgeComment] = useState("");

  // Json data of survey feedback api
  const [surveyJson, setSurveyJson] = useState({});

  // Array data of
  const [surveyFeedback, setSurveyFeedback] = useState([]);

  //const [questionCounter, setQuestionCounter] = useState(1);

  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");

  // custom style for multiselect
  const customSelectStyle = {
    // options style
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted pink",
      color: "#666666",
      fontSize: "16px",
      fontFamily: "sans-serif",
    }),

    // Value style
    control: (styles) => ({
      ...styles,
      fontSize: "16px",
      padding: "5px",
      fontFamily: "sans-serif",
      border: "none",
      backgroundColor: "#F0F0F0",
      height: 50,
      borderRadius: "8px",
    }),

    // placeholder style
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#8E8E8E",
        padding: "5px",
        fontSize: "16px",
        marginVertical: "4px",
        fontFamily: "sans-serif",
      };
    },

    // Indicator style
    dropdownIndicator: (base, state) => {
      let changes = { color: "#505050" };
      return Object.assign(base, changes);
    },
  };

  /*---------- Options selection start -----------*/
  const [selectedOptions, setSelectedOptions] = useState(null);

  const optionSelectionHandler = (val, sectionid, questionid, type, e) => {
    let optionsVal = val.map((item) => {
      return item.value;
    });

    if (optionsVal.length != 0) {
      setSelectedOptions();
      handleFormChange(sectionid, questionid, type, e, optionsVal);
    }
  };

  /*---- Options selection end ----*/

  /* ----------------- Single-Select options start ---------------- */
  const [selectedSingleOption, setSelectedSingleOption] = useState("");

  const singleSelectionHandler = (val, sectionid, questionid, type, e) => {
    setSelectedSingleOption();
    // setData({ ...data, status: val.value })
    handleFormChange(sectionid, questionid, type, e, val.value);
  };
  /* ----------------- Single-Select options end ---------------- */

  // function to call the API and get all the courses
  const getFeedbackQuestionsListData = async (mod, com, secret) => {
    setIsLoading(true);

    let requestURL;

    if (token != null) {
      requestURL =
        url.API_BaseUrl +
        url.API_FEEDBACK_QUESTIONS +
        `?token=${token}` +
        `&mod=${mod}` +
        `&com=${com}` +
        `&secret=${secret}`;
    } else {
      requestURL =
        url.API_BaseUrl +
        url.API_FEEDBACK_QUESTIONS +
        `?mod=${mod}` +
        `&com=${com}` +
        `&secret=${secret}`;
    }

    try {
      const response = await getData(requestURL);

      if (response.status) {
        if (response.surveyfeedback) {
          setSurveyJson(response.surveyfeedback);

          let surveyFeedbackData = [];

          response.surveyfeedback.section.map((sectionComponent, indexS) => {
            let sectionFeedbackData = [];

            // map questions ---------------------->
            sectionComponent.questions.map((question, indexQ) => {
              let questionFeedbackData = [];

              questionFeedbackData["answer"] = question.answer;
              questionFeedbackData["comments"] = question.comment;
              questionFeedbackData["questionid"] = question.id;
              questionFeedbackData["sectionid"] = sectionComponent.id;

              sectionFeedbackData[question.id] = questionFeedbackData;
            });

            surveyFeedbackData[sectionComponent.id] = sectionFeedbackData;
          });

          setSurveyFeedback(surveyFeedbackData);
        }

        if (response.name) {
          setUserName(response.name);
        } else {
          setUserName("");
        }

        if (response.email) {
          setUserEmail(response.email);
        } else {
          setUserEmail("");
        }

        setIsLoading(false);
      }

      if (
        response.status == true &&
        response.surveyfeedback == null &&
        response.hasAccess == false
      ) {
        setHasPriviledge(false);
        setIsLoading(true);
        setNoPriviledgeComment(response.comment);
      }
    } catch (error) {}
  };

  // handle all feedback response for each section and questionid
  // types can be rating, text, number, select, multiselect, comments
  const handleFormChange = (
    sectionid,
    questionid,
    type = "answer",
    e,
    options = []
  ) => {
    let surveyData = [...surveyFeedback];

    if (type == "comments") {
      e.preventDefault();
      surveyData[sectionid][questionid]["comments"] = e.target.value;
    } else if (type == "rating") {
      surveyData[sectionid][questionid]["answer"] = e;
    } else if (type == "multiselect") {
      surveyData[sectionid][questionid]["answer"] = options;
    } else if (type == "number") {
      surveyData[sectionid][questionid]["answer"] = e.target.value;
    } else if (type == "singleselect") {
      surveyData[sectionid][questionid]["answer"] = options;
    } else {
      e.preventDefault();
      surveyData[sectionid][questionid]["answer"] = e.target.value;
    }

    setSurveyFeedback(surveyData);
  };

  // function to handle the answer submission
  const handleAnswerSubmit = async (e) => {
    e.preventDefault();

    let feedbackUrl;
    let feedBackSentData;

    let surveyJsonData = arrayToJSON(surveyFeedback);
    // ---------------------- API CALL STARTS FROM BELOW ----------------------
    try {
      if (token != null) {
        feedbackUrl =
          url.API_BaseUrl + url.API_SURVEY_FEEDBACK_SAVE + `?token=${token}`;
        feedBackSentData = {
          name: userInfo.name,
          email: userInfo.email,
          surveyFeedback: surveyJsonData,
          secret: secret,
        };
      } else {
        feedbackUrl = url.API_BaseUrl + url.API_SURVEY_FEEDBACK_SAVE;
        feedBackSentData = {
          name: userName,
          email: userEmail,
          surveyFeedback: surveyJsonData,
          secret: secret,
        };
      }

      setUserName("");
      setUserEmail("");

      let response = await postData(feedbackUrl, feedBackSentData);

      if (response.status) {
        let myModal = new bootstrap.Modal(
          document.getElementById("feedback_success_mdl")
        );
        myModal.show();
        getFeedbackQuestionsListData(mod, com, secret);
      }
    } catch (error) {}
  };

  const arrayToJSON = (surveyFeedback) => {
    let surveyJsonData = {};

    surveyFeedback.forEach(function (sectionData, sectionId) {
      if (sectionData == null) {
        return;
      }

      var sectionJsonData = {};

      sectionData.forEach(function (questionData, questionId) {
        var questionJsonData = {};

        if (questionData == null) {
          return;
        }

        for (var fieldName in questionData) {
          questionJsonData[fieldName] = questionData[fieldName];
        }

        sectionJsonData[questionId] = questionJsonData;
      });

      surveyJsonData[sectionId] = sectionJsonData;
    });

    return surveyJsonData;
  };

  useEffect(() => {
    if (params.get("secret") != null) {
      if (params.get("mod") != null) {
        mod = params.get("mod");
      }

      if (params.get("com") != null) {
        com = params.get("com");
      }

      if (params.get("secret") != null) {
        secret = params.get("secret");
      }

      getFeedbackQuestionsListData(mod, com, secret);
    } else {
      setIsLoading(true);
    }
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="mdl_rghtpnl">
          {/* ---------- Glow placeholder starts here ---------- */}

          {/* ----------- priviledge message displayed here ----------- */}
          {hasPriviledge == false ? (
            <div className="activity_rightHdng">
              <h3>{t("Not enough privilege to view the feedback!")}</h3>
              {noPriviledgeComment == "" ? null : (
                <p className="text-danger mb-1">{noPriviledgeComment}</p>
              )}
            </div>
          ) : null}

          <div className="events_itemBx">
            <div className="champions_textpnl">
              <div className="details_btn">
                <span
                  className="placeholder rounded-3 col-12"
                  style={{ height: "52px" }}
                ></span>
              </div>
              <div className="details_btn">
                <span
                  className="placeholder rounded-3 col-12"
                  style={{ height: "52px" }}
                ></span>
              </div>
              <div className="details_btn">
                <span
                  className="placeholder rounded-3 col-12"
                  style={{ height: "52px" }}
                ></span>
              </div>
              <div className="details_btn">
                <span
                  className="placeholder rounded-3 col-12"
                  style={{ height: "52px" }}
                ></span>
              </div>
              <div className="details_btn">
                <span
                  className="placeholder rounded-3 col-12"
                  style={{ height: "54px" }}
                ></span>
              </div>
            </div>
          </div>
          {/* ---------- Glow placeholder ends here ---------- */}
        </div>
      ) : (
        <div className="mdl_rghtpnl">
          {hideRightPanel ? (
            <div className="srvay_feedhdng">
              {params.get("mod") == "MOD_USER" && (
                <div className="champ_sec">
                  <h2>{t("User not found")}</h2>
                  <div className="events_itemBx">
                    <div className="champions_textpnl">
                      <div className="details_btn">
                        <span
                          className="placeholder rounded-3 col-12"
                          style={{ height: "52px" }}
                        ></span>
                      </div>
                      <div className="details_btn">
                        <span
                          className="placeholder rounded-3 col-12"
                          style={{ height: "52px" }}
                        ></span>
                      </div>
                      <div className="details_btn">
                        <span
                          className="placeholder rounded-3 col-12"
                          style={{ height: "52px" }}
                        ></span>
                      </div>
                      <div className="details_btn">
                        <span
                          className="placeholder rounded-3 col-12"
                          style={{ height: "52px" }}
                        ></span>
                      </div>
                      <div className="details_btn">
                        <span
                          className="placeholder rounded-3 col-12"
                          style={{ height: "54px" }}
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {params.get("mod") == "MOD_EVENT" && (
                <div className="event_sec">
                  <h2>{t("Event not found")}</h2>
                  <div className="events_itemBx">
                    <div className="champions_textpnl">
                      <div className="details_btn">
                        <span
                          className="placeholder rounded-3 col-12"
                          style={{ height: "52px" }}
                        ></span>
                      </div>
                      <div className="details_btn">
                        <span
                          className="placeholder rounded-3 col-12"
                          style={{ height: "52px" }}
                        ></span>
                      </div>
                      <div className="details_btn">
                        <span
                          className="placeholder rounded-3 col-12"
                          style={{ height: "52px" }}
                        ></span>
                      </div>
                      <div className="details_btn">
                        <span
                          className="placeholder rounded-3 col-12"
                          style={{ height: "52px" }}
                        ></span>
                      </div>
                      <div className="details_btn">
                        <span
                          className="placeholder rounded-3 col-12"
                          style={{ height: "54px" }}
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {params.get("mod") == "MOD_COURSE" && (
                <div className="course_sec">
                  <h2>{t("Course not found")}</h2>
                  <div className="events_itemBx">
                    <div className="champions_textpnl">
                      <div className="details_btn">
                        <span
                          className="placeholder rounded-3 col-12"
                          style={{ height: "52px" }}
                        ></span>
                      </div>
                      <div className="details_btn">
                        <span
                          className="placeholder rounded-3 col-12"
                          style={{ height: "52px" }}
                        ></span>
                      </div>
                      <div className="details_btn">
                        <span
                          className="placeholder rounded-3 col-12"
                          style={{ height: "52px" }}
                        ></span>
                      </div>
                      <div className="details_btn">
                        <span
                          className="placeholder rounded-3 col-12"
                          style={{ height: "52px" }}
                        ></span>
                      </div>
                      <div className="details_btn">
                        <span
                          className="placeholder rounded-3 col-12"
                          style={{ height: "54px" }}
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {params.get("mod") == "MOD_CHALLANGES" && (
                <div className="job_sec">
                  <h2>{t("Challenge not found")}</h2>
                  <div className="events_itemBx">
                    <div className="champions_textpnl">
                      <div className="details_btn">
                        <span
                          className="placeholder rounded-3 col-12"
                          style={{ height: "52px" }}
                        ></span>
                      </div>
                      <div className="details_btn">
                        <span
                          className="placeholder rounded-3 col-12"
                          style={{ height: "52px" }}
                        ></span>
                      </div>
                      <div className="details_btn">
                        <span
                          className="placeholder rounded-3 col-12"
                          style={{ height: "52px" }}
                        ></span>
                      </div>
                      <div className="details_btn">
                        <span
                          className="placeholder rounded-3 col-12"
                          style={{ height: "52px" }}
                        ></span>
                      </div>
                      <div className="details_btn">
                        <span
                          className="placeholder rounded-3 col-12"
                          style={{ height: "54px" }}
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {params.get("mod") == "MOD_GROUP" && (
                <div className="group_sec">
                  <h2>{t("Group not found")}</h2>
                  <div className="events_itemBx">
                    <div className="champions_textpnl">
                      <div className="details_btn">
                        <span
                          className="placeholder rounded-3 col-12"
                          style={{ height: "52px" }}
                        ></span>
                      </div>
                      <div className="details_btn">
                        <span
                          className="placeholder rounded-3 col-12"
                          style={{ height: "52px" }}
                        ></span>
                      </div>
                      <div className="details_btn">
                        <span
                          className="placeholder rounded-3 col-12"
                          style={{ height: "52px" }}
                        ></span>
                      </div>
                      <div className="details_btn">
                        <span
                          className="placeholder rounded-3 col-12"
                          style={{ height: "52px" }}
                        ></span>
                      </div>
                      <div className="details_btn">
                        <span
                          className="placeholder rounded-3 col-12"
                          style={{ height: "54px" }}
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="survey_right_pnl">
              {surveyJson && (
                <div className="mdl_rightpnl_inner">
                  {/* ---------- Heading starts here ---------- */}
                  <div className="srvay_feedhdng">
                    <h2>{t("Need your opinion")}</h2>
                  </div>
                  {/* ---------- Heading ends here ---------- */}

                  {/* ---------- Name & Email starts here ---------- */}
                  {!token && (
                    <div className="guest_feed_fillOuter">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="input_fill">
                            <label>{t("Name")}</label>
                            {userName == "" ? (
                              <input
                                type="text"
                                value={userName}
                                onChange={(e) => setUserName(e.target.value)}
                                className="form-control"
                                placeholder="Please enter your name"
                              />
                            ) : (
                              <div className="form_commnts_area">
                                <div className="feedback_bx">
                                  <p>{userName}</p>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input_fill">
                            <label>{t("Email")}</label>
                            {userEmail == "" ? (
                              <input
                                type="email"
                                value={userEmail}
                                onChange={(e) => setUserEmail(e.target.value)}
                                className="form-control"
                                placeholder="Please enter your email"
                              />
                            ) : (
                              <div className="form_commnts_area">
                                <div className="feedback_bx">
                                  <p>{userEmail}</p>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* ---------- Name & Email ends here ---------- */}

                  {/* ---------- Accordian section starts here ---------- */}
                  {surveyJson.section != null ? (
                    <div className="accordian_area mdlblue_accordian">
                      {surveyJson.section.length > 0 ? (
                        <div
                          className="accordion accordion-flush"
                          id="faq-accordian"
                        >
                          {/* ----------- Accordion 1 starts here ----------- */}
                          {surveyJson.section.map((sectionComponent, index) => {
                            return (
                              <div className="accordion-item" key={index}>
                                {/* ------ Collapsible Link starts here ------ */}
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={
                                      "#flush-collapseOne" + index
                                    }
                                  >
                                    <span>{sectionComponent.title}</span>
                                    <div className="survay_accordianarw_right">
                                      <div className="question_count">
                                        <h5>
                                          Q- {sectionComponent.questions.length}{" "}
                                          /{" "}
                                          <span>
                                            A- {sectionComponent.response}
                                          </span>
                                        </h5>
                                      </div>
                                      <i className="material-icons-round">
                                        expand_more
                                      </i>
                                    </div>
                                  </button>
                                </h2>

                                {/* ------ Collapsible Body starts here ------ */}
                                <div
                                  id={"flush-collapseOne" + index}
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#faq-accordian"
                                >
                                  {sectionComponent.questions != null ? (
                                    <>
                                      {sectionComponent.questions.length > 0 ? (
                                        <div className="accordion-body">
                                          {sectionComponent.questions.map(
                                            (question, index2) => {
                                              return (
                                                <div
                                                  className="accor_ansArea"
                                                  key={index2}
                                                >
                                                  <div className="ans_count">
                                                    <h3>{index2 + 1}</h3>
                                                  </div>

                                                  <div className="ans_count_text flex-fill">
                                                    {/* ----------- Question section starts here ----------- */}
                                                    <div className="ans_para">
                                                      <p>{question.label}</p>
                                                    </div>

                                                    {/* -- Question answer type can be of --- 1. Rating 2. Text 3. Number 4. Select 5. Multiselect */}
                                                    <>
                                                      {question.type ==
                                                      "rating" ? (
                                                        <div className="ans_rating">
                                                          <ul>
                                                            <li
                                                              className={
                                                                surveyFeedback[
                                                                  sectionComponent
                                                                    .id
                                                                ][question.id][
                                                                  "answer"
                                                                ] >= 1
                                                                  ? "active"
                                                                  : ""
                                                              }
                                                              onClick={
                                                                question.hasreviewed ==
                                                                false
                                                                  ? (e) => {
                                                                      handleFormChange(
                                                                        sectionComponent.id,
                                                                        question.id,
                                                                        "rating",
                                                                        1
                                                                      );
                                                                    }
                                                                  : (e) => {}
                                                              }
                                                            >
                                                              <span className="material-icons-outlined">
                                                                star_outline
                                                              </span>
                                                            </li>

                                                            <li
                                                              className={
                                                                surveyFeedback[
                                                                  sectionComponent
                                                                    .id
                                                                ][question.id][
                                                                  "answer"
                                                                ] >= 2
                                                                  ? "active"
                                                                  : ""
                                                              }
                                                              onClick={
                                                                question.hasreviewed ==
                                                                false
                                                                  ? (e) => {
                                                                      handleFormChange(
                                                                        sectionComponent.id,
                                                                        question.id,
                                                                        "rating",
                                                                        2
                                                                      );
                                                                    }
                                                                  : (e) => {}
                                                              }
                                                            >
                                                              <span className="material-icons-outlined">
                                                                star_outline
                                                              </span>
                                                            </li>

                                                            <li
                                                              className={
                                                                surveyFeedback[
                                                                  sectionComponent
                                                                    .id
                                                                ][question.id][
                                                                  "answer"
                                                                ] >= 3
                                                                  ? "active"
                                                                  : ""
                                                              }
                                                              onClick={
                                                                question.hasreviewed ==
                                                                false
                                                                  ? (e) => {
                                                                      handleFormChange(
                                                                        sectionComponent.id,
                                                                        question.id,
                                                                        "rating",
                                                                        3
                                                                      );
                                                                    }
                                                                  : (e) => {}
                                                              }
                                                            >
                                                              <span className="material-icons-outlined">
                                                                star_outline
                                                              </span>
                                                            </li>

                                                            <li
                                                              className={
                                                                surveyFeedback[
                                                                  sectionComponent
                                                                    .id
                                                                ][question.id][
                                                                  "answer"
                                                                ] >= 4
                                                                  ? "active"
                                                                  : ""
                                                              }
                                                              onClick={
                                                                question.hasreviewed ==
                                                                false
                                                                  ? (e) => {
                                                                      handleFormChange(
                                                                        sectionComponent.id,
                                                                        question.id,
                                                                        "rating",
                                                                        4
                                                                      );
                                                                    }
                                                                  : (e) => {}
                                                              }
                                                            >
                                                              <span className="material-icons-outlined">
                                                                star_outline
                                                              </span>
                                                            </li>

                                                            <li
                                                              className={
                                                                surveyFeedback[
                                                                  sectionComponent
                                                                    .id
                                                                ][question.id][
                                                                  "answer"
                                                                ] == 5
                                                                  ? "active"
                                                                  : ""
                                                              }
                                                              onClick={
                                                                question.hasreviewed ==
                                                                false
                                                                  ? (e) => {
                                                                      handleFormChange(
                                                                        sectionComponent.id,
                                                                        question.id,
                                                                        "rating",
                                                                        5
                                                                      );
                                                                    }
                                                                  : (e) => {}
                                                              }
                                                            >
                                                              <span className="material-icons-outlined">
                                                                star_outline
                                                              </span>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                      ) : question.type ==
                                                        "multiselect" ? (
                                                        <>
                                                          {question.hasreviewed ==
                                                          false ? (
                                                            <div className="mb-3">
                                                              <Select
                                                                placeholder="Select multiple options"
                                                                options={
                                                                  question.answeroptions
                                                                }
                                                                value={
                                                                  selectedOptions
                                                                }
                                                                isMulti={true}
                                                                onChange={(
                                                                  val,
                                                                  e
                                                                ) => {
                                                                  optionSelectionHandler(
                                                                    val,
                                                                    sectionComponent.id,
                                                                    question.id,
                                                                    question.type,
                                                                    e
                                                                  );
                                                                }}
                                                                // classNamePrefix="select2-selection"
                                                                className="select2 form-select shadow-none rounded-0"
                                                                // styles={customSelectStyle}
                                                              />
                                                            </div>
                                                          ) : (
                                                            <div className="form_commnts_area">
                                                              <div className="feedback_bx">
                                                                <p>
                                                                  {
                                                                    question.answer
                                                                  }
                                                                </p>
                                                              </div>
                                                            </div>
                                                          )}
                                                        </>
                                                      ) : question.type ==
                                                        "textbox" ? (
                                                        <>
                                                          {question.hasreviewed ==
                                                          false ? (
                                                            <div className="mb-3">
                                                              <input
                                                                type="text"
                                                                placeholder="Type Your Answer"
                                                                className="form-control shadow-none rounded-0"
                                                                value={
                                                                  surveyFeedback[
                                                                    sectionComponent
                                                                      .id
                                                                  ][
                                                                    question.id
                                                                  ]["answer"]
                                                                    ? surveyFeedback[
                                                                        sectionComponent
                                                                          .id
                                                                      ][
                                                                        question
                                                                          .id
                                                                      ][
                                                                        "answer"
                                                                      ]
                                                                    : ""
                                                                }
                                                                onChange={(e) =>
                                                                  handleFormChange(
                                                                    sectionComponent.id,
                                                                    question.id,
                                                                    "answer",
                                                                    e
                                                                  )
                                                                }
                                                              />
                                                            </div>
                                                          ) : (
                                                            <div className="form_commnts_area">
                                                              <div className="feedback_bx">
                                                                <p>
                                                                  {
                                                                    question.answer
                                                                  }
                                                                </p>
                                                              </div>
                                                            </div>
                                                          )}
                                                        </>
                                                      ) : question.type ==
                                                        "numeric" ? (
                                                        <>
                                                          {question.hasreviewed ==
                                                          false ? (
                                                            <div className="mb-3">
                                                              <input
                                                                type="number"
                                                                min={0}
                                                                placeholder="Enter a number"
                                                                className="form-control shadow-none rounded-0"
                                                                value={
                                                                  surveyFeedback[
                                                                    sectionComponent
                                                                      .id
                                                                  ][
                                                                    question.id
                                                                  ]["answer"]
                                                                    ? surveyFeedback[
                                                                        sectionComponent
                                                                          .id
                                                                      ][
                                                                        question
                                                                          .id
                                                                      ][
                                                                        "answer"
                                                                      ]
                                                                    : 0
                                                                }
                                                                onChange={(e) =>
                                                                  handleFormChange(
                                                                    sectionComponent.id,
                                                                    question.id,
                                                                    "number",
                                                                    e
                                                                  )
                                                                }
                                                              />
                                                            </div>
                                                          ) : (
                                                            <div className="form_commnts_area">
                                                              <div className="feedback_bx">
                                                                <p>
                                                                  {
                                                                    question.answer
                                                                  }
                                                                </p>
                                                              </div>
                                                            </div>
                                                          )}
                                                        </>
                                                      ) : question.type ==
                                                        "singleselect" ? (
                                                        <>
                                                          {question.hasreviewed ==
                                                          false ? (
                                                            <div className="mb-3">
                                                              <Select
                                                                value={
                                                                  selectedSingleOption
                                                                }
                                                                onChange={(
                                                                  val,
                                                                  e
                                                                ) => {
                                                                  singleSelectionHandler(
                                                                    val,
                                                                    sectionComponent.id,
                                                                    question.id,
                                                                    question.type,
                                                                    e
                                                                  );
                                                                }}
                                                                options={
                                                                  question.answeroptions
                                                                }
                                                                //classNamePrefix="select2-selection"
                                                                placeholder="Select option"
                                                                className="select2 form-select shadow-none rounded-0"
                                                              />
                                                            </div>
                                                          ) : (
                                                            <div className="form_commnts_area">
                                                              <div className="feedback_bx">
                                                                <p>
                                                                  {
                                                                    question.answer
                                                                  }
                                                                </p>
                                                              </div>
                                                            </div>
                                                          )}
                                                        </>
                                                      ) : null}
                                                    </>

                                                    {/* ----------- TextArea & Button section starts here ----------- */}
                                                    {question.hasreviewed ==
                                                    false ? (
                                                      <>
                                                        {question.allowcomments ==
                                                        1 ? (
                                                          <div className="form_commnts_area">
                                                            <textarea
                                                              name="comments"
                                                              className="form-control"
                                                              placeholder="Enter your comments"
                                                              value={
                                                                surveyFeedback[
                                                                  sectionComponent
                                                                    .id
                                                                ][question.id][
                                                                  "comments"
                                                                ]
                                                                  ? surveyFeedback[
                                                                      sectionComponent
                                                                        .id
                                                                    ][
                                                                      question
                                                                        .id
                                                                    ][
                                                                      "comments"
                                                                    ]
                                                                  : ""
                                                              }
                                                              onChange={(e) =>
                                                                handleFormChange(
                                                                  sectionComponent.id,
                                                                  question.id,
                                                                  "comments",
                                                                  e
                                                                )
                                                              }
                                                            ></textarea>

                                                            {/* -------- Disabled button starts here -------- */}
                                                            {/* <div className="furmcmnt_btn">
                                                          <button
                                                            className="cmnt_btn"
                                                            onClick={(e) =>
                                                              handleAnswerSubmit(
                                                                e
                                                              )
                                                            }
                                                          >
                                                            <span className="material-icons-outlined">
                                                              send
                                                            </span>
                                                          </button>
                                                        </div> */}
                                                          </div>
                                                        ) : null}
                                                      </>
                                                    ) : (
                                                      <>
                                                        {question.comment !=
                                                        "" ? (
                                                          <div className="form_commnts_area">
                                                            <div className="feedback_bx">
                                                              <p>
                                                                {
                                                                  question.comment
                                                                }
                                                              </p>
                                                            </div>
                                                          </div>
                                                        ) : null}
                                                      </>
                                                    )}
                                                  </div>

                                                  {/* -------- Date component starts here -------- */}
                                                  {question.hasreviewed ==
                                                    false ||
                                                  question.date ==
                                                    null ? null : (
                                                    <div className="ans_date">
                                                      <h5>
                                                        <i className="material-icons-outlined">
                                                          event
                                                        </i>{" "}
                                                        <span>
                                                          {question.date}
                                                        </span>
                                                      </h5>
                                                    </div>
                                                  )}

                                                  {/* -------- Date component ends here -------- */}
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      ) : null}
                                    </>
                                  ) : null}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      ) : null}
                    </div>
                  ) : null}

                  {/* ---------- Accordian section ends here ---------- */}

                  {/* ---------- Submit Button section starts here ---------- */}
                  {surveyJson.pendingfeedback != 0 ||
                  surveyJson.pendingfeedback == undefined ? (
                    <div className="submit_survay">
                      <button
                        type="submit"
                        className="basic_btn ylw_btn"
                        onClick={(e) => handleAnswerSubmit(e)}
                      >
                        {t("Submit Feeedback")}
                      </button>
                    </div>
                  ) : null}
                  {/* ---------- Submit Button section ends here ---------- */}
                </div>
              )}
            </div>
          )}
        </div>
      )}
      <ThankYouModal />
    </>
  );
};

export default RightPanel;
