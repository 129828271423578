import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { GlobalIndraInfo } from "App";
import { t } from "i18next";

const BreadCrumbs = () => {
  const { groupName, refreshGroupsLists } = useContext(GlobalIndraInfo);
  return (
    <>
      <div className="bredcum_area">
        <div className="container">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">{t("Home")}</Link>
              </li>
              <li className="breadcrumb-item">
                <Link
                  to="/groups"
                  onClick={() => {
                    refreshGroupsLists();
                  }}
                >
                  {t("Groups")}
                </Link>
              </li>
              {groupName == "" ? (
                <li className="breadcrumb-item active" aria-current="page"></li>
              ) : (
                <li className="breadcrumb-item active" aria-current="page">
                  {groupName}
                </li>
              )}
            </ol>
          </nav>
        </div>
      </div>
    </>
  );
};

export default BreadCrumbs;
