import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// importing context API here
import { GlobalIndraInfo } from "App";

// import sample video here
import blankImage from "assets/CourseDetailsAssests/images/blank-img.jpg";
import blankUser from "assets/CourseDetailsAssests/images/default-user.png";
import defaultUser from "assets/ConversationAssests/images/default-user.png";

import blankVideo from "assets/CourseDetailsAssests/images/course-vdo-right-blank.jpg";
import blankLesson from "assets/CourseAssets/svg/lesson-empty.svg";

import brochurePdf from "assets/CourseDetailsAssests/images/PDF.jpg";
import brochureDoc from "assets/CourseDetailsAssests/images/DOC.jpg";
import brochurePpt from "assets/CourseDetailsAssests/images/PPT.jpg";
import brochureXls from "assets/CourseDetailsAssests/images/XLS.jpg";

/* -------------------------- required imports for API Call -------------------------- */

import MarkCompleteModal from "components/CourseDetailsComponents/Modals/MarkCompleteModal";
/* ----------------------------------------------------------------------------------- */

import downloadFileHandler from "helpers/downloadfile";



const LessonContent = (props) => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  const brochureBgStyle = {
    backgroundImage: 'url(/images/brochure-bg.png)',
  };

  // importing useContext functions here
  const { isCourseUploader } = useContext(GlobalIndraInfo);

  // Initial useState to save the content recceived from the props.
  const [contentData, setContentData] = useState(null);
  const [lessonId, setLessonId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // const downloadFile = (url, filename) => {
  //   fetch(url)
  //     .then(response => response.blob())
  //     .then(blob => {
  //       const href = window.URL.createObjectURL(blob);
  //       const link = document.createElement('a');
  //       link.href = href;
  //       link.download = filename;
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //     })
  //     .catch(error => console.error('Error downloading file:', error));
  // };

  const buttonStyle = {
    cursor: 'pointer',
  };

  // UseEffect starts from here
  useEffect(() => {
    if (props.content) {
      // assigning the data to the useState
      setContentData(props.content);

      console.log('contentData', contentData);
    }
  }, [props.content]);

  useEffect(() => {
    if (props.lessonContentLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [props.lessonContentLoading]);

  return (
    <div className="tab-pane fade active show" id="content">
      {isLoading ? (
        <div className="content_pnl lesson_pnl">
          <p className="data_btnOuter mb-20">
            <span className="placeholder placeholder-sm col-12 rounded-3 mb-10"></span>
            <span className="placeholder placeholder-sm col-10 rounded-3 mb-0"></span>
          </p>
          <p className="data_btnOuter mb-20">
            <span className="placeholder placeholder-sm col-1 rounded-3 mb-0"></span>
            <span className="placeholder placeholder-sm col-2 rounded-3 mb-0"></span>
            <span className="placeholder placeholder-sm col-3 rounded-3 mb-0"></span>
          </p>
          <div className="course_vdopnl">
            <img src={blankImage} alt="" />
          </div>
        </div>
      ) : (
        <div className="content_pnl lesson_pnl">
          {contentData == null ? null : (
            <div>
              <div className="lesson_detailsHdng">
                <h2>{contentData.title}</h2>
              </div>

              <div className="lesson_info">
                {contentData.instructors != null &&
                contentData.instructors.length > 0 ? (
                  <div className="userexprt_pnltxt">
                    {contentData.instructors.map((instructor, index) => {
                      return (
                        <div className="userexprt_pnltxt me-2" key={index}>
                          <div className="expert_usr">
                            {instructor.image ? (
                              <img src={instructor.image} alt="user" />
                            ) : (
                              <img src={defaultUser} alt="user" />
                            )}
                          </div>
                          <div className="exprt_text">
                            <h5>{instructor.label ? instructor.label : ""}</h5>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="userexprt_pnltxt">
                    {contentData.members.map((instructor, index) => {
                      return (
                        <div className="userexprt_pnltxt" key={index}>
                          <div className="expert_usr">
                            {instructor.image ? (
                              <img src={instructor.image} alt="user" />
                            ) : (
                              <img src={defaultUser} alt="user" />
                            )}
                          </div>
                          <div className="exprt_text me-2">
                            <h5>{instructor.label ? instructor.label : ""}</h5>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}

                {/* ---------- Lesson time duration starts here ---------- */}
                <div className="info_rating">
                  <ul>
                    <li>
                      <i className="material-icons-outlined">schedule</i>
                      <span>{contentData.duration} mins</span>
                    </li>
                  </ul>
                </div>
                {/* ---------- Lesson time duration ends here ---------- */}
              </div>

              {/* -------- Lesson video section starts here -------- */}
              {contentData.videos.length === 0 ? null : (
                <div className="course_vdopnl brouchure_course_pnl">
                  {contentData.videos.map((video, index) => {
                    return (

                      video.uploadtype === null || video.uploadtype === 'video' ?

                        (<div
                            className="embed-responsive embed-responsive-16by9 course_vdoBx"
                            key={index}
                          >
                            {/* ----- Video Label start ----- */}
                            {video.label && (
                              <div className="lesson_info">
                                <div className="info_rating mt-4 mx-0">
                                  <ul>
                                    <li>
                                      <span>{video.label}</span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            )}
                            {/* ----- Video Label end ----- */}

                            <div className="contnt_hdng mt-3">
                              <h4>{video.title}</h4>
                            </div>
                            <video
                              className="embed-responsive-item"
                              style={{ borderRadius: "5px" }}
                              src={video.path}
                              id="samplepreview"
                              controls
                              allowFullScreen
                              width="100%"
                              height="100%"
                              fullscreen="true"
                            ></video>
                        </div>
                          )
                        :
                        (
                          <div className="brochure_bx p-4 mt-5 d-flex gap-3" style={brochureBgStyle} key={index}>
                              <div className="brochure_pic mb-3">
                                {video.uploadtype === 'pdf' ?
                                  <img src={brochurePdf} alt="" />
                                  :''}

                                {video.uploadtype === 'doc' ?
                                  <img src={brochureDoc} alt="" />
                                  :''}

                                {video.uploadtype === 'ppt' ?
                                  <img src={brochurePpt} alt="" />
                                  :''}

                                {video.uploadtype === 'xls' ?
                                  <img src={brochureXls} alt="" />
                                  :''}
                              </div>
                              <div className="brochure_text">
                                  <h2 className="mb-3">{video.title}</h2>
                                  <p className="mb-3"
                                  dangerouslySetInnerHTML={{
                                      __html: video.description,
                                    }}>
                                  </p>
                                  <div className="d-flex gap-2 brouchure_slct_outer">
                                      
                                      <div className="brochure_btn w-100">
                                          <Link to="#" className="basic_btn ylw_btn gap-1 w-100"
                                          onClick={() => downloadFileHandler(video)}
                                          >
                                              <i className="material-icons-outlined d-block">file_download</i>
                                              <span>{t("Download")} </span>
                                          </Link>
                                      </div>
                                  </div>

                              </div>
                          </div>
                        )                    

                    );
                  })}
                </div>
              )}
              {/* -------- Lesson video section ends here -------- */}

              <div className="course_contentOuter">
                <div className="course_contentBx">
                  <div className="contnt_hdng">
                    <h4>{contentData.title}</h4>
                  </div>
                  {contentData.content != null ? (
                    <div className="results_para">
                      <form>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: contentData.content,
                          }}
                        ></p>
                      </form>
                    </div>
                  ) : null}
                </div>
              </div>
              
              {/* -------------- Lesson documents -------------- */}

              {/* {contentData.documents && contentData.documents.length > 0 ?            
              <div className="download_list mt-3 mb-3">
                <hr/>
                <span className="contnt_hdng">
                  <h4 className="text-uppercase">{t("Attached Documents")}</h4>
                </span>

                <ul className=" mt-2">

                   {contentData.documents.map((doc, index)=>{

                      return (
                              <li className="mb-2" onClick={() => downloadFileHandler(doc)} style={buttonStyle}>
                                  <div className="d-flex align-items-center gap-2 py-2 px-3 justify-content-between">
                                      <span className="d-block download_text">{doc.title}</span>
                                      <i className="material-icons-outlined d-block">file_download</i>
                                  </div>
                              </li>
                      )

                    })}

                </ul>

              </div>
              :''
              } */}

             
              

              {/* -------- Mark lesson complete button also trigger complete modal -------- */}
              {contentData.hascompleted ||
              userInfo.id == contentData.moderatorid ||
              userInfo.isadmin ||
              isCourseUploader ? null : (
                <div className="lesson_btn">
                  <Link
                    to="#"
                    className="basic_btn"
                    data-bs-toggle="modal"
                    data-bs-target="#complete_success_modal"
                    onClick={() => setLessonId(contentData.id)}
                  >
                    {t("Mark Lesson Complete")}
                  </Link>
                </div>
              )}
            </div>
          )}
        </div>
      )}

      <MarkCompleteModal lessonId={lessonId} />
    </div>
  );
};

export default LessonContent;
