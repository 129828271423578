/* eslint-disable */
import { useEffect } from "react";

const Logout = () => {
  const token = localStorage.getItem("token");

  useEffect(() => {
    try {
      if (token) {
        localStorage.clear();
        window.location.reload();
      }
    } catch (error) {}
  }, [token]);
};

export default Logout;
