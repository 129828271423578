/* eslint-disable */
import React, { useState, useEffect, createContext } from "react";
import { Switch, Route } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import route lists
import { publicRoutes, protectedRoutes } from "./routes/index";

/* ============--- Error 404 Route Components ==================- */
import Error from "pages/Error/Error";

// import middlewire for authenticated routing
import Authmiddleware from "./routes/Authmiddleware";

// Creating the context API here in the parent
export const GlobalIndraInfo = createContext();

const App = () => {
  /*====== all usestates for surveyfeedback.js start here ======*/
  const [hideRightPanel, setHideRightPanel] = useState(false); //for hiding survey right panel rightPanel.js
  /*====== all usestates for surveyfeedback.js end here ======*/

  /*====== all usestates for blog.js and bloglist.js start here ======*/
  const [blogName, setBlogName] = useState(""); //for blog name in breadcrumb.js(blog)
  /*====== all usestates for blog.js and bloglist.js end here ======*/

  /*====== all use state for banner.js start here ======*/
  const [callSlider, setCallSlider] = useState(false); //for reload slider in banner.js
  /*====== all use state for banner.js end here ======*/

  /* ====== all  activity.js use states start here ======*/
  const [loadFilterData, setloadFilterData] = useState(false); // for reload filter option activityFilter.js
  /* ====== all activity.js use states end here ======*/

  /* ====== all  conversation.js use states start here ======*/
  const [realoadChatPanel, setRealoadChatPanel] = useState(false); // for reload chat section conversation -->leftpaenl.js
  const [showToastGroupWarning, setShowToastGroupWarning] = useState(false);
  /* ====== all conversation.js use states end here ======*/

  /* ============ Course Requirements starts here ============ */
  const [receivedCourseName, setReceivedCourseName] = useState(""); // Initial useState to save the Course Name from Course Details
  const [lessonTabReload, setLessonTabReload] = useState(false);
  const [coursePageReset, setCoursePageReset] = useState(false);
  /* ==================== Course Requirements ends here ==================== */

  /*==================== course useStates start ========================*/
  const [joinNow, setJoinNow] = useState(false);
  const [joinIsLoading, setJoinIsLoading] = useState(false);
  const [lessonComplete, setLessonComplete] = useState(false);
  const [subscriberStatus, setSubscriberStatus] = useState(false);
  const [searchTitle, setSearchTitle] = useState("");
  const [searchSkills, setSearchSkills] = useState([]);
  const [searchCategory, setSearchCategory] = useState([]);
  const [refreshCourseList, setRefreshCourseList] = useState(false);
  const [isCourseUploader, setIsCourseUploader] = useState(false);
  const [selectedCourseFilterData, setSelectedCourseFilterData] =
    useState(null);
  const [trainingCourseTabReload, setTrainingCourseTabReload] = useState(false);
  const [afterReviewReload, setAfterReviewReload] = useState(false);
  /*==================== course useStates end ========================*/

  /* ==================== Challenges Search Query =================== */
  const [searchJobTitle, setSearchJobTitle] = useState("");
  const [searchJobCity, setSearchJobCity] = useState("");
  const [searchJobCategory, setSearchJobCategory] = useState([]);
  const [refreshChallengeList, setRefreshChallengeList] = useState(false);
  const [challengeFilterValue, setChallengeFilterValue] = useState(null);
  const [challengeName, setChallengeName] = useState("");
  const [reloadChallengeDetails, setReloadChallengeDetails] = useState(false);
  const [isModeratorApproved, setIsModeratorApproved] = useState(false);
  const [isJobPoster, setIsJobPoster] = useState(false);
  const [jobPageReset, setJobPageReset] = useState(false);
  const [realoadAppliedTab, setRealoadAppliedTab] = useState(false);

  /* ==================== champions use states ===================== */
  const [champName, setChampName] = useState("");
  const [champCategory, setChampCategory] = useState([]);
  const [champCity, setChampCity] = useState("");
  const [refreshChampLists, setRefreshChampLists] = useState(false);
  const [championName, setChampionName] = useState("");
  const [isChampionConnect, setIsChampionConnect] = useState(false);
  const [reloadChampionDetails, setReloadChampionDetails] = useState(false);
  const [champPageReset, setChampPageReset] = useState(false);

  /* ============ all Event Details Requirements starts here ============ */
  const [receivedEventName, setReceivedEventName] = useState("");
  /* ============ all Event Details Requirements ends here ============ */

  /* ==================== events use states ===================== */
  const [eventTitle, setEventTitle] = useState("");
  const [eventCategory, setEventCategory] = useState([]);
  const [eventCity, setEventCity] = useState("");
  const [eventFilterValue, setEventFilterValue] = useState(null);
  const [refreshEventList, setRefreshEventList] = useState(false);
  const [joinEvent, setJoinEvent] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [isJoined, setIsJoined] = useState(false);
  const [afterReviewEvent, setAfterReviewEvent] = useState(false);
  const [eventPageReset, setEventPageReset] = useState(false);
  const [reloadParticipantsTab, setReloadParticipantsTab] = useState(false);
  const [reloadForumTab, setReloadForumTab] = useState(false);
  const [reloadReviewTab, setReloadReviewTab] = useState(false);

  /* =============== groups and group details use states start hare =============== */
  const [groupTitle, setGroupTitle] = useState("");
  const [groupCategory, setGroupCategory] = useState([]);
  const [groupCity, setGroupCity] = useState("");
  const [refreshGroupList, setRefreshGroupList] = useState(false);
  const [groupFilterType, setGroupFilterType] = useState(null);
  const [reviewPermission, setReviewPermission] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [afterReviewGroup, setAfterReviewGroup] = useState(false);
  const [groupPageReset, setGroupPageReset] = useState(false);
  const [reloadMemberTab, setReloadMemberTab] = useState(false);
  const [reloadGroupForumTab, setReloadGroupForumTab] = useState(false);
  const [reloadGroupReviewTab, setReloadGroupReviewTab] = useState(false);
  /* =============== groups and group details use states end hare =============== */

  /*================== cookie use states start ======================*/
  const [cookieReload, setCookieReload] = useState(false);
  /*================== cookie use states end ======================*/

  /* ======================== Event details requirements start ======================== */
  const getEventName = (data) => {
    setReceivedEventName(data);
  };
  /* ======================== Event details requirements end ======================== */

  //function for join course
  const joinNowConfirm = (data) => {
    if (data) {
      setJoinNow(true);
    } else {
      setJoinNow(false);
    }
  };

  //function for join loading
  const joinNowLoading = (data) => {
    if (data) {
      setJoinIsLoading(true);
    } else {
      setJoinIsLoading(false);
    }
  };

  //function for load lesson
  const completeLesson = (data) => {
    if (data) {
      setLessonComplete(true);
    } else {
      setLessonComplete(false);
    }
  };

  //function for get search course queries paramters
  const searchQueryValues = (data) => {
    if (data) {
      setSearchTitle(data.searchTitle);
      setSearchSkills(data.skills);
      setSearchCategory(data.category);
      setSelectedCourseFilterData(data.selectedCourseFilter);
    }
  };

  //function for reset course title only
  const resetCourseTitleOnly = (data) => {
    if (data) {
      setSearchTitle("");
    }
  };

  //function for reset course skills only
  const resetCourseSkillsOnly = (data) => {
    if (data) {
      setSearchSkills([]);
    }
  };

  //function for reset course category only
  const resetCourseCategoryOnly = (data) => {
    if (data) {
      setSearchCategory([]);
    }
  };

  // function for delete single skill
  const deleteSingleCourseSkill = (data) => {
    setSearchSkills(data);
  };

  //function for delete single course category only
  const deleteSingleCourseCategory = (data) => {
    setSearchCategory(data);
  };

  // function for delete filter type only
  const resetCourseFilterOnly = (data) => {
    if (data) {
      setSelectedCourseFilterData(null);
    }
  };

  // function for reset all queries for courselists components
  const resetCourseQuery = (data) => {
    if (data) {
      setRefreshCourseList(true);
    } else {
      setRefreshCourseList(false);
    }
    setSearchTitle("");
    setSearchSkills([]);
    setSearchCategory([]);
  };

  /*====== chllenge all function start here ======*/
  // function for get search challenges parameters
  const searchJobsQueryValues = (data) => {
    // assign to the useStates
    if (data) {
      setSearchJobTitle(data.jobTitle);
      setSearchJobCity(data.jobCity);
      setSearchJobCategory(data.selectedJobsCategory);
      setChallengeFilterValue(data.jobFilter);
    }
  };

  // function to reset the challenges filter query
  const resetJobsQuery = (data) => {
    if (data) {
      setRefreshChallengeList(true);
    } else {
      setRefreshChallengeList(false);
    }
    setSearchJobTitle("");
    setSearchJobCity("");
    setSearchJobCategory([]);
    setChallengeFilterValue(null);
  };

  //function for reset job title only
  const resetJobTitleOnly = (data) => {
    if (data) {
      setSearchJobTitle("");
    }
  };

  //function for reset job category only
  const resetJobCategoryOnly = (data) => {
    if (data) {
      setSearchJobCategory([]);
    }
  };

  //function for reset job location only
  const resetJobLocationOnly = (data) => {
    if (data) {
      setSearchJobCity("");
    }
  };

  //function for reset challenge filter value only
  const resetJobFilterOnly = (data) => {
    if (data) {
      setChallengeFilterValue(null);
    }
  };

  //function for delete signle job category
  const deleteSingleJob = (data) => {
    setSearchJobCategory(data);
  };

  /*====== chllenge all function end here ======*/

  /* ============ champion function start ========*/
  //functions for search champions
  const searchChampions = (data) => {
    if (data) {
      setChampName(data.champName);
      setChampCategory(data.champCategory);
      setChampCity(data.championCity);
    }
  };

  //function for reset champions search
  const refreshChampionLists = (data) => {
    if (data) {
      setRefreshChampLists(true);
    } else {
      setRefreshChampLists(false);
    }
    setChampName("");
    setChampCategory([]);
    setChampCity("");
  };

  const resetChampNameOnly = (data) => {
    if (data) {
      setChampName("");
    }
  };

  const resetChampSkillsOnly = (data) => {
    if (data) {
      setChampCategory([]);
    }
  };

  const resetChampLocationOnly = (data) => {
    if (data) {
      setChampCity("");
    }
  };

  const deleteSingleSkill = (data) => {
    setChampCategory(data);
  };

  // function to assign champion name for the breadcrumb
  const getChampionName = (data) => {
    setChampionName(data);
  };

  // function for get connection status with champions
  const getConnectionStatus = (data) => {
    if (data) {
      setIsChampionConnect(true);
    } else {
      setIsChampionConnect(false);
    }
  };

  /* ============ champion function end ==========*/

  /* ======================== Events filter requirements start ======================== */
  // function to search event list
  const searchEventsList = (data) => {
    if (data) {
      setEventTitle(data.eventTitle);
      setEventCity(data.eventCity);
      setEventCategory(data.selectedEventsItemsCategory);
      setEventFilterValue(data.selectedFilterEventItem);
    }
  };

  //function for reset events search
  const refreshEventsLists = (data) => {
    if (data) {
      setRefreshEventList(true);
    } else {
      setRefreshEventList(false);
    }
    setEventTitle("");
    setEventCategory([]);
    setEventCity("");
    setEventFilterValue(null);
  };

  //function for join event
  const joinEventHandler = (data) => {
    if (data) {
      setJoinEvent(true);
    } else {
      setJoinEvent(false);
    }
  };

  // function for reset event title only
  const resetEventTitleOnly = (data) => {
    if (data) {
      setEventTitle("");
    }
  };

  //function for reset event category only
  const resetEventCategoryOnly = (data) => {
    if (data) {
      setEventCategory([]);
    }
  };

  // function for reset event location only
  const resetEventLocationOnly = (data) => {
    if (data) {
      setEventCity("");
    }
  };

  //function for reset event filter value only
  const resetEventFilterOnly = (data) => {
    if (data) {
      setEventFilterValue(null);
    }
  };

  const deleteSingleEvent = (data) => {
    setEventCategory(data);
  };
  /* ======================== Events filter requirements end ======================== */

  /* ======================== Groups filter requirements start ======================== */
  // function to search group list
  const searchGroupsList = (data) => {
    if (data) {
      setGroupTitle(data.groupTitle);
      setGroupCity(data.groupCity);
      setGroupCategory(data.selectedGroupItemsCategory);
      setGroupFilterType(data.groupFilterType);
    }
  };

  //function for reset group name only
  const resetGroupNameOnly = (data) => {
    if (data) {
      setGroupTitle("");
    }
  };
  //function for reset group category only
  const resetGroupCategoryOnly = (data) => {
    if (data) {
      setGroupCategory([]);
    }
  };
  //function for reset group city only
  const resetGroupCityOnly = (data) => {
    if (data) {
      setGroupCity("");
    }
  };

  //function for reset group filter only
  const resetGroupFilterOnly = (data) => {
    if (data) {
      setGroupFilterType(null);
    }
  };

  //function for delete single group category only
  const deleteSingleGroupOnly = (data) => {
    setGroupCategory(data);
  };

  // function for reset group search
  const refreshGroupsLists = (data) => {
    if (data) {
      setRefreshGroupList(true);
    } else {
      setRefreshGroupList(false);
    }
    setGroupTitle("");
    setGroupCategory([]);
    setGroupCity("");
    setGroupFilterType(null);
  };

  // React toastify notification for non-moderators
  useEffect(() => {
    if (showToastGroupWarning) {
      toast.info("only moderator can choose a winner", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
      setShowToastGroupWarning(false);
    }
  }, [showToastGroupWarning]);

  return (
    <>
      <GlobalIndraInfo.Provider
        value={{
          /*====== banner.js requirements start ======*/
          callSlider,
          setCallSlider,
          /*====== banner.js requirements end ======*/

          /*====== blog.js requirements start ======*/
          blogName,
          setBlogName,
          /*====== blog.js requirements end ======*/

          /*====== survey.js requirements start ======*/
          hideRightPanel,
          setHideRightPanel,
          /*====== survey.js requirements end ======*/

          /*====== activity.js requirements start ======*/
          loadFilterData,
          setloadFilterData,
          /*====== activity.js requirements end ======*/

          /*====== conversation.js requirements start ======*/
          realoadChatPanel,
          setRealoadChatPanel,
          showToastGroupWarning,
          setShowToastGroupWarning,
          /*====== conversation.js requirements end ======*/

          /* ======== Course Details requirements starts here ========*/
          receivedCourseName,
          setReceivedCourseName,
          trainingCourseTabReload,
          setTrainingCourseTabReload,
          afterReviewReload,
          setAfterReviewReload,

          /* ======== Course Details requirements end here ========*/
          coursePageReset,
          setCoursePageReset,
          //lesson join
          joinNowConfirm: joinNowConfirm,
          joinNow: joinNow,
          joinNowLoading: joinNowLoading,
          joinIsLoading: joinIsLoading,
          completeLesson,
          lessonComplete,
          subscriberStatus,
          setSubscriberStatus,
          searchQueryValues,
          searchTitle,
          searchSkills,
          searchCategory,
          resetCourseQuery,
          refreshCourseList,
          resetCourseTitleOnly,
          resetCourseSkillsOnly,
          resetCourseCategoryOnly,
          deleteSingleCourseSkill,
          deleteSingleCourseCategory,
          isCourseUploader,
          setIsCourseUploader,
          selectedCourseFilterData,
          resetCourseFilterOnly,
          lessonTabReload,
          setLessonTabReload,
          /*======== course requirments end ======== */

          /*============= challenges  requirements start ============*/
          searchJobsQueryValues,
          searchJobTitle,
          searchJobCity,
          searchJobCategory,
          resetJobsQuery,
          refreshChallengeList,
          challengeFilterValue,
          challengeName,
          setChallengeName,
          reloadChallengeDetails,
          setReloadChallengeDetails,
          isModeratorApproved,
          setIsModeratorApproved,
          isJobPoster,
          setIsJobPoster,
          jobPageReset,
          setJobPageReset,
          realoadAppliedTab,
          setRealoadAppliedTab,
          /*============= challenges requirements end =============*/

          /*============= champions requirements start =============*/
          searchChampions,
          champName,
          champCategory,
          champCity,
          refreshChampLists,
          refreshChampionLists,
          championName,
          getChampionName,
          getConnectionStatus,
          isChampionConnect,
          resetChampNameOnly,
          resetChampSkillsOnly,
          resetChampLocationOnly,
          deleteSingleSkill,
          reloadChampionDetails,
          setReloadChampionDetails,
          champPageReset,
          setChampPageReset,
          /*============= champions requirements end =============*/

          /*============= events  requirements start ============= */
          eventTitle,
          eventCity,
          eventCategory,
          refreshEventList,
          refreshEventsLists,
          searchEventsList,
          deleteSingleEvent,
          resetEventFilterOnly,
          resetJobTitleOnly,
          resetJobCategoryOnly,
          resetJobLocationOnly,
          resetJobFilterOnly,
          deleteSingleJob,
          eventName: receivedEventName,
          getEventName,
          joinEventHandler,
          jointEvent: joinEvent,
          isApproved,
          setIsApproved,
          isJoined,
          setIsJoined,
          resetEventTitleOnly,
          resetEventCategoryOnly,
          resetEventLocationOnly,
          afterReviewEvent,
          setAfterReviewEvent,
          eventPageReset,
          setEventPageReset,
          reloadParticipantsTab,
          setReloadParticipantsTab,
          reloadForumTab,
          setReloadForumTab,
          reloadReviewTab,
          setReloadReviewTab,
          /*============= events requirements end ============= */

          /*============= group requirements start =============*/
          groupTitle,
          groupCity,
          groupCategory,
          groupFilterType,
          refreshGroupList,
          refreshGroupsLists,
          searchGroupsList,
          eventFilterValue,
          groupName,
          setGroupName,
          resetGroupNameOnly,
          resetGroupCategoryOnly,
          resetGroupCityOnly,
          deleteSingleGroupOnly,
          resetGroupFilterOnly,
          reviewPermission,
          setReviewPermission,
          afterReviewGroup,
          setAfterReviewGroup,
          groupPageReset,
          setGroupPageReset,
          reloadMemberTab,
          setReloadMemberTab,
          reloadGroupForumTab,
          setReloadGroupForumTab,
          reloadGroupReviewTab,
          setReloadGroupReviewTab,
          /*============= group filter requirements end =============*/

          /*================== cookie requirements start ======================*/
          cookieReload,
          setCookieReload,
          /*================== cookie requirements end ======================*/
        }}
      >
        <Switch>
          {/* ====== non authenticated routes ====== */}
          {publicRoutes.map((routename, index) => (
            <Authmiddleware
              path={routename.path}
              component={routename.component}
              key={index}
              exact
              isAuthProtected={false}
            />
          ))}
          {/* ======end of  non authenticated routes ====== */}

          {/* ====== authenticated routes ====== */}
          {protectedRoutes.map((routename, index) => (
            <Authmiddleware
              path={routename.path}
              component={routename.component}
              key={index}
              exact
              isAuthProtected={true}
            />
          ))}
          {/* ====== end of authenticated routes ====== */}
          <Route path="*" component={Error} />
        </Switch>
      </GlobalIndraInfo.Provider>

      {/* React Toastify component */}
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
    </>
  );
};

export default App;
