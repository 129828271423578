/* eslint-disable */
import { t } from "i18next";
import React from "react";
import { Link } from "react-router-dom";

const ThanksJoinGroupModal = (props) => {
  return (
    <div className="question_modal_bx">
      <div
        className="modal fade"
        id="group_join_success_modal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="sucess_question_icon">
                <span className="material-icons-outlined">task_alt</span>
              </div>
              {props.moderatorStatus ? (
                <div className="question_success_hdng">
                  <h2>
                    {t("Welcome to")} {props.groupDetailsData.name}
                  </h2>
                  <h5>
                    {t(
                      "Congratulations on becoming the newest member of this group."
                    )}
                  </h5>
                  {/* <h5>{props.modalAdminMessage}</h5> */}
                </div>
              ) : (
                <div className="question_success_hdng">
                  <h2>{t("Thank you for join this group")}</h2>
                  <h5>
                    {t(
                      "Your request will be reviewed by Admin. Notification mail will be sent as accepted"
                    )}
                  </h5>
                  {/* <h5>{props.modalAdminMessage}</h5> */}
                </div>
              )}
              <div className="question_closeBtn">
                <Link
                  to="#"
                  data-bs-dismiss="modal"
                  className="basic_btn ylw_btn"
                  onClick={() => props.afterThankYouModalClose()}
                >
                  {t("Close")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThanksJoinGroupModal;
