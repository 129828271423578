import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { GlobalIndraInfo } from "App";
import { useTranslation } from "react-i18next";

const BreadCrumb = () => {
  // getting search params from useContext
  const { championName, refreshChampionLists } = useContext(GlobalIndraInfo);

  const { t } = useTranslation();

  return (
    <>
      <div className="bredcum_area">
        <div className="container">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">{t("Home")}</Link>
              </li>
              <li className="breadcrumb-item">
                <Link
                  to="/champions"
                  onClick={() => {
                    refreshChampionLists(true);
                  }}
                >
                  {t("Champion")}
                </Link>
              </li>
              {championName ? (
                <li className="breadcrumb-item active" aria-current="page">
                  {championName}
                </li>
              ) : (
                <li className="breadcrumb-item active" aria-current="page"></li>
              )}
            </ol>
          </nav>
        </div>
      </div>
    </>
  );
};

export default BreadCrumb;
