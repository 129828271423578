/* eslint-disable */
import React, { useState, useEffect, useContext, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

// importing context API here
import { GlobalIndraInfo } from "App";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* --------------------------------------------------------------*/

// import custom components here
import LessonContent from "./LessonTabs/LessonContent";
import LessonTask from "./LessonTabs/LessonTask";
import LessonQuestions from "./LessonTabs/LessonQuestions";
import LessonScores from "./LessonTabs/LessonScores";

//import images
import blankLesson from "assets/CourseAssets/svg/lesson-empty.svg";

const Lessons = () => {
  // accessing the user's token here
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const params = useParams();

  //for auto scrolling to top
  const autoScrollTop = useRef(null);

  // importing useContext functions here
  const {
    joinNowConfirm,
    joinIsLoading,
    lessonComplete,
    completeLesson,
    lessonTabReload,
    setLessonTabReload,
    subscriberStatus,
    isCourseUploader,
  } = useContext(GlobalIndraInfo);

  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();

  //initial for lesson lists
  const [isListLoading, setIsListLoading] = useState(false);
  const [joinCourseLoading, setJoinCourseLoading] = useState(false);
  // Intial useState to save all the lessons array from the useContext
  const [lessonData, setLessonData] = useState([]);

  // Intial useState to save the selected lesson ID
  const [selectedLessonData, setSelectedLessonData] = useState(null);

  /*----- initial state for toggling tabs ------*/
  const [lessonContentTab, setLessonContentTab] = useState(true);
  const [lessonTaskTab, setLessonTaskTab] = useState(false);
  const [lessonQuestionTab, setLessonQuestionTab] = useState(false);
  const [lessonScoresTab, setLessonScoresTab] = useState(false);
  const [selectLessonId, setSelectLessonId] = useState(null);
  /*----- end of initial state for toggling tabs ------*/

  //initial for loading glowcount for lesson tab
  const [lessonContentLoading, setLessonContentLoading] = useState(false);

  // number of glow placeholder
  const glowCount = [1, 2, 3];

  // function for get all lesson lists
  const getLessonLists = async (id) => {
    setIsListLoading(true);
    let requestURL =
      url.API_BaseUrl +
      url.API_VIEW_LESSONTAB_DETAILS +
      `?token=${token}` +
      `&cid=${id}`;

    // setIsLoading(true);

    try {
      const response = await getData(requestURL);

      if (response.status) {
        if (response.data && response.data.length > 0) {
          setLessonData(response.data);
          if (selectLessonId == null) {
            getLessonData(response.data[0].id);
          } else {
            if (selectLessonId) {
              getLessonData(selectLessonId);
            }
          }
        } else {
          setLessonData([]);
        }
        setIsListLoading(false);
      }
    } catch (error) {}
  };

  // function for get lesson details using lesson id
  const getLessonData = async (id) => {
    setLessonContentLoading(true);
    let requestURL =
      url.API_BaseUrl +
      url.API_VIEW_LESSON_DETAILS +
      `?token=${token}` +
      `&lid=${id}`;

    // setIsLoading(true);

    try {
      const response = await getData(requestURL);

      console.log('response >>', response);

      if (response.status) {
        if (response.data) {
          setSelectedLessonData(response.data);
        } else {
          setSelectedLessonData(null);
        }
        setLessonContentLoading(false);
      }
    } catch (error) {}
  };

  // funtion for display contents tab
  const showLessonContentTab = (e) => {
    e.preventDefault();
    setLessonContentTab(true);
    setLessonTaskTab(false);
    setLessonQuestionTab(false);
    setLessonScoresTab(false);
  };

  const showLessonTaskTab = (e) => {
    e.preventDefault();
    setLessonContentTab(false);
    setLessonTaskTab(true);
    setLessonQuestionTab(false);
    setLessonScoresTab(false);
  };

  const showLessonQuestionTab = (e) => {
    e.preventDefault();
    setLessonContentTab(false);
    setLessonTaskTab(false);
    setLessonQuestionTab(true);
    setLessonScoresTab(false);
  };

  // function to assign the selected lesson id and send to other tabs
  const assignLessonId = (e, id) => {
    e.preventDefault();
    autoScrollTop.current?.scrollIntoView();
    setSelectLessonId(id);
    getLessonData(id);
    showLessonContentTab(e);
    document.querySelector("body").classList.add("course_open");
  };

  //function for get lesson list after confirm
  const onConfirmLesson = () => {
    getLessonLists(params.id);
  };

  //function for join course by clicking join button
  const joinHandler = () => {
    joinNowConfirm(true);
    getLessonLists(params.id);
  };

  // use effect for change complete lesson status in context
  useEffect(() => {
    if (lessonComplete) {
      getLessonLists(params.id);
      completeLesson(false);
    }
  }, [lessonComplete]);

  // use effect to display glow effect by clicking join button
  useEffect(() => {
    if (joinIsLoading) {
      setJoinCourseLoading(true);
    } else {
      setJoinCourseLoading(false);
    }
  }, [joinIsLoading]);

  useEffect(() => {
    if (lessonTabReload) {
      getLessonLists(params.id);
      setLessonTabReload(false);
    }
  }, [lessonTabReload]);

  // useEffect for Lesson Data array
  useEffect(() => {
    getLessonLists(params.id);
  }, [params.id]);

  return (
    <>
      {/* --------------- Lessons Body starts here --------------- */}
      <div className="tab-pane show active fade" id="lessons">
        {isListLoading ? (
          <div className="lessons_outer">
            <div className="lessons_lftpnl">
              <div className="left_innr">
                {glowCount.map((count) => {
                  return (
                    <div
                      className="lesson_rating_outer placeholder-glow"
                      key={count}
                    >
                      <Link to="#" className="lesson_rating_row">
                        <p className="data_btnOuter mb-20">
                          <span className="placeholder placeholder-sm col-2 rounded-3 mb-0"></span>
                          <span className="placeholder placeholder-sm col-2 rounded-3 mb-0"></span>
                        </p>

                        <p className="data_btnOuter mb-10">
                          <span className="placeholder placeholder-sm col-12 rounded-3 mb-0"></span>
                        </p>
                        <p className="data_btnOuter mb-20">
                          <span className="placeholder placeholder-sm col-10 rounded-3 mb-0"></span>
                        </p>

                        <p className="data_btnOuter mb-20">
                          <span className="placeholder placeholder-sm col-2 rounded-3 mb-0"></span>
                          <span className="placeholder placeholder-sm col-4 rounded-3 mb-0"></span>
                        </p>
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="lessons_rightpnl"></div>
          </div>
        ) : (
          <div>
            {lessonData.length == 0 ? (
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="empty_lessonsArea">
                    <div className="empty_lessonpic">
                      <img src={blankLesson} alt="blanklesson" />
                    </div>
                    <div className="empty_lessons_text">
                      <h2>{t("Sorry....!")}</h2>
                      <p>{t("Lessons are not available at this moment.")}</p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="lessons_outer">
                {/* ---------- Left side component starts here ---------- */}
                <div className="lessons_lftpnl">
                  <div className="left_innr">
                    {lessonData.length > 0 ? (
                      <div className="lesson_rating_outer">
                        {lessonData.map((lesson, index) => {
                          return (
                            <Link
                              to="#"
                              className="lesson_rating_row"
                              key={index}
                              onClick={(e) => assignLessonId(e, lesson.id)}
                            >
                              <div className="lesson_hdng">
                                <h3
                                  style={{
                                    color: "#37AAB2",
                                    fontSize: "15px",
                                  }}
                                >
                                  {lesson.chapter}
                                </h3>
                              </div>
                              <div className="lesson_statusOuter">
                                <div className="lesson_time">
                                  <ul>
                                    <li>
                                      {lesson.duration} {t("mins")}
                                    </li>
                                  </ul>
                                </div>

                                {/* --------- Complete marking here --------- */}
                                {lesson.hascompleted ? (
                                  <div className="lesson_status">
                                    <i className="material-icons-outlined">
                                      check_circle
                                    </i>
                                    <span>
                                      {t("Completed")} on{" "}
                                      {lesson.completiondate}
                                    </span>
                                  </div>
                                ) : null}
                              </div>
                              <div className="lesson_hdng">
                                <h3>{lesson.title}</h3>
                              </div>
                              {/* <div className="lesson_time rating_status">
                                <ul>
                                  <li>Member 1</li>
                                  <li>Member 2</li>
                                </ul>
                              </div> */}

                              {!isCourseUploader || subscriberStatus ? (
                                <div className="lesson_time rating_status">
                                  <ul>
                                    {lesson.approvedtask ? (
                                      <li className="text-success">
                                        {t("Approved")} : {lesson.approvedtask}
                                      </li>
                                    ) : (
                                      <li className="text-success">
                                        {t("Approved")} : 0
                                      </li>
                                    )}
                                    {lesson.pendingtask ? (
                                      <li className="text-danger">
                                        {t("Pending")} : {lesson.pendingtask}
                                      </li>
                                    ) : (
                                      <li className="text-danger">
                                        {t("Pending")} : 0
                                      </li>
                                    )}
                                    {lesson.submittedtask ? (
                                      <li>
                                        {t("Sumitted")} : {lesson.submittedtask}
                                      </li>
                                    ) : (
                                      <li>{t("Sumitted")} : 0</li>
                                    )}

                                    {lesson.totaltask ? (
                                      <li>
                                        {t("Total")} : {lesson.totaltask}
                                      </li>
                                    ) : (
                                      <li>{t("Total")} : 0</li>
                                    )}
                                  </ul>
                                </div>
                              ) : null}
                            </Link>
                          );
                        })}
                      </div>
                    ) : null}
                  </div>
                </div>
                {/* ---------- Left side component ends here ---------- */}

                {/* ---------- Right side component starts here ---------- */}
                {selectedLessonData ? (
                  <div className="lessons_rightpnl">
                    <span ref={autoScrollTop} name="scrollRefSpan" />
                    {isCourseUploader ||
                    userInfo.isadmin ||
                    (selectedLessonData.isapproved &&
                      selectedLessonData.isjoined) ? (
                      <div className="course_detailspnl_innr">
                        <div className="progress_courseOuter">
                          <div className="back_progressOuter">
                            <div className="page_back">
                              <Link
                                to="#"
                                className="back_course"
                                onClick={() => {
                                  document
                                    .querySelector("body")
                                    .classList.remove("course_open");
                                }}
                              >
                                <span className="material-icons-outlined">
                                  arrow_back
                                </span>
                                {t("Back")}
                              </Link>
                            </div>
                            <div className="progress_text">
                              <h5>
                                <span>32%</span> {t("Completed")}
                              </h5>
                            </div>
                          </div>

                          <div className="invite_progressouter">
                            <span
                              className="invite_progress_bar blue_bar"
                              style={{ width: "32%" }}
                            ></span>
                          </div>
                        </div>

                        <div className="lessons_tablink">
                          <ul className="nav nav-tabs">
                            <li className="nav-item">
                              <Link
                                className={
                                  lessonContentTab
                                    ? "nav-link active show"
                                    : "nav-link"
                                }
                                data-bs-toggle="tab"
                                to="#"
                                onClick={(e) => showLessonContentTab(e)}
                              >
                                <span>{t("Content")}</span>
                              </Link>
                            </li>

                            <li className="nav-item">
                              <Link
                                className={
                                  lessonTaskTab
                                    ? "nav-link active show"
                                    : "nav-link"
                                }
                                data-bs-toggle="tab"
                                to="#"
                                onClick={(e) => showLessonTaskTab(e)}
                              >
                                <span>{t("Assignments")}</span>
                              </Link>
                            </li>

                            <li className="nav-item">
                              <Link
                                className={
                                  lessonQuestionTab
                                    ? "nav-link active show"
                                    : "nav-link"
                                }
                                data-bs-toggle="tab"
                                to="#"
                                onClick={(e) => showLessonQuestionTab(e)}
                              >
                                <span>{t("Questions")}</span>
                              </Link>
                            </li>
                          </ul>
                        </div>

                        <div className="tab-content">
                          {lessonContentTab && (
                            <LessonContent
                              content={selectedLessonData}
                              lessonContentLoading={lessonContentLoading}
                            />
                          )}

                          {lessonTaskTab && (
                            <LessonTask
                              tasks={selectedLessonData}
                              onConfirmLesson={onConfirmLesson}
                            />
                          )}

                          {lessonQuestionTab && (
                            <LessonQuestions
                              lessonId={selectedLessonData.id}
                              questionData={selectedLessonData}
                            />
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="empty_lessonsArea">
                        <div className="empty_lessonpic">
                          <img src={blankLesson} alt="blanklesson" />
                        </div>
                        <div className="empty_lessons_text">
                          <h2>{t("Sorry....!")}</h2>
                          {selectedLessonData.isjoined ? (
                            <p>
                              {t(
                                "To view this content, wait for moderator approval."
                              )}
                            </p>
                          ) : (
                            <p>
                              {t(
                                "To view this content, you need to join the course. Moderator approval may required too"
                              )}
                            </p>
                          )}
                          {selectedLessonData.isjoined ? null : (
                            <div className="join_btn">
                              <Link
                                to="#"
                                className="basic_btn ylw_btn"
                                onClick={joinHandler}
                              >
                                {t("Join Now")}
                                {joinCourseLoading && (
                                  <div
                                    className="mx-2 spinner-border spinner-border-sm"
                                    role="status"
                                  >
                                    <span className="visually-hidden">
                                      Loading...
                                    </span>
                                  </div>
                                )}
                              </Link>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
            )}
          </div>
        )}
      </div>
      {/* --------------- Lessons Body ends here --------------- */}
    </>
  );
};

export default Lessons;
