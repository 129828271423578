import React, { useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { GlobalIndraInfo } from "App";

import { useTranslation } from "react-i18next";

const Breadcrumb = () => {
  const params = useParams();
  const { blogName } = useContext(GlobalIndraInfo);

  const { t } = useTranslation();

  return (
    <section id="breadcrumbs_container" className="bg-info py-3">
      <div className="container">
        <ul className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">{t("Home")}</Link>
          </li>

          <li className="breadcrumb-item">
            <Link to="/blogs">{t("Blog")}</Link>
          </li>
          {blogName && (
            <li className="breadcrumb-item active text-dark">
              <span>{blogName}</span>
            </li>
          )}
        </ul>
      </div>
    </section>
  );
};

export default Breadcrumb;
