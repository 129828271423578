/* eslint-disable */
import React, { useState, useContext, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";

// Import CKEditor here (both required).
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

// importing context API here
import { GlobalIndraInfo } from "App";

// importing images here
import PDF from "assets/ConversationAssests/svg/pdf.svg";
import XLS from "assets/ConversationAssests/svg/xls.svg";
import DOCS from "assets/ConversationAssests/svg/docs.svg";
import VIDEO from "assets/ConversationAssests/svg/video.svg";
import ZIP from "assets/ConversationAssests/svg/zip.svg";
import user1 from "assets/ChallegesDetailsAsstes/user1.png";
import defaultUser from "assets/ConversationAssests/images/default-user.png";

// import custom images here
import Google from "assets/ChallegesDetailsAsstes/google.png";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { postFormdata, uploadFile, getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";

/* ----------------------------------------------------------------------------------- */

const ApplyNowModal = (props) => {
  // accessing the user's token here
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  // 👇️ get ID from url
  const params = useParams();

  const imageInputRef = useRef(null);

  const { t } = useTranslation();

  const { setReloadChallengeDetails, setRealoadAppliedTab } =
    useContext(GlobalIndraInfo);

  /*---- CKEditor classic editor configuration----- */
  const editorConfig = {
    toolbar: ["bold", "italic", "link", "bulletedList", "numberedList"],
    ckfinder: {
      // Upload the images to the server using the CKFinder QuickUpload command.
      uploadUrl: url.URL_TEXT_EDITOR_IMAGE_UPLOAD_2,
    },
    placeholder: t("Enter details..."),
  };
  /*----- end of CKEditor classic editor part----- */

  // custom style for react select
  const customSelectStyle = {
    // options style
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted pink",
      borderRadius: 0,
      color: "#666666",
      fontSize: "16px",
      fontFamily: "sans-serif",
    }),

    // Value style
    control: (styles, state) => ({
      ...styles,
      fontSize: "16px",
      padding: "5px",
      fontFamily: "sans-serif",
      borderRadius: 0,
      border: "1px solid #888",
      boxShadow: state.isFocused ? "0px 0px 0px #888" : "none",
      "&:hover": {
        border: "1px solid #888",
        boxShadow: "0px 0px 1px #888",
      },
      backgroundColor: "#F3F3F3",
      height: 50,
    }),

    // placeholder style
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#6f7a7c",
        padding: "5px",
        fontSize: "16px",
        marginVertical: "4px",
        borderRadius: 0,
        fontFamily: "sans-serif",
      };
    },

    // Indicator style
    dropdownIndicator: (base, state) => {
      let changes = { color: "#505050" };
      return Object.assign(base, changes);
    },
  };

  //initial for saving skills options
  const [skillOptions, setSkillOptions] = useState([]);

  // Initial useStates to save data
  const [uploadedImage, setUploadedImage] = useState(userInfo.image);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadedImageId, setUploadedImageId] = useState(null);
  const [firstName, setFirstName] = useState(userInfo.firstname);
  const [lastName, setLastName] = useState(userInfo.lastname);
  const [email, setEmail] = useState(userInfo.email);
  const [phone, setPhone] = useState(userInfo.phone);
  const [resumeTitle, setResumeTitle] = useState("");
  const [resumeSummary, setResumeSummary] = useState("");
  const [resumeDetails, setResumeDetails] = useState("");
  const [ctcMin, setCtcMin] = useState("");
  const [ctcMax, setCtcMax] = useState("");
  const [videoLink, setVideoLink] = useState("");
  const [comments, setComments] = useState("");
  const [privacy, setPrivacy] = useState(0);
  const [selectSkill, setSelectSkill] = useState();
  const [selectedSkills, setSelectedSkills] = useState([]);

  // Initial useState for warnings
  const [resumeTitleWarning, setResumeTitleWarning] = useState(false);
  const [resumeSummaryWarning, setResumeSummaryWarning] = useState(false);
  const [resumeDetailsWarning, setResumeDetailsWarning] = useState(false);
  const [resumeDataWithWarning, setResumeDataWithWarning] = useState(false);
  const [skillWarining, setSkillWarining] = useState(false);
  const [minCtcWarning, setMinCtcWarning] = useState(false);

  const [fileWithoutDataUploading, setFileWithoutDataUploading] =
    useState(false);
  const [fileWithDataUploading, setFileWithDataUploading] = useState(false);

  // initial for uploading images
  const [resumeWithDataWarning, setResumeWithDataWarning] = useState("");
  const [
    resumeWithDataUnsupportedWarning,
    setResumeWithDataUnsupportedWarning,
  ] = useState(false);
  const [uploadedResumeWithData, setUploadedResumeWithData] = useState("");
  const [uploadedResumeWithDataId, setUploadedResumeWithDataId] = useState("");
  const [resumeWithoutDataWarning, setResumeWithoutDataWarning] = useState("");
  const [
    resumeWithoutDataUnsupportedWarning,
    setResumeWithoutDataUnsupportedWarning,
  ] = useState(false);
  const [uploadedResumeWithoutData, setUploadedResumeWithoutData] =
    useState("");
  const [uploadedResumeWithoutDataId, setUploadedResumeWithoutDataId] =
    useState("");
  const [unSupportWarning, setUnSupportWarning] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [uploadedVideoId, setUploadedVideoId] = useState(null);
  const [showVideoPanel, setShowVideoPanel] = useState(false);
  const [videoFile, setVideoFile] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);

  /*------ use state for all view application status -----*/
  const [viewImage, setViewImage] = useState("");
  const [viewName, setViewName] = useState("");
  const [viewSurname, setViewSurname] = useState("");
  const [viewEmail, setViewEmail] = useState("");
  const [viewPhone, setViewPhone] = useState("");
  const [viewTitle, setViewTitle] = useState("");
  const [viewSummary, setViewSummary] = useState("");
  const [viewDetails, setViewDetails] = useState("");
  const [viewSkills, setViewSkills] = useState([]);
  const [viewMinCtc, setViewMinCtc] = useState("");
  const [viewMaxCtc, setViewMaxCtc] = useState("");
  const [viewResumeWithData, setViewResumeWithData] = useState("");
  const [viewResumeWithoutData, setViewResumeWithoutData] = useState("");
  const [viewResumeVideo, setViewResumeVideo] = useState("");
  const [viewVideoLink, setViewVideoLink] = useState("");
  const [viewComments, setViewComments] = useState("");

  // useState to check the checkbox
  const [checkval, setCheckval] = useState("");

  // function to get categories details from API
  const getSkills = async () => {
    const requestURL =
      url.API_BaseUrl + url.API_ALL_CHAMPION_SKILLS + `?token=${token}`;

    try {
      const response = await getData(requestURL);

      if (response.status) {
        if (response.data) {
          setSkillOptions(response.data.options);
        } else {
          setSkillOptions([]);
        }
      } else {
        setSkillOptions([]);
      }
    } catch (error) {}
  };

  //function for upload image
  const uploadImageHandler = async (e) => {
    setIsUploading(true);
    let imgFile = e.target.files[0];

    try {
      let requestURL =
        url.API_BaseUrl + url.API_FILE_UPLOAD + `?token=${token}`;

      const response = await uploadFile(requestURL, imgFile);

      if (response.status) {
        if (response.data && response.data.id) {
          setIsUploading(false);
          setUploadedImageId(response.data.id);
          let previewImage = URL.createObjectURL(imgFile);
          setUploadedImage(previewImage);
        }
      }
    } catch (error) {}
  };

  // function for clear file value
  const resetFile = () => {
    const file = document.getElementById("apply_now_modal_image_upload");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  //function for reset image
  const imageReset = () => {
    setUploadedImage("");
    setUploadedImageId(null);
    resetFile();
  };

  // Function to toggle the checkbox
  const checkBoxToggler = (value) => {
    if (value) {
      setPrivacy(1);
    } else {
      setPrivacy(0);
    }
  };

  /*----- upload resume with data section start -----*/
  const resumeWithDataHandler = async (e) => {
    let uploadedFile = e.target.files[0];

    let proceed = false;

    if (
      uploadedFile.name.split(".").pop() == "docx" ||
      uploadedFile.name.split(".").pop() == "doc" ||
      uploadedFile.name.split(".").pop() == "docm" ||
      uploadedFile.name.split(".").pop() == "pdf"
    ) {
      if (uploadedFile.size <= 12500000) {
        proceed = true;
      } else {
        proceed = false;
      }

      if (proceed) {
        setResumeWithDataWarning("");
        setResumeWithDataUnsupportedWarning(false);
        setFileWithDataUploading(true);
        let apiUrl = url.API_BaseUrl + url.API_FILE_UPLOAD + `?token=${token}`;

        try {
          let response = await uploadFile(apiUrl, uploadedFile);

          if (response.status) {
            setUploadedResumeWithDataId(response.data.id);
          }
        } catch (error) {}
        setFileWithDataUploading(false);
        setUploadedResumeWithData(e.target.files[0]);
      } else {
        setResumeWithDataWarning(t("File size big"));
      }
    } else {
      setResumeWithDataUnsupportedWarning(true);
    }
  };
  /*----- upload resume with data section end -----*/

  /*----- upload resume without data section start -----*/
  const resumeWithoutDataHandler = async (e) => {
    let uploadedFile = e.target.files[0];

    let proceed = false;

    if (
      uploadedFile.name.split(".").pop() == "docx" ||
      uploadedFile.name.split(".").pop() == "doc" ||
      uploadedFile.name.split(".").pop() == "docm" ||
      uploadedFile.name.split(".").pop() == "pdf"
    ) {
      if (uploadedFile.size <= 12500000) {
        proceed = true;
      } else {
        proceed = false;
      }

      if (proceed) {
        setResumeWithoutDataUnsupportedWarning(false);
        setResumeWithoutDataWarning("");
        setFileWithoutDataUploading(true);
        let apiUrl = url.API_BaseUrl + url.API_FILE_UPLOAD + `?token=${token}`;

        try {
          let response = await uploadFile(apiUrl, uploadedFile);

          if (response.status) {
            setUploadedResumeWithoutDataId(response.data.id);
          }
        } catch (error) {}
        setFileWithoutDataUploading(false);
        setUploadedResumeWithoutData(e.target.files[0]);
      } else {
        setResumeWithoutDataWarning("File size big");
      }
    } else {
      setResumeWithoutDataUnsupportedWarning(true);
    }
  };
  /*----- upload resume without data section end -----*/

  /*------ function for select skills start ------*/
  const skillSelectionHandler = (val) => {
    let skillVal = val.map((item) => {
      let skillsObject = {
        label: item.label,
        id: item.id,
      };
      return skillsObject.id;
    });

    if (skillVal.length != 0) {
      setSelectSkill();
      setSelectedSkills(skillVal); //set multiselect values in array
    }
  };
  /*------ function for select skills end ------*/

  /* ----------- video upload section ------------ */
  // count number for spinner
  const loadingCircle = [1, 2, 3, 4, 5, 6];

  const videouploadHandler = async (e) => {
    e.preventDefault();
    if (
      e.target.files[0].type == "video/mp4" ||
      e.target.files[0].type == "video/avi" ||
      e.target.files[0].type == "video/quicktime" ||
      e.target.files[0].type == "video/x-ms-wmv"
    ) {
      setShowVideoPanel(true);
      setUnSupportWarning(false);
      setUploadComplete(true);
      let videoFile = e.target.files[0];

      let apiUrl = url.API_BaseUrl + url.API_FILE_UPLOAD + `?token=${token}`;

      try {
        let response = await uploadFile(apiUrl, videoFile);

        if (response.status) {
          setUploadedVideoId(response.data.id);
          setVideoFile(e.target.files[0]);
          setUploadComplete(false);
        }
      } catch (error) {}

      let videoUrl = URL.createObjectURL(e.target.files[0]);

      // setup preview of video
      let preview = document.getElementById("samplepreview");
      preview.setAttribute("src", `${videoUrl}`);
    } else {
      setUnSupportWarning(true);
    }
  };
  /*---- video upload section end -----*/

  // Function to submit the applicant's application to the API.
  const applicationSubmitHandler = async (e) => {
    e.preventDefault();

    let uploadedPictureId;

    if (
      resumeTitle == "" ||
      selectedSkills.length == 0 ||
      uploadedResumeWithData == "" ||
      ctcMin == "" ||
      resumeDetails == "" ||
      resumeSummary == ""
    ) {
      if (resumeTitle == "") {
        setResumeTitleWarning(true);
      } else {
        setResumeTitleWarning(false);
      }
      if (uploadedResumeWithData == "") {
        setResumeDataWithWarning(true);
      } else {
        setResumeDataWithWarning(false);
      }
      if (selectedSkills.length === 0) {
        setSkillWarining(true);
      } else {
        setSkillWarining(false);
      }
      if (ctcMin == "") {
        setMinCtcWarning(true);
      } else {
        setMinCtcWarning(false);
      }
      if (resumeDetails == "") {
        setResumeDetailsWarning(true);
      } else {
        setResumeDetailsWarning(false);
      }
      if (resumeSummary == "") {
        setResumeSummaryWarning(true);
      } else {
        setResumeSummaryWarning(false);
      }
    } else {
      setResumeTitleWarning(false);
      setResumeDataWithWarning(false);
      setSkillWarining(false);
      setMinCtcWarning(false);
      setResumeSummaryWarning(false);
      setResumeDetailsWarning(false);
      setIsSubmitting(true);

      if (uploadedImageId == null) {
        uploadedPictureId = userInfo.imageid;
      } else {
        uploadedPictureId = uploadedImageId;
      }

      let requestURL =
        url.API_BaseUrl +
        url.API_CHALLENGES_APPLY_NOW +
        `?token=${token}&cid=${params.id}`;

      let applicationData = {
        image: uploadedPictureId,
        firstname: firstName,
        lastname: lastName,
        email: email,
        phone: phone,
        resumetitle: resumeTitle,
        summary: resumeSummary,
        resumedetails: resumeDetails,
        skill: selectedSkills,
        minctc: ctcMin,
        maxctc: ctcMax,
        url: videoLink,
        comments: comments,
        privacy: privacy,
        attachmentwithdata: uploadedResumeWithDataId,
        attachmentwithoutdata: uploadedResumeWithoutDataId,
        videoresume: uploadedVideoId,
      };

      try {
        const response = await postFormdata(requestURL, applicationData);

        if (response.status) {
          setIsSubmitting(false);
          resetHandler();
          let myModal = document.querySelector("#job_apply_modal");
          let modal = bootstrap.Modal.getInstance(myModal);
          modal.hide();
          let newModal = new bootstrap.Modal(
            document.getElementById("challenge_applied_success_modal")
          );
          newModal.show();
          setReloadChallengeDetails(true);
          setRealoadAppliedTab(true);
        }
      } catch (error) {}
    }
  };

  // function to reset all useStates after submission
  const resetHandler = () => {
    if (uploadedImageId != null) {
      setUploadedImageId(null);
    }
    resetFile();
    setUploadedImage(userInfo.image);
    setResumeTitle("");
    setResumeSummaryWarning(false);
    setResumeWithDataUnsupportedWarning(false);
    setResumeDetails("");
    setResumeDetailsWarning(false);
    setResumeWithoutDataUnsupportedWarning(false);
    setSkillWarining(false);
    setCtcMin("");
    setCtcMax("");
    setComments("");
    setPrivacy("");
    setResumeWithDataWarning("");
    resetResumeWithData();
    resetResumeWithoutData();
    setCheckval("");
    setUnSupportWarning(false);
    setResumeTitleWarning(false);
    setResumeDataWithWarning(false);
    setMinCtcWarning(false);
    resetUploadedVideo();
  };

  // reset function for reset resume with data file section
  const resetResumeWithData = () => {
    setUploadedResumeWithData("");
    setUploadedResumeWithDataId("");
  };

  // reset function for reset resume without data file section
  const resetResumeWithoutData = () => {
    setUploadedResumeWithoutData("");
    setUploadedResumeWithoutDataId("");
  };

  // reset function for reset video section
  const resetUploadedVideo = () => {
    // Resetting the uploaded video preview
    let videoUrl = "";
    // setup preview of video
    let preview = document.getElementById("samplepreview");
    if (preview != null) {
      preview.setAttribute("src", `${videoUrl}`);
    }
    setShowVideoPanel(false);
    setUploadComplete(false);
    setUploadedVideoId("");
    setVideoLink("");
    setVideoFile();
  };

  // function for view application status
  const getApplcationDetails = async () => {
    try {
      let requestURL =
        url.API_BaseUrl +
        url.VIEW_APPLICATION_STATUS +
        `?token=${token}&cid=${params.id}`;

      const response = await getData(requestURL);

      if (response.status) {
        if (response.data) {
          if (response.data.image) {
            setViewImage(response.data.image);
          } else {
            setViewImage("");
          }
          setViewName(response.data.name);
          setViewSurname(response.data.surname);
          setViewEmail(response.data.email);
          if (response.data.phone) {
            setViewPhone(response.data.phone);
          } else {
            setViewPhone("");
          }
          if (response.data.resumetitle) {
            setViewTitle(response.data.resumetitle);
          } else {
            setViewTitle("");
          }
          if (response.data.resumesummary) {
            setViewSummary(response.data.resumesummary);
          } else {
            setViewSummary("");
          }
          if (response.data.resumedetails) {
            setViewDetails(response.data.resumedetails);
          } else {
            setViewDetails("");
          }

          if (response.data.skills) {
            setViewSkills(response.data.skills);
          } else {
            setViewSkills([]);
          }
          if (response.data.minctc) {
            setViewMinCtc(response.data.minctc);
          } else {
            setViewMinCtc("");
          }
          if (response.data.maxctc) {
            setViewMaxCtc(response.data.maxctc);
          } else {
            setViewMaxCtc("");
          }
          if (response.data.cvwithdata.path) {
            setViewResumeWithData(response.data.cvwithdata.path);
          } else {
            setViewResumeWithData("");
          }
          if (response.data.cvwithoutdata.path) {
            setViewResumeWithoutData(response.data.cvwithoutdata.path);
          } else {
            setViewResumeWithoutData("");
          }

          if (response.data.videoresume.path) {
            setViewResumeVideo(response.data.videoresume.path);
          } else {
            setViewResumeVideo("");
          }
          if (response.data.videolink) {
            setViewVideoLink(response.data.videolink);
          } else {
            setViewVideoLink("");
          }
          setViewComments(response.data.comment);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (props.isApplied) {
      getApplcationDetails();
    }
  }, [props.isApplied]);

  useEffect(() => {
    getSkills();
  }, []);

  // return statement from here
  return (
    <div className="job_modal">
      {props.isApplied ? (
        <div
          className="modal fade"
          id="job_apply_modal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              {/* ----------- Modal header starts here ----------- */}
              <div className="modal-header">
                <div className="company_logotxt">
                  {/* <span className="logo_circle">
                  <img src={Google} alt="" />
                </span> */}
                  <i className="material-icons-outlined text-white">business</i>
                  <span className="mx-1">
                    {props.companyname == null ? "" : props.companyname}
                  </span>
                </div>
                <div className="modal_hdng">
                  <h3>
                    {t("Apply for")}{" "}
                    {props.jobname != null ? props.jobname : ""}
                  </h3>
                </div>
                <div className="job_tags">
                  <ul>
                    <li>
                      <i className="material-icons-outlined"> place </i>
                      <span>
                        {props.joblocation != null ? props.joblocation : ""}
                      </span>
                    </li>
                    <li>
                      <i className="material-icons-outlined"> event_note </i>
                      <span>{props.jobdate != null ? props.jobdate : ""}</span>
                    </li>
                    <li>
                      <i className="material-icons-outlined"> schedule </i>
                      <span>{props.jobtype != null ? props.jobtype : ""}</span>
                    </li>
                  </ul>
                </div>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={resetHandler}
                >
                  <i className="material-icons-outlined"> close </i>
                </button>
              </div>
              {/* ----------- Modal header ends here ----------- */}

              {/* ----------- Modal body starts here ----------- */}
              <div className="modal-body">
                {/* -------- Personal details row start here -------- */}
                <div className="prsnl_detailsArea">
                  <div className="row">
                    <div className="col-md-3">
                      {viewImage == "" ? null : (
                        <div className="profile-avatar rounded-circle overflow-hidden mx-auto border">
                          <img
                            src={viewImage}
                            alt="User"
                            className="img-fluid w-100 h-100 avatar"
                          />
                        </div>
                      )}
                    </div>
                    <div className="col-md-9">
                      <div className="row mb-0">
                        {/* ----- first name start here ----- */}
                        <div className="col-sm-6">
                          <div className="input_fill jobinput_fill">
                            <label>{t("First Name")}</label>
                            <input
                              type="text"
                              value={viewName}
                              className="form-control bg-transparent border-0 p-0 text-dark"
                              readOnly
                            />
                          </div>
                        </div>
                        {/* ----- first name end here ----- */}

                        {/* ----- last name start here ----- */}
                        <div className="col-sm-6">
                          <div className="input_fill jobinput_fill">
                            <label>{t("Last Name")}</label>
                            <input
                              type="text"
                              value={viewSurname}
                              className="form-control bg-transparent border-0 p-0 text-dark"
                              readOnly
                            />
                          </div>
                        </div>
                        {/* ----- last name end here ----- */}

                        {/* ----- email start here ----- */}
                        <div className="col-sm-6">
                          <div className="input_fill jobinput_fill">
                            <label>{t("Email")}</label>
                            <input
                              type="email"
                              value={viewEmail}
                              className="form-control bg-transparent border-0 p-0 text-dark"
                              placeholder="Enter email"
                              readOnly
                            />
                          </div>
                        </div>
                        {/* ----- email end here ----- */}

                        {/* ----- phone start here ----- */}
                        <div className="col-sm-6">
                          <div className="input_fill jobinput_fill">
                            <label>{t("Phone No")}.</label>
                            <input
                              type="text"
                              value={viewPhone}
                              className="form-control bg-transparent border-0 p-0 text-dark"
                              placeholder="Enter phone no"
                              readOnly
                            />
                          </div>
                        </div>
                        {/* ----- phone end here ----- */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* -------- Personal details row end here -------- */}

                {/* -------- Other details row start here -------- */}
                <div className="others_detailsArea">
                  {/* -------- Resume title start here -------- */}
                  <div className="details_row">
                    <div className="row">
                      <div className="col-lg-3">
                        <div className="details_input_txt">
                          <label>{t("Resume Title")}</label>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        {viewTitle == "" ? null : (
                          <div className="input_fill jobinput_fill">
                            <input
                              type="text"
                              value={viewTitle}
                              className="form-control bg-transparent border-0 p-0 text-dark"
                              placeholder="Enter title"
                              readOnly
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* -------- Resume title end here -------- */}

                  {/* -------- Resume summary start here -------- */}
                  <div className="details_row">
                    <div className="row align-items-start">
                      <div className="col-lg-3">
                        <div className="details_input_txt">
                          <label>{t("Resume Summary")}</label>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className="input_fill jobinput_fill description_fill">
                          <p className="form-control bg-transparent border-0 p-0 text-dark">
                            {viewSummary}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* -------- Resume summary end here -------- */}

                  {/* -------- Resume details start here -------- */}
                  <div className="details_row">
                    <div className="row align-items-start">
                      <div className="col-lg-3">
                        <div className="details_input_txt">
                          <label>{t("Resume Details")}</label>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className="input_fill jobinput_fill description_fill">
                          <p
                            className="form-control bg-transparent border-0 p-0 text-dark"
                            dangerouslySetInnerHTML={{
                              __html: viewDetails,
                            }}
                          ></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* -------- Resume details end here -------- */}

                  {/* ----- skill details start here ------ */}
                  <div className="details_row">
                    <div className="row">
                      <div className="col-lg-3">
                        <div className="details_input_txt">
                          <label>{t("Skills")}</label>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        {viewSkills.length === 0 ? null : (
                          <div className="input_fill jobinput_fill description_fill">
                            <div
                              className="skills d-flex flex-wrap gap-2 my-4"
                              id="ofr_skills"
                            >
                              {viewSkills.map((skill, index) => {
                                return (
                                  <span
                                    className="d-block border border-gray text-dark p-2"
                                    key={index}
                                  >
                                    {skill}
                                  </span>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* ----- skill details end here ------ */}

                  {/* -------- CTC Details section start here -------- */}
                  <div className="details_row">
                    <div className="row">
                      <div className="col-lg-3">
                        <div className="details_input_txt">
                          <label>{t("Expected CTC")}</label>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className="job_fillOuter">
                          <div className="input_fill jobinput_fill">
                            <input
                              type="text"
                              value={viewMinCtc}
                              className="form-control uro_sign bg-transparent border-0 p-0 text-dark"
                              placeholder="Maximum expectation"
                              readOnly
                            />
                          </div>
                          <div className="input_fill jobinput_fill">
                            <input
                              type="text"
                              value={viewMaxCtc}
                              className="form-control uro_sign bg-transparent border-0 p-0 text-dark"
                              placeholder="Minimum expectation"
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* -------- CTC Details section end here -------- */}

                  {/* -------- Upload attachment with data section start here -------- */}
                  <div className="details_row attached_row">
                    <div className="row">
                      <div className="col-lg-3">
                        <div className="details_input_txt">
                          <label>{t("Attach Resume")}</label>
                          <p>{t("with data")}</p>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className="clearfix">
                          <div className="upload_picOuter">
                            {/* ---- displayed uploaded file area ---- */}
                            {viewResumeWithData == "" ? null : (
                              <div className="upload_pic_item">
                                {/* ------------ image preview starts here ------------ */}
                                <div
                                  className="upload_viewimg"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    window.open(viewResumeWithData, "_blank")
                                  }
                                >
                                  <button
                                    type="button"
                                    className="download_icon"
                                    aria-label="Close"
                                  >
                                    <span className="material-icons-outlined">
                                      file_download
                                    </span>
                                  </button>
                                  {viewResumeWithData.split(".").pop() ==
                                    "docx" ||
                                  viewResumeWithData.split(".").pop() ==
                                    "txt" ||
                                  viewResumeWithData.split(".").pop() ==
                                    "doc" ||
                                  viewResumeWithData.split(".").pop() ==
                                    "odt" ||
                                  viewResumeWithData.split(".").pop() ==
                                    "dotx" ||
                                  viewResumeWithData.split(".").pop() ==
                                    "docm" ||
                                  viewResumeWithData.split(".").pop() ==
                                    "pptx" ? (
                                    <>
                                      <img src={DOCS} alt="docs" />
                                      {/* <label>{viewResumeWithData.name}</label> */}
                                    </>
                                  ) : null}
                                  {viewResumeWithData.split(".").pop() ==
                                  "pdf" ? (
                                    <>
                                      <img src={PDF} alt="pdf" />
                                      {/* <label>{viewResumeWithData.name}</label> */}
                                    </>
                                  ) : null}
                                  {viewResumeWithData.split(".").pop() ==
                                    "xlsx" ||
                                  viewResumeWithData.split(".").pop() ==
                                    "xls" ? (
                                    <figure>
                                      <img src={XLS} alt="docs" />
                                      {/* <label>{uploadedResumeWithData.name}</label> */}
                                    </figure>
                                  ) : null}{" "}
                                </div>
                                {/* ------------ image preview ends here ------------ */}{" "}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* -------- Upload attachment with data section end here -------- */}

                  {/* -------- Upload attachment without data section starts here -------- */}
                  <div className="details_row attached_row">
                    <div className="row">
                      <div className="col-lg-3">
                        <div className="details_input_txt">
                          <label>{t("Attach Resume")}</label>
                          <p>{t("without data")}</p>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className="clearfix">
                          <div className="upload_picOuter">
                            {/* ---- displayed uploaded file area ---- */}
                            {viewResumeWithoutData == "" ? null : (
                              <div className="upload_pic_item">
                                {/* ------------ image preview starts here ------------ */}
                                <div
                                  className="upload_viewimg"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    window.open(viewResumeWithoutData, "_blank")
                                  }
                                >
                                  <button
                                    type="button"
                                    className="download_icon"
                                    aria-label="Close"
                                  >
                                    <span className="material-icons-outlined">
                                      file_download
                                    </span>
                                  </button>
                                  {viewResumeWithoutData.split(".").pop() ==
                                    "docx" ||
                                  viewResumeWithoutData.split(".").pop() ==
                                    "txt" ||
                                  viewResumeWithoutData.split(".").pop() ==
                                    "doc" ||
                                  viewResumeWithoutData.split(".").pop() ==
                                    "odt" ||
                                  viewResumeWithoutData.split(".").pop() ==
                                    "dotx" ||
                                  viewResumeWithoutData.split(".").pop() ==
                                    "docm" ||
                                  viewResumeWithoutData.split(".").pop() ==
                                    "pptx" ? (
                                    <>
                                      <img src={DOCS} alt="docs" />
                                      {/* <label>{viewResumeWithData.name}</label> */}
                                    </>
                                  ) : null}
                                  {viewResumeWithoutData.split(".").pop() ==
                                  "pdf" ? (
                                    <>
                                      <img src={PDF} alt="pdf" />
                                      {/* <label>{viewResumeWithData.name}</label> */}
                                    </>
                                  ) : null}
                                  {viewResumeWithoutData.split(".").pop() ==
                                    "xlsx" ||
                                  viewResumeWithoutData.split(".").pop() ==
                                    "xls" ? (
                                    <>
                                      <img src={XLS} alt="docs" />
                                      {/* <label>{uploadedResumeWithData.name}</label> */}
                                    </>
                                  ) : null}
                                </div>
                                {/* ------------ image preview ends here ------------ */}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* -------- Upload attachment video section start here -------- */}
                  <div className="details_row attached_row">
                    <div className="row">
                      <div className="col-lg-3">
                        <div className="details_input_txt">
                          <label>{t("Attach Resume")}</label>
                          <p>{t("video")}</p>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className="upload_area">
                          {/* ------------ Video preview starts here ------------ */}

                          {viewResumeVideo == "" ? null : (
                            <div className="upload_item">
                              <div className="uploaded_innrBx uploaded_vdobx course_uploadBx">
                                <video
                                  id="samplepreviewresume"
                                  controls
                                  height="150px"
                                  width="150px"
                                  fullscreen="true"
                                >
                                  <source src={viewResumeVideo} />
                                </video>
                              </div>
                            </div>
                          )}

                          {/* ------------ Video preview ends here ------------ */}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* -------- Upload attachment video section end here -------- */}

                  {/* -------- Video link section start here -------- */}
                  <div className="details_row">
                    <div className="row">
                      <div className="col-lg-3">
                        <div className="details_input_txt">
                          <label>{t("Video Link")}</label>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className="input_fill jobinput_fill">
                          <input
                            type="text"
                            value={viewVideoLink}
                            className="form-control bg-transparent border-0 p-0 text-dark"
                            readOnly
                          />
                          <Link to="#" className="copy_link">
                            <span className="material-icons-outlined">
                              link
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* -------- Video link section end here -------- */}

                  {/* -------- Comments section start here -------- */}
                  <div className="details_row">
                    <div className="row align-items-start">
                      <div className="col-lg-3">
                        <div className="details_input_txt">
                          <label>{t("Comments")}</label>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className="input_fill jobinput_fill description_fill">
                          <p className="form-control bg-transparent border-0 p-0 text-dark">
                            {viewComments}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* -------- Comments section end here -------- */}
                </div>
                {/* -------- Other details row end here -------- */}
              </div>
              {/* ----------- Modal body ends here ----------- */}
            </div>
          </div>
        </div>
      ) : (
        <div
          className="modal fade"
          id="job_apply_modal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              {/* ----------- Modal header starts here ----------- */}
              <div className="modal-header">
                <div className="company_logotxt">
                  <i className="material-icons-outlined text-white">business</i>
                  <span className="mx-1 mt-1">
                    {props.companyname == null ? "" : props.companyname}
                  </span>
                </div>
                <div className="modal_hdng">
                  <h3>
                    {t("Apply for")}{" "}
                    {props.jobname != null ? props.jobname : ""}
                  </h3>
                </div>
                <div className="job_tags">
                  <ul>
                    <li>
                      <i className="material-icons-outlined"> place </i>
                      <span>
                        {props.joblocation != null ? props.joblocation : ""}
                      </span>
                    </li>
                    <li>
                      <i className="material-icons-outlined"> event_note </i>
                      <span>{props.jobdate != null ? props.jobdate : ""}</span>
                    </li>
                    <li>
                      <i className="material-icons-outlined"> schedule </i>
                      <span>{props.jobtype != null ? props.jobtype : ""}</span>
                    </li>
                  </ul>
                </div>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={resetHandler}
                >
                  <i className="material-icons-outlined">close</i>
                </button>
              </div>
              {/* ----------- Modal header ends here ----------- */}

              {/* ----------- Modal body starts here ----------- */}
              <div className="modal-body">
                {/* -------- Personal details row starts here -------- */}
                <div className="prsnl_detailsArea">
                  <div className="row">
                    <div className="col-md-3">
                      {/* ------ image preview section start ------ */}
                      <div className="profile-avatar rounded-circle overflow-hidden mx-auto border">
                        {isUploading ? (
                          <div className="d-flex align-items-center justify-content-center mt-2">
                            <div
                              className="spinner-border text-secondary"
                              style={{ width: "6.5rem", height: "6.5rem" }}
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          </div>
                        ) : (
                          <img
                            src={
                              uploadedImage == "" ? defaultUser : uploadedImage
                            }
                            alt="User"
                            className="img-fluid w-100 h-100 avatar"
                          />
                        )}
                      </div>
                      {/* ------ image preview section end ------ */}

                      {/* ------ image upload section start ------ */}
                      <div className="action d-flex gap-2 align-items-center justify-content-center mt-3">
                        <div className="user_image_upload">
                          <input
                            type="file"
                            className="applynow_image_upload_input"
                            id="apply_now_modal_image_upload"
                            onChange={uploadImageHandler}
                          />
                          <i className="material-icons-outlined">
                            photo_camera
                          </i>
                        </div>
                        <button
                          onClick={imageReset}
                          className="user_image_upload"
                        >
                          <i className="material-icons-outlined">delete</i>
                        </button>
                      </div>
                      {/* ------ image upload section end ------ */}
                    </div>

                    {/* ------- Preloaded user info section start -------- */}
                    <div className="col-md-9">
                      <div className="row mb-0">
                        <div className="col-sm-6">
                          {/* ------ first name start ----- */}
                          <div className="input_fill jobinput_fill">
                            <label>{t("First Name")}</label>
                            <input
                              type="text"
                              value={firstName}
                              onChange={(e) => {
                                setFirstName(e.target.value);
                              }}
                              className="form-control"
                              placeholder={t("Enter first name")}
                            />
                          </div>
                          {/* ------ first name end ----- */}
                        </div>
                        <div className="col-sm-6">
                          {/* ------ last name start ----- */}
                          <div className="input_fill jobinput_fill">
                            <label>{t("Last Name")}</label>
                            <input
                              type="text"
                              value={lastName}
                              onChange={(e) => {
                                setLastName(e.target.value);
                              }}
                              className="form-control"
                              placeholder={t("Enter last name")}
                            />
                          </div>
                          {/* ------ last name end ----- */}
                        </div>
                        <div className="col-sm-6">
                          {/* ------ email section start ------ */}
                          <div className="input_fill jobinput_fill">
                            <label>{t("Email")}</label>
                            <input
                              type="email"
                              value={email}
                              onChange={(e) => {
                                setEmail(e.target.value);
                              }}
                              className="form-control"
                              placeholder={t("Enter email")}
                            />
                          </div>
                          {/* ------ email section end ------ */}
                        </div>
                        <div className="col-sm-6">
                          {/* ------ phone section start ------ */}
                          <div className="input_fill jobinput_fill">
                            <label>{t("Phone No")}.</label>
                            <input
                              type="number"
                              value={phone ? phone : ""}
                              onChange={(e) => {
                                setPhone(e.target.value);
                              }}
                              className="form-control"
                              placeholder={t("Enter phone no")}
                            />
                          </div>
                          {/* ------ phone section end ------ */}
                        </div>
                      </div>
                    </div>
                    {/* ------- Preloaded user info section end -------- */}
                  </div>
                </div>

                {/* ======== Other details row start ======== */}
                <div className="others_detailsArea">
                  {/* -------- Resume title start -------- */}
                  <div className="details_row">
                    <div className="row align-items-start">
                      <div className="col-lg-3">
                        <div className="details_input_txt">
                          <label>{t("Resume Title")}</label>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className="input_fill jobinput_fill">
                          <input
                            type="text"
                            className="form-control bg-transparent text-dark"
                            placeholder={t("Enter title")}
                            value={resumeTitle}
                            onChange={(e) => {
                              setResumeTitle(e.target.value);
                              setResumeTitleWarning(false);
                            }}
                          />
                          {resumeTitleWarning && (
                            <p className="text-danger mt-2">
                              * {t("please enter resume title")}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* -------- Resume title end -------- */}

                  {/* -------- Resume summary start -------- */}
                  <div className="details_row">
                    <div className="row align-items-start">
                      <div className="col-lg-3">
                        <div className="details_input_txt">
                          <label>{t("Resume Summary")}</label>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className="input_fill jobinput_fill description_fill">
                          <textarea
                            className="form-control pt-2"
                            placeholder={t("Enter Summary")}
                            value={resumeSummary}
                            onChange={(e) => setResumeSummary(e.target.value)}
                          ></textarea>
                          {resumeSummaryWarning && (
                            <p className="text-danger mt-2">
                              * {t("please enter resume summary")}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* -------- Resume summary end -------- */}

                  {/* -------- Resume details start -------- */}
                  <div className="details_row">
                    <div className="row align-items-start">
                      <div className="col-lg-3">
                        <div className="details_input_txt">
                          <label>{t("Resume Details")}</label>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className="input_fill jobinput_fill description_fill">
                          <CKEditor
                            className="form-control"
                            editor={ClassicEditor}
                            config={editorConfig}
                            data={resumeDetails ? resumeDetails : ""}
                            onReady={(editor) => {
                              editor.editing.view.change((writer) => {
                                writer.setStyle(
                                  "height",
                                  "300px",
                                  editor.editing.view.document.getRoot()
                                );
                              });
                              editor.editing.view.change((writer) => {
                                writer.setStyle(
                                  "background-color",
                                  "#F8F8F8",
                                  editor.editing.view.document.getRoot()
                                );
                              });
                              editor.editing.view.change((writer) => {
                                writer.setStyle(
                                  "border",
                                  "1px solid #D7D7D7",
                                  editor.editing.view.document.getRoot()
                                );
                              });
                            }}
                            onChange={(event, editor) => {
                              let editContent = editor.getData();
                              setResumeDetails(editContent);
                            }}
                          />
                          {resumeDetailsWarning && (
                            <p className="text-danger mt-2">
                              * {t("please enter resume details")}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* -------- Resume details end -------- */}

                  {/* ------ resume skills start ------- */}
                  <div className="details_row">
                    <div className="row align-items-start">
                      <div className="col-lg-3">
                        <div className="details_input_txt">
                          <label>{t("Skills")}</label>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className="input_fill jobinput_fill description_fill">
                          <Select
                            isMulti={true}
                            placeholder={t("Select skills")}
                            options={skillOptions} // show all options
                            value={selectSkill} // show selected values
                            styles={customSelectStyle}
                            onChange={(val) => {
                              skillSelectionHandler(val);
                            }} // handler function
                            classNamePrefix="select2-selection"
                          />
                          {skillWarining && (
                            <p className="text-danger mt-2">
                              * {t("please select skills")}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ------ resume skills end ------- */}

                  {/* -------- CTC Details section start -------- */}
                  <div className="details_row">
                    <div className="row align-items-start">
                      <div className="col-lg-3">
                        <div className="details_input_txt">
                          <label>{t("Expected CTC")}</label>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className="job_fillOuter">
                          <div className="input_fill jobinput_fill">
                            <input
                              type="text"
                              className="form-control uro_sign"
                              placeholder={t("Minimum expectation")}
                              value={ctcMin}
                              onChange={(e) => {
                                setCtcMin(e.target.value);
                                setMinCtcWarning(false);
                              }}
                            />
                            {minCtcWarning && (
                              <p className="mt-1 text-danger">
                                * {t("Please fill up minimum CTC")}
                              </p>
                            )}
                          </div>
                          <div className="input_fill jobinput_fill">
                            <input
                              type="text"
                              className="form-control uro_sign"
                              placeholder={t("Maximum expectation")}
                              value={ctcMax}
                              onChange={(e) => setCtcMax(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* -------- CTC Details section end -------- */}

                  {/* -------- Upload attachment with data section start here -------- */}
                  <div className="details_row attached_row">
                    <div className="row align-items-start">
                      <div className="col-lg-3">
                        <div className="details_input_txt">
                          <label>{t("Attach Resume")}</label>
                          <p>{t("with data")}</p>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className="upload_area">
                          <div className="upload_item">
                            {fileWithDataUploading ? (
                              <div className="upload_btn course_uploadBx">
                                <div
                                  className="spinner-border"
                                  style={{ width: "3rem", height: "3rem" }}
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <div className="upload_btn course_uploadBx">
                                <input
                                  name="story"
                                  type="file"
                                  className="file_uoload"
                                  onChange={(e) => {
                                    resumeWithDataHandler(e);
                                    setResumeDataWithWarning(false);
                                  }}
                                />

                                <div className="upload_icon">
                                  <span className="material-icons-outlined">
                                    file_upload
                                  </span>
                                </div>
                                <div className="upload_txt">
                                  <h4>{t("Upload")}</h4>
                                  <h5>{t("Max")} 10mb Doc/PDF</h5>
                                </div>
                              </div>
                            )}
                          </div>

                          {/* ---- displayed uploaded file area ---- */}
                          {uploadedResumeWithDataId && (
                            <div className="upload_item">
                              {/* ------------ image preview starts here ------------ */}
                              <div className="uploaded_innrBx uploaded_vdobx course_uploadBx">
                                <Link
                                  to="#"
                                  className="delete_btn"
                                  onClick={resetResumeWithData}
                                >
                                  <span className="material-icons-outlined">
                                    delete
                                  </span>
                                </Link>
                                {uploadedResumeWithData.type ==
                                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                uploadedResumeWithData.type ==
                                  "application/msword" ||
                                uploadedResumeWithData.type ==
                                  "application/vnd.oasis.opendocument.text" ||
                                uploadedResumeWithData.type ==
                                  "application/vnd.ms-word.document.macroEnabled.12" ||
                                uploadedResumeWithData.type ==
                                  "application/vnd.openxmlformats-officedocument.wordprocessingml.template" ||
                                uploadedResumeWithData.type ==
                                  "application/vnd.openxmlformats-officedocument.presentationml.presentation" ? (
                                  <>
                                    <img
                                      src={DOCS}
                                      alt="docs"
                                      style={{
                                        width: "215px",
                                        height: "215px",
                                      }}
                                    />
                                    <label>{uploadedResumeWithData.name}</label>
                                  </>
                                ) : null}
                                {uploadedResumeWithData.type ==
                                "application/pdf" ? (
                                  <>
                                    <img
                                      src={PDF}
                                      alt="pdf"
                                      style={{
                                        width: "215px",
                                        height: "215px",
                                      }}
                                    />
                                    <label>{uploadedResumeWithData.name}</label>
                                  </>
                                ) : null}
                              </div>
                              {/* ------------ image preview ends here ------------ */}
                            </div>
                          )}

                          {resumeDataWithWarning && (
                            <p className="text-danger mt-2">
                              * {t("please upload atleast one resume atleast")}
                            </p>
                          )}
                          {resumeWithDataUnsupportedWarning ? (
                            <div className="warning_text">
                              <p className="text-danger">
                                * {t("Unsupported File")}
                              </p>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* -------- Upload attachment with data section end here -------- */}

                  {/* -------- Upload attachment without data section start here -------- */}
                  <div className="details_row attached_row">
                    <div className="row align-items-start">
                      <div className="col-lg-3">
                        <div className="details_input_txt">
                          <label>{t("Attach Resume")}</label>
                          <p>{t("without data")}</p>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className="upload_area">
                          <div className="upload_item">
                            {fileWithoutDataUploading ? (
                              <div className="upload_btn course_uploadBx">
                                <div
                                  className="spinner-border"
                                  style={{ width: "3rem", height: "3rem" }}
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <div className="upload_btn course_uploadBx">
                                <input
                                  name="story"
                                  type="file"
                                  className="file_uoload"
                                  onChange={(e) => resumeWithoutDataHandler(e)}
                                />

                                <div className="upload_icon">
                                  <span className="material-icons-outlined">
                                    file_upload
                                  </span>
                                </div>
                                <div className="upload_txt">
                                  <h4>{t("Upload")}</h4>
                                  <h5>{t("Max")} 10mb Doc/PDF</h5>
                                </div>
                              </div>
                            )}
                          </div>
                          {uploadedResumeWithoutDataId && (
                            <div className="upload_item">
                              {/* ------------ image preview starts here ------------ */}
                              <div className="uploaded_innrBx uploaded_vdobx course_uploadBx">
                                <Link
                                  to="#"
                                  className="delete_btn"
                                  onClick={resetResumeWithoutData}
                                >
                                  <span className="material-icons-outlined">
                                    delete
                                  </span>
                                </Link>
                                {uploadedResumeWithoutData.type ==
                                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                uploadedResumeWithoutData.type ==
                                  "application/msword" ||
                                uploadedResumeWithoutData.type ==
                                  "application/vnd.oasis.opendocument.text" ||
                                uploadedResumeWithoutData.type ==
                                  "application/vnd.ms-word.document.macroEnabled.12" ||
                                uploadedResumeWithoutData.type ==
                                  "application/vnd.openxmlformats-officedocument.wordprocessingml.template" ||
                                uploadedResumeWithoutData.type ==
                                  "application/vnd.openxmlformats-officedocument.presentationml.presentation" ? (
                                  <>
                                    <img
                                      src={DOCS}
                                      alt="docs"
                                      style={{
                                        width: "215px",
                                        height: "215px",
                                      }}
                                    />
                                    <label>
                                      {uploadedResumeWithoutData.name}
                                    </label>
                                  </>
                                ) : null}
                                {uploadedResumeWithoutData.type ==
                                "application/pdf" ? (
                                  <>
                                    <img
                                      src={PDF}
                                      alt="pdf"
                                      style={{
                                        width: "215px",
                                        height: "215px",
                                      }}
                                    />
                                    <label>
                                      {uploadedResumeWithoutData.name}
                                    </label>
                                  </>
                                ) : null}
                              </div>
                              {/* ------------ image preview ends here ------------ */}
                            </div>
                          )}
                          {resumeWithoutDataUnsupportedWarning ? (
                            <div className="warning_text">
                              <p className="text-danger">
                                * {t("Unsupported File")}
                              </p>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* -------- Upload attachment without data section end here -------- */}

                  {/* -------- Upload attachment video section start here -------- */}
                  <div className="details_row attached_row">
                    <div className="row align-items-start">
                      <div className="col-lg-3">
                        <div className="details_input_txt">
                          <label>{t("Attach Resume")}</label>
                          <p>{t("video")}</p>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className="upload_area">
                          {/* ------------ Video uploader starts here ------------ */}
                          {uploadComplete ? (
                            <div className="upload_btn course_uploadBx">
                              <div
                                className="spinner-border"
                                style={{ width: "3rem", height: "3rem" }}
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div className="upload_item">
                              <div className="upload_btn course_uploadBx">
                                <input
                                  name="story"
                                  type="file"
                                  className="file_uoload"
                                  onChange={(e) => videouploadHandler(e)}
                                />
                                <div className="upload_icon">
                                  <span className="material-icons-outlined">
                                    file_upload
                                  </span>
                                </div>
                                <div className="upload_txt">
                                  <h4>{t("Upload")}</h4>
                                  <h5>{t("Max 10mb")}</h5>
                                  <h5>{t("Video")}</h5>
                                </div>
                              </div>
                            </div>
                          )}
                          {/* ------------ Video uploader endss here ------------ */}

                          {/* ------------ Video preview starts here ------------ */}
                          {showVideoPanel && (
                            <div className="upload_item">
                              <div className="uploaded_innrBx uploaded_vdobx course_uploadBx">
                                {!uploadComplete && (
                                  <Link
                                    to="#"
                                    className="delete_btn reset_video"
                                    onClick={resetUploadedVideo}
                                  >
                                    <span className="material-icons-outlined">
                                      delete
                                    </span>
                                  </Link>
                                )}
                                <video
                                  id="samplepreview"
                                  controls
                                  height="150px"
                                  width="150px"
                                  fullscreen="true"
                                >
                                  <source id="sampleVideoUrl" />
                                  {/* <source src={URL.createObjectURL(videoFile)} /> */}
                                </video>
                                {uploadComplete && (
                                  <div className="vdoplay_Btn">
                                    <p className="text-white">
                                      {t("Uploading")}
                                    </p>
                                    {loadingCircle.map((item, index) => {
                                      return (
                                        <div
                                          key={index}
                                          className="spinner-grow mx-1 text-white"
                                          style={{
                                            height: "3px",
                                            width: "3px",
                                          }}
                                          role="status"
                                        >
                                          <span className="visually-hidden">
                                            Loading...
                                          </span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                          {unSupportWarning && (
                            <div className="warning_text">
                              <p className="text-danger">
                                * {t("Unsupported File")}
                              </p>
                            </div>
                          )}
                          {/* ------------ Video preview ends here ------------ */}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* -------- Upload attachment video section end here -------- */}

                  {/* -------- Video link section start here -------- */}
                  <div className="details_row">
                    <div className="row align-items-start">
                      <div className="col-lg-3">
                        <div className="details_input_txt">
                          <label>{t("Video Link")}</label>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className="input_fill jobinput_fill">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t("Enter URL")}
                            value={videoLink}
                            onChange={(e) => setVideoLink(e.target.value)}
                          />
                          <Link to="#" className="copy_link">
                            <span className="material-icons-outlined">
                              link
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* -------- Video link section end here -------- */}

                  {/* -------- Comments section start here -------- */}
                  <div className="details_row">
                    <div className="row align-items-start">
                      <div className="col-lg-3">
                        <div className="details_input_txt">
                          <label>{t("Comments")}</label>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className="input_fill jobinput_fill description_fill">
                          <textarea
                            className="form-control pt-2"
                            placeholder={t("Enter comments")}
                            value={comments}
                            onChange={(e) => setComments(e.target.value)}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* -------- Comments section end here -------- */}

                  {/* -------- Checkbox start here -------- */}
                  <div className="details_row">
                    <div className="row">
                      <div className="col-lg-3 hidden-sm"></div>
                      <div className="col-lg-9">
                        <div className="chek_row">
                          <label className="radio-check">
                            <input
                              type="checkbox"
                              defaultChecked={checkval}
                              onChange={(e) =>
                                checkBoxToggler(e.target.checked)
                              }
                            />
                            <span></span>
                            <p>
                              {t("I don't want to present my details publicly")}
                            </p>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* -------- Checkbox end here -------- */}

                  {/* -------- Apply now button section start here -------- */}
                  <div className="details_row">
                    <div className="row">
                      <div className="col-lg-3 hidden-sm"></div>
                      <div className="col-lg-9">
                        <div className="apply_btn">
                          <button
                            className="basic_btn"
                            disabled={isSubmitting ? true : false}
                            style={{
                              cursor: isSubmitting ? "not-allowed" : "pointer",
                            }}
                            onClick={(e) => applicationSubmitHandler(e)}
                          >
                            {t("Apply Now")}
                            {isSubmitting && (
                              <div
                                className="mx-2 spinner-border spinner-border-sm"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* -------- Apply now button section end here -------- */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ApplyNowModal;
