import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GlobalIndraInfo } from "App";

const EventBreadCumb = () => {
  const { t } = useTranslation();

  // getting search params from useContext
  const { eventName, refreshEventsLists } = useContext(GlobalIndraInfo);

  return (
    <div className="bredcum_area">
      <div className="container">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">{t("Home")}</Link>
            </li>
            <li className="breadcrumb-item">
              <Link
                to="/events"
                onClick={() => {
                  refreshEventsLists();
                }}
              >
                {t("Events")}
              </Link>
            </li>
            {eventName && (
              <li className="breadcrumb-item active" aria-current="page">
                {eventName}
              </li>
            )}
          </ol>
        </nav>
      </div>
    </div>
  );
};

export default EventBreadCumb;
