/* eslint-disable */
import React, { useState, useEffect, useContext, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { GlobalIndraInfo } from "App";
import { useTranslation } from "react-i18next";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* --------------------------------------------------------------*/

// importing the images here
import Shopify from "assets/ChallengesAssets/svg/shopify.svg";
import blankLesson from "assets/CourseAssets/svg/lesson-empty.svg";

const ChallengesListArea = () => {
  // saving user token here
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  // getting search params from useContext
  const {
    searchJobTitle,
    searchJobCity,
    searchJobCategory,
    searchJobsQueryValues,
    resetJobsQuery,
    refreshChallengeList,
    challengeFilterValue,
    resetJobTitleOnly,
    resetJobCategoryOnly,
    resetJobLocationOnly,
    resetJobFilterOnly,
    deleteSingleJob,
    jobPageReset,
    setJobPageReset,
  } = useContext(GlobalIndraInfo);

  const { t } = useTranslation();

  const challengeListRef = useRef(null);

  // for routing
  const history = useHistory();

  // Initial loading status
  const [isLoading, setIsLoading] = useState(false);

  // initial useState for static JSON data to display the Challenges
  const [jobsData, setJobsData] = useState([]);

  // Initial useState to set page numbers for pagination
  const [page, setPage] = useState(1);

  //initial for paginations
  const [totalPages, setTotalPages] = useState([]);
  const [lastPageNo, setLastPageNo] = useState(1);

  //glow number count
  const glowCount = [1, 2, 3, 4, 5, 6];

  // function to call the API and get all the courses
  const getChallengesListData = async () => {
    let categoryValue = "";

    setIsLoading(true);

    let requestURL =
      url.API_BaseUrl +
      url.API_VIEW_ALL_JOBS +
      `?token=${token}` +
      `&page=${page}`;

    if (searchJobTitle != "") {
      requestURL = requestURL + `&query=${searchJobTitle}`;
    }

    if (searchJobCategory.length > 0) {
      searchJobCategory.map((data) => {
        categoryValue += `&skill[]=${data.id}`;
      });
      requestURL = requestURL + `${categoryValue}`;
    }

    if (searchJobCity != "") {
      requestURL = requestURL + `&location=${searchJobCity}`;
    }

    if (challengeFilterValue != null) {
      requestURL = requestURL + `&selecteditem=${challengeFilterValue.value}`;
    }

    try {
      const response = await getData(requestURL);

      if (response.status) {
        if (response.data) {
          setJobsData(response.data);

          // set last page no from API
          setLastPageNo(response.totalpages);

          /*--- Creating dynamic array for pagination ----*/
          let pageArray = [];

          for (let i = 1; i <= response.totalpages; i++) {
            pageArray.push(i);
          }
          setTotalPages(pageArray);
          /*--- Creating dynamic array for pagination end ----*/

          setIsLoading(false);
        }
      } else {
        setJobsData([]);
      }
    } catch (error) {}
  };

  // function to go to the challenge details page
  const goToChallengeDetails = (e, id) => {
    e.preventDefault();
    history.push(`/challenges/details/${id}`);
  };

  //function for set next page
  const nextPage = (data) => {
    setPage(data);
  };

  // function for focus to commentbox
  const challengeRefToggle = () => {
    challengeListRef.current?.scrollIntoView();
  };

  //function reset event title
  const resetJobTitle = () => {
    resetJobTitleOnly(true);
    setPage(1);
  };

  //function for reset event category only
  const resetJobCategory = () => {
    resetJobCategoryOnly(true);
    setPage(1);
  };

  //function for reset event location only
  const resetJobLocation = () => {
    resetJobLocationOnly(true);
    setPage(1);
  };

  //function for reset event filter value only
  const resetChallengeFilter = () => {
    resetJobFilterOnly(true);
    setPage(1);
  };

  //function for delete single event category
  const deleteSingleValue = (index) => {
    if (index === 0) {
      setPage(1);
    }
    let deletedJobCategory = [...searchJobCategory];
    deletedJobCategory.splice(index, 1);
    deleteSingleJob(deletedJobCategory);
  };

  useEffect(() => {
    if (jobPageReset) {
      setPage(1);
      setJobPageReset(false);
    }
  }, [jobPageReset]);

  // useEffect for the useContext values
  useEffect(() => {
    if (
      searchJobTitle != "" ||
      searchJobCity != "" ||
      searchJobCategory.length > 0 ||
      challengeFilterValue != null
    ) {
      getChallengesListData();
      searchJobsQueryValues(null);
    } else {
      getChallengesListData();
    }
  }, [
    page,
    searchJobTitle,
    searchJobCity,
    searchJobCategory,
    challengeFilterValue,
  ]);

  // return starts here
  return (
    <>
      <div ref={challengeListRef} name="scrollChallengeRefDiv" />
      <section className="champions_area">
        {searchJobTitle != "" ||
        searchJobCategory.length > 0 ||
        searchJobCity != "" ||
        challengeFilterValue != null ? (
          <div id="filter_result" className="mb-4">
            <div className="container">
              <div className="d-flex flex-wrap gap-2">
                {/* ---------- Search Title Section starts here ---------- */}

                {searchJobTitle == "" ? null : (
                  <div className="col-auto px-0">
                    <div className="result-box d-flex align-items-center gap-3 p-2 border">
                      <label htmlFor="name" className="fw-bold">
                        {t("Name")}
                      </label>
                      {/* <input
                  type="text"
                  // value="Victor Herrero"
                  className="form-control shadow-none rounded-0"
                /> */}
                      <label>{searchJobTitle}</label>
                      <Link to="#" className="cancel" onClick={resetJobTitle}>
                        <i className="d-block material-icons">clear</i>
                      </Link>
                    </div>
                  </div>
                )}

                {/* ---------- Search Skills Section starts here ---------- */}

                {searchJobCategory.length == 0 ? null : (
                  <div className="col-auto px-0">
                    <div className="result-box d-flex align-items-center justify-content-between gap-3 p-2 border">
                      <label htmlFor="name" className="fw-bold">
                        {t("Category")}
                      </label>
                      <ul className="badges d-flex flex-wrap gap-2">
                        {searchJobCategory.map((jobNames, index) => {
                          return (
                            <li className="d-flex gap-2 border" key={index}>
                              {jobNames.label}
                              <Link
                                to="#"
                                onClick={() => deleteSingleValue(index)}
                              >
                                <i className="d-block material-icons">clear</i>
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                      <Link
                        to="#"
                        className="cancel"
                        onClick={resetJobCategory}
                      >
                        <i className="d-block material-icons">clear</i>
                      </Link>
                    </div>
                  </div>
                )}

                {/* ---------- Search Location Section starts here ---------- */}

                {searchJobCity == "" ? null : (
                  <div className="col-auto px-0">
                    <div className="result-box d-flex align-items-center gap-3 p-2 border">
                      <label htmlFor="name" className="fw-bold">
                        {t("Location")}
                      </label>
                      <label>{searchJobCity}</label>
                      <Link
                        to="#"
                        className="cancel"
                        onClick={resetJobLocation}
                      >
                        <i className="d-block material-icons">clear</i>
                      </Link>
                    </div>
                  </div>
                )}

                {/* ----- searched event filter value ------- */}
                {challengeFilterValue == null ? null : (
                  <div className="col-auto px-0">
                    <div className="result-box d-flex align-items-center gap-3 p-2 border">
                      <label htmlFor="name" className="fw-bold">
                        {t("Type")}
                      </label>
                      <label>{challengeFilterValue.label}</label>
                      <Link
                        to="#"
                        className="cancel"
                        onClick={resetChallengeFilter}
                      >
                        <i className="d-block material-icons">clear</i>
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : null}

        <div className="container">
          {isLoading ? (
            <div className="champions_outer">
              {/* ------------- glow placeholder when data is being loaded ------------- */}
              <div className="row">
                {glowCount.map((count) => {
                  return (
                    <div className="col-lg-4 col-6" key={count}>
                      <div className="challenges_item sameheight_col">
                        <div className="challenge_top_head">
                          <div className="challenge_iconOuter">
                            <span
                              className="placeholder rounded-circle me-2"
                              style={{ width: "22px", height: "22px" }}
                            ></span>
                            <span
                              className="placeholder rounded-2"
                              style={{ width: "50px" }}
                            ></span>
                          </div>
                          <div className="bookmark_iconOuter">
                            <div className="chappions_rating">
                              <span
                                className="placeholder rounded-2"
                                style={{ width: "40px" }}
                              ></span>
                            </div>
                            <div className="bookmark_icon">
                              <span
                                className="placeholder rounded-2"
                                style={{ width: "16px", height: "20px" }}
                              ></span>
                            </div>
                          </div>
                        </div>
                        <div className="club_nameLocation">
                          <span
                            className="placeholder d-block rounded-2 col-6 mb-2"
                            style={{ height: "20px" }}
                          ></span>
                          <span
                            className="placeholder d-block rounded-2 col-4 mb-2"
                            style={{ height: "18px" }}
                          ></span>
                          <span
                            className="placeholder d-block rounded-2 col-4"
                            style={{ height: "16px" }}
                          ></span>
                        </div>
                        <div className="experts_skill d-flex flex-wrap">
                          <span
                            className="placeholder d-block rounded-2 me-2 mb-2"
                            style={{ width: "80px", height: "30px" }}
                          ></span>
                          <span
                            className="placeholder d-block rounded-2 me-2 mb-2"
                            style={{ width: "80px", height: "30px" }}
                          ></span>
                          <span
                            className="placeholder d-block rounded-2 me-2 mb-2"
                            style={{ width: "80px", height: "30px" }}
                          ></span>
                          <span
                            className="placeholder d-block rounded-2 me-2 mb-2"
                            style={{ width: "80px", height: "30px" }}
                          ></span>
                          <span
                            className="placeholder d-block rounded-2 me-2 mb-2"
                            style={{ width: "80px", height: "30px" }}
                          ></span>
                        </div>
                        <div className="work_details">
                          <div className="d-flex justify-content-between">
                            <span
                              className="placeholder d-block rounded-2 col-3"
                              style={{ height: "20px" }}
                            ></span>
                            <span
                              className="placeholder d-block rounded-2 col-3"
                              style={{ height: "20px" }}
                            ></span>
                            <span
                              className="placeholder d-block rounded-2"
                              style={{ width: "20px", height: "20px" }}
                            ></span>
                          </div>
                        </div>
                        <div className="details_btn">
                          <span
                            className="placeholder d-block rounded-2 col-12"
                            style={{ height: "50px" }}
                          ></span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              {/* ------------- glow placeholder till here ------------- */}
            </div>
          ) : (
            <div className="champions_outer">
              {jobsData.length === 0 ? (
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="empty_lessonsArea">
                      <div className="empty_lessonpic">
                        <img src={blankLesson} alt="blanklesson" />
                      </div>
                      <div className="empty_lessons_text">
                        <h2>{t("Sorry....!")}</h2>
                        <p>{t("Jobs are not available for this moment.")}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row">
                  {/* ------------ Mapping starts here ------------ */}
                  {jobsData.map((job, index) => (
                    <div className="col-lg-4 col-6" key={index}>
                      <div className="challenges_item sameheight_col">
                        {/* ----------- Job Icon & Rating section ----------- */}
                        <div className="challenge_top_head">
                          <div className="challenge_iconOuter">
                            {/* <i className="challenge_icon">
                            <img src={job.image} alt="companyLogo" />
                          </i> */}
                            <i className="material-icons-outlined challenge_icon">
                              business
                            </i>
                            <span className="challenge_icon_text">
                              {job.company != null ? job.company : ""}
                            </span>
                          </div>
                          <div className="bookmark_iconOuter">
                            <div className="chappions_rating">
                              <Link to="#">
                                <i className="material-icons-outlined">star</i>
                                <span>
                                  {job.rating && job.rating != null
                                    ? job.rating
                                    : "0"}
                                </span>
                                <em className="mx-1">({job.reviews})</em>
                              </Link>
                            </div>
                            {/* <div className="bookmark_icon">
                              <Link to="#">
                                <i className="material-icons-outlined">
                                  bookmark_border
                                </i>
                              </Link>
                            </div> */}
                          </div>
                        </div>

                        {/* ----------- Job title, Price range, Location details section ----------- */}
                        <div className="club_nameLocation">
                          <h3>{job.name != null ? job.name : ""}</h3>
                          <h4>
                            € {job.price != null ? job.price : t("private")}
                          </h4>
                          <div className="locate_text">
                            <h5 className="d-flex">
                              <i className="material-icons-outlined"> place </i>
                              <span>
                                {job.location != null
                                  ? job.location
                                  : t("Undisclosed")}
                              </span>
                            </h5>
                          </div>
                        </div>

                        {/* ----------- Tags section ----------- */}
                        <div className="experts_skill">
                          {job.skills && job.skills.length > 0 ? (
                            <ul>
                              {job.skills.slice(0, 5).map((skill, index) => {
                                return (
                                  <li key={index}>
                                    <Link to="#">
                                      <span>{skill.label}</span>
                                    </Link>
                                  </li>
                                );
                              })}
                              {job.skills.length <= 5 ? null : (
                                <li>
                                  <Link to="#" className="skills_count">
                                    +{job.skills.length - 5} {t("more")}
                                  </Link>
                                </li>
                              )}
                            </ul>
                          ) : null}
                        </div>

                        {/* ----------- Job Type & pay details section ----------- */}
                        <div className="work_details">
                          <ul>
                            <li>{job.jobtype}</li>
                            <li>
                              {t("Experience")}:{" "}
                              {job.experience != null
                                ? job.experience + "+ years"
                                : " Undisclosed"}
                            </li>
                            <li>
                              {/* <span className="material-icons-outlined">
                              signal_cellular_alt
                            </span> */}
                              {job.whf == "0" ? null : (
                                <span className="material-icons-outlined">
                                  home_work
                                </span>
                              )}
                            </li>
                          </ul>
                        </div>

                        {/* ----------- See details button section ----------- */}
                        <div className="details_btn">
                          <Link
                            to="#"
                            className="basic_btn ylw_btn"
                            tabIndex="0"
                            onClick={(e) => goToChallengeDetails(e, job.id)}
                          >
                            {job.owner.id == userInfo.id ? (
                              <i className="material-icons me-3">done_all</i>
                            ) : (
                              <span>
                                {job.isapplied ? (
                                  <span>
                                    {job.moderatorstatus ? (
                                      <i className="material-icons me-3">
                                        done_all
                                      </i>
                                    ) : (
                                      <i className="material-icons me-3">
                                        done
                                      </i>
                                    )}
                                  </span>
                                ) : null}
                              </span>
                            )}
                            {t("See Details")}
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                  {/* ------------ Mapping ends here ------------ */}

                  {/* ------------ Pagination starts here ------------ */}
                  <div className="pagination_list">
                    <ul>
                      <li>
                        <Link
                          to="#"
                          onClick={() => {
                            if (page != 1) {
                              nextPage(page - 1);
                              challengeRefToggle();
                            }
                          }}
                        >
                          <i className="material-icons-outlined">
                            arrow_back_ios
                          </i>
                        </Link>
                      </li>
                      {totalPages.map((pageNo) => {
                        return (
                          <li
                            className={page == pageNo ? "active" : ""}
                            key={pageNo}
                          >
                            <Link
                              to="#"
                              onClick={() => {
                                nextPage(pageNo);
                                challengeRefToggle();
                              }}
                            >
                              {pageNo}
                            </Link>
                          </li>
                        );
                      })}

                      <li>
                        <Link
                          to="#"
                          onClick={() => {
                            if (page != lastPageNo) {
                              nextPage(page + 1);
                              challengeRefToggle();
                            }
                          }}
                        >
                          <i className="material-icons-outlined">
                            arrow_forward_ios
                          </i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default ChallengesListArea;
