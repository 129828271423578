/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import defaultUser from "assets/ConversationAssests/images/default-user.png";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData, postData, postFormdata } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* ----------------------------------------------------------------------------------- */

const LeftProfilePanel = (props) => {
  // accessing the user's token here
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  /*---- initial state for edit user information ----- */
  const [showEditName, setShowEditName] = useState(false);
  const [showEditEmail, setShowEditEmail] = useState(false);
  const [showEditPhone, setshowEditPhone] = useState(false);
  const [showEditAddress, setshowEditAddress] = useState(false);
  const [showEditWork, setshowEditWork] = useState(false);
  const [showImage, setShowImage] = useState("");

  /* ------ UseState to save new data ----- */
  const [newData, setNewData] = useState(null);
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [position, setPosition] = useState("");

  // function for uploading and edit profile picture
  const pictureUploadHandler = async (e) => {
    // create local url for display uploaded image
    const objectUrl = URL.createObjectURL(e.target.files[0]);

    setNewData({ ...newData, image: objectUrl });

    // setShowImage(objectUrl);

    let requestURL =
      url.API_BaseUrl + url.API_UPDATE_USERINFO + `?token=${token}`;

    var imgData = { image: e.target.files[0] };

    const response = await postFormdata(requestURL, imgData);

    if (response.status) {
      localStorage.setItem("userInfo", JSON.stringify(response.userdata));

      setNewData(response.userdata);
    }
  };

  // API call to fetch conversations list
  const saveNewDataHandler = async () => {
    let requestURL =
      url.API_BaseUrl + url.API_UPDATE_USERINFO + `?token=${token}`;

    let updatedData = {
      phone,
      address,
      position,
    };

    try {
      const response = await postFormdata(requestURL, updatedData);

      if (response.status) {
        localStorage.setItem("userInfo", JSON.stringify(response.userdata));

        setNewData(response.userdata);
      }
    } catch (error) {}
  };

  // use effect for set set user information
  useEffect(() => {
    if (userInfo == null) {
      setNewData(null);
      setPhone("");
      setAddress("");
      setPosition("");
    } else {
      setNewData(userInfo);
      if (userInfo.phone == null || userInfo.phone == "") {
        setPhone("");
      } else {
        setPhone(userInfo.phone);
      }

      if (userInfo.address == null || userInfo.address == "") {
        setAddress("");
      } else {
        setAddress(userInfo.address);
      }

      if (userInfo.position == null || userInfo.position == "") {
        setPosition("");
      } else {
        setPosition(userInfo.position);
      }
    }
  }, [localStorage.getItem("userInfo")]);

  // use effect for enter press function
  useEffect(() => {
    /*---- function for detect enter key -----*/
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  return (
    <>
      {newData == null ? null : (
        <div className="chat_profileinfo_pnl">
          <div className="pro_back_btn">
            <Link
              to="#"
              onClick={() => {
                document.body.classList.toggle("profile_open");
              }}
            >
              <i className="material-icons-round"> arrow_back </i>
            </Link>
          </div>

          <div className="profile_infoOuter">
            {/* ------------- user name and profile-image section ------------- */}
            <div
              className={`profile_info_row ${
                showEditEmail ? "editfill_open" : ""
              }`}
            >
              <div className="profile_picPnl">
                {/* ------------- user profile image section ------------- */}

                <div className="profile_img">
                  <figure>
                    {newData.image == null ? (
                      <img src={defaultUser} alt="" />
                    ) : (
                      <img src={newData.image} alt="image" />
                    )}
                  </figure>

                  <div className="profile_upload">
                    <span>
                      <i
                        className="material-icons-round"
                        style={{ cursor: "pointer" }}
                      >
                        photo_camera
                      </i>
                      <input
                        type="file"
                        className="pic_upload"
                        onChange={(e) => pictureUploadHandler(e)}
                      />
                    </span>
                  </div>
                </div>

                {/* ------------- user name and email section ------------- */}
                <div className="profile_textPnl">
                  <div className="pro_edit_btn email_editicon"></div>
                  <h4>{newData.name}</h4>
                  <h5>{newData.email}</h5>
                </div>
              </div>
            </div>

            {/* ------------- user phone section ------------- */}
            <div
              className={`profile_info_row ${
                showEditPhone ? "editfill_open" : ""
              }`}
            >
              <div className="profile_details_row">
                <div className="profile_iocnedit_outer">
                  <div className="info_icon">
                    <i className="material-icons-round">phone_in_talk </i>
                    <span>{t("Phone")}</span>
                  </div>
                  <div className="pro_edit_btn">
                    <Link
                      to="#"
                      onClick={() => {
                        setshowEditPhone(!showEditPhone);
                        showEditPhone ? saveNewDataHandler() : "";
                      }}
                    >
                      <i className="material-icons-round">
                        drive_file_rename_outline
                      </i>
                    </Link>
                  </div>
                </div>
                <div className="info_textfill">
                  <div className="protxt_con">
                    <p>{newData.phone}</p>
                  </div>
                  <div className="editable_fill">
                    <input
                      type="text"
                      value={phone}
                      className="form-control"
                      onChange={(e) => {
                        setPhone(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* ------------- user address section ------------- */}
            <div
              className={`profile_info_row ${
                showEditAddress ? "editfill_open" : ""
              }`}
            >
              <div className="profile_details_row">
                <div className="profile_iocnedit_outer">
                  <div className="info_icon">
                    <i className="material-icons-round">place </i>
                    <span>{t("Address")}</span>
                  </div>
                  <div className="pro_edit_btn">
                    <Link
                      to="#"
                      onClick={() => {
                        setshowEditAddress(!showEditAddress);

                        showEditAddress ? saveNewDataHandler() : "";
                      }}
                    >
                      <i className="material-icons-round">
                        drive_file_rename_outline
                      </i>
                    </Link>
                  </div>
                </div>
                <div className="info_textfill">
                  <div className="protxt_con">
                    <p>{newData.address}</p>
                  </div>
                  <div className="editable_fill">
                    <input
                      type="text"
                      value={address}
                      className="form-control"
                      onChange={(e) => {
                        setAddress(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* ------------- user company section ------------- */}
            <div
              className={`profile_info_row ${
                showEditWork ? "editfill_open" : ""
              }`}
            >
              <div className="profile_details_row">
                <div className="profile_iocnedit_outer">
                  <div className="info_icon">
                    <i className="material-icons-round">location_city </i>
                    <span>{t("Position")}</span>
                  </div>
                  <div className="pro_edit_btn">
                    <Link
                      to="#"
                      onClick={() => {
                        setshowEditWork(!showEditWork);

                        showEditWork ? saveNewDataHandler() : "";
                      }}
                    >
                      <i className="material-icons-round">
                        drive_file_rename_outline
                      </i>
                    </Link>
                  </div>
                </div>
                <div className="info_textfill">
                  <div className="protxt_con">
                    <p>{newData.position}</p>
                  </div>
                  <div className="editable_fill">
                    <input
                      type="text"
                      value={position}
                      className="form-control"
                      onChange={(e) => {
                        setPosition(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* ------------- mute button section ------------- */}
            {/* <div className="profile_info_row">
              <div className="profile_details_row mute_row">
                <div className="info_icon">
                  <i className="material-icons-round">notifications_active</i>
                  <span>{t("Mute Notification")}</span>
                </div>
                <div className="toggle_switch">
                  <div className="switch_box box_1">
                    <input type="checkbox" className="switch_1" />
                  </div>
                </div>
              </div>
            </div> */}

            {/* ------------- signout button section ------------- */}
            <div className="profile_info_row">
              <div className="profile_details_row signout_fill">
                <Link
                  to="#"
                  className="info_icon"
                  onClick={() => {
                    localStorage.clear(); // clear the local storage after log out
                    window.location.reload(); // for reloading page after log out
                  }}
                >
                  <i className="material-icons-round">account_circle </i>
                  <span>{t("Sign Out")}</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LeftProfilePanel;
