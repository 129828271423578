/* eslint-disable */
import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./css/CookieConfigurePopup.css";
import { GlobalIndraInfo } from "App";

const CookieConfigurePopup = (props) => {
  const { t } = useTranslation();
  const { setCookieReload } = useContext(GlobalIndraInfo);

  //function for toggle cookie Configure pop up
  const cookieToogler = () => {
    const cp = document.getElementById("cookie_policy_wrapper");
    // const cpToggler = document.querySelector(".cookie-policy-toggle");
    // cpToggler.addEventListener("click", function () {
    //   cp.classList.toggle("open");
    // });
    cp.classList.toggle("open");
  };

  // Function to get the cookie value
  const getCookie = (name) => {
    const value = " " + document.cookie;
    const parts = value.split(" " + name + "=");
    return parts.length < 2 ? undefined : parts.pop().split(";").shift();
  };

  //function for clear cookie
  const clearCookie = () => {
    if (getCookie("indra_necessary") == undefined) {
      return;
    } else {
      document.cookie =
        "indra_necessary" + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";

      if (getCookie("indra_preference") != undefined) {
        document.cookie =
          "indra_preference" + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      }
      if (getCookie("indra_marketing") != undefined) {
        document.cookie =
          "indra_marketing" + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      }
      if (getCookie("indra_analytics") != undefined) {
        document.cookie =
          "indra_analytics" + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      }
      setCookieReload(true);
      let myOffcanvas = new bootstrap.Offcanvas(
        document.getElementById("acceptCookies")
      );
      myOffcanvas.show();
    }
  };

  return (
    <div
      id="cookie_policy_wrapper"
      className="position-fixed start-0 end-0 bottom-0"
    >
      <div className="cookie-policy-container text-center text-white p-4 position-relative">
        <div className="container">
          <p>
            {t(
              "Indra Sistemas S.A. uses its own and third-party cookies in order to correctly offer all the functionalities of the website, quantify the number of users who access it, record their browsing activities on it, analyze the use of the different ones. You can withdraw the consent previously given by clicking on the button shown below."
            )}
          </p>
          <div className="action d-flex flex-wrap align-items-center justify-content-center gap-3 mt-4">
            <button
              type="button"
              className="basic_btn ylw_btn w-auto"
              data-bs-toggle="modal"
              data-bs-target="#cookie_config_mdl"
              onClick={cookieToogler}
            >
              {t("Configure cookies")}
            </button>
            <button
              type="button"
              className="border_btn ylwbrdr_btn"
              onClick={clearCookie}
            >
              {t("Withdraw Consent")}
            </button>
          </div>
        </div>
        <Link
          to="#"
          className="cookie-policy-toggle d-inline-block text-white fw-bold px-2 py-1 position-absolute top-0"
          onClick={cookieToogler}
        >
          {t("Cookies Policy")}
        </Link>
      </div>
    </div>
  );
};

export default CookieConfigurePopup;
