/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* ----------------------------------------------------------------------------------- */

// import images here
import docIcon from "assets/ChallegesDetailsAsstes/doc.svg";
import pdfIcon from "assets/ChallegesDetailsAsstes/pdf.svg";
import blankLesson from "assets/CourseAssets/svg/lesson-empty.svg";

const DocTab = () => {
  // saving user token here
  const token = localStorage.getItem("token");

  // 👇️ get ID from url
  const params = useParams();

  const { t } = useTranslation();

  const glowCount = [1, 2, 3, 4]; //for glow placeholder

  // Initial useState to save all the incoming data from API.
  const [jobDetailsData, setJobDetailsData] = useState(null);
  const [jobDetailsDocs, setJobDetailsDocs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Function to call the API and get all the data
  const getJobDetailsData = async (id) => {
    setIsLoading(true);
    let requestURL =
      url.API_BaseUrl +
      url.API_CHALLENGES_DETAILS_DOCS_TAB +
      `?token=${token}` +
      `&cid=${id}`;

    try {
      const response = await getData(requestURL);

      if (response.status) {
        setJobDetailsData(response.data);
        if (response.data) {
          if (response.data.docs) {
            setJobDetailsDocs(response.data.docs);
          } else {
            setJobDetailsDocs([]);
          }
        } else {
          setJobDetailsDocs([]);
        }
        setIsLoading(false);
      } else {
        setJobDetailsDocs([]);
      }
    } catch (error) {}
  };

  // useEffect from here
  useEffect(() => {
    getJobDetailsData(params.id);
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="course_detailspnl">
          {/* <div className="contnt_hdng">
            <span
              className="placeholder rounded-3 col-1"
              style={{ height: "22px" }}
            ></span>
          </div> */}

          <div className="docs_outer">
            {glowCount.map((count) => {
              return (
                <div className="docs_row" key={count}>
                  <div className="docs_left">
                    <div className="doc_icon">
                      <span
                        className="placeholder rounded-3"
                        style={{ width: "25px", height: "25px" }}
                      ></span>
                    </div>
                    <div className="doc_text">
                      <span
                        className="placeholder placeholder-sm rounded-3"
                        style={{ width: "180px" }}
                      ></span>
                    </div>
                  </div>
                  <div className="docs_right">
                    <ul>
                      <li>
                        <span
                          className="placeholder placeholder-sm rounded-3"
                          style={{ width: "85px" }}
                        ></span>
                      </li>
                      <li>
                        <span
                          className="placeholder placeholder-sm rounded-3"
                          style={{ width: "35px" }}
                        ></span>
                      </li>
                      <li>
                        <span
                          className="placeholder rounded-3"
                          style={{ width: "20px", height: "20px" }}
                        ></span>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="course_detailspnl">
          {jobDetailsDocs.length === 0 ? (
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="empty_lessonsArea">
                  <div className="empty_lessonpic">
                    <img src={blankLesson} alt="blanklesson" />
                  </div>
                  <div className="empty_lessons_text">
                    <h2>{t("Sorry....!")}</h2>
                    <p>{t("Docs are not available at this moment.")}</p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              {/* <div className="contnt_hdng">
                <h4>Docs</h4>
              </div> */}
              <div className="docs_outer">
                {jobDetailsDocs.map((doc, index) => {
                  return (
                    <div className="docs_row" key={index}>
                      <div className="docs_left">
                        <div className="doc_icon">
                          {doc.type == "doc" && <img src={docIcon} alt="" />}
                          {doc.type == "pdf" && <img src={pdfIcon} alt="" />}
                          {doc.type == "jpg" && <img src={doc.link} alt="" />}
                        </div>
                        <div className="doc_text">
                          <h5>{doc.title}</h5>
                        </div>
                      </div>
                      <div className="docs_right">
                        <ul>
                          <li>{doc.created}</li>
                          {/* <li>{doc.size}</li> */}
                          <li>
                            <Link
                              to="#"
                              onClick={() => window.open(doc.link, "_blank")}
                            >
                              <span className="material-icons-outlined">
                                file_download
                              </span>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default DocTab;
