import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

const MainSearchComponent = () => {
  // 👇️ get 'String' from url
  const params = useParams();

  // intial useSatate to save the string received from the URL
  const [searchString, setSearchString] = useState("");

  // intial useState to contain the static JSON data
  const [searchResults, setSearchResults] = useState([
    {
      id: "1",
      module: "Courses",
      title: "System Administrator User Manual",
      summary:
        "The meaning of HI is —used especially as a greeting. How to use hi in a sentence.",
    },
    {
      id: "2",
      module: "Blog",
      title: "More than just a System Administrator User",
      summary:
        "As Nielsen states, shoppers don't always realize that they arrive at a “no results found” page, simply because As Nielsen states, shoppers don't always realize that they arrive at a “no results found” page, simply because conducting searches on the web is such an conducting searches on the web is such an ...",
    },
    {
      id: "3",
      module: "Blog",
      title: "System Administrator User Manual",
      summary:
        "24-Jun-2022 — This article can help you get rid of any worries and create the best search results page design possible. Our simple tips and search UX best ...",
    },
    {
      id: "4",
      module: "Web",
      title: "What is Lorem Ipsum?",
      summary:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
  ]);

  useEffect(() => {
    let query = new URLSearchParams(window.location.search).get("query");
    if (query != null) {
      setSearchString(query);
    }
  }, []);

  return (
    <section id="search_result_wrapper" className="py-5">
      {/* ------------- Search box component starts here ------------- */}
      <div className="search-result-head border-bottom">
        <div className="container">
          <form action="" className="position-relative mb-3">
            <input
              type="search"
              className="form-control rounded-0 shadow-none"
              value={searchString}
              placeholder="Search"
              onChange={(e) => setSearchString(e.target.value)}
            />
            <button className="btn p-0 position-absolute rounded-0 shadow-none text-center">
              <i className="material-icons d-block">search</i>
            </button>
          </form>
        </div>
      </div>
      {/* ------------- Search box component ends here ------------- */}

      {/* ------------- Search results component starts here ------------- */}
      <div className="search-results pt-4">
        <div className="container">
          {/* ---------- Search result count starts here ---------- */}
          <p className="result-count mb-5">
            Got 2530 results matching
            <span className="search-terms"> "System Administrator"</span>
          </p>
          {/* ---------- Search result count ends here ---------- */}

          {searchResults.map((result, index) => {
            return (
              <div className="single-item" key={result.id}>
                <span className="d-block category">{result.module}</span>
                <h5 className="fw-bold my-2">{result.title}</h5>
                <p>{result.summary}</p>
              </div>
            );
          })}

          {/* -------------- Pagination starts here -------------- */}
          <nav className="pagination-container mt-5">
            <ul className="pagination align-items-center">
              <li className="page-item">
                <Link
                  className="page-link fw-bold shadow-none border-0 p-0 rounded-0"
                  to="#"
                  aria-label="Previous"
                >
                  <i className="material-icons" aria-hidden="true">
                    chevron_left
                  </i>
                </Link>
              </li>
              <li className="page-item active">
                <Link
                  className="page-link fw-bold shadow-none border-0 p-0"
                  to="#"
                >
                  1
                </Link>
              </li>
              <li className="page-item">
                <Link
                  className="page-link fw-bold shadow-none border-0 p-0"
                  to="#"
                >
                  2
                </Link>
              </li>
              <li className="page-item">
                <Link
                  className="page-link fw-bold shadow-none border-0 p-0"
                  to="#"
                >
                  3
                </Link>
              </li>
              <li className="page-item">
                <span>...</span>
              </li>
              <li className="page-item">
                <Link
                  className="page-link fw-bold shadow-none border-0 p-0"
                  to="#"
                >
                  98
                </Link>
              </li>
              <li className="page-item">
                <Link
                  className="page-link fw-bold shadow-none border-0 p-0"
                  to="#"
                >
                  99
                </Link>
              </li>
              <li className="page-item">
                <Link
                  className="page-link fw-bold shadow-none border-0 p-0 rounded-0"
                  to="#"
                  aria-label="Next"
                >
                  <i className="material-icons" aria-hidden="true">
                    chevron_right
                  </i>
                </Link>
              </li>
            </ul>
          </nav>
          {/* -------------- Pagination ends here -------------- */}
        </div>
      </div>
      {/* ------------- Search results component ends here ------------- */}
    </section>
  );
};

export default MainSearchComponent;
