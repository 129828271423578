import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// import images here
import graphBar from "assets/ChampionAssets/images/graph-bnr.jpg";

const Banner = () => {
  const { t } = useTranslation();

  return (
    <section
      className="graph_bnr_area bg_area"
      style={{ backgroundImage: `url(${graphBar})` }}
    >
      <div className="container">
        <div className="bnr_graph_textPnl">
          <h1>
            <span>Champions {t("at")} </span> {t("Indra ATM Ecosystem")}
          </h1>
        </div>
      </div>
    </section>
  );
};

export default Banner;
