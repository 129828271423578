/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

const JoinCourseModal = (props) => {
  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();

  return (
    <div className="question_modal_bx">
      <div
        className="modal fade"
        id="join_success_modal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="sucess_question_icon">
                <span className="material-icons-outlined">task_alt</span>
              </div>
              <div className="question_success_hdng">
                <h2>{t("Thank you for joining this course")}</h2>
                {/* <h5>
                  Your request will be reviewed by Admin. Notification mail will
                  be sent as accepted
                </h5> */}
                <h5>{props.modalAdminMessage}</h5>
              </div>
              <div className="question_closeBtn">
                <Link
                  to="#"
                  data-bs-dismiss="modal"
                  className="basic_btn ylw_btn"
                  onClick={() => props.afterModalClose()}
                >
                  {t("Close")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinCourseModal;
