/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// import custom components here
import defaultUser from "assets/ConversationAssests/images/default-user.png";
import EmptyMessage from "../Others/EmptyMessage";
import ViewEmailPopupModal from "../Modals/PopupModals/ViewEmailPopupModal";
import ForwardEmailModal from "../Modals/ForwardEmailModal";

const MailBoard = (props) => {
  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  // initial state for maildata
  const [mailDetails, setmailDetails] = useState({});

  // accessing the user's token here
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  // assign useref hook for auto scrolling
  const mailEndRef = useRef(null);

  // function for scroll chat bottom behavior
  const scrollToBottom = () => {
    mailEndRef.current?.scrollIntoView();
  };

  // initial useState to accept the mail id to send to Modal
  const [mailId, setMailId] = useState("");

  // initial useState to accept the forward mail ID to send to Modal
  const [sendForwardedMailId, setSendForwardedMailId] = useState("");

  // function for pass data into email pop up modal
  const replyMailHandler = (item) => {
    props.mailSingleData(item);
  };

  // function for passing props in tag
  const tagMail = (item) => {
    props.getTag(item.id);
  };

  /*----- useeffect for scroll to the bottom updating the chats ------*/
  useEffect(() => {
    scrollToBottom();
  }, [props.mailDetails]);

  return (
    <>
      {props.mailIsLoading == true ? (
        <div className="contact_chat_section">
          <div className="chating_mdlPnl">
            <div className="chatting_innr pd_top_60">
              <div className="placeholder_row placeholder-glow">
                <div className="user_placeHoledr">
                  <div className="default_usr">
                    <img src={defaultUser} alt="" />
                  </div>
                  <div className="default_usr_name">
                    <span className="placeholder placeholder-sm col-3 rounded-3"></span>
                    <span className="placeholder placeholder-sm col-2 rounded-3"></span>
                  </div>
                </div>
                <p className="card-text placeholder-glow">
                  <span className="placeholder placeholder-sm col-12 rounded-3"></span>
                  <span className="placeholder placeholder-sm col-10 rounded-3"></span>
                  <span className="placeholder placeholder-sm col-9 rounded-3"></span>
                  <span className="placeholder placeholder-sm col-8 rounded-3"></span>
                  <span className="placeholder placeholder-sm col-7 rounded-3"></span>
                  <span className="placeholder placeholder-sm col-6 rounded-3"></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="contact_chat_section">
          <div className="chating_mdlPnl">
            <div className="chatting_innr pd_top_60">
              {props.mailDetails == null || props.mailDetails.length == 0 ? (
                <EmptyMessage messageType={"mail"} />
              ) : (
                <div className="msg_outer">
                  {props.mailDetails.map((item, index) => {
                    return (
                      <div className="msg_row mail_msgRow" key={index}>
                        <div className="chat_infoLft">
                          <div className="chatting_user mail_person">
                            {item.senderimage == null ||
                            item.senderimage == undefined ? (
                              <span className="material-icons-round">
                                person
                              </span>
                            ) : (
                              <img src={item.senderimage} alt="senderPic" />
                            )}
                          </div>
                          <div className="chat_info_timeOuter">
                            <h4>
                              <i className="material-icons-round">email</i>
                              {t("mail")}
                            </h4>
                            <h5>
                              {item.created}

                              {item.senderemail == userInfo.email ? (
                                <span
                                  className={`msg_status double_tick ${
                                    item.tracker == 2 ? "seen_tick" : ""
                                  }`}
                                >
                                  <i className="material-icons-round">
                                    done_all
                                  </i>
                                </span>
                              ) : null}
                            </h5>
                          </div>
                        </div>

                        <div className="chat_msgright">
                          {item.tags.length > 0 ? (
                            <div className="tag_msg">
                              <Link to="#">
                                <i className="material-icons">label</i>
                                {item.tags.map((item2, index2) => {
                                  return (
                                    <span key={index2}>{item2.label},</span>
                                  );
                                })}
                              </Link>
                            </div>
                          ) : null}
                          <div className="mail_text">
                            <h4
                              style={{ cursor: "pointer" }}
                              onClick={() => setMailId(item.id)}
                              data-bs-toggle="modal"
                              data-bs-target="#emaildetails_mdl"
                            >
                              {item.subject}
                            </h4>
                            {item.attachment == null ||
                            item.attachment == undefined ? (
                              ""
                            ) : (
                              <Link to="#">
                                <i className="material-icons-round">
                                  upload_file
                                </i>
                                <span>{item.attachment} Attachment</span>
                              </Link>
                            )}
                          </div>
                          <div className="msg_shareicon_info">
                            <ul>
                              {/* -------------- Reply Button here -------------- */}
                              {item.senderemail == userInfo.email ? (
                                <li>
                                  <Link to="#"></Link>
                                </li>
                              ) : (
                                <li>
                                  <Link
                                    to="#"
                                    onClick={() => {
                                      setMailId(item.id);
                                      document.body.classList.toggle(
                                        "emailpopup_open"
                                      );
                                      replyMailHandler(item);
                                    }}
                                  >
                                    <i className="material-icons-round">
                                      reply
                                    </i>
                                    {t("Reply")}
                                  </Link>
                                </li>
                              )}
                              <li>
                                <Link
                                  to="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#forward_mdl"
                                  onClick={() =>
                                    setSendForwardedMailId(item.id)
                                  }
                                >
                                  <i className="material-icons-round">
                                    fast_forward
                                  </i>
                                  {t("Forward")}
                                </Link>
                              </li>

                              {/* -------- Calling Add Tag Modal here -------- */}
                              <li>
                                <Link
                                  to="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#addtag_mdl"
                                  role="button"
                                  onClick={() => {
                                    tagMail(item);
                                  }}
                                >
                                  <i className="material-icons-round">loupe</i>
                                  {t("Labels")}
                                </Link>
                              </li>
                              {/* ---- end of calling add tag modal ---- */}

                              {/* -------------- Dropdown button Button here -------------- */}
                              {/* <li className="dropdown">
                          <Link to="#" data-bs-toggle="dropdown">
                            <i className="material-icons-round">
                              expand_circle_down
                            </i>
                          </Link>
                          <ul className="dropdown-menu">
                            <li>
                              <Link
                                to="#"
                                data-bs-toggle="modal"
                                data-bs-target="#forward_mdl"
                                onClick={() => setSendForwardedMailId(item.id)}
                              >
                                <i className="material-icons-round">
                                  fast_forward
                                </i>
                                <span>Forward</span>
                              </Link>
                            </li>

                            <li>
                              <Link
                                to="#"
                                data-bs-toggle="modal"
                                data-bs-target="#addtag_mdl"
                              >
                                <i className="material-icons-round">loupe</i>
                                <span>Add Tag</span>
                              </Link>
                            </li>

                            <li>
                              <Link to="#">
                                <i className="material-icons-round">
                                  delete_outline
                                </i>
                                <span>Delete</span>
                              </Link>
                            </li> 
                           
                          </ul>
                        </li>  */}
                            </ul>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {/* ---- calling useref hook for scroll behaviour ----- */}
                  <div ref={mailEndRef} name="scrollRefDiv" />
                </div>
              )}
              {/* ---- chat section ---- */}

              {/* ---- end of chat section ---- */}
            </div>
          </div>
        </div>
      )}

      {/* ------------- Adding view email popup modal and sending the mail id via props ----------- */}
      <ViewEmailPopupModal assignedMailId={mailId} />

      {/* ----------------- Calling the Forward Modal here ----------------- */}
      <ForwardEmailModal mailId={sendForwardedMailId} />
    </>
  );
};

export default MailBoard;
