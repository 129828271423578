/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GlobalIndraInfo } from "App";

// import methods from gatway for API call
import { getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";

//import images
import defaultUser from "assets/ConversationAssests/images/default-user.png";
import blankInvite from "assets/ChampionAssets/images/people-collaborating-with-tech- 1.png";
import "./css/InviteStyle.css";
import { useTranslation } from "react-i18next";

const Sent = (props) => {
  const token = localStorage.getItem("token");

  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  //initial for recieved lists
  const [sentLists, setSentLists] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // function for all recieved lists
  const getSentLists = async () => {
    setIsLoading(true);
    let requestURL =
      url.API_BaseUrl +
      url.API_INVITE_LISTS +
      `?token=${token}` +
      `&state=sent`;

    try {
      const response = await getData(requestURL);

      if (response.status) {
        if (response.data) {
          setSentLists(response.data);
        } else {
          setSentLists([]);
        }
        setIsLoading(false);
      }
    } catch (error) {}
  };

  useEffect(() => {
    props.isLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    getSentLists();
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="invite_table_innr">
          <table>
            <tbody>
              <tr>
                <td>
                  <div className="user_placeHoledr">
                    <div className="default_usr invite_usr">
                      <img src={defaultUser} alt="user" />
                    </div>
                    <div className="tble_usrtext_glow">
                      <span className="placeholder placeholder-sm col-10 rounded-3"></span>{" "}
                      <br />
                      <span className="placeholder placeholder-sm col-6 rounded-3"></span>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="tble_usrtext_glow">
                    <span className="placeholder placeholder-sm col-10 rounded-3"></span>{" "}
                    <br />
                    <span className="placeholder placeholder-sm col-6 rounded-3"></span>
                  </div>
                </td>
                <td>
                  <div className="tble_usrtext_glow">
                    <span className="placeholder placeholder-sm col-10 rounded-3"></span>{" "}
                    <br />
                    <span className="placeholder placeholder-sm col-6 rounded-3"></span>
                  </div>
                </td>
                <td>
                  <div
                    className="tble_usrtext_glow"
                    style={{ display: "flex", gap: "10px" }}
                  >
                    <span className="placeholder placeholder-sm col-5 rounded-3"></span>
                    <span className="placeholder placeholder-sm col-5 rounded-3"></span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <div className="invitations-container sent">
          {sentLists.length == 0 ? (
            <ul className="invitations">
              <li className="invitation">
                <div className="d-flex gap-3">
                  <img
                    src={blankInvite}
                    alt=""
                    style={{
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  />
                </div>
                <div
                  className="empty_lessons_text"
                  style={{ textAlign: "center" }}
                >
                  <h2>{t("Sorry....!")}</h2>
                  <p>{t("No connection requests have been sent yet !")}</p>
                </div>
              </li>
            </ul>
          ) : (
            <ul className="invitations">
              {sentLists.map((list, index) => {
                return (
                  <li className="invitation" key={index}>
                    <div className="d-flex gap-3">
                      <Link
                        to="#"
                        className="avatar rounded-circle overflow-hidden"
                      >
                        <img
                          src={list.image ? list.image : defaultUser}
                          alt="Bertha Sandoval"
                          className="img-fluid"
                        />
                      </Link>
                      <div className="w-100">
                        <div className="user-info d-flex justify-content-between mb-2">
                          <div className="name">
                            {list.name == null ? null : (
                              <h6 className="fw-bold mb-1">{list.name}</h6>
                            )}
                            {list.position == null ? null : (
                              <p className="designation">{list.position}</p>
                            )}
                          </div>
                          {list.approved ? (
                            <div className="action d-flex align-items-center gap-4">
                              <a
                                to="#"
                                className="status approved d-flex align-items-center"
                              >
                                <i className="material-icons d-block me-1">
                                  check_circle_outline
                                </i>{" "}
                                {t("Approved")}
                              </a>
                              <span className="date">{list.approvedate}</span>
                            </div>
                          ) : (
                            <div className="action d-flex align-items-center gap-4">
                              <Link
                                to="#"
                                className="status pending d-flex align-items-center"
                              >
                                <i className="material-icons d-block me-1">
                                  error_outline
                                </i>{" "}
                                {t("Pending")}
                              </Link>
                              <span className="date">{list.requestdate}</span>
                              {/* <Link to="#" className="edit">
                              <i className="material-icons d-block"> edit</i>
                            </Link> */}
                              {/* <Link to="#" className="delete">
                                <i className="material-icons d-block">
                                  {" "}
                                  delete
                                </i>
                              </Link> */}
                            </div>
                          )}
                        </div>
                        <p className="sent-info p-3">
                          {t("Sent to")} <Link to="#">{list.reason}</Link>{" "}
                          {t("and found him")} {t("on")}{" "}
                          <Link to="#">{list.relation}</Link>
                        </p>
                        <div className="comment p-3 bg-white">
                          {list.comment == "" ||
                          list.comment == null ||
                          list.comment == undefined ? null : (
                            <p>{list.comment}</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      )}
    </>
  );
};

export default Sent;
