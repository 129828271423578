const downloadFileHandler = async (file) => {
  try {
    // const response = await fetch(file.path);
    // const blob = await response.blob();
    // const fileName = file.title;
    // if (navigator.msSaveBlob) {
    //   navigator.msSaveBlob(blob, fileName);
    // } else {
    //   const url = window.URL.createObjectURL(blob);
    //   const link = document.createElement("a");
    //   link.href = url;
    //   link.download = fileName;
    //   link.click();
    //   window.URL.revokeObjectURL(url);
    // }

    const url = file.path; // Replace with your file URL
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = file.title; // Specify the filename you want the user to download with
    anchor.target = '_blank'; // Open in a new tab
    anchor.click();












  } catch (error) {
    console.error("Error occurred while downloading the file:", error);
  }
};

export default downloadFileHandler;
