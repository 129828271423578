import React from "react";
import { useTranslation } from "react-i18next";

const PolicyContents = () => {
  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  return (
    <>
      <div className="post-head d-flex flex-wrap gap-3 align-items-center justify-content-between">
        <div className="post-title me-3">
          <h1 className="fw-bold mb-3">{t("Privacy Policy")}</h1>
        </div>
      </div>

      <div className="blog-details-container mt-5">
        <div className="blog-desc mb-4">
          <p>{t("1. IDENTITY OF THE DATA CONTROLLER")}</p>
          <p>
            {t(
              "MPFORALL BUSINESS AND COLLABORATION SL with CIF B87644837,hereinafter the RESPTTO, condomicile in Madrid (Madrid), at Avda. Padre Piquer no 14, and email rgpd@lobees.com, is the Owner of this Website and Responsible for Data Processing Personal Data of the User and informs you that the information provided will be treated in accordance with the provisions of the EU Data Protection Regulation 679/2016 and Organic Law 3/2018 on the Protection of Personal Data and guarantee of digital rights. This Privacy Policy applies when you use our services. We offer our users different choices about the data we collect, use and share, as described in this Privacy Policy, and in the Cookies Policy."
            )}
          </p>
          <p>{t("2. PURPOSES AND LEGITIMATION OF THE TREATMENT")}</p>
          <p>
            {t(
              "Send you informative and/or promotional communications if you provide your contact information and consent. Management of contacts for different selection processes that companies publish in the Lobees portal and consent."
            )}
          </p>
          <p>{t("3. CONSERVATION PERIODS")}</p>
          <p>
            {t(
              "The data provided will be kept until the User requests the deletion of their data or unsubscribes from the Lobees platform. Once the interested party has communicated the deletion of their data, it will be blocked and subsequently canceled in our files or returned to the owner of the data, as the case may be, of the media in which the information provided is collected, without admits the retention of copies of said information."
            )}
          </p>
          <p>{t("4. COMMUNICATION OF DATA TO THIRD PARTIES")}</p>
          <p>
            {t(
              "Your data will only be used for the aforementioned purposes and will not be transferred to third parties, except for those communications to the Authorities, Bodies or Offices of the Public Administrations that were consented by the User or person authorized by him / her for the adequate fulfillment of the obligations arising from the services provided, or that are mandatory or authorized by law."
            )}
          </p>
          <p>{t("5. DUTY OF CONFIDENTIALITY")}</p>
          <p>
            {t(
              "The RESPTTO guarantees the confidentiality of the personal data contained in its files and will adopt the regulatory measures that prevent, as far as possible, its alteration, loss, treatment or unauthorized access."
            )}
          </p>
          <p>{t("6. EXERCISE OF RIGHTS")}</p>
          <p>
            {t(
              "The User has the right to access their personal data, rectify inaccurate data or request its deletion when the data is no longer necessary. On certain occasions, the interested parties may request the limitation of the processing of their data, in which case we will only keep them for the exercise or defense of claims. The User can exercise their rights by sending their request in writing and identifying themselves sufficiently to the email address rgpd@lobees.com. He also has the right to withdraw his consent to the processing of his personal data at any time and to submit the claim he deems appropriate to the Control Authority."
            )}
          </p>
          <p>{t("7. STORED DATA")}</p>
          <p>
            {t(
              "Registration: To create an account with Lobees, you must provide us with information such as your name, your email address or mobile number, as well as a password."
            )}
          </p>
          <p>
            {t(
              "Profile: In your profile you have different options that you can include, such as your education, work experience, skills, a photo, your city. You do not need to provide additional information about your profile; however, profile information helps you get more out of Lobees services, such as making it easier for recruiters and job opportunities to find you. You decide if you want to include sensitive information in your profile and make it public. Do not post or add personal data that you do not want to be made public."
            )}
          </p>
          <p>
            {t(
              "Posting and content: We collect your personal data when you provide, post or upload it to our services, such as when you fill out a form, respond to a survey or submit a resume. If you choose to import your address book, we will receive your contacts (including contact information that your service or application provider(s) have automatically added to your address book when you have contacted addresses or numbers that are not already in your list) . If you sync your contacts or calendars through our Services, we will collect your address book and calendar meeting information to help your network continue to grow by suggesting contacts for you and others, as well as providing information about events, such as the time , the place, the attendees and the contacts. You are not required to post or upload personal data, although failure to do so may limit your ability to grow and interact with your network through our services."
            )}
          </p>
          <p>
            {t(
              "Contact and calendar information: We receive personal data (including contact information) about you when other people import or sync their contacts or calendar with our services, associate their contacts with member profiles. If you or others choose to sync email accounts with our services, we will also collect 'email header' information that we may associate with Lobees member profiles."
            )}
          </p>
          <p>
            {t(
              "Cookies and other technologies used: As described in our Cookies Policy, we use cookies and similar technologies to collect information (for example, device identifiers) to recognize you and/or your device(s) across different Services and devices. We allow other people to use cookies in the ways described in our Cookie Policy. You can also check the opt-out option so that we do not use information from cookies or similar technologies to track your behavior on other websites for third-party advertising."
            )}
          </p>
          <p>
            {t(
              "Use of Services: We record Usage Data when you visit or otherwise use our Services, including our Websites, any Lobees Applications and Platform Technology, such as when you view or click on content (for example, a video training) or in ads (on or off our websites and apps), perform a search, install or update one of our apps, share articles, or apply for jobs. We use login records, device information, and addresses Internet Protocol ('IP') addresses to identify you and record your use of Lobees."
            )}
          </p>
          <p>
            {t(
              "Messages: We collect information about you when you send, receive or interact with messages related to our Services. For example, if you receive a friend request on lobees, we'll track to see if you've taken action on it and send you reminders. We use technology to suggest possible responses to messages and to manage or block content that violates our Terms of Use for our Lobees services."
            )}
          </p>
          <p>
            {t(
              "Data that does not identify you: We use your data to generate and share information that does not identify you. For example, we may use your data to generate statistics about our members, their profession or sector; to calculate impressions or clicks on an ad; or to publish detailed information on visitors to a service or obtain detailed information on the workforce."
            )}
          </p>
          <p>
            {t(
              "Other information: Lobees services are dynamic and we introduce new features, which may require the collection of new information. If we collect significantly different personal data or if we significantly change the way we collect, use or share your data, we will notify you and may also change this Privacy Policy."
            )}
          </p>
          <p>
            {t(
              "Data retention: we retain your personal data for as long as you keep your account open, or for the period necessary to provide you with our services. This includes data that you or others have provided to us or data generated or inferred from your use of the Services. In some cases, we decide to keep certain information (for example, information about the use of the Services) in a depersonalized or aggregated format."
            )}
          </p>
        </div>
      </div>
    </>
  );
};

export default PolicyContents;
