import React from "react";
import SuccessImg from "assets/FeedbackAssets/images/success.png";

const ThankYouModal = () => {
  return (
    <div className="signin_modal job_modal sucess_modal">
      <div className="modal fade show" id="feedback_success_mdl">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
              >
                <i className="material-icons-outlined">close</i>
              </button>
            </div>

            <div className="modal-body">
              <div className="login_body">
                <div className="success_mdlpic">
                  <img src={SuccessImg} alt="" />
                </div>
                <div className="congrats_text">
                  <h3>Success</h3>
                  <h5>Thanks for your valuable review.</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankYouModal;
