import React, { useEffect } from "react";

//import custom components
import Header from "common/Header";
import Banner from "components/EventsComponents/Banner";
import FeedbackMainContainer from "components/FeedbackComponents/FeedbackMainContainer";
import Footer from "common/Footer";

const Feedback = () => {
  // Setting the title name here
  useEffect(() => {
    document.title = "Feedback";
  }, []);

  return (
    <>
      <Header />
      <FeedbackMainContainer />
      <Footer />
    </>
  );
};

export default Feedback;
