/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";
import getBgImg from "assets/BlogAssets/images/get-in-touch-bg.jpg";

import { useTranslation } from "react-i18next";

const BlogGetInTouch = () => {
  const { t } = useTranslation();

  return (
    <section id="get_in_touch" className="pb-5">
      <div className="container">
        <div
          className="contact-container text-white text-center"
          style={{ backgroundImage: `url(${getBgImg})` }}
        >
          <h2 className="mb-4">
            {t("What is the professional portal of Champions ATM?")}
          </h2>
          <ul className="mb-4">
            <li>
              {t(
                "It is a habitATM that encourages communication and collaboration between users of Indra air traffic systems to generate professional growth for the user as well as benefits for the collective through both knowledge and improvements in the use of systems"
              )}
            </li>
          </ul>
          <Link
            to="#"
            onClick={() => {
              document.body.classList.add("contact_open");
            }}
            className="basic_btn ylw_btn w-auto"
          >
            {t("Contact Us")}
          </Link>
        </div>
      </div>
    </section>
  );
};

export default BlogGetInTouch;
