/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { GlobalIndraInfo } from "App";

// impoting modal here
import JoinModal from "./Modals/JoinModal";
import AddTagsModal from "./Modals/AddTagsModal";

// importing thank you modal here
import ThankYouModal from "./Modals/ThankYouModal";

/* -------------------------------------------------------------- */
// import Gateway for API fetch call
import { getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
import { t } from "i18next";
/* -------------------------------------------------------------- */

import blankLesson from "assets/CourseAssets/svg/lesson-empty.svg";

const EventDetailsBanner = () => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const params = useParams();

  // getting search params from useContext
  const {
    getEventName,
    setIsApproved,
    setIsJoined,
    afterReviewEvent,
    setAfterReviewEvent,
    setReloadParticipantsTab,
    setReloadForumTab,
    setReloadReviewTab,
  } = useContext(GlobalIndraInfo);

  //initial for champion data
  const [eventData, setEventData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isJoining, setIsJoining] = useState(false);

  // Function to get all the review data from API
  const getEventBannerData = async (id) => {
    window.scrollTo(0, 0);
    setIsLoading(true);

    let requestURL =
      url.API_BaseUrl + url.API_EVENT_BANNER + `?token=${token}` + `&id=${id}`;

    try {
      const response = await getData(requestURL);

      if (response.status) {
        if (response.data) {
          setEventData(response.data);
          getEventName(response.data.title);
          if (response.data.isjoined) {
            setIsJoined(true);
          } else {
            setIsJoined(false);
          }
          if (
            response.data.isapproved ||
            userInfo.isadmin ||
            response.data.owner.id === userInfo.id
          ) {
            setIsApproved(true);
          } else {
            setIsApproved(false);
          }
        } else {
          setEventData(null);
        }
        setIsLoading(false);
      } else {
        setEventData(null);
      }
    } catch (error) {}
  };

  // Function to join the Event using join now button
  const joinEvent = async () => {
    setIsJoining(true);
    let requestURL =
      url.API_BaseUrl +
      url.API_JOIN_EVENT +
      `?token=${token}` +
      `&eid=${params.id}`;

    try {
      const response = await getData(requestURL);

      if (response.status) {
        setIsJoining(false);
        let myModal = new bootstrap.Modal(
          document.getElementById("event_join_success_modal")
        );
        myModal.show();
      }
    } catch (error) {}
  };

  // function for calling API after closing add tag modal
  const afterModalClose = () => {
    getEventBannerData(params.id);
  };

  // function for calling API after closing thank you modal
  const afterThankYouModalClose = () => {
    getEventBannerData(params.id);
    setReloadParticipantsTab(true);
    setReloadForumTab(true);
    setReloadReviewTab(true);
  };

  // use effect for reload after submit review from review tab
  useEffect(() => {
    if (afterReviewEvent) {
      getEventBannerData(params.id);
      setAfterReviewEvent(false);
    }
  }, [afterReviewEvent]);

  // useEffect from here
  useEffect(() => {
    getEventBannerData(params.id);
  }, [params.id]);

  return (
    <>
      {isLoading ? (
        <section className="course_details_sec placeholder-glow">
          {/* ---- glow more area start ---- */}
          <div className="container">
            <div className="course_detailsBx">
              <div className="topdetails_left">
                <div className="expertholder_outer">
                  <div className="experts_holdertext flex-fill">
                    <span className="placeholder placeholder-sm rounded-2 col-6 mb-3"></span>
                    <span
                      className="placeholder rounded-3 col-8"
                      style={{ height: "26px" }}
                    ></span>
                    <div className="details_tags expert_details">
                      <span
                        className="placeholder placeholder-md rounded-2 mb-3"
                        style={{ width: "142px" }}
                      ></span>
                    </div>

                    <div className="experts_skill">
                      <ul>
                        <li>
                          <span
                            className="placeholder d-block rounded-2"
                            style={{ width: "80px", height: "30px" }}
                          ></span>
                        </li>
                        <li>
                          <span
                            className="placeholder d-block rounded-2"
                            style={{ width: "80px", height: "30px" }}
                          ></span>
                        </li>
                        <li>
                          <span
                            className="placeholder d-block rounded-2"
                            style={{ width: "80px", height: "30px" }}
                          ></span>
                        </li>
                      </ul>
                    </div>
                    <div className="vdo_link">
                      <span className="placeholder placeholder-sm rounded-2 col-4"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="topdetails_right">
                <div className="asign_top">
                  <div className="asign_outer">
                    <div className="asign_item">
                      <span
                        className="placeholder placeholder-md rounded-2 me-3"
                        style={{ width: "120px" }}
                      ></span>
                    </div>
                  </div>
                  <div className="exprt_share traning_share">
                    <ul>
                      <li>
                        <span
                          className="placeholder"
                          style={{ width: "40px", height: "40px" }}
                        ></span>
                      </li>
                      <li>
                        <span
                          className="placeholder"
                          style={{ width: "40px", height: "40px" }}
                        ></span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ---- glow more area end ---- */}
        </section>
      ) : (
        <section className="course_details_sec">
          <div className="container">
            {eventData == null || eventData.length == 0 ? (
              <div className="container">
                <div className="course_detailsBx">
                  <div className="topdetails_left">
                    <div className="expertholder_outer">
                      <div className="experts_holdertext flex-fill">
                        <span className="placeholder placeholder-sm rounded-2 col-6 mb-3"></span>
                        <span
                          className="placeholder rounded-3 col-8"
                          style={{ height: "26px" }}
                        ></span>
                        <div className="details_tags expert_details">
                          <span
                            className="placeholder placeholder-md rounded-2 mb-3"
                            style={{ width: "142px" }}
                          ></span>
                        </div>

                        <div className="experts_skill">
                          <ul>
                            <li>
                              <span
                                className="placeholder d-block rounded-2"
                                style={{ width: "80px", height: "30px" }}
                              ></span>
                            </li>
                            <li>
                              <span
                                className="placeholder d-block rounded-2"
                                style={{ width: "80px", height: "30px" }}
                              ></span>
                            </li>
                            <li>
                              <span
                                className="placeholder d-block rounded-2"
                                style={{ width: "80px", height: "30px" }}
                              ></span>
                            </li>
                          </ul>
                        </div>
                        <div className="vdo_link">
                          <span className="placeholder placeholder-sm rounded-2 col-4"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="topdetails_right">
                    <div className="asign_top">
                      <div className="asign_outer">
                        <div className="asign_item">
                          <span
                            className="placeholder placeholder-md rounded-2 me-3"
                            style={{ width: "120px" }}
                          ></span>
                        </div>
                      </div>
                      <div className="exprt_share traning_share">
                        <ul>
                          <li>
                            <span
                              className="placeholder"
                              style={{ width: "40px", height: "40px" }}
                            ></span>
                          </li>
                          <li>
                            <span
                              className="placeholder"
                              style={{ width: "40px", height: "40px" }}
                            ></span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="course_detailsBx">
                {/* --------------------------- Left Section --------------------------- */}
                <div className="topdetails_left">
                  <div className="expertholder_outer">
                    <div className="experts_holdertext">
                      {/* ---------- 'Date & Time' section ---------- */}
                      {eventData.startday == eventData.endday ? (
                        <h5>
                          {eventData.startday} {eventData.startmonth}{" "}
                          {eventData.startyear}
                        </h5>
                      ) : (
                        <h5>
                          {t("From")} {eventData.startday}{" "}
                          {eventData.startmonth} {eventData.startyear}{" "}
                          <>
                            {(eventData.endday == "" ||
                              eventData.endday == null ||
                              eventData.endday == undefined) &&
                            (eventData.endmonth == "" ||
                              eventData.endmonth == null ||
                              eventData.endmonth == undefined) &&
                            (eventData.endyear == "" ||
                              eventData.endyear == null ||
                              eventData.endyear == undefined) ? null : (
                              <>
                                {t("To")} {eventData.endday}{" "}
                                {eventData.endmonth} {eventData.endyear}
                              </>
                            )}
                          </>
                        </h5>
                      )}

                      <h3>{eventData.title}</h3>

                      {/* ---------- 'Star Rating, Location' section ---------- */}
                      <div className="details_tags expert_details">
                        <div className="jb_rating star_color">
                          {eventData.rating && (
                            <i className="material-icons-outlined">star</i>
                          )}
                          {eventData.rating && <span>{eventData.rating}</span>}
                          {eventData.reviews && (
                            <h6>
                              ({eventData.reviews}
                              <em className="mx-1">{t("reviews")}</em>)
                            </h6>
                          )}
                        </div>
                        {eventData.city && eventData.country ? (
                          <div className="course_text">
                            <i className="material-icons-outlined">place</i>
                            <h4>
                              {eventData.city},
                              <span className="mx-1">{eventData.country}</span>
                            </h4>
                          </div>
                        ) : null}
                        {eventData.members ? (
                          <div className="course_text">
                            <i className="material-icons-outlined">people</i>
                            <h4>
                              {eventData.members}
                              <span className="mx-1">{t("Enrolled")}</span>
                            </h4>
                          </div>
                        ) : null}
                      </div>

                      {/* ---------- Tags section ---------- */}
                      {eventData.category ? (
                        <div className="experts_skill">
                          {eventData.category.length > 0 ? (
                            <ul>
                              {eventData.category.map((item, index) => {
                                return (
                                  <li key={index}>
                                    <Link to="#">
                                      <span>{item}</span>
                                    </Link>
                                  </li>
                                );
                              })}
                            </ul>
                          ) : null}
                        </div>
                      ) : null}

                      {/* ---------- Video Link section ---------- */}
                      {eventData.eventurl == "" ||
                      eventData.eventurl == null ||
                      eventData.eventurl == undefined ? null : (
                        <div className="vdo_link">
                          {/* <Link to={eventData.eventurl}>
                            <i className="material-icons-outlined">
                              video_call
                            </i>
                            <span> {eventData.eventurl}</span>
                          </Link> */}
                          <a href={eventData.eventurl} target="_blank">
                            <i className="material-icons-outlined">
                              video_call
                            </i>
                            <span> {eventData.eventurl}</span>
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {/* --------------------------- Right Section --------------------------- */}
                <div className="topdetails_right d-flex flex-column align-items-end justify-content-between">
                  <div className="asign_top">
                    <div className="asign_outer">
                      <div className="asign_item">
                        {/* <h4>
                          {t("Offered By")} <span>Indra</span>
                        </h4> */}
                      </div>
                    </div>

                    <div className="exprt_share traning_share">
                      <ul>
                        <li>
                          <Link
                            to="#"
                            data-bs-toggle="modal"
                            data-bs-target="#eventtag_mdl"
                          >
                            <i className="material-icons-outlined">more</i>
                          </Link>
                        </li>
                        {/* <li>
                          <Link to="#">
                            <i className="material-icons-outlined">share</i>
                          </Link>
                        </li> */}
                      </ul>
                    </div>
                  </div>

                  {/* --------- Tags list and dropdown start --------- */}
                  <div className="tags_menu">
                    {/* -------- Top 3 tags here -------- */}
                    {eventData.tags.length > 0 ? (
                      <ul className="tags_list">
                        {eventData.tags.slice(0, 3).map((tags, index) => {
                          return (
                            <li key={index}>
                              <Link to="#">{tags.label}</Link>
                            </li>
                          );
                        })}
                      </ul>
                    ) : null}

                    {/* -------- Remaining tags here in dropdown --------- */}
                    {eventData.tags.length > 3 ? (
                      <div className="tags_drop dropdown">
                        <Link to="#" data-bs-toggle="dropdown">
                          <span className="material-icons-outlined">
                            expand_more
                          </span>
                        </Link>
                        {eventData.tags.length > 0 ? (
                          <ul className="dropdown-menu">
                            {eventData.tags
                              .slice(3, eventData.tags.length)
                              .map((tags, index) => {
                                return (
                                  <li key={index}>
                                    <Link to="#">{tags.label}</Link>
                                  </li>
                                );
                              })}
                          </ul>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                  {/* --------- Tags list and dropdown end --------- */}

                  {/* ----- Display approval date and join date by condition ----- */}
                  {eventData.owner.id === userInfo.id ? (
                    <p className="text-end text-white join-event-request">
                      {t("Creating Date")} : {eventData.joindate} <br />
                      {eventData.myroles.length == 0 ||
                      eventData.myroles == null ||
                      eventData.myroles == undefined ? null : (
                        <>
                          {t("Roles")}{" "}
                          <span>
                            {eventData.myroles.map((role, index) => {
                              return <span key={index}>|| {role} </span>;
                            })}
                          </span>
                        </>
                      )}
                    </p>
                  ) : (
                    <>
                      {userInfo.isadmin ? (
                        <p className="text-end text-white join-event-request">
                          {t("Roles")}
                          <span> ||Admin</span>
                        </p>
                      ) : (
                        <>
                          {eventData.isapproved ? (
                            <p className="text-end text-white join-event-request">
                              {t("Joining Date")} : {eventData.joindate} <br />
                              {eventData.myroles.length == 0 ||
                              eventData.myroles == null ||
                              eventData.myroles == undefined ? null : (
                                <>
                                  {t("Roles")}{" "}
                                  <span>
                                    {eventData.myroles.map((role, index) => {
                                      return (
                                        <span key={index}>|| {role} </span>
                                      );
                                    })}
                                  </span>
                                </>
                              )}
                            </p>
                          ) : eventData.moderatorstatus == 1 ||
                            eventData.memberstatus == 1 ? (
                            <p className="text-end text-white join-event-request">
                              <i className="material-icons me-2">error</i>
                              {eventData.moderatorstatus == 0
                                ? `${t("Moderator Response Pending")}`
                                : `${t("Join invitation from Moderator")}`}{" "}
                              <br />
                              {t("Request Date")} : {eventData.joindate}
                            </p>
                          ) : null}
                        </>
                      )}
                    </>
                  )}

                  {eventData.isjoined ||
                  eventData.owner.id == userInfo.id ||
                  userInfo.isadmin ? null : (
                    <Link
                      to="#"
                      className="basic_btn ylw_btn w-auto mt-1"
                      onClick={joinEvent}
                      style={{ pointerEvents: isJoining ? "none" : "" }}
                    >
                      {t("Join Now")}
                      {isJoining && (
                        <div
                          className="mx-2 spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      )}
                    </Link>
                  )}
                </div>
              </div>
            )}
          </div>
        </section>
      )}

      {/* --------- Join modal imported here --------- */}
      {/* <JoinModal /> */}
      <AddTagsModal afterModalClose={afterModalClose} />
      <ThankYouModal afterThankYouModalClose={afterThankYouModalClose} />
    </>
  );
};

export default EventDetailsBanner;
