import React from "react";

const ViewGalleryImageModal = ({ viewImageUrl }) => {
  return (
    <div className="job_modal">
      <div
        className="modal fade"
        id="view_group_gallery_image_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            {/* ----------- Modal header starts here ----------- */}
            <div className="modal-header">
              <div className="company_logotxt"></div>
              {/* <div className="modal_hdng">
                <h3>Image</h3>
              </div> */}
              {/* <div className="job_tags">
                  <ul>
                    <li>
                      <i className="material-icons-outlined"> place </i>
                      <span>
                        {props.joblocation != null ? props.joblocation : ""}
                      </span>
                    </li>
                    <li>
                      <i className="material-icons-outlined"> event_note </i>
                      <span>{props.jobdate != null ? props.jobdate : ""}</span>
                    </li>
                    <li>
                      <i className="material-icons-outlined"> schedule </i>
                      <span>{props.jobtype != null ? props.jobtype : ""}</span>
                    </li>
                  </ul>
                </div> */}
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="material-icons-outlined"> close </i>
              </button>
            </div>
            {/* ----------- Modal header ends here ----------- */}

            {/* ----------- Modal body starts here ----------- */}
            <div className="modal-body">
              {viewImageUrl ? (
                <div>
                  <img
                    src={viewImageUrl}
                    alt="User"
                    className="img-fluid w-100 h-100 avatar"
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewGalleryImageModal;
