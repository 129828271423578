/* eslint-disable */
import React from "react";

const ContactFormRightPanel = () => {
  return (
    <div className="col-md-6 pb-5 pb-md-0">
      <div className="map-container top-0 end-0 bottom-0 ps-md-4">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d200193.390055324!2d-3.913091043972401!3d40.387288864161896!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd422997800a3c81%3A0xc436dec1618c2269!2sMadrid%2C%20Spain!5e0!3m2!1sen!2sin!4v1664207348969!5m2!1sen!2sin"
          width="100%"
          height="100%"
          style={{ border: "0" }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default ContactFormRightPanel;
