import React, { useEffect } from "react";

// importing custom components here
import Header from "common/Header";
import Banner from "components/BlogComponents/Banner";
import BlogListArea from "components/BlogComponents/BlogListArea";
import Footer from "common/Footer";
import BlogGetInTouch from "components/BlogComponents/BlogGetInTouch";

const Blog = () => {
  useEffect(() => {
    document.title = "Blogs";
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <Banner />
      <BlogListArea />
      <BlogGetInTouch />
      <Footer />
    </>
  );
};

export default Blog;
