/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { postData } from "utils/Gateway";
import * as url from "helpers/url_helper";

const ConfirmModal = (props) => {
  const token = localStorage.getItem("token");

  const code1Ref = useRef(null);
  const code2Ref = useRef(null);
  const code3Ref = useRef(null);
  const code4Ref = useRef(null);

  // initial data for validate
  const [email, setEmail] = useState("user@indra.com");
  const [code, setCode] = useState(0);
  const [codeWarning, setCodeWarning] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [firstCode, setFirstCode] = useState("");
  const [secondCode, setSecondCode] = useState("");
  const [thirdCode, setThirdCode] = useState("");
  const [fourthCode, setFourthCode] = useState("");

  const { t } = useTranslation(); //for translation
  const history = useHistory();

  //function for validate user
  const validateCodeHandler = async () => {
    if (
      firstCode == "" ||
      secondCode == "" ||
      thirdCode == "" ||
      fourthCode == ""
    ) {
      setCodeWarning(true);
    } else {
      setCodeWarning(false);
      let finalCode = firstCode + secondCode + thirdCode + fourthCode;

      try {
        setIsValidating(true);
        let userData = {
          email: email,
          code: finalCode,
        };

        let requestUrl = url.API_BaseUrl + url.API_VALIDATE_USER;

        let result = await postData(requestUrl, userData);
        if (result.status) {
          let validateModal = document.querySelector("#register_success_modal");
          let modal = bootstrap.Modal.getInstance(validateModal);
          modal.hide();
          resetAll();
          if (result.authdata.adminstatus) {
            // assigning the received values to the browser's local storage.
            localStorage.setItem("token", result.authdata.token);

            localStorage.setItem("userInfo", JSON.stringify(result.authdata));

            // assigning the loggedin user's id to the browser's local storage.
            localStorage.setItem("userID", JSON.stringify(result.authdata.id));
          } else {
            let myModal = new bootstrap.Modal(
              document.getElementById("contact_admin_modal")
            );
            myModal.show();
          }
          setIsValidating(false);
        }
      } catch (error) {
        throw error;
      }
    }
  };

  //function for reset all date
  const resetAll = () => {
    setEmail("user@indra.com");
    setCode(0);
    setFirstCode("");
    setSecondCode("");
    setThirdCode("");
    setFourthCode("");
  };

  // function for backspace reference fourth digit
  const fourthCodeKey = (e) => {
    if (e.key == "Backspace") {
      if (fourthCode == "" && fourthCode.length == 0) {
        code3Ref.current?.focus();
      }
    }
  };

  // function for backspace reference third digit
  const thirdCodeKey = (e) => {
    if (e.key == "Backspace") {
      if (thirdCode == "" && thirdCode.length == 0) {
        code2Ref.current?.focus();
      }
    }
  };

  // function for backspace reference second digit
  const secondCodeKey = (e) => {
    if (e.key == "Backspace") {
      if (secondCode == "" && secondCode.length == 0) {
        code1Ref.current?.focus();
      }
    }
  };

  // useEffect for cheking the login token available or not with refferel url
  useEffect(() => {
    if (token) {
      if (document.referrer == "" || document.referrer == null) {
        history.push("/");
      } else {
        window.location.href = document.referrer;
      }
    }
  }, [token]);

  // use effect for checking data coming from props
  useEffect(() => {
    if (props) {
      setEmail(props.userSignUpEmail);
      // if (props.confirmCode == 0 || props.confirmCode.length < 4) {
      //   return;
      // } else {
      //   let userCode = props.confirmCode;
      //   let digitCode = userCode.toString().split("");
      //   let codeArr = digitCode.map(Number);
      //   setFirstCode(codeArr[0].toString());
      //   setSecondCode(codeArr[1].toString());
      //   setThirdCode(codeArr[2].toString());
      //   setFourthCode(codeArr[3].toString());
      // }
    }
  }, [props]);

  // use effect for shift second input
  useEffect(() => {
    if (firstCode !== "" && firstCode.length == 1) {
      code2Ref.current?.focus();
    }
  }, [firstCode]);

  // use effect for shift third input
  useEffect(() => {
    if (secondCode !== "" && secondCode.length == 1) {
      code3Ref.current?.focus();
    }
  }, [secondCode]);

  // use effect for shift fourth input
  useEffect(() => {
    if (thirdCode !== "" && thirdCode.length == 1) {
      code4Ref.current?.focus();
    }
  }, [thirdCode]);

  return (
    <div className="question_modal_bx">
      <div
        className="modal fade"
        id="register_success_modal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="sucess_question_icon">
                <span className="material-icons-outlined">email</span>
              </div>
              <div className="question_success_hdng">
                {/* ---- modal heading ---- */}
                <h2>
                  {t("Thank you for registration. Please validate yourself.")}
                </h2>
                {/* ---- modal heading end ---- */}

                {/* ---- email section ---- */}
                <div className="input_fill">
                  <input
                    type="email"
                    className="registration_code form-control mb-2"
                    placeholder={email}
                    value={email}
                    readOnly
                  />
                </div>
                {/* ---- email section end ---- */}

                {/* ---- code input area ---- */}
                <div className="validate_fillOuter">
                  <div className="input_fill validate_fill">
                    <input
                      type="number"
                      ref={code1Ref}
                      value={firstCode}
                      onChange={(e) => {
                        setFirstCode(
                          e.target.value.slice(0, e.target.maxLength)
                        );
                      }}
                      className="form-control"
                      placeholder="--"
                      maxLength="1"
                    />
                  </div>
                  <div className="input_fill validate_fill">
                    <input
                      type="number"
                      ref={code2Ref}
                      value={secondCode}
                      onChange={(e) => {
                        setSecondCode(
                          e.target.value.slice(0, e.target.maxLength)
                        );
                      }}
                      onKeyDown={secondCodeKey}
                      className="form-control"
                      placeholder="--"
                      maxLength="1"
                    />
                  </div>
                  <div className="input_fill validate_fill">
                    <input
                      type="number"
                      ref={code3Ref}
                      value={thirdCode}
                      onChange={(e) => {
                        setThirdCode(
                          e.target.value.slice(0, e.target.maxLength)
                        );
                      }}
                      onKeyDown={thirdCodeKey}
                      className="form-control"
                      placeholder="--"
                      maxLength="1"
                    />
                  </div>
                  <div className="input_fill validate_fill">
                    <input
                      type="number"
                      ref={code4Ref}
                      value={fourthCode}
                      onChange={(e) =>
                        setFourthCode(
                          e.target.value.slice(0, e.target.maxLength)
                        )
                      }
                      onKeyDown={fourthCodeKey}
                      className="form-control"
                      placeholder="--"
                      maxLength="1"
                    />
                  </div>
                </div>
                {codeWarning && (
                  <p className="text-danger">*{t("Enter code")}</p>
                )}
                {/* <input
                  type="text"
                  className="registration_code form-control"
                  placeholder={
                    props.confirmCode == null || props.confirmCode == undefined
                      ? t("Confirmation code")
                      : props.confirmCode
                  }
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                />
                {codeWarning && (
                  <p className="text-danger">*{t("Enter code")}</p>
                )} */}
              </div>

              {/* ----- button section ----- */}
              <div className="group_btnOuter">
                <ul>
                  <li className="w_100">
                    <button
                      className="basic_btn ylw_btn"
                      role="button"
                      onClick={validateCodeHandler}
                      disabled={isValidating ? true : false}
                      style={{
                        cursor: isValidating ? "not-allowed" : "pointer",
                      }}
                    >
                      {isValidating && (
                        <div
                          className="mx-2 spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      )}
                      <span>{t("Validate")}</span>
                    </button>
                  </li>
                  <li className="w_100">
                    <Link
                      to="#"
                      data-bs-dismiss="modal"
                      className="red_btn"
                      onClick={resetAll}
                    >
                      <span>{t("Cancel")}</span>
                    </Link>
                  </li>
                </ul>
              </div>
              {/* ----- button section end ----- */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
