/* eslint-disable */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

// importing custom components here
import About from "components/CourseTrainingDetailsComponent/Tabs/About";
import TrainingCourses from "components/CourseTrainingDetailsComponent/Tabs/TrainingCourses";
import TrainingTeam from "components/CourseTrainingDetailsComponent/Tabs/TrainingTeam";
import TrainingReview from "components/CourseTrainingDetailsComponent/Tabs/TrainingReview";
import TrainingCertificate from "components/CourseTrainingDetailsComponent/Tabs/TrainingCertificate";

const CourseTrainingDetailsBottomSection = () => {
  // accessing the user's token here
  const token = localStorage.getItem("token");

  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();

  /*----- initial state for toggling tabs ------*/
  const [aboutTab, setAboutTab] = useState(true);
  const [trainingCoursesTab, setTrainingCoursesTab] = useState(false);
  const [teamTab, setTeamTab] = useState(false);
  const [trainingReviewTab, setTrainingReviewTab] = useState(false);
  const [certificationTab, setCertificationTab] = useState(false);
  /*----- end of initial state for toggling tabs ------*/

  // funtion for display contents tab
  const showAboutTab = (e) => {
    e.preventDefault();
    setAboutTab(true);
    setTrainingCoursesTab(false);
    setTeamTab(false);
    setTrainingReviewTab(false);
    setCertificationTab(false);
  };

  const showTrainingCoursesTab = (e) => {
    e.preventDefault();
    setAboutTab(false);
    setTrainingCoursesTab(true);
    setTeamTab(false);
    setTrainingReviewTab(false);
    setCertificationTab(false);
  };

  const showTeamTab = (e) => {
    e.preventDefault();
    setAboutTab(false);
    setTrainingCoursesTab(false);
    setTeamTab(true);
    setTrainingReviewTab(false);
    setCertificationTab(false);
  };

  const showTrainingReviewTab = (e) => {
    e.preventDefault();
    setAboutTab(false);
    setTrainingCoursesTab(false);
    setTeamTab(false);
    setTrainingReviewTab(true);
    setCertificationTab(false);
  };

  const showCertificationTab = (e) => {
    e.preventDefault();
    setAboutTab(false);
    setTrainingCoursesTab(false);
    setTeamTab(false);
    setTrainingReviewTab(false);
    setCertificationTab(true);
  };

  return (
    <>
      <section className="indra_course_tabArea">
        {/* --------------- Bottom section header starts here --------------- */}
        <div className="course_tablinks_outer">
          <div className="container">
            <div className="tab_links">
              <ul className="nav nav-tabs">
                {/* -------------- About Link from here -------------- */}
                <li className="nav-item">
                  <Link
                    to="#"
                    className={
                      aboutTab == true ? "nav-link active show" : "nav-link"
                    }
                    onClick={(e) => showAboutTab(e)}
                  >
                    {t("About")}
                  </Link>
                </li>

                {/* -------------- Courses Link from here -------------- */}
                <li className="nav-item">
                  <Link
                    to="#"
                    className={
                      trainingCoursesTab == true
                        ? "nav-link active show"
                        : "nav-link"
                    }
                    onClick={(e) => showTrainingCoursesTab(e)}
                  >
                    {t("Courses")}
                  </Link>
                </li>

                {/* -------------- Team Link from here -------------- */}
                <li className="nav-item">
                  <Link
                    to="#"
                    className={
                      teamTab == true ? "nav-link active show" : "nav-link"
                    }
                    onClick={(e) => showTeamTab(e)}
                  >
                    {t("Teams")}
                  </Link>
                </li>

                {/* -------------- Reviews Link from here -------------- */}
                <li className="nav-item">
                  <Link
                    to="#"
                    className={
                      trainingReviewTab == true
                        ? "nav-link active show"
                        : "nav-link"
                    }
                    onClick={(e) => showTrainingReviewTab(e)}
                  >
                    {t("Reviews")}
                  </Link>
                </li>

                {/* -------------- Certificate Link from here -------------- */}
                <li className="nav-item">
                  <Link
                    to="#"
                    className={
                      certificationTab == true
                        ? "nav-link active show"
                        : "nav-link"
                    }
                    onClick={(e) => showCertificationTab(e)}
                  >
                    {t("Certification")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* --------------- Bottom section header ends here --------------- */}

        {/* --------------- Bottom section body starts here --------------- */}
        <div className="tab_bodyArea">
          <div className="container">
            <div className="tab-content">
              {/* --------------- About starts here --------------- */}
              {aboutTab && <About />}
              {/* --------------- About ends here --------------- */}

              {/* --------------- Course starts here --------------- */}
              {trainingCoursesTab && <TrainingCourses />}
              {/* --------------- Course ends here --------------- */}

              {/* --------------- Team starts here --------------- */}
              {teamTab && <TrainingTeam />}
              {/* --------------- Team ends here --------------- */}

              {/* --------------- Review starts here --------------- */}
              {trainingReviewTab && <TrainingReview />}
              {/* --------------- Review ends here --------------- */}

              {/* --------------- Certificate starts here --------------- */}
              {certificationTab && <TrainingCertificate />}
              {/* --------------- Certificate ends here --------------- */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CourseTrainingDetailsBottomSection;
