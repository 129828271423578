import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// importing custom components here
import About from "./Tabs/About";
import EventGallery from "./Tabs/EventGallery";
import EventProduct from "./Tabs/EventProduct";
import Forum from "./Tabs/Forum";
import Meeting from "./Tabs/Meeting";
import Participants from "./Tabs/Participants";
import Review from "./Tabs/Review";

const EventDetailsTabs = () => {
  const { t } = useTranslation();

  /*----- initial state for toggling tabs ------*/
  const [aboutTab, setAboutTab] = useState(true);
  const [productsTab, setProductsTab] = useState(false);
  const [galleryTab, setGalleryTab] = useState(false);
  const [participantsTab, setParticipantsTab] = useState(false);
  const [forumTab, setForumTab] = useState(false);
  const [meetingTab, setMeetingTab] = useState(false);
  const [reviewTab, setReviewTab] = useState(false);
  /*----- end of initial state for toggling tabs ------*/

  // function to display the tabs
  const showAboutTab = (e) => {
    e.preventDefault();
    setAboutTab(true);
    setProductsTab(false);
    setGalleryTab(false);
    setParticipantsTab(false);
    setForumTab(false);
    setMeetingTab(false);
    setReviewTab(false);
  };

  const showProductsTab = (e) => {
    e.preventDefault();
    setAboutTab(false);
    setProductsTab(true);
    setGalleryTab(false);
    setParticipantsTab(false);
    setForumTab(false);
    setMeetingTab(false);
    setReviewTab(false);
  };

  const showGalleryTab = (e) => {
    e.preventDefault();
    setAboutTab(false);
    setProductsTab(false);
    setGalleryTab(true);
    setParticipantsTab(false);
    setForumTab(false);
    setMeetingTab(false);
    setReviewTab(false);
  };

  const showParticipantsTab = (e) => {
    e.preventDefault();
    setAboutTab(false);
    setProductsTab(false);
    setGalleryTab(false);
    setParticipantsTab(true);
    setForumTab(false);
    setMeetingTab(false);
    setReviewTab(false);
  };

  const showForumTab = (e) => {
    e.preventDefault();
    setAboutTab(false);
    setProductsTab(false);
    setGalleryTab(false);
    setParticipantsTab(false);
    setForumTab(true);
    setMeetingTab(false);
    setReviewTab(false);
  };

  const showMeetingTab = (e) => {
    e.preventDefault();
    setAboutTab(false);
    setProductsTab(false);
    setGalleryTab(false);
    setParticipantsTab(false);
    setForumTab(false);
    setMeetingTab(true);
    setReviewTab(false);
  };

  const showReviewTab = (e) => {
    e.preventDefault();
    setAboutTab(false);
    setProductsTab(false);
    setGalleryTab(false);
    setParticipantsTab(false);
    setForumTab(false);
    setMeetingTab(false);
    setReviewTab(true);
  };

  // return starts here
  return (
    <section className="indra_course_tabArea">
      {/* ----- tab selection start ----- */}
      <div className="course_tablinks_outer">
        <div className="container">
          <div className="tab_links">
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <Link
                  className={
                    aboutTab == true ? "nav-link active show" : "nav-link"
                  }
                  to="#"
                  onClick={(e) => showAboutTab(e)}
                >
                  {t("About")}
                </Link>
              </li>

              {/* <li className="nav-item">
                <Link
                  className={
                    productsTab == true ? "nav-link active show" : "nav-link"
                  }
                  to="#"
                  onClick={(e) => showProductsTab(e)}
                >
                  Products
                </Link>
              </li> */}

              <li className="nav-item">
                <Link
                  className={
                    galleryTab == true ? "nav-link active show" : "nav-link"
                  }
                  to="#"
                  onClick={(e) => showGalleryTab(e)}
                >
                  {t("Gallery")}
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  className={
                    participantsTab == true
                      ? "nav-link active show"
                      : "nav-link"
                  }
                  to="#"
                  onClick={(e) => showParticipantsTab(e)}
                >
                  {t("Participants")}
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  className={
                    forumTab == true ? "nav-link active show" : "nav-link"
                  }
                  to="#"
                  onClick={(e) => showForumTab(e)}
                >
                  {t("Forum")}
                </Link>
              </li>

              {/* <li className="nav-item">
                <Link
                  className={
                    meetingTab == true ? "nav-link active show" : "nav-link"
                  }
                  to="#"
                  onClick={(e) => showMeetingTab(e)}
                >
                  Meeting
                </Link>
              </li> */}

              <li className="nav-item">
                <Link
                  className={
                    reviewTab == true ? "nav-link active show" : "nav-link"
                  }
                  to="#"
                  onClick={(e) => showReviewTab(e)}
                >
                  {t("Review")}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* ----- tab selcetion end ----- */}

      {/* ----- selected tab area ----- */}
      <div className="tab_bodyArea">
        <div className="container">
          <div className="tab-content">
            {/* ------ About tab area ----- */}
            <div className="tab-pane show active fade" id="evnt_about">
              {aboutTab && <About />}
            </div>
            {/* ------ About tab area end ----- */}

            {/* ------ event product tab details area ------ */}
            {/* <div className="tab-pane show active fade" id="evnt_prdct">
              {productsTab && <EventProduct />}
            </div> */}
            {/* ------ end of event product tab details area ------ */}

            {/* --------- event gallary tab details area ------  */}
            <div className="tab-pane show active fade" id="evnt_gallery">
              {galleryTab && <EventGallery />}
            </div>
            {/* --------- event gallary tab details area end ------  */}

            {/* --------- event Participants tab details area  ------  */}
            <div className="tab-pane show active fade" id="evnt_participnt">
              {participantsTab && <Participants />}
            </div>
            {/* --------- event Participants tab details area end ------  */}

            {/* --------- event Forum tab details area  ------  */}
            <div className="tab-pane show active fade" id="forum">
              {forumTab && <Forum />}
            </div>
            {/* --------- event Forum tab details area end ------  */}

            {/* --------- event Meeting tab details area  ------  */}
            {/* <div className="tab-pane show active fade" id="evnt_meeting">
              {meetingTab && <Meeting />}
            </div> */}
            {/* --------- event Meeting tab details area end ------  */}

            {/* --------- event Review tab details area  ------  */}
            <div className="tab-pane show active fade" id="evnt_review">
              {reviewTab && <Review />}
            </div>
            {/* --------- event Review tab details area end ------  */}
          </div>
        </div>
      </div>
      {/* ----- selected tab area end ----- */}
    </section>
  );
};

export default EventDetailsTabs;
