/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// importing context API here
import { GlobalIndraInfo } from "App";

// import images here
import defaultUser from "assets/ConversationAssests/images/default-user.png";
import blankLesson from "assets/CourseAssets/svg/lesson-empty.svg";
/* -------------------------------------------------------------- */
// import Gateway for API fetch call
import { getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* -------------------------------------------------------------- */

const Participants = () => {
  const token = localStorage.getItem("token");
  const params = useParams();

  const { reloadParticipantsTab, setReloadParticipantsTab } =
    useContext(GlobalIndraInfo); //import context files

  //initial useState for event details about data
  const [eventParticipantsData, setEventParticipantsData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [participantsData, setParticipantsData] = useState([]);
  const [guestMembers, setGuestMembers] = useState([]);
  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  // Function to get all the about data from API
  const getEventParticipantsData = async (id) => {
    setIsLoading(true);

    let requestURL =
      url.API_BaseUrl +
      url.API_EVENT_PARTICIPANTS +
      `?token=${token}` +
      `&id=${id}`;

    try {
      const response = await getData(requestURL);

      if (response.status) {
        if (response.data) {
          setEventParticipantsData(response.data);
          if (
            response.data.length === 0 ||
            response.data.length !== undefined
          ) {
            setParticipantsData([]);
          } else {
            let memberResult;
            const mergeArrays = (avalaibleRolesArray, membersByRoleArray) => {
              return avalaibleRolesArray.map((obj) => {
                const membersFilter = membersByRoleArray.filter(
                  (memberElements) => memberElements["roleid"] === obj["id"]
                );

                if (!membersFilter.length) {
                  obj.members = membersFilter;
                  return obj;
                }
                obj.member = membersFilter.map((memberlists) => ({
                  member: memberlists.member,
                }));
                return obj;
              });
            };

            memberResult = mergeArrays(
              response.data.availableroles,
              response.data.membersbyrole
            );
            setParticipantsData(memberResult);
          }

          if (response.data.othermembers) {
            setGuestMembers(response.data.othermembers);
          } else {
            setGuestMembers([]);
          }
        } else {
          setEventParticipantsData(null);
        }
        setIsLoading(false);
      } else {
        setEventParticipantsData(null);
      }
    } catch (error) {}
  };

  // reload this tab after join event
  useEffect(() => {
    if (reloadParticipantsTab) {
      getEventParticipantsData(params.id);
      setReloadParticipantsTab(false);
    }
  }, [reloadParticipantsTab]);

  // useEffect from here
  useEffect(() => {
    getEventParticipantsData(params.id);
  }, [params.id]);

  return (
    <>
      {isLoading ? (
        <div className="course_detailspnl">
          {/* --------------- Glow placeholder starts here --------------- */}
          <div className="member_box_outer">
            <div className="contnt_hdng tab_Hdng">
              <span
                className="placeholder rounded-3 col-2"
                style={{ height: "22px" }}
              ></span>
            </div>
            <div className="member_box_outer">
              <div className="row">
                <div className="col-lg-3 col-sm-6">
                  <div
                    className="expertbx_item members_teamItem"
                    style={{ minHeight: "115px" }}
                  >
                    <div className="membrs_teamOuter">
                      <div className="team_membrs_pic">
                        <span
                          className="placeholder rounded-circle"
                          style={{ width: "40px", height: "40px" }}
                        ></span>
                      </div>
                      <div className="membrs_team_details flex-fill">
                        <div className="team_name">
                          <span className="placeholder placeholder-md rounded-2 col-8 mb-2"></span>
                          <span className="placeholder placeholder-sm rounded-2 col-6"></span>
                        </div>
                        <span className="placeholder placeholder-sm rounded-2 col-4"></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div
                    className="expertbx_item members_teamItem"
                    style={{ minHeight: "115px" }}
                  >
                    <div className="membrs_teamOuter">
                      <div className="team_membrs_pic">
                        <span
                          className="placeholder rounded-circle"
                          style={{ width: "40px", height: "40px" }}
                        ></span>
                      </div>
                      <div className="membrs_team_details flex-fill">
                        <div className="team_name">
                          <span className="placeholder placeholder-md rounded-2 col-8 mb-2"></span>
                          <span className="placeholder placeholder-sm rounded-2 col-6"></span>
                        </div>
                        <span className="placeholder placeholder-sm rounded-2 col-4"></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div
                    className="expertbx_item members_teamItem"
                    style={{ minHeight: "115px" }}
                  >
                    <div className="membrs_teamOuter">
                      <div className="team_membrs_pic">
                        <span
                          className="placeholder rounded-circle"
                          style={{ width: "40px", height: "40px" }}
                        ></span>
                      </div>
                      <div className="membrs_team_details flex-fill">
                        <div className="team_name">
                          <span className="placeholder placeholder-md rounded-2 col-8 mb-2"></span>
                          <span className="placeholder placeholder-sm rounded-2 col-6"></span>
                        </div>
                        <span className="placeholder placeholder-sm rounded-2 col-4"></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div
                    className="expertbx_item members_teamItem"
                    style={{ minHeight: "115px" }}
                  >
                    <div className="membrs_teamOuter">
                      <div className="team_membrs_pic">
                        <span
                          className="placeholder rounded-circle"
                          style={{ width: "40px", height: "40px" }}
                        ></span>
                      </div>
                      <div className="membrs_team_details flex-fill">
                        <div className="team_name">
                          <span className="placeholder placeholder-md rounded-2 col-8 mb-2"></span>
                          <span className="placeholder placeholder-sm rounded-2 col-6"></span>
                        </div>
                        <span className="placeholder placeholder-sm rounded-2 col-4"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* --------------- Glow placeholder ends here --------------- */}
        </div>
      ) : (
        <div className="course_detailspnl">
          {/* --------------- Contents starts here --------------- */}
          {eventParticipantsData == null ||
          eventParticipantsData.length == 0 ||
          (participantsData.length === 0 && guestMembers.length === 0) ? (
            <div className="empty_lessonsArea">
              <div className="empty_lessonpic">
                <img src={blankLesson} alt="blanklesson" />
              </div>
              <div className="empty_lessons_text">
                <h2>{t("Sorry....!")}</h2>
                <p>{t("No participants available for this event.")}</p>
              </div>
            </div>
          ) : (
            <div>
              {participantsData.length > 0 && (
                <div className="member_box_outer">
                  {participantsData.map((role, index) => {
                    return (
                      <div className="row" key={index}>
                        <div className="contnt_hdng tab_Hdng">
                          <h4>{role.label}</h4>
                        </div>

                        {role.member.map((memberLists, index2) => {
                          return (
                            <div className="col-lg-3 col-sm-6" key={index2}>
                              <div className="expertbx_item members_teamItem">
                                <div className="membrs_teamOuter">
                                  <div className="team_membrs_pic">
                                    <img
                                      src={
                                        memberLists.member.fullimage
                                          ? memberLists.member.fullimage
                                          : defaultUser
                                      }
                                      alt="memberPic"
                                    />
                                  </div>
                                  <div className="membrs_team_details">
                                    <div className="team_name">
                                      <h3>{memberLists.member.name}</h3>
                                      {memberLists.member.position ? (
                                        <h5>{memberLists.member.position}</h5>
                                      ) : null}
                                      {memberLists.member.roles.map(
                                        (pos, index3) => {
                                          return (
                                            <h5 className="mt-1" key={index3}>
                                              {pos}
                                            </h5>
                                          );
                                        }
                                      )}
                                    </div>

                                    <div className="team_rating star_color ">
                                      <h3>
                                        {" "}
                                        <i className="material-icons-outlined">
                                          star
                                        </i>{" "}
                                        <span>{memberLists.member.rating}</span>
                                      </h3>

                                      <h6>
                                        {memberLists.member.reviews}
                                        <span>({t("reviews")})</span>
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              )}
              {guestMembers.length === 0 ? null : (
                <div className="member_box_outer">
                  <div className="row">
                    <div className="contnt_hdng tab_Hdng">
                      <h4>{t("Guest members")}</h4>
                    </div>

                    {guestMembers.map((memberLists, index2) => {
                      return (
                        <div className="col-lg-3 col-sm-6" key={index2}>
                          <div className="expertbx_item members_teamItem">
                            <div className="membrs_teamOuter">
                              <div className="team_membrs_pic">
                                <img
                                  src={
                                    memberLists.fullimage
                                      ? memberLists.fullimage
                                      : defaultUser
                                  }
                                  alt="memberPic"
                                />
                              </div>
                              <div className="membrs_team_details">
                                <div className="team_name">
                                  <h3>{memberLists.name}</h3>
                                  {memberLists.position ? (
                                    <h5>{memberLists.position}</h5>
                                  ) : null}
                                  {memberLists.roles.map((pos, index3) => {
                                    return (
                                      <h5 className="mt-1" key={index3}>
                                        {pos}
                                      </h5>
                                    );
                                  })}
                                </div>

                                <div className="team_rating star_color ">
                                  <h3>
                                    {" "}
                                    <i className="material-icons-outlined">
                                      star
                                    </i>{" "}
                                    <span>{memberLists.rating}</span>
                                  </h3>

                                  <h6>
                                    {memberLists.reviews}
                                    <span>({t("reviews")})</span>
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          )}
          {/* --------------- Contents ends here --------------- */}
        </div>
      )}
    </>
  );
};

export default Participants;
