/* eslint-disable */
import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
// import Gateway for API fetch call
import { getData } from "utils/Gateway";
// importing context API here
import { GlobalIndraInfo } from "App";
import { useTranslation } from "react-i18next";

// import all URLs from url_helper
import * as url from "helpers/url_helper";

const MarkCompleteModal = (props) => {
  const token = localStorage.getItem("token");

  const { t, i18n } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const { completeLesson } = useContext(GlobalIndraInfo);

  // function for complete lesson
  const markCompleteHandler = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    let requestURL =
      url.API_BaseUrl +
      url.API_LESSON_COMPLETE +
      `?token=${token}` +
      `&lid=${props.lessonId}`;

    try {
      const response = await getData(requestURL);

      if (response.status) {
        let completeLessonModal = document.querySelector(
          "#complete_success_modal"
        );
        let modal = bootstrap.Modal.getInstance(completeLessonModal);
        modal.hide();
        completeLesson(true);
      } else {
        completeLesson(false);
      }
      setIsLoading(false);
    } catch (error) {}
  };

  return (
    <div className="question_modal_bx">
      <div
        className="modal fade"
        id="complete_success_modal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="sucess_question_icon">
                <span className="material-icons-outlined">task_alt</span>
              </div>
              <div className="question_success_hdng">
                <h2>{t("have you finished this lesson?")}</h2>

                <h5>{props.modalAdminMessage}</h5>
              </div>
              <div className="group_btnOuter">
                <ul>
                  <li
                    className="w_100"
                    style={{ cursor: isLoading ? "not-allowed" : "" }}
                  >
                    <Link
                      to="#"
                      className="ornage_btn"
                      role="button"
                      onClick={markCompleteHandler}
                      style={{
                        pointerEvents: isLoading ? "none" : "",
                      }}
                    >
                      <span>{t("Complete")}</span>
                      {isLoading && (
                        <div
                          className="mx-2 spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span className="visually-hidden">
                            {t("Loading...")}
                          </span>
                        </div>
                      )}
                    </Link>
                  </li>
                  <li className="w_100">
                    <Link
                      to="#"
                      className="red_btn"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <span>{t("Cancel")}</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarkCompleteModal;
